import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { COLOR, FONT } from '../../../theme'

import type { PropsWithChildren } from 'react'

interface Props {
  title: string
  subtitle?: string
}

export default function ProjectDashboardItem(props: PropsWithChildren<Props>) {
  const { title, subtitle, children } = props

  return (
    <Flex direction="column" gap={24}>
      <Flex direction="column" gap={6}>
        <Text
          tag="h2"
          size={FONT.size.xl}
          weight={FONT.weight.semibold}
          color={COLOR.neutral.darkest}
        >
          {title}
        </Text>
        {subtitle && (
          <Text tag="p" color={COLOR.neutral.dark}>
            {subtitle}
          </Text>
        )}
      </Flex>
      {children}
    </Flex>
  )
}
