import { Capacitor } from '@capacitor/core'

const success = function (message: string) {
  console.log('success', message)
}
const failure = function (message: string) {
  console.log('failure', message)
}

declare let ClarityPlugin: any
if (Capacitor.getPlatform() === 'android') {
  ClarityPlugin.initialize('lgk5fqc2ys', success, failure, { isIonic: true })
}
