import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { flexGapObject } from '../../../utils/css'

import type { ReactNode } from 'react'

interface ButtonGroupProps {
  children: ReactNode
  orientation?: 'horizontal' | 'vertical'
  buttonsGrow?: boolean
  gap?: number
}

const ButtonGroupStyled = styled.div<ButtonGroupProps>(
  ({ orientation = 'vertical', buttonsGrow = false, gap = 8 }) => ({
    'display': 'flex',
    'flexDirection': orientation === 'horizontal' ? 'row' : 'column',
    ...flexGapObject(gap, orientation === 'vertical' ? 'row' : 'column'),

    '> button': {
      flex: buttonsGrow ? '1' : 'none',
    },
  }),
)

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>((props, ref) => {
  const { children, ...rest } = props

  return (
    <ButtonGroupStyled {...rest} ref={ref}>
      {children}
    </ButtonGroupStyled>
  )
})

ButtonGroup.displayName = 'ButtonGroup'
export { ButtonGroup }
