import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgPhoneFaceOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.829 14.3595C24.829 12.5404 25.5516 10.7958 26.8379 9.50944 28.1242 8.22313 29.8688 7.50049 31.688 7.50049H67.256C68.1567 7.50023 69.0486 7.67743 69.8809 8.02197 70.7131 8.36651 71.4693 8.87164 72.1062 9.5085 72.7432 10.1454 73.2484 10.9015 73.5931 11.7337 73.9378 12.5658 74.1151 13.4578 74.115 14.3585V90.6425C74.1151 91.5431 73.9378 92.435 73.5932 93.2671 73.2486 94.0992 72.7434 94.8553 72.1066 95.4921 71.4697 96.129 70.7137 96.6341 69.8816 96.9787 69.0494 97.3233 68.1576 97.5006 67.257 97.5005H31.688C29.8689 97.5002 28.1245 96.7775 26.8382 95.4912 25.5519 94.205 24.8292 92.4605 24.829 90.6415V14.3595ZM69.829 20.3575H29.115V83.5715H69.829V20.3575ZM47.329 13.9295H59.115M49.472 93.6825C51.2094 93.6825 52.618 92.274 52.618 90.5365 52.618 88.799 51.2094 87.3905 49.472 87.3905 47.7345 87.3905 46.326 88.799 46.326 90.5365 46.326 92.274 47.7345 93.6825 49.472 93.6825ZM69.829 83.5715C68.4499 79.2555 65.7347 75.4896 62.0756 72.8175 58.4166 70.1453 54.0029 68.7053 49.472 68.7053 44.941 68.7053 40.5274 70.1453 36.8683 72.8175 33.2092 75.4896 30.4941 79.2555 29.115 83.5715H69.829ZM49.472 76.3005C51.2521 76.2994 52.9755 75.6735 54.3414 74.5318 55.7072 73.3901 56.629 71.8052 56.946 70.0535 52.1246 68.2602 46.8193 68.2602 41.998 70.0535 42.3149 71.8052 43.2367 73.3901 44.6026 74.5318 45.9685 75.6735 47.6918 76.2994 49.472 76.3005ZM38.084 50.3235V51.1925C37.5325 51.2981 37.0379 51.5997 36.6915 52.0417 36.3451 52.4836 36.1704 53.0359 36.1995 53.5966 36.2286 54.1573 36.4597 54.6886 36.85 55.0922 37.2403 55.4958 37.7635 55.7446 38.323 55.7925 38.8573 58.3543 40.2576 60.654 42.2882 62.3047 44.3189 63.9555 46.856 64.8566 49.473 64.8566 52.0899 64.8566 54.627 63.9555 56.6577 62.3047 58.6883 60.654 60.0886 58.3543 60.623 55.7925 61.1824 55.7446 61.7056 55.4958 62.0959 55.0922 62.4862 54.6886 62.7173 54.1573 62.7464 53.5966 62.7755 53.0359 62.6008 52.4836 62.2544 52.0417 61.908 51.5997 61.4134 51.2981 60.862 51.1925V49.6735C60.8625 48.7825 60.7588 47.8944 60.553 47.0275"
      />
      <path
        stroke="currentColor"
        d="M40.903 14.4295C40.6268 14.4295 40.403 14.2056 40.403 13.9295 40.403 13.6533 40.6268 13.4295 40.903 13.4295M40.903 14.4295C41.1791 14.4295 41.403 14.2056 41.403 13.9295 41.403 13.6533 41.1791 13.4295 40.903 13.4295"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M46.626 58.3005C47.4689 58.8629 48.4596 59.163 49.473 59.163 50.4863 59.163 51.477 58.8629 52.32 58.3005M61.81 46.8265C61.8246 45.197 61.5163 43.5807 60.9029 42.0709 60.2895 40.5612 59.383 39.188 58.2359 38.0305 57.0888 36.873 55.7238 35.9542 54.2196 35.3272 52.7155 34.7002 51.1021 34.3774 49.4725 34.3774 47.8429 34.3774 46.2294 34.7002 44.7253 35.3272 43.2211 35.9542 41.8561 36.873 40.709 38.0305 39.5619 39.188 38.6554 40.5612 38.042 42.0709 37.4286 43.5807 37.1203 45.197 37.135 46.8265V50.6265C38.6077 50.2569 39.9743 49.5501 41.1272 48.562 42.28 47.5738 43.1875 46.3313 43.778 44.9325 49.095 48.5075 56.116 47.7755 61.81 46.8265ZM41.88 43.0305C42.3642 43.7972 43.0133 44.4463 43.78 44.9305"
      />
      <path
        stroke="currentColor"
        d="M45.202 51.6005C44.9258 51.6005 44.702 51.3766 44.702 51.1005 44.702 50.8243 44.9258 50.6005 45.202 50.6005M45.202 51.6005C45.4781 51.6005 45.702 51.3766 45.702 51.1005 45.702 50.8243 45.4781 50.6005 45.202 50.6005M53.743 51.6005C53.4668 51.6005 53.243 51.3766 53.243 51.1005 53.243 50.8243 53.4668 50.6005 53.743 50.6005M53.743 51.6005C54.0191 51.6005 54.243 51.3766 54.243 51.1005 54.243 50.8243 54.0191 50.6005 53.743 50.6005"
      />
    </svg>
  )
}
const Memo = memo(SvgPhoneFaceOutline)
export default Memo
