import styled from '@emotion/styled'
import { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { DraggableStyled } from '../../../components/common/Draggable'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { CaretDown, ViewOff } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, TRANSITION } from '../../../theme'
import { alpha } from '../../../utils/colors'
import { flexGapString } from '../../../utils/css'
import { parseReceiverName } from '../../../utils/textParsing'

import DraggableClipItem from './DraggableClipItem'

import type { ProjectQuestionDto } from '../../../types'

interface Props {
  question: ProjectQuestionDto
  receiverName: string
  isDragging?: boolean
  onAnswerUpdated: (id: string, excluded: boolean) => void
}

const QuestionItemStyled = styled(DraggableStyled)`
  padding: 12px;
  display: flex;
  flex-direction: column;
  ${flexGapString(4, 'row')}
  border-radius: ${BORDER_RADIUS.base};
  background: ${alpha(COLOR.primary.lighter, 0.75)};

  .trigger {
    padding: 8px 20px 8px 8px;
  }

  :active:not(:has(:active)),
  :active:has(.trigger:active) {
    background-color: ${COLOR.primary.lighter};
  }
`

const CaretStyled = styled(CaretDown)<{ isExpanded: boolean }>`
  transform: rotate(${(props) => (props.isExpanded ? '180deg' : '0deg')});
  transition: ${TRANSITION.base};
`

export default function ExpandableDraggableQuestionItem(props: Props) {
  const { question, isDragging = false, receiverName, onAnswerUpdated } = props

  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <QuestionItemStyled isDragging={isDragging}>
      <Flex
        className="trigger"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Text weight={FONT.weight.semibold} size={FONT.size.base} style={{ lineHeight: '1.15' }}>
          {parseReceiverName(question.title, receiverName)}
        </Text>
        <CaretStyled height={16} width={16} isExpanded={isExpanded} style={{ flexShrink: 0 }} />
      </Flex>
      {isExpanded && (
        <Droppable droppableId={question.id} type="answer">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {question.answers?.map((answer, index) => (
                <Draggable
                  key={question.id + answer.id}
                  draggableId={question.id + answer.id}
                  index={index}
                >
                  {(providedDrag, snapshot) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                    >
                      <DraggableClipItem
                        answer={answer}
                        isDragging={snapshot.isDragging}
                        question={question}
                        receiverName={receiverName}
                        onAnswerUpdated={onAnswerUpdated}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      <ActionSheetModal isOpen={isActionSheetOpen} onClose={() => setIsActionSheetOpen(false)}>
        <ButtonGroup>
          <Button
            variant="secondary"
            icon={<ViewOff />}
            onClick={() => setIsActionSheetOpen(false)}
          >
            Alle ausblenden
          </Button>
        </ButtonGroup>
      </ActionSheetModal>
    </QuestionItemStyled>
  )
}
