import { Capacitor } from '@capacitor/core'
import { IonSpinner, useIonViewWillEnter } from '@ionic/react'

import { DEFAULT_SPINNER } from '../../App'
import Flex from '../../components/ui/Flex'
import { useProducts } from '../../hooks/useProducts'
import { BRAND_COLORS } from '../../utils/colors'

import ProductItem from './ProductItem'

interface Props {
  onPurchase?: () => void
}

export default function ProductsList(props: Props) {
  const { onPurchase } = props

  const { products, isLoading } = useProducts()

  useIonViewWillEnter(() => {
    if (!Capacitor.isNativePlatform()) return

    void CdvPurchase.store.update() // might also be initialize()
  })

  if (!Capacitor.isNativePlatform()) return null

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <IonSpinner name={DEFAULT_SPINNER} />
      </Flex>
    )
  }

  return (
    <Flex gap={8} direction="column">
      {products.map((product, index) => (
        <ProductItem
          key={product.id}
          product={product}
          color={BRAND_COLORS[(index + 1) % 4]}
          onPurchase={onPurchase}
        />
      ))}
    </Flex>
  )
}
