import { Capacitor } from '@capacitor/core'

import { NavigationBar } from '../plugins/NavigationBar'
import { COLOR } from '../theme'

export function setNavigationBarBlack() {
  if (!Capacitor.isNativePlatform()) return

  void NavigationBar.setColor({ color: COLOR.black, iconsDark: false })
}

export function setNavigationBarLight() {
  if (!Capacitor.isNativePlatform()) return

  void NavigationBar.setColor({ color: COLOR.neutral.lightest, iconsDark: true })
}
