export const {
  REACT_APP_API_URL = '',
  REACT_APP_SUPABASE_URL = '',
  REACT_APP_SUPABASE_KEY = '',
  REACT_APP_SUPABASE_BUCKET = '',
  REACT_APP_WEBSITE_URL = 'https://winkii.de',
  REACT_APP_DYNAMIC_LINK_URL = 'https://link.winkii.de',
  REACT_APP_IAP_ONE_CREDIT_ID = 'winkii_one_credit',
  REACT_APP_IAP_TWO_CREDITS_ID = 'winkii_two_credits',
  REACT_APP_IAP_SIX_CREDITS_ID = 'winkii_six_credits',
  REACT_APP_IOS_BUNDLE_ID = 'com.winkii.ios',
  REACT_APP_ANDROID_PACKAGE_NAME = 'com.winkii.android',
  REACT_APP_APP_STORE_ID = '1667873462',
  REACT_APP_GOOGLE_WEB_CLIENT_ID = '',
  REACT_APP_IRON_SOURCE_APP_KEY_ANDROID = '',
  REACT_APP_IRON_SOURCE_APP_KEY_IOS = '',
  MARKER_PROJECT_ID = '6564afcb48cbbccbadb9ab2d',
} = process.env
