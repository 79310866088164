import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgUser(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12 1.5C9.51472 1.5 7.5 3.51472 7.5 6C7.5 8.48528 9.51472 10.5 12 10.5C14.4853 10.5 16.5 8.48528 16.5 6C16.5 3.51472 14.4853 1.5 12 1.5ZM6 6C6 2.68629 8.68629 0 12 0C15.3137 0 18 2.68629 18 6C18 9.31371 15.3137 12 12 12C8.68629 12 6 9.31371 6 6ZM12 14.25C9.61305 14.25 7.32387 15.1982 5.63604 16.886C3.94821 18.5739 3 20.8631 3 23.25C3 23.6642 2.66421 24 2.25 24C1.83579 24 1.5 23.6642 1.5 23.25C1.5 20.4652 2.60625 17.7945 4.57538 15.8254C6.54451 13.8562 9.21523 12.75 12 12.75C14.7848 12.75 17.4555 13.8562 19.4246 15.8254C21.3938 17.7945 22.5 20.4652 22.5 23.25C22.5 23.6642 22.1642 24 21.75 24C21.3358 24 21 23.6642 21 23.25C21 20.8631 20.0518 18.5739 18.364 16.886C16.6761 15.1982 14.3869 14.25 12 14.25Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
const Memo = memo(SvgUser)
export default Memo
