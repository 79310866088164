/**
 * Button Component.
 * We don't use `IonButton` here because transitions are not working at the moment.
 */
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { COLOR, SHADOW, TRANSITION } from '../../../theme'
import { flexGapString } from '../../../utils/css'
import BaseButton from '../Button/BaseButton'

import type { BaseButtonProps } from '../Button/BaseButton'

const ButtonStyled = styled(BaseButton)`
  transition: ${TRANSITION.base};
  box-shadow: ${SHADOW.base};
  background: ${COLOR.white};
  border: 1px solid ${COLOR.neutral.light};
  color: ${COLOR.neutral.darkest};
  text-align: left;
  justify-content: space-between;
  ${flexGapString(16, 'column')}

  :active {
    background: ${COLOR.primary.lighter};
  }

  :disabled {
    background: ${COLOR.white};
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
  }
`

const CardButton = forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const { children, ...rest } = props

  return (
    <ButtonStyled {...rest} ref={ref}>
      {children}
    </ButtonStyled>
  )
})

CardButton.displayName = 'CardButton'
export default CardButton
