import { CameraResultType, Camera as CapCamera } from '@capacitor/camera'
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import { IonFab, useIonLoading, useIonRouter, useIonToast, useIonViewWillLeave } from '@ionic/react'
import { format } from 'date-fns'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { ROUTES } from '../../Router'
import DatePicker from '../../components/common/DatePicker'
import ProjectSubpage from '../../components/layout/ProjectSubpage'
import MusicTrackSelection from '../../components/projects/musicTracks/MusicTrackSelection'
import Button from '../../components/ui/Button/Button'
import FAB from '../../components/ui/Button/FAB'
import GhostButton from '../../components/ui/Button/GhostButton'
import Dialog from '../../components/ui/Dialog'
import Flex from '../../components/ui/Flex'
import FormInput from '../../components/ui/Input/FormInput'
import Text from '../../components/ui/Text'
import { useApiContext } from '../../context/ApiContext'
import { useProjectContext } from '../../context/ProjectContext'
import { useMusicTracks } from '../../hooks/useMusicTracks'
import { useSingleProject } from '../../hooks/useSingleProject'
import { Bin, Camera, CheckBold } from '../../icons'
import { BORDER_RADIUS, COLOR, FONT, PADDING } from '../../theme'
import { stopAllAudio } from '../../utils/media'
import { FormStyled } from '../Auth/Auth.styled'

import type { FieldValues } from 'react-hook-form'

const ThumbnailStyled = styled.img`
  width: 100%;
  max-height: 100vw;
  border-radius: ${BORDER_RADIUS.base};
  object-fit: cover;
  object-position: center;
`

const FormCss = css`
  width: 100% !important;
  margin-bottom: 0 !important;
  font-size: ${FONT.size.base};
  font-weight: ${FONT.weight.normal};
`

const DangerBox = styled.div`
  background-color: ${COLOR.danger.lighter};
  border-radius: ${BORDER_RADIUS.base};
  padding: ${PADDING.DEFAULT};
`

export default function EditProject() {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { apiClient } = useApiContext()
  const { clearProject } = useProjectContext()
  const { project, refetch: refetchProject } = useSingleProject(id)

  const [projectImage, setProjectImage] = useState(project?.thumbnail.sourceUrl)
  const [projectDate, setProjectDate] = useState(project?.dueAt)

  const { control, handleSubmit, watch } = useForm({ mode: 'onSubmit' })
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()
  const ionRouter = useIonRouter()

  const { data: musicTracks } = useMusicTracks(project?.category.id || '')
  const [activeMusicTrackId, setActiveMusicTrackId] = useState(project?.musicTrack?.id || '')

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
  const openConfirmDeleteDialog = () => setConfirmDeleteDialogOpen(true)
  const closeConfirmDeleteDialog = () => setConfirmDeleteDialogOpen(false)

  useEffect(() => {
    if (project) {
      setProjectImage(project.thumbnail.sourceUrl)
      setProjectDate(project.dueAt)
    }
  }, [project])

  const handleEditProject = async (data: FieldValues) => {
    if (!project || !projectDate) return

    await presentLoading(t('ProjectDashboard.EditProject.isEditing'))
    let newProjectImage = project.thumbnail.sourceUrl

    if (projectImage && projectImage !== project.thumbnail.sourceUrl) {
      await apiClient.uploadImage(projectImage, 'square', 800).then((res) => {
        newProjectImage = res.thumbnailUrl
      })
    }

    void apiClient
      .updateProject(project?.id, {
        title: data.new_project_title as string,
        receiverName: data.new_project_receiver as string,
        dueAt: projectDate,
        thumbnail: {
          sourceUrl: newProjectImage,
          thumbnailUrl: newProjectImage,
        },
        musicTrackId: activeMusicTrackId || null,
      })
      .then(() => {
        void dismissLoading()
        void refetchProject()
        ionRouter.push(ROUTES.projectDashboard(project?.id), 'forward')
      })
  }

  useIonViewWillLeave(() => {
    stopAllAudio()
  })

  if (!project) return null

  const deleteProject = () => {
    openConfirmDeleteDialog()
  }

  const confirmDeleteProject = async () => {
    closeConfirmDeleteDialog()
    await presentLoading(t('ProjectDashboard.EditProject.isDeleting'))

    try {
      await apiClient.deleteProject(project.id)
      await clearProject()
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.EditProject.deleteSuccess'),
        duration: 2500,
        icon: checkmark,
      })
      ionRouter.push(ROUTES.dashboard, 'root', 'pop')
    } catch {
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.EditProject.deleteError'),
        duration: 2500,
        icon: alertCircle,
      })
    }
  }

  function hasChanges() {
    const newDate = projectDate ? new Date(projectDate).toDateString() : ''
    const oldDate = new Date(project?.dueAt as string).toDateString()

    return [
      projectImage !== project?.thumbnail.sourceUrl,
      watch('new_project_title') !== project?.title,
      watch('new_project_receiver') !== project?.receiverName,
      newDate !== oldDate,
      activeMusicTrackId !== project?.musicTrack?.id,
    ].some(Boolean)
  }

  const setNewPicture = async () => {
    const image = await CapCamera.getPhoto({
      quality: 90,
      resultType: CameraResultType.Uri,
    })

    const imageUrl = image.webPath
    if (!imageUrl) return

    setProjectImage(imageUrl)
  }

  return (
    <ProjectSubpage project={project} title={t('ProjectDashboard.EditProject.title')}>
      <Flex direction="column" gap={32}>
        <Flex direction="column" gap={20}>
          <Flex direction="column" alignItems="center" gap={4}>
            <ThumbnailStyled src={projectImage || ''} alt={'project thumbnail'} />
            <GhostButton
              icon={<Camera />}
              variant="secondary"
              iconPosition="leading"
              onClick={() => void setNewPicture()}
            >
              {t('ProjectDashboard.EditProject.changeImage')}
            </GhostButton>
          </Flex>
          <FormStyled className={cx(FormCss)}>
            <FormInput
              control={control}
              variant="primary"
              inputName="new_project_title"
              label={t('ProjectDashboard.EditProject.projectName')}
              errorMessage=""
              type="text"
              defaultValue={project?.title}
            />
            <FormInput
              control={control}
              variant="primary"
              inputName="new_project_receiver"
              label={t('ProjectDashboard.EditProject.projectReceiver')}
              errorMessage=""
              type="text"
              defaultValue={project?.receiverName}
            />
          </FormStyled>
          {projectDate && (
            <Flex direction="column" alignItems="flex-start" gap={6}>
              <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.dark}>
                {t('ProjectDashboard.EditProject.completionDate')}
              </Text>
              <DatePicker
                selectedDate={new Date(projectDate)}
                setSelectedDate={(date) => {
                  setProjectDate(format(date, 'yyyy-MM-dd'))
                }}
              />
            </Flex>
          )}
          <Flex direction="column">
            <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.dark}>
              {t('ProjectDashboard.EditProject.musicTrack')}
            </Text>
            <MusicTrackSelection
              musicTracks={musicTracks}
              activeMusicTrackId={activeMusicTrackId}
              onSelect={(value) => setActiveMusicTrackId(value || '')}
            />
          </Flex>
        </Flex>
        <DangerBox>
          <Flex direction="column" gap={20}>
            <Text size={FONT.size.base} weight={FONT.weight.semibold} tag="h2">
              {t('ProjectDashboard.EditProject.dangerZone')}
            </Text>
            <Button variant="danger" icon={<Bin />} onClick={deleteProject}>
              {t('ProjectDashboard.EditProject.deleteProject')}
            </Button>
          </Flex>
        </DangerBox>
      </Flex>
      {hasChanges() && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <FAB
            type="submit"
            icon={<CheckBold />}
            onClick={() => void handleSubmit(handleEditProject)()}
          >
            Speichern
          </FAB>
        </IonFab>
      )}
      <Dialog isOpen={confirmDeleteDialogOpen} onClose={closeConfirmDeleteDialog}>
        <Dialog.Title>{t('ProjectDashboard.EditProject.ConfirmDeleteDialog.title')}</Dialog.Title>
        <Dialog.Body>{t('ProjectDashboard.EditProject.ConfirmDeleteDialog.body')}</Dialog.Body>
        <Dialog.Buttons>
          <Button variant="danger" onClick={() => void confirmDeleteProject()}>
            {t('ProjectDashboard.EditProject.ConfirmDeleteDialog.confirm')}
          </Button>
          <Button variant="secondary" onClick={closeConfirmDeleteDialog}>
            {t('ProjectDashboard.EditProject.ConfirmDeleteDialog.cancel')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </ProjectSubpage>
  )
}
