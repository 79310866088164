import { cx } from '@emotion/css'
import { forwardRef } from 'react'

import { InputLabelStyled, InputWrapperStyled, inputStyles, inputVariantStyles } from './Input'

import type { BaseInputProps } from './Input'
import type { TextareaHTMLAttributes } from 'react'

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    BaseInputProps {}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { value, inputName, label, error, onChange, onBlur, ...rest } = props

  return (
    <InputWrapperStyled>
      <InputLabelStyled hasError={!error} htmlFor={inputName}>
        {label}
      </InputLabelStyled>
      <textarea
        ref={ref}
        id={inputName}
        placeholder={rest.placeholder}
        name={inputName}
        value={value}
        className={cx(inputVariantStyles.primary, inputStyles(!!error))}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
    </InputWrapperStyled>
  )
})

Textarea.displayName = 'Textarea'

export default Textarea
