import { useEffect, useState } from 'react'

import { useApiContext } from '../context/ApiContext'
import { useProjectContext } from '../context/ProjectContext'

export function useSingleProject(projectId: string) {
  const { apiClient } = useApiContext()
  const { project, setProject, clearProject, setQuestions } = useProjectContext()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const refetch = async () => {
    try {
      const newProject = await apiClient.getProject(projectId)
      await setProject(newProject)
    } catch {}
  }

  useEffect(() => {
    if (project?.id === projectId) return

    setQuestions([])
    setIsLoading(true)

    void clearProject().then(() => {
      apiClient
        .getProject(projectId)
        .then((newProject) => setProject(newProject))
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false))
    })
  }, [projectId])

  return { project, refetch, isLoading, isError }
}
