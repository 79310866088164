import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { CheckBold } from '../../icons'
import { BORDER_RADIUS, COLOR, FONT, PADDING, TRANSITION } from '../../theme'
import { ProjectStatus } from '../../types'
import { alpha } from '../../utils/colors'
import LazyImage from '../common/LazyImage'
import { UnStyledLink } from '../common/UnStyledLink'

import type { ProjectDto } from '../../types'

interface ProjectListItemProps {
  project: ProjectDto
}

function itemStyles(completed = false) {
  return css`
    position: relative;
    width: 100%;
    height: calc(((100vw - ${PADDING.DEFAULT} * 2) / 2) / 7 * 6);
    border-radius: ${BORDER_RADIUS.base};
    display: flex;
    padding: 14px 16px;
    align-items: flex-end;
    overflow: hidden;
    opacity: ${completed ? 0.4 : 1};
    transition: ${TRANSITION.base};

    :active {
      transform: scale(0.975);
    }
  `
}

const ImageOverlayStyled = styled.div`
  z-index: -1;
  position: absolute;
  border-radius: ${BORDER_RADIUS.base};
  inset: 0;
  background: linear-gradient(
    180deg,
    ${alpha(COLOR.neutral.darkest, 0)} 0%,
    ${COLOR.neutral.darkest} 100%
  );
`

const TitleStyled = styled.p({
  fontSize: FONT.size.base,
  fontWeight: FONT.weight.semibold,
  color: COLOR.white,
  lineHeight: FONT.lineHeight.sm,
  margin: 0,
  hyphens: 'auto',
  wordBreak: 'break-all',
})

const CheckmarkStyled = styled.span`
  position: absolute;
  top: 12px;
  right: 12px;
  color: ${COLOR.black};
  background-color: ${COLOR.primary.DEFAULT};
  border-radius: ${BORDER_RADIUS.full};
  border: 2px solid ${COLOR.white};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    height: 20px;
    width: 20px;
  }
`

export default function ProjectListItem(props: ProjectListItemProps) {
  const { project } = props

  const completed = project.status === ProjectStatus.Completed

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <UnStyledLink className={itemStyles(completed)} to={`/projects/${project.id}`}>
        <LazyImage
          src={project.thumbnail?.sourceUrl || './img/default-thumbnail.png'}
          style={{ zIndex: -2, borderRadius: BORDER_RADIUS.base }}
        />
        <ImageOverlayStyled />
        <TitleStyled>{project.title}</TitleStyled>
      </UnStyledLink>
      {completed && (
        <CheckmarkStyled>
          <CheckBold />
        </CheckmarkStyled>
      )}
    </div>
  )
}
