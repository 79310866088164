import { css, cx } from '@emotion/css'

import { BORDER_RADIUS, COLOR, TRANSITION } from '../../../theme'
import { alpha } from '../../../utils/colors'

import type { MouseEventHandler, ReactNode } from 'react'

type IconButtonVariant = 'primary' | 'ghost'
export type IconButtonEventHandler = MouseEventHandler<HTMLButtonElement> &
  MouseEventHandler<HTMLAnchorElement> &
  MouseEventHandler<HTMLSpanElement>

interface IconButtonProps {
  icon: ReactNode
  disabled?: boolean
  id?: string
  onClick?: IconButtonEventHandler
  as?: 'button' | 'a' | 'span'
  href?: string
  title: string
  onDark?: boolean
  variant?: IconButtonVariant
  className?: string
}

const backgroundColor = {
  primary: COLOR.primary.DEFAULT,
  ghost: 'transparent',
}

const activeBackgroundColor = {
  primary: COLOR.primary.dark,
  ghost: COLOR.primary.lighter,
}

export function iconButtonStyles(
  onDark = false,
  disabled = false,
  variant: IconButtonVariant = 'ghost',
) {
  return css`
    display: inline-flex;
    padding: 10px;
    border-radius: ${BORDER_RADIUS.full};
    background-color: ${onDark ? 'transparent' : backgroundColor[variant]};
    transition: ${TRANSITION.base};
    color: ${onDark ? COLOR.white : COLOR.neutral.darkest};
    opacity: ${disabled ? '0.4' : '1'};
    pointer-events: ${disabled ? 'none' : 'auto'};

    .icon {
      transition: ${TRANSITION.base};
    }

    &:active {
      background-color: ${onDark ? alpha(COLOR.white, 0.2) : activeBackgroundColor[variant]};

      transform: scale(0.975);
    }
  `
}

export default function IconButton(props: IconButtonProps) {
  const {
    icon,
    onClick,
    as: Tag = 'button',
    id = '',
    title,
    href,
    onDark = false,
    disabled,
    variant = 'ghost',
    className,
  } = props

  return (
    <Tag
      id={id}
      className={cx(iconButtonStyles(onDark, disabled, variant), className)}
      title={title}
      aria-label={title}
      href={href}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </Tag>
  )
}
