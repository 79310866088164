import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, TRANSITION } from '../../theme'
import { flexGapString } from '../../utils/css'

export const SwiperPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexGapString(12, 'column')};
`

export function swiperBulletStyles(active = false) {
  return css`
    height: 8px;
    width: 8px;
    border-radius: ${BORDER_RADIUS.full};
    opacity: 1;
    background: ${active ? `${COLOR.primary.DEFAULT} !important` : COLOR.neutral.light};
    transition: ${TRANSITION.base};
    ${active && 'transform: scale(1.25);'}
  `
}
