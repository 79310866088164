import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { memo, useRef } from 'react'
import { useBoolean } from 'usehooks-ts'

import { CheckBold } from '../../icons'
import { BORDER_RADIUS, COLOR, PADDING, TRANSITION } from '../../theme'
import Flex from '../ui/Flex'
import IconButton from '../ui/IconButton/IconButton'
import Text from '../ui/Text'

import { ScalingFadingEnterLeaveTransition } from './Transitions'

import type { MusicTrackDto } from '../../types'
import type { MouseEventHandler } from 'react'

interface Props {
  musicTrack: MusicTrackDto
  isSelected?: boolean
  onClick: () => void
  onPlay?: () => void
}

const PlayIcon = memo(() => (
  <svg
    aria-hidden={true}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    height={20}
    width={20}
  >
    <path
      fillRule="evenodd"
      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
      clipRule="evenodd"
    />
  </svg>
))
PlayIcon.displayName = 'Play'

const PauseIcon = memo(() => (
  <svg
    aria-hidden={true}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    height={20}
    width={20}
  >
    <path
      fillRule="evenodd"
      d="M6.75 5.25a.75.75 0 01.75-.75H9a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H7.5a.75.75 0 01-.75-.75V5.25zm7.5 0A.75.75 0 0115 4.5h1.5a.75.75 0 01.75.75v13.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V5.25z"
      clipRule="evenodd"
    />
  </svg>
))
PauseIcon.displayName = 'Pause'

export const MusicItemStyled = styled.button<{ isSelected: boolean }>`
  background-color: ${COLOR.white};
  border-radius: ${BORDER_RADIUS.base};
  border: 1px solid
    ${({ isSelected }) => (isSelected ? COLOR.primary.DEFAULT : COLOR.neutral.light)};
  box-shadow: ${({ isSelected }) => (isSelected ? '0 0 0 2px' : 'none')} ${COLOR.primary.DEFAULT};
  padding: 12px ${PADDING.DEFAULT};
  margin: 0 2px;
  text-align: start;
  transition: ${TRANSITION.base};
  min-height: 72px;
`

export default function MusicTrackItem(props: Props) {
  const { musicTrack, isSelected = false, onClick, onPlay } = props

  const audioRef = useRef<HTMLAudioElement>(null)

  const { value: isPlaying, toggle: togglePlay, setFalse } = useBoolean(false)

  const playPause: MouseEventHandler = async (event) => {
    event.stopPropagation()

    if (!audioRef.current) return

    if (isPlaying) {
      audioRef.current.pause()
    } else {
      await audioRef.current.play()

      const otherAudioElements = document.querySelectorAll('audio')
      if (!otherAudioElements) return
      for (let i = 0; i < otherAudioElements.length; i++) {
        const audioElement = otherAudioElements[i]
        if (audioElement !== audioRef.current) audioElement.pause()
      }

      onPlay?.()
    }

    togglePlay()
  }

  return (
    <MusicItemStyled isSelected={isSelected} onClick={onClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
              width: 20,
              height: 20,
              borderRadius: '50%',
              backgroundColor: isSelected ? COLOR.primary.DEFAULT : COLOR.white,
              border: `1px solid ${isSelected ? COLOR.primary.DEFAULT : COLOR.neutral.light}`,
            })}
          >
            <ScalingFadingEnterLeaveTransition show={isSelected}>
              <CheckBold height={12} width={12} />
            </ScalingFadingEnterLeaveTransition>
          </div>
          <Text>{musicTrack.title}</Text>
        </Flex>
        <IconButton
          variant="primary"
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
          title="play"
          onClick={playPause}
        />
        <audio ref={audioRef} loop src={musicTrack.sourceUrl} onPause={setFalse} />
      </Flex>
    </MusicItemStyled>
  )
}
