import styled from '@emotion/styled'
import { useIonLoading, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UserAvatar from '../../../components/common/UserAvatar'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { DotsVertical } from '../../../icons'
import { FONT, PADDING } from '../../../theme'
import { getFullName } from '../../../utils/user'

import type { UserDto } from '../../../types'

interface Props {
  user: UserDto
  isCreator?: boolean
  onDelete?: () => void
  collaboratorId?: string
}

const CollaboratorItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px ${PADDING.DEFAULT};
`

export default function CollaboratorItem(props: Props) {
  const { user, isCreator = false, onDelete, collaboratorId } = props

  const { t } = useTranslation()
  const [actionSheetOpen, setActionSheetOpen] = useState(false)
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const { user: currentUser } = useAuthContext()
  const { apiClient } = useApiContext()

  const openActionSheet = () => setActionSheetOpen(true)
  const dismissActionSheet = () => setActionSheetOpen(false)

  const deleteCollaborator = () => {
    if (isCreator || !collaboratorId) return

    void presentLoading(t('ProjectDashboard.Collaborators.removingCollaborator'))

    apiClient
      .removeCollaborator(collaboratorId)
      .then(() => {
        void dismissLoading()
        void presentToast({
          message: t('ProjectDashboard.Collaborators.removingCollaboratorSuccess'),
          duration: 2000,
        })
        dismissActionSheet()
        onDelete?.()
      })
      .catch(() => {
        void presentToast({
          message: t('ProjectDashboard.Collaborators.removingCollaboratorError'),
          duration: 2000,
        })
        void dismissLoading()
      })
  }

  return (
    <>
      <CollaboratorItemStyled>
        <Flex alignItems="center">
          <UserAvatar user={user} />
          <Text weight={FONT.weight.medium}>{getFullName(user, currentUser, t)}</Text>
        </Flex>
        {!isCreator && <IconButton icon={<DotsVertical />} title="" onClick={openActionSheet} />}
      </CollaboratorItemStyled>
      {!isCreator && (
        <ActionSheetModal isOpen={actionSheetOpen} onClose={dismissActionSheet}>
          <ButtonGroup>
            <Button variant="danger" onClick={deleteCollaborator}>
              {t('ProjectDashboard.Collaborators.removeCollaborator')}
            </Button>
          </ButtonGroup>
        </ActionSheetModal>
      )}
    </>
  )
}
