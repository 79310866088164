import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

export const PinStyled = styled.span`
  font-size: 32px;
  font-family: ${FONT.family.mono};
  letter-spacing: 0.14em;
  color: ${COLOR.neutral.darkest};
`

export const InviteViaEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(8, 'row')}
  width: 100%;
  text-align: left;

  h2 {
    align-self: flex-start;
    margin: 0;
    font-size: ${FONT.size.base};
    color: ${COLOR.neutral.darkest};
    font-weight: ${FONT.weight.semibold};
  }

  p {
    margin: 0;
    text-align: left;
    color: ${COLOR.neutral.dark};
    font-size: ${FONT.size.base};
    font-weight: ${FONT.weight.normal};
    line-height: 24px;
    margin-bottom: 20px;
  }
`

export const CollaboratorEmailsBoxStyled = styled.div`
  border: 1px solid ${COLOR.neutral.light};
  border-radius: ${BORDER_RADIUS.base};
  padding: 6px 12px;
  display: flex;
  align-items: center;
  ${flexGapString(6, 'column')}
  justify-content: space-between;
  font-size: ${FONT.size.sm};
  color: ${COLOR.neutral.darkest};
  width: auto;
  float: left;
  margin: 4px;
`
