import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { IonContent, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import { useAppConfig } from '../../../../context/AppConfigContext'
import { useAuthContext } from '../../../../context/AuthContext'
import { ArrowCircleRight } from '../../../../icons'
import { RowsWithDividingWrapper } from '../../../Project/Dashboard/ProjectDasboard.styled'
import { ProfileSectionItem } from '../Profile'

export default function AppSettings() {
  const { t } = useTranslation()

  const ionRouter = useIonRouter()

  const { user } = useAuthContext()
  const { linkPrivacy, linkTerms } = useAppConfig()

  async function openPrivacyPolicy() {
    if (Capacitor.isNativePlatform()) await Browser.open({ url: linkPrivacy })
  }

  async function openTermsAndConditions() {
    if (Capacitor.isNativePlatform()) await Browser.open({ url: linkTerms })
  }

  if (!user) {
    ionRouter.push('/login', 'none')
    return null
  } else {
    return (
      <Page>
        <SubpageHeader
          title={t('Profile.AppSettings.title')}
          showBackButton={true}
          backButtonRoute={ROUTES.profile}
        />
        <IonContent className="ion-padding">
          <RowsWithDividingWrapper topBorder={true}>
            <ProfileSectionItem to={ROUTES.languageSettings}>
              {t('Profile.AppSettings.Language.title')}
              <ArrowCircleRight />
            </ProfileSectionItem>
            <ProfileSectionItem to={'#'} onClick={() => void openTermsAndConditions()}>
              {t('Profile.AppSettings.TermsAndConditions.title')}
              <ArrowCircleRight />
            </ProfileSectionItem>
            <ProfileSectionItem to={'#'} onClick={() => void openPrivacyPolicy()}>
              {t('Profile.AppSettings.PrivacyPolicy.title')}
              <ArrowCircleRight />
            </ProfileSectionItem>
          </RowsWithDividingWrapper>
        </IonContent>
      </Page>
    )
  }
}
