import styled from '@emotion/styled'
import { useIonLoading, useIonToast } from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { useApiContext } from '../../../context/ApiContext'
import { CloseBold } from '../../../icons'
import { BORDER_RADIUS, COLOR, TRANSITION } from '../../../theme'
import LazyImage from '../../common/LazyImage'
import Button from '../../ui/Button/Button'
import Dialog from '../../ui/Dialog'

import { ItemStyled } from './EmptyAttachmentItem'
import ViewAttachmentsModal from './ViewAttachmentsModal'

import type { ProjectAttachmentDto } from '../../../types'
import type { MouseEventHandler } from 'react'

interface Props {
  attachment: ProjectAttachmentDto
  attachments: ProjectAttachmentDto[]
  index?: number
  onDelete: () => void
}

const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  margin: 6px;
  width: 28px;
  height: 28px;
  border-radius: ${BORDER_RADIUS.full};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.neutral.darkest};
  background: ${COLOR.white};
  transition: ${TRANSITION.base};

  :active {
    background: ${COLOR.neutral.lighter};
    transform: scale(0.95);
  }
`

export default function AttachmentItem(props: Props) {
  const { attachment, attachments, index = 0, onDelete } = props

  const { t } = useTranslation()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const { apiClient } = useApiContext()

  const [showModal, setShowModal] = useState(false)
  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const openDeleteDialog = () => setShowDeleteDialog(true)
  const closeDeleteDialog = () => setShowDeleteDialog(false)

  const confirmDelete = async () => {
    closeDeleteDialog()

    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('ProjectDashboard.Attachments.Delete.loading'),
    })

    try {
      await apiClient.deleteAttachment(attachment.id)
    } catch {
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.Attachments.Delete.error'),
        icon: alertCircle,
        duration: 2500,
      })
    }

    await dismissLoading()
    await presentToast({
      message: t('ProjectDashboard.Attachments.Delete.success'),
      icon: checkmark,
      duration: 2500,
    })

    onDelete()
  }

  const onDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()

    openDeleteDialog()
  }

  return (
    <>
      <ItemStyled onClick={openModal}>
        <LazyImage src={attachment.media.thumbnailUrl} />
        <DeleteButton onClick={onDeleteButtonClick}>
          <CloseBold width={14} height={14} />
        </DeleteButton>
      </ItemStyled>
      <ViewAttachmentsModal
        attachments={attachments}
        isOpen={showModal}
        initialActiveIndex={index}
        onDismiss={closeModal}
      />
      <Dialog isOpen={showDeleteDialog} onClose={closeDeleteDialog}>
        <Dialog.Title>{t('ProjectDashboard.Attachments.Delete.title')}</Dialog.Title>
        <Dialog.Body>{t('ProjectDashboard.Attachments.Delete.message')}</Dialog.Body>
        <Dialog.Buttons>
          <Button variant="danger" onClick={() => void confirmDelete()}>
            {t('ProjectDashboard.Attachments.Delete.confirm')}
          </Button>
          <Button variant="secondary" onClick={closeDeleteDialog}>
            {t('ProjectDashboard.Attachments.Delete.cancel')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </>
  )
}
