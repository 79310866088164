import { useIonToast } from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

export function useToast() {
  const [present] = useIonToast()

  const { t } = useTranslation()

  const duration = 2500

  const presentSuccessToast = (message: string) =>
    present({
      message,
      duration,
      icon: checkmark,
    })

  const presentErrorToast = (message = t('General.error')) =>
    present({
      message,
      duration,
      icon: alertCircle,
    })

  return { presentSuccessToast, presentErrorToast }
}
