import styled from '@emotion/styled'
import { IonModal } from '@ionic/react'

import { BORDER_RADIUS, COLOR, FONT, SHADOW } from '../../theme'

import type { PropsWithChildren } from 'react'

export interface ActionSheetModalProps {
  isOpen: boolean
  onClose: () => void
  backdropDismiss?: boolean
}

const ActionSheetModalStyled = styled(IonModal)`
  justify-content: flex-end;
  flex-direction: column;

  --height: fit-content;
  --border-radius: ${BORDER_RADIUS.base};
  --background: ${COLOR.white};

  ::part(content) {
    padding-bottom: var(--ion-safe-area-bottom);
    box-shadow: ${SHADOW.base};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const Content = styled.div`
  padding: 20px 24px;
  font-family: ${FONT.family.sans};
`

export default function ActionSheetModal(props: PropsWithChildren<ActionSheetModalProps>) {
  const { isOpen, onClose, children, backdropDismiss = true } = props

  return (
    <ActionSheetModalStyled
      isOpen={isOpen}
      backdropDismiss={backdropDismiss}
      onDidDismiss={onClose}
    >
      <Content>{children}</Content>
    </ActionSheetModalStyled>
  )
}
