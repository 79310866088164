import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import styled from '@emotion/styled'
import { IonLabel, useIonLoading, useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import AuthPage from '../../components/layout/AuthPage'
import Button from '../../components/ui/Button/Button'
import GhostButton from '../../components/ui/Button/GhostButton'
import Flex from '../../components/ui/Flex'
import FormInput from '../../components/ui/Input/FormInput'
import { useAppConfig } from '../../context/AppConfigContext'
import { useAuthContext } from '../../context/AuthContext'
import { FONT } from '../../theme'
import { flexGapString } from '../../utils/css'

import { FormStyled } from './Auth.styled'

import type { FieldValues } from 'react-hook-form'

const PasswordAgbWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${flexGapString(16, 'row')}
  p {
    margin: 0;
    font-size: ${FONT.size.sm};
    line-height: ${FONT.lineHeight.sm};
  }
`

export default function GuestLogin() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'GuestLogin', nameOverride: 'Auth' })
  })

  const { t } = useTranslation()
  const ionRouter = useIonRouter()

  const [present, dismiss] = useIonLoading()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onSubmit' })

  const { loginAsGuest } = useAuthContext()
  const { linkTerms } = useAppConfig()

  const loginErrorMessage = t('Login.guestError')

  const resetErrors = () => {
    if (errors.submitError) clearErrors('submitError')
  }

  const handleLoginAsGuest = async (data: FieldValues) => {
    void present(t('Register.loading'))

    await FirebaseAnalytics.logEvent({ name: 'submitGuestLogin', params: {} })

    await loginAsGuest({
      firstName: data.firstName as string,
      lastName: data.lastName as string | undefined,
    })
      .then(() => {
        void dismiss()
      })
      .catch(() => {
        void dismiss()
        setError('submitError', { message: loginErrorMessage })
      })
  }

  return (
    <AuthPage title={t('Register.createGuestAccount')} subtitle={t('Register.guestSubTitle')}>
      <Flex direction="column">
        {}
        <FormStyled onSubmit={handleSubmit(handleLoginAsGuest)}>
          <Flex direction="column">
            <FormInput
              control={control}
              inputName="firstName"
              type="text"
              errorMessage={loginErrorMessage}
              label={t('Register.firstName')}
            />
            <FormInput
              control={control}
              inputName="lastName"
              defaultValue={''}
              type="text"
              errorMessage={loginErrorMessage}
              label={`${t('Register.lastName')}`}
            />
          </Flex>
          {errors.firstname || errors.lastname || errors.submitError ? (
            <IonLabel color="danger">
              {errors.submitError ? (errors.submitError.message as string) : loginErrorMessage}
            </IonLabel>
          ) : null}
          <PasswordAgbWrapper>
            <p
              dangerouslySetInnerHTML={{
                __html: t('Register.agb', {
                  url: linkTerms,
                }),
              }}
            />
          </PasswordAgbWrapper>
          <Button type="submit" onClick={resetErrors}>
            {t('Register.createGuestAccount')}
          </Button>
        </FormStyled>
        <GhostButton
          variant="secondary"
          onClick={async () => {
            await FirebaseAnalytics.logEvent({ name: 'backToLogin', params: {} })
            ionRouter.push(ROUTES.auth, 'back')
          }}
        >
          {t('Login.backToLogin')}
        </GhostButton>
      </Flex>
    </AuthPage>
  )
}
