export interface InitializeIronSourceOptions {
  appKey: string
}

export enum InterstitialPlacement {
  Default = 'DefaultInterstitial',
  CreateProject = 'Create_Project',
  SaveQuestions = 'Save_Questions',
  UploadAnswer = 'Upload_Answer',
  WatchClips = 'Watch_Clips',
}

export interface ShowInterstitialOptions {
  placement: InterstitialPlacement
}

export interface IsTrackingUndeterminedResult {
  isTrackingUndetermined: boolean
}

export interface IronSourceIntegrationPlugin {
  initializeIronSource: (options: InitializeIronSourceOptions) => Promise<void>
  loadInterstitial: () => Promise<void>
  showInterstitial: (options: ShowInterstitialOptions) => Promise<void>

  /**
   * iOS only
   */
  isTrackingUndetermined: () => Promise<IsTrackingUndeterminedResult>

  /**
   * iOS only
   */
  requestTracking: () => Promise<void>
}
