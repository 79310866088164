import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import {
  IonContent,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import { useBoolean } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../../App'
import Page from '../../../components/layout/Page'
import PageHeader from '../../../components/layout/PageHeader'
import { useInfiniteProjects } from '../../../hooks/useProjects'
import { BORDER_RADIUS, COLOR, FONT, PADDING } from '../../../theme'
import ExampleVideos from '../Dashboard/ExampleVideos/ExampleVideos'

import ProjectsInfiniteList from './ProjectsInfiniteList'

import type { RefresherCustomEvent } from '@ionic/react'
import type { Swiper } from 'swiper/types'

import 'swiper/swiper.min.css'

const SwiperStyled = styled(ReactSwiper)``
//   display: flex;
//   max-height: 60%;
// `

const SegmentWrapperStyled = styled.div`
  padding: 0 ${PADDING.DEFAULT};
  background: ${COLOR.white};
`

const ExampleVideosStyled = styled.div``
//   padding: 0 ${PADDING.DEFAULT};
//   background: ${COLOR.white};
//   bottom: 0;
//   width: 100%;
//   height: content;
// `

const SegmentStyled = styled(IonSegment)`
  padding: 3px 0;
  --background: ${COLOR.primary.lighter};
  border-radius: ${BORDER_RADIUS.base};

  ion-segment-button {
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --border-radius: ${BORDER_RADIUS.base};
    --indicator-color: ${COLOR.primary.DEFAULT};
    --indicator-box-shadow: none;

    margin: 0;
  }

  ion-label {
    font-size: ${FONT.size.sm};
  }
`

enum SegmentValues {
  All = 'all',
  Owned = 'owned',
}

export default function MyProjects() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'MyProjects', nameOverride: 'MyProjects' })
  }, [])

  const { t } = useTranslation()

  const [segmentValue, setSegmentValue] = useState(SegmentValues.All)
  const [swiper, setSwiper] = useState<Swiper | null>(null)

  const { value: isRefetching, setValue: setRefetching } = useBoolean(false)

  const PER_PAGE = 10

  const {
    data: projectsByUserData,
    refetch: refetchProjectsByUser,
    isError: isErrorByUser,
    isLoading: isLoadingByUser,
    fetchNextPage: fetchNextPageByUser,
    hasNextPage: hasNextPageByUser,
  } = useInfiniteProjects('infiniteProjectsCreatedByUser', {
    limit: PER_PAGE,
    createdByUserOnly: true,
  })

  const {
    data: allProjectsData,
    refetch: refetchAll,
    isError: isErrorAll,
    isLoading: isLoadingAll,
    fetchNextPage: fetchNextPageAll,
    hasNextPage: hasNextPageAll,
  } = useInfiniteProjects('infiniteAllProjects', {
    limit: PER_PAGE,
    createdByUserOnly: false,
  })

  const refetch = async () => {
    await Promise.all([refetchProjectsByUser(), refetchAll()])
  }

  const handleRefresh = async (event: RefresherCustomEvent) => {
    setRefetching(true)
    await refetch()
    event.detail.complete()
    setRefetching(false)
  }

  return (
    <Page safeArea={false}>
      <PageHeader title={t('MyProjects.title')}>
        <SegmentWrapperStyled>
          <SegmentStyled
            value={segmentValue}
            onIonChange={(event) => {
              if (isRefetching) return

              setSegmentValue(event.detail.value as SegmentValues)
              if (swiper) swiper.slideTo(event.detail.value === SegmentValues.All ? 0 : 1)
            }}
          >
            <IonSegmentButton value={SegmentValues.All} disabled={isRefetching}>
              <IonLabel>{t(`MyProjects.Segments.all`)}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={SegmentValues.Owned} disabled={isRefetching}>
              <IonLabel>{t(`MyProjects.Segments.owned`)}</IonLabel>
            </IonSegmentButton>
          </SegmentStyled>
        </SegmentWrapperStyled>
      </PageHeader>
      <IonContent
        className={css({
          position: 'relative',
        })}
      >
        <IonRefresher slot="fixed" onIonRefresh={(e) => void handleRefresh(e)}>
          <IonRefresherContent refreshingSpinner={DEFAULT_SPINNER} />
        </IonRefresher>
        <SwiperStyled
          className={css({
            display: 'flex',
          })}
          allowTouchMove={!isRefetching}
          allowSlidePrev={!isRefetching}
          allowSlideNext={!isRefetching}
          onSwiper={(swipe) => setSwiper(swipe)}
          onActiveIndexChange={() => {
            if (swiper)
              setSegmentValue(swiper.activeIndex === 0 ? SegmentValues.All : SegmentValues.Owned)
          }}
        >
          <SwiperSlide
            className={css({
              overflowY: 'auto',
            })}
          >
            <ProjectsInfiniteList
              data={allProjectsData}
              isLoading={isLoadingAll}
              fetchNextPage={fetchNextPageAll}
              isError={isErrorAll}
              hasNextPage={hasNextPageAll}
            />
          </SwiperSlide>
          <SwiperSlide
            className={css({
              overflowY: 'auto',
            })}
          >
            <ProjectsInfiniteList
              data={projectsByUserData}
              isLoading={isLoadingByUser}
              fetchNextPage={fetchNextPageByUser}
              isError={isErrorByUser}
              hasNextPage={hasNextPageByUser}
            />
          </SwiperSlide>
        </SwiperStyled>
        <ExampleVideosStyled
          className={css({
            padding: `0 ${PADDING.DEFAULT}`,
            background: COLOR.white,
            position: 'sticky',
            bottom: 0,
            width: '100%',
            height: 'content',
            overflowY: 'hidden',
            zIndex: 1,
          })}
        >
          <ExampleVideos />
        </ExampleVideosStyled>
      </IonContent>
    </Page>
  )
}
