import type { PluginListenerHandle } from '@capacitor/core'

export enum VideoRecorderEvent {
  RecordingError = 'recordingError',
  RecordingStopped = 'recordingStopped',
  RecordingProcessed = 'recordingProcessed',
}

export interface RecordingProcessedResponse {
  video: string
  webPath: string
  thumbnailUrl: string
}

export interface VideoRecorderPlugin {
  startRecording: () => Promise<void>
  stopRecording: () => Promise<void>
  startCamera: () => Promise<void>
  destroyCamera: () => Promise<void>
  flipCamera: () => Promise<void>
  addListener: (
    eventName: VideoRecorderEvent,
    listenerFunc: (event: RecordingProcessedResponse) => void,
  ) => Promise<PluginListenerHandle>
}
