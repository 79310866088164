import { useTranslation } from 'react-i18next'

import ScrollSnapContainer from '../../../../components/ui/ScrollSnapContainer'
import { useAppConfig } from '../../../../context/AppConfigContext'
import DashboardSection, { DashboardSectionContentWrapper } from '../DashboardSection'

import ExampleVideo from './ExampleVideo'

export default function ExampleVideos() {
  const { t } = useTranslation()
  const { exampleProjectVideoUrls = [] } = useAppConfig()

  if (exampleProjectVideoUrls.length === 0) return null

  return (
    <DashboardSection title={t('Dashboard.ExampleVideos.title')}>
      <DashboardSectionContentWrapper>
        <ScrollSnapContainer childrenWidth={160}>
          {exampleProjectVideoUrls.map((url, index) => (
            <ExampleVideo key={url + index} url={url} index={index} />
          ))}
        </ScrollSnapContainer>
      </DashboardSectionContentWrapper>
    </DashboardSection>
  )
}
