import { useQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'

export function useBlogPosts() {
  const { apiClient } = useApiContext()

  return useQuery(['blogPosts'], () => apiClient.getBlogPosts(), {
    retry: 2,
  })
}
