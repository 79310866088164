import { css } from '@emotion/css'
import { useTranslation } from 'react-i18next'

import { UnStyledLink } from '../../../../components/common/UnStyledLink'
import WithLoading from '../../../../components/common/WithLoading'
import ProjectListItem from '../../../../components/projects/ProjectListItem'
import Button from '../../../../components/ui/Button/Button'
import EmptyState from '../../../../components/ui/EmptyState/EmptyState'
import Flex from '../../../../components/ui/Flex'
import ScrollSnapContainer from '../../../../components/ui/ScrollSnapContainer'
import { Clapperboard } from '../../../../illustrations'
import DashboardSection from '../DashboardSection'

import type { ProjectDto } from '../../../../types'

interface DashboardProjectsProps {
  projects?: ProjectDto[]
  isLoading: boolean
}

function Projects({ projects = [] }: { projects: ProjectDto[] }) {
  const { t } = useTranslation()
  //const [present] = useNewProjectModal()

  return (
    <Flex direction="column" gap={20}>
      {projects.length === 0 ? (
        <EmptyState illustration={<Clapperboard />}>{t('MyProjects.noProjects')}</EmptyState>
      ) : (
        <>
          <ScrollSnapContainer childrenWidth={250}>
            {projects.map((project) => (
              <ProjectListItem key={project.id} project={project} />
            ))}
          </ScrollSnapContainer>

          <UnStyledLink to="/tabs/projects" className={css({ width: '100%' })}>
            <Button variant="secondary" className={css({ width: '100%' })}>
              {t('Dashboard.Projects.viewAll')}
            </Button>
          </UnStyledLink>
        </>
      )}
      {/* <Button icon={<PlusCircle />} onClick={() => present()}>
        {projects.length === 0
          ? t('MyProjects.createProject')
          : t('MyProjects.createAnotherProject')}
      </Button> */}
    </Flex>
  )
}

export default function DashboardProjects(props: DashboardProjectsProps) {
  const { projects = [], isLoading } = props

  const { t } = useTranslation()

  return (
    <DashboardSection title={t('Dashboard.Projects.title')} id="blogPosts">
      <WithLoading isLoading={isLoading}>
        <Projects projects={projects} />
      </WithLoading>
    </DashboardSection>
  )
}
