import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { type ReactNode, useEffect } from 'react'

import Flex from '../../../components/ui/Flex'
import InfoBox from '../../../components/ui/InfoBox'
import TextField from '../../../components/ui/Input/TextField'
import Text from '../../../components/ui/Text'

import { CategoryImage, InsertTextStepWrapper } from './NewProjectWizard.styled'

import type { StepProps } from './NewProjectWizard'

interface InsertTextStepProps extends StepProps {
  title: string
  placeholder: string
  image: ReactNode
  description?: string
}

export default function InsertTextStep(props: InsertTextStepProps) {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({
      screenName: 'InsertTextStep',
      nameOverride: 'NewProject',
    })
  }, [])

  const { title, placeholder, image, description, value, setValue } = props

  const onChange = (newValue: string) => {
    setValue(newValue)
  }

  return (
    <InsertTextStepWrapper>
      <CategoryImage>{image}</CategoryImage>
      <Flex direction="column" gap={16} style={{ width: '100%' }}>
        <Text tag="p" style={{ textAlign: 'center' }}>
          {title}
        </Text>
        <TextField
          name={title}
          placeholder={placeholder}
          value={value}
          textAlign="center"
          rows={4}
          onChange={onChange}
        />
        {description && <InfoBox>{description}</InfoBox>}
      </Flex>
    </InsertTextStepWrapper>
  )
}
