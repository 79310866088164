import styled from '@emotion/styled'

import { CheckBold } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, TRANSITION } from '../../../theme'
import Flex from '../Flex'
import Text from '../Text'

import type { BaseInputProps } from './Input'
import type { InputHTMLAttributes } from 'react'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & BaseInputProps

const CheckboxWrapper = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  color: ${COLOR.neutral.darkest};
  flex-shrink: 0;
`

const Wrapper = styled(Flex)`
  text-align: start;
`

const CheckboxStyled = styled.input`
  width: 100%;
  height: 100%;
  border-radius: ${BORDER_RADIUS.sm};
  appearance: none;
  border: 1px solid ${COLOR.neutral.light};
  background: ${COLOR.white};
  transition: ${TRANSITION.base};

  ~ .icon {
    opacity: 0;
    transition: ${TRANSITION.base};
  }

  :checked {
    background: ${COLOR.primary.DEFAULT};
    border: 1px solid ${COLOR.primary.DEFAULT};

    ~ .icon {
      opacity: 1;
    }
  }
`

const IconWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

export default function Checkbox(props: Props) {
  const { inputName, label, ...rest } = props

  return (
    <Wrapper gap={16} direction="row">
      <CheckboxWrapper>
        <CheckboxStyled id={inputName} {...rest} type="checkbox" />
        <IconWrapper className="icon">
          <CheckBold width={16} height={16} />
        </IconWrapper>
      </CheckboxWrapper>
      <label htmlFor={inputName}>
        <Text color={COLOR.neutral.darkest} size={FONT.size.base} weight={FONT.weight.normal}>
          {label}
        </Text>
      </label>
    </Wrapper>
  )
}
