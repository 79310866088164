import { Capacitor } from '@capacitor/core'
import { css, cx } from '@emotion/css'
import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

import './services/i18n'

import './clarity'

import App from './App'
import Button from './components/ui/Button/Button'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Suspense fallback={<></>}>
    <App />
    {process.env.NODE_ENV === 'development' && Capacitor.isNativePlatform() && (
      <div
        className={cx('ion-safe-area-bottom', css({ position: 'fixed', bottom: 100, left: 8 }))}
        onClick={() => window.location.reload()}
      >
        <Button size="small">Reload</Button>
      </div>
    )}
  </Suspense>,
)
