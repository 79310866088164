import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgHouse(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_42_454)">
        <path
          fillRule="evenodd"
          d="M11.5119.180558C11.7928-.0601858 12.2072-.0601858 12.4881.180558L22.9881 9.18056C23.3026 9.45012 23.339 9.9236 23.0694 10.2381 22.7999 10.5526 22.3264 10.589 22.0119 10.3194L12 1.73781 1.98808 10.3194C1.67358 10.589 1.20011 10.5526.930541 10.2381.660974 9.9236.697395 9.45012 1.01189 9.18056L11.5119.180558ZM11.5126 4.67995C11.7932 4.44002 12.2067 4.44002 12.4874 4.67995L21.1074 12.05C21.1134 12.0551 21.1194 12.0604 21.1252 12.0657 21.4971 12.4047 21.721 12.8761 21.7488 13.3786 21.7496 13.3924 21.75 13.4062 21.75 13.42V22.27C21.75 22.7341 21.5656 23.1792 21.2374 23.5074 20.9092 23.8356 20.4641 24.02 20 24.02H14.75C14.3358 24.02 14 23.6842 14 23.27V16.53H9.99998V23.27C9.99998 23.6842 9.6642 24.02 9.24998 24.02H3.99998C3.53585 24.02 3.09073 23.8356 2.76255 23.5074 2.43436 23.1792 2.24998 22.7341 2.24998 22.27V13.42C2.24998 13.4062 2.25037 13.3924 2.25113 13.3786 2.27893 12.8761 2.50286 12.4047 2.87472 12.0657 2.88059 12.0604 2.88656 12.0551 2.8926 12.05L11.5126 4.67995ZM3.87871 13.1804C3.80503 13.2504 3.75934 13.3448 3.74998 13.4459V22.27C3.74998 22.3363 3.77632 22.3999 3.82321 22.4468 3.87009 22.4937 3.93368 22.52 3.99998 22.52H8.49998V16.27C8.49998 16.1072 8.53206 15.9459 8.59437 15.7955 8.65669 15.645 8.74802 15.5083 8.86317 15.3932 8.97832 15.278 9.11502 15.1867 9.26546 15.1244 9.4159 15.0621 9.57714 15.03 9.73998 15.03H14.26C14.4228 15.03 14.5841 15.0621 14.7345 15.1244 14.8849 15.1867 15.0216 15.278 15.1368 15.3932 15.2519 15.5083 15.3433 15.645 15.4056 15.7955 15.4679 15.9459 15.5 16.1072 15.5 16.27V22.52H20C20.0663 22.52 20.1299 22.4937 20.1768 22.4468 20.2236 22.3999 20.25 22.3363 20.25 22.27V13.4459C20.2406 13.3448 20.1949 13.2504 20.1213 13.1804L12 6.23676 3.87871 13.1804Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_454">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgHouse)
export default Memo
