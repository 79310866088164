import { css } from '@emotion/css'
import { useMemo } from 'react'

import { QuestionBubble } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT } from '../../../theme'
import { BRAND_COLORS } from '../../../utils/colors'
import { parseReceiverName } from '../../../utils/textParsing'
import Flex from '../../ui/Flex'
import Text from '../../ui/Text'

import type { ReactNode } from 'react'

interface QuestionItemProps {
  questionTitle: string
  questionId?: string
  receiverName?: string
  button: ReactNode
}

export default function QuestionItem(props: QuestionItemProps) {
  const { questionTitle, receiverName, button } = props

  const color = COLOR[BRAND_COLORS[0]].light

  const title = useMemo(
    () => parseReceiverName(questionTitle, receiverName || ''),
    [questionTitle, receiverName],
  )

  return (
    <Flex alignItems="center">
      <Flex
        alignItems="center"
        justifyContent="center"
        className={css({
          flexShrink: 0,
          width: 32,
          height: 32,
          borderRadius: BORDER_RADIUS.sm,
          backgroundColor: color,
        })}
      >
        <QuestionBubble height={16} width={16} />
      </Flex>
      <Text
        size={FONT.size.base}
        weight={FONT.weight.normal}
        className={css({ flex: 1, lineHeight: 1.25, wordBreak: 'break-all' })}
      >
        {title}
      </Text>
      <Flex alignItems="center" justifyContent="center" className={css({ flexShrink: 0 })}>
        {button}
      </Flex>
    </Flex>
  )
}
