import styled from '@emotion/styled'
import { IonRouterLink } from '@ionic/react'

import { COLOR } from '../../../theme'

import type { HTMLAttributes } from 'react'

export interface AppLinkProps extends HTMLAttributes<HTMLIonRouterLinkElement> {
  to: string
  direction?: 'back' | 'forward' | 'none'
}

const AppLinkStyled = styled(IonRouterLink)`
  padding: 0 0 4px;
  color: ${COLOR.black};
  border-bottom: 2px solid ${COLOR.primary.DEFAULT};
  width: fit-content;
  align-self: center;

  &:active {
    border-bottom: 2px solid ${COLOR.primary.dark};
  }
`

export default function AppLink(props: AppLinkProps) {
  const { to, children, direction = 'forward' } = props

  return (
    <AppLinkStyled routerLink={to} routerDirection={direction}>
      {children}
    </AppLinkStyled>
  )
}
