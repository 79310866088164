import { Capacitor } from '@capacitor/core'
import styled from '@emotion/styled'
import { useIonModal } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { DraggableStyled } from '../../../components/common/Draggable'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import { useAuthContext } from '../../../context/AuthContext'
import { Play, View, ViewOff } from '../../../icons'
import {
  InterstitialPlacement,
  IronSourceIntegration,
} from '../../../plugins/IronSourceIntegration'
import { BORDER_RADIUS, COLOR, TRANSITION } from '../../../theme'
import { alpha } from '../../../utils/colors'
import { flexGapString } from '../../../utils/css'
import { setNavigationBarBlack, setNavigationBarLight } from '../../../utils/navigationBar'
import { setStatusbarBlack, setStatusbarLight } from '../../../utils/statusBar'
import { getFullName } from '../../../utils/user'

import ViewClip from './ViewClip'

import type { ProjectAnswerDto, ProjectQuestionDto } from '../../../types'
import type { MouseEventHandler } from 'react'

interface Props {
  answer: ProjectAnswerDto
  question: ProjectQuestionDto
  receiverName: string
  isDragging?: boolean
  onAnswerUpdated: (id: string, excluded: boolean) => void
}

export const DraggableItemStyled = styled(DraggableStyled)<{ excluded: boolean }>`
  padding: 8px;
  display: flex;
  border-radius: ${BORDER_RADIUS.base};
  ${flexGapString(16, 'column')}
  align-items: center;
  opacity: ${({ excluded }) => (excluded ? 0.25 : 1)};
  transition: ${TRANSITION.base};
  background-color: ${({ isDragging }) =>
    isDragging ? `${COLOR.white} !important` : 'transparent'};
  box-shadow: ${({ isDragging }) => (isDragging ? `0 0 13px 0 rgba(0, 0, 0, 0.25)` : 'none')};

  :active:not(:has(button:active)) {
    background-color: ${COLOR.neutral.lighter};
  }
`

const ThumbnailStyled = styled.div`
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: ${BORDER_RADIUS.sm};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    position: relative;
    z-index: 2;
  }

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
`

const ThumbnailOverlayStyled = styled.div`
  position: absolute;
  inset: 0;
  background: ${alpha(COLOR.black, 0.3)};
  z-index: 1;
`

const DataStyled = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  ${flexGapString(2, 'row')}
`

const VisibleToggleStyled = styled.div`
  flex-shrink: 0;
`

export default function DraggableClipItem(props: Props) {
  const { answer, question, isDragging = false, receiverName, onAnswerUpdated } = props
  const { user } = useAuthContext()
  const { t } = useTranslation()

  const [presentClipViewModal, dismissClipViewModal] = useIonModal(ViewClip, {
    answer,
    question,
    onDismiss: async () => {
      try {
        if (Capacitor.isNativePlatform())
          await IronSourceIntegration.showInterstitial({
            placement: InterstitialPlacement.WatchClips,
          })
      } catch {
        // no-op
      }
      dismissClipViewModal()
    },
    receiverName,
  })

  const onClick: MouseEventHandler<HTMLDivElement> = () => {
    if (Capacitor.isNativePlatform()) void IronSourceIntegration.loadInterstitial()

    presentClipViewModal({
      onWillPresent: () => {
        setNavigationBarBlack()
        setStatusbarBlack()
      },
      onWillDismiss: () => {
        setStatusbarLight()
      },
      onDidDismiss: () => {
        setNavigationBarLight()
      },
    })
  }

  const toggleExcluded = () => {
    onAnswerUpdated(answer.id, !answer.isExcluded)
  }

  return (
    <DraggableItemStyled excluded={answer.isExcluded} isDragging={isDragging} onClick={onClick}>
      <ThumbnailStyled>
        <img src={answer.video.thumbnailUrl} alt="" />
        <ThumbnailOverlayStyled />
        <Play height={16} width={16} color={COLOR.white} />
      </ThumbnailStyled>
      <DataStyled>
        <Text>{getFullName(answer.answerBy, user, t)}</Text>
      </DataStyled>
      <VisibleToggleStyled>
        <IconButton
          icon={answer.isExcluded ? <View /> : <ViewOff />}
          title=""
          onClick={(event) => {
            event.stopPropagation()
            toggleExcluded()
          }}
        />
      </VisibleToggleStyled>
    </DraggableItemStyled>
  )
}
