import { useEffect, useState } from 'react'

import { useApiContext } from '../context/ApiContext'
import { useProjectContext } from '../context/ProjectContext'

export function useProjectQuestions(projectId: string) {
  const { apiClient } = useApiContext()
  const { questions, setQuestions, project } = useProjectContext()

  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const refetch = async () => {
    try {
      const refetchedQuestions = await apiClient.getProjectQuestions(projectId)
      setQuestions(refetchedQuestions)
    } catch {}
  }

  useEffect(() => {
    if (isFetching) return

    if (project?.id === projectId && questions.length > 0) return

    setIsFetching(true)
    setIsLoading(true)

    setQuestions([])

    apiClient
      .getProjectQuestions(projectId)
      .then((projectQuestions) => {
        setQuestions(projectQuestions)
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false)
        setIsFetching(false)
      })
  }, [])

  return { questions, refetch, isLoading, isError }
}
