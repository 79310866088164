import styled from '@emotion/styled'
import { IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import PausableVideo from '../../../components/video/PausableVideo'
import { useAuthContext } from '../../../context/AuthContext'
import { CloseCircle } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { parseReceiverName } from '../../../utils/textParsing'
import { getFullName } from '../../../utils/user'

import type { ProjectAnswerDto, ProjectQuestionDto } from '../../../types'

export interface ViewClipProps {
  onDismiss: () => void
  answer: ProjectAnswerDto
  question: ProjectQuestionDto
  receiverName: string
}

const ToolbarStyled = styled(IonToolbar)`
  --background: ${COLOR.black};
  --color: ${COLOR.white};
`

const TitleStyled = styled(IonTitle)`
  font-size: ${FONT.size.sm};
`

export default function ViewClip(props: ViewClipProps) {
  const { onDismiss, answer, question, receiverName } = props
  const { t } = useTranslation()
  const { user } = useAuthContext()

  return (
    <>
      <IonHeader>
        <ToolbarStyled>
          <TitleStyled>
            {parseReceiverName(question.title, receiverName)}
            <Text
              tag="p"
              color={COLOR.neutral.DEFAULT}
              size={FONT.size.sm}
              weight={FONT.weight.medium}
            >
              {getFullName(answer.answerBy, user, t)}
            </Text>
          </TitleStyled>
          <IonButtons slot="end">
            <IconButton icon={<CloseCircle />} title="" onClick={onDismiss} onDark={true} />
          </IonButtons>
        </ToolbarStyled>
      </IonHeader>
      <IonContent>
        <PausableVideo video={answer.video} showProgress={true} layout="fullscreen" />
      </IonContent>
    </>
  )
}
