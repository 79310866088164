import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, FONT, TRANSITION } from '../../theme'
import { getBrandColor } from '../../utils/colors'

interface Props {
  text: string
  variant?: 'primary' | 'secondary' | 'colorful'
}

const ChipStyled = styled.span<{ isSecondary: boolean; color: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-shadow: ${({ isSecondary }) =>
    isSecondary ? `inset 0 0 0 1px ${COLOR.neutral.light}` : 'none'};
  border-radius: ${BORDER_RADIUS.full};
  background: ${({ isSecondary, color }) => (isSecondary ? COLOR.white : color)};
  color: ${COLOR.neutral.darkest};
  font-size: ${FONT.size.sm};
  font-weight: ${FONT.weight.normal};
  line-height: 18px;
  transition: ${TRANSITION.base};
`

export default function Chip(props: Props) {
  const { text, variant = 'primary' } = props

  const isSecondary = variant === 'secondary'

  const color = {
    primary: COLOR.primary.DEFAULT,
    secondary: COLOR.neutral.light,
    colorful: COLOR[getBrandColor(text)].light,
  }

  return (
    <ChipStyled isSecondary={isSecondary} color={color[variant]}>
      {text}
    </ChipStyled>
  )
}

interface InteractiveChipProps extends Props {
  onClick: () => void
}

const UnstyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: inline;

  :focus {
    outline: none;
  }
`

export function InteractiveChip(props: InteractiveChipProps) {
  const { onClick, ...rest } = props

  return (
    <UnstyledButton onClick={onClick}>
      <Chip {...rest} />
    </UnstyledButton>
  )
}
