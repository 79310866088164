import { IonContent, useIonViewWillEnter } from '@ionic/react'

import { ROUTES } from '../../Router'
import { FONT } from '../../theme'
import { setStatusbarLight } from '../../utils/statusBar'
import Text from '../ui/Text'

import Page from './Page'
import SubpageHeader from './SubpageHeader'

import type { ProjectDto } from '../../types'
import type { PropsWithChildren, ReactNode } from 'react'

interface Props {
  title?: string
  headerButton?: ReactNode
  project?: ProjectDto | null
  showBackButton?: boolean
  backButtonRoute?: string
}

export default function ProjectSubpage(props: PropsWithChildren<Props>) {
  const {
    project,
    title,
    headerButton,
    children,
    showBackButton = true,
    backButtonRoute = ROUTES.projectDashboard(project?.id),
  } = props

  useIonViewWillEnter(() => {
    setStatusbarLight()
  })

  return (
    <Page>
      <SubpageHeader
        title={project?.title || ''}
        showBackButton={showBackButton}
        backButtonRoute={backButtonRoute}
        button={headerButton}
      />
      <IonContent className="ion-padding">
        {title && (
          <Text
            tag="h1"
            size={FONT.size.xl}
            weight={FONT.weight.semibold}
            style={{ marginBottom: 20 }}
          >
            {title}
          </Text>
        )}
        {children}
      </IonContent>
    </Page>
  )
}
