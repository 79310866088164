import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgInfoCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_210_338)">
        <path d="M12.75 7.125C12.75 6.50366 12.2463 6 11.625 6 11.0037 6 10.5 6.50366 10.5 7.125 10.5 7.74634 11.0037 8.25 11.625 8.25 12.2463 8.25 12.75 7.74634 12.75 7.125ZM10.5 9.75C10.0858 9.75 9.75 10.0858 9.75 10.5 9.75 10.9142 10.0858 11.25 10.5 11.25H11.2499L11.25 15C11.25 15.5966 11.487 16.169 11.909 16.591 12.331 17.013 12.9034 17.25 13.5 17.25H14.25C14.6642 17.25 15 16.9142 15 16.5 15 16.0858 14.6642 15.75 14.25 15.75H13.5C13.301 15.75 13.1102 15.671 12.9696 15.5304 12.829 15.3898 12.75 15.199 12.75 15V11.25C12.75 10.8525 12.5922 10.4709 12.3108 10.1896 12.0295 9.9082 11.6475 9.75 11.25 9.75H10.5Z" />
        <path
          fillRule="evenodd"
          d="M12 0C5.37259 0 0 5.37259 0 12C0 18.6274 5.37259 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0ZM1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_210_338">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgInfoCircle)
export default Memo
