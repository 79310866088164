interface BaseModel {
  id: string
  created?: string
  updated?: string
}

export interface LoginResponseDto {
  accessToken: string
  userId: string
  email: string
  emailValidated: boolean
  firstName: string
  status: USER_STATUS
  lastName: string
  avatar: {
    sourceUrl: string
    thumbnailUrl: string
  }
  role: {
    name: string
    numLiteral: number
  }
  hasProjectWizardSeen: boolean
  hasAppOnboardingWizardSeen: boolean
}

export enum USER_STATUS {
  COMPELETE_REGISTRATION_NEEDED = 'registration needed',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  GUEST = 'guest',
}

export interface UserDto extends BaseModel {
  accessToken: string
  firstName: string
  lastName: string
  email: string
  status: USER_STATUS
  avatar?: MediaDto
  emailValidated: boolean
  hasProjectWizardSeen: boolean
  hasAppOnboardingWizardSeen: boolean
  phone?: string
  lang?: string
  isRegisteredWithGoogle?: boolean
  isRegisteredWithApple?: boolean
}

export interface RegisterRequestDto {
  email: string
  password: string
  firstName: string
  lastName: string
}

export interface BlogPostDto extends BaseModel {
  title: string
  featuredImage: MediaDto
  content: string
}

export interface ProjectCategoryDto extends BaseModel {
  title: string
  type: string
}

export interface MediaDto extends BaseModel {
  sourceUrl: string
  thumbnailUrl: string
}

export enum CollaboratorStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export interface CollaboratorDto extends BaseModel {
  user: UserDto
  project: ProjectDto
  status: CollaboratorStatus
}

export interface QuestionOptionDto {
  id: string
  created: string
  updated: string
  title: string
  context: string
  projectCategory: ProjectCategoryDto
}

export enum ActivityAction {
  CREATE_PROJECT = 'created-project',
  CREATE_ANSWER = 'created-answer',
  CREATE_QUESTION = 'created-question',
  ACCEPT_INVITATION = 'accept-invitation',
  CREATE_INVITATION = 'created-invitation',
  JOINED_PROJECT = 'joined-project',
  PRODUCTION_QUEUED = 'production-queued',
  PRODUCTION_STARTED = 'production-started',
  PRODUCTION_FINISHED = 'production-finished',
  PRODUCTION_FAILED = 'production-failed',
  RECEIVER_HAS_VIEWED = 'receiver-has-viewed',
  CREATE_ATTACHMENT = 'created-attachment',
}

export interface ActivityDto {
  id: string
  created: string
  updated: string
  action: ActivityAction
  actionCount: number
  user: UserDto
}

export enum ProjectStatus {
  Draft = 'draft',
  Planned = 'planned',
  QuestionSelected = 'question_selected',
  InProgress = 'in_progress',
  Deleted = 'deleted',
  Completed = 'completed',
}

export interface ProjectAttachmentDto extends BaseModel {
  submittedBy: UserDto
  media: MediaDto
  isExcluded: boolean
}

export interface CreateProjectAttachmentDto {
  media: CreateMediaDto
}

export interface UpdateProjectAttachmentDto {
  isExcluded: boolean
}

export type AttachmentOrderDto = Pick<ProjectAttachmentDto, 'id'>

export interface UpdateProjectAttachmentsOrderDto {
  attachments: AttachmentOrderDto[]
}

export interface ProjectDto extends BaseModel {
  eventId: string
  title: string
  thumbnail: MediaDto
  dueAt: string
  lastActivityAt: string
  created: string
  creator: UserDto
  progress: number | undefined
  invitationCode: string
  collaborators: CollaboratorDto[]
  attachments: ProjectAttachmentDto[]
  category: ProjectCategoryDto
  questions: ProjectQuestionDto[]
  receiverName: string
  status: ProjectStatus
  finalVideo?: MediaDto
  productionJob?: ProductionJobDto
  musicTrack: MusicTrackDto | null
}

export enum ProductionJobStatus {
  Queued = 'queued',
  InProgress = 'in_progress',
  NoCredits = 'no_credits',
  Completed = 'completed',
  Failed = 'failed',
  NoClips = 'no_clips',
}

export interface ProductionJobDto extends BaseModel {
  status: ProductionJobStatus
}

export interface PaginationDto<T> {
  items: T[]
  meta: {
    totalCount: number
    currentPage: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
  }
}

export interface CreateProjectDto {
  projectCategoryId: string
  title: string
  dueAt: string
  receiverName: string
  thumbnail: CreateMediaDto
  collaborators: CollaboratorDto[]
  musicTrackId?: string
  invitationCode: string
}

export interface PaginationQueryParams {
  page?: number
  limit?: number
}

export interface GetProjectsQueryParams extends PaginationQueryParams {
  createdByUserOnly?: boolean
  status?: ProjectStatus[]
}

export interface ProjectAnswerDto extends BaseModel {
  answerBy: UserDto
  video: MediaDto
  question?: ProjectQuestionDto
  order: number
  isExcluded: boolean
}

export interface ProjectQuestionDto extends BaseModel {
  title: string
  project: ProjectDto
  multipleAnswers: boolean
  answers?: ProjectAnswerDto[]
}

export type CreateProjectQuestionDto = Pick<ProjectQuestionDto, 'title' | 'multipleAnswers'>

export interface UpdateQuestionsOrderDto {
  id: string
  answers: { id: string }[]
}

export interface CreateProjectAnswerDto {
  video: CreateMediaDto
}

export type UpdateProjectAnswerDto = Pick<ProjectAnswerDto, 'isExcluded'>

export interface CreateMediaDto {
  sourceUrl: string
  thumbnailUrl?: string
}

export type UpdateMediaDto = Partial<CreateMediaDto>

export interface UpdateProjectDto {
  title?: string
  dueAt?: string
  receiverName?: string
  thumbnail?: UpdateMediaDto
  musicTrackId?: string | null
}

export interface AnswerWithQuestionId extends ProjectAnswerDto {
  questionId: string
  questionTitle: string
}

export interface CreateReportDto {
  reason: string
}

export enum StoreType {
  GooglePlay = 'google_play_store',
  AppStore = 'apple_store',
}

export enum OrderStatus {
  Pending = 'pending',
  Completed = 'completed',
  Refunded = 'refunded',
}

export interface CreateInAppOrderDto {
  inAppId: string
  orderedFrom: StoreType
  status: OrderStatus
  totalAmount: number
}

export type UpdateInAppOrderDto = Pick<CreateInAppOrderDto, 'status'>

export interface OrderDto extends BaseModel {
  buyer: UserDto
  orderNumber: string
  orderedFrom: StoreType
  totalAmount: number
  totalDiscount: number
}

export interface PurchaseDto extends BaseModel {
  foo: string
}

export interface BlockUserDto {
  blockedId: string
}

export interface MusicTrackDto extends BaseModel {
  title: string
  sourceUrl: string
  category: ProjectCategoryDto
}

export interface LoginWithAppleDto {
  idToken: string
  firstName?: string | null
  lastName?: string | null
}

export interface LoginAsGuestDto {
  firstName: string | null
  lastName?: string | null
}

export enum ONBOARDING_SLIDE_TYPE {
  VIDEO = 'video',
  IMAGE = 'image',
}

export interface OnboardingSlideDto {
  mediaUrl: string
  mediaType: ONBOARDING_SLIDE_TYPE
  title: string
  text: string
}

export interface AppConfigDto {
  lang: string
  exampleProjectVideoUrls: string[]
  appStartVideoUrl: string
  linkTerms: string
  linkPrivacy: string
  onboardingSlides: OnboardingSlideDto[]
}
