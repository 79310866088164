import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { Global } from '@emotion/react'
import { IonApp, setupIonicReact } from '@ionic/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from 'react'

import { GLOBAL_STYLES } from './App.styled'
import IAPListeners from './IAPListeners'
import PushNotificationSetup from './PushNotificationSetup'
import Router from './Router'
import { ApiProvider } from './context/ApiContext'
import { AppConfigProvider } from './context/AppConfigContext'
import { AuthProvider } from './context/AuthContext'
import { ProjectProvider } from './context/ProjectContext'
import { AndroidSafeArea } from './plugins/AndroidSafeArea'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Fonts */
import '@fontsource/jetbrains-mono/600.css'
import '@fontsource/outfit/400.css'
import '@fontsource/outfit/500.css'
import '@fontsource/outfit/600.css'

/* Tailwind css */
import './input.css'

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: Capacitor.getPlatform() === 'ios',
  keyboardHeight: Capacitor.getPlatform() === 'ios' ? 0 : undefined,
})

const queryClient = new QueryClient()

export const DEFAULT_SPINNER = 'crescent'

export default function App() {
  useEffect(() => {
    const init = async () => {
      if (Capacitor.isNativePlatform()) {
        if (Capacitor.getPlatform() === 'android') {
          void AndroidSafeArea.getSafeAreaInsets().then(({ top, bottom, left, right }) => {
            document.documentElement.style.setProperty('--ion-safe-area-top', `${top}px`)
            document.documentElement.style.setProperty('--ion-safe-area-bottom', `${bottom}px`)
            document.documentElement.style.setProperty('--ion-safe-area-left', `${left}px`)
            document.documentElement.style.setProperty('--ion-safe-area-right', `${right}px`)
          })
        }

        await GoogleAuth.signOut()
      } else {
        await FirebaseAnalytics.initializeFirebase({
          apiKey: process.env.REACT_APP_FIREBASE_ANALYTICS_API_KEY ?? '',
          appId: process.env.REACT_APP_FIREBASE_ANALYTICS_APP_ID ?? '',
          projectId: process.env.REACT_APP_FIREBASE_ANALYTICS_PROJECT_ID ?? '',
          messagingSenderId: process.env.REACT_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID ?? '',
          storageBucket: process.env.REACT_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET ?? '',
          authDomain: process.env.REACT_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN ?? '',
          measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID ?? '',
        })
      }

      // TODO: set correct pseudo user id
      await FirebaseAnalytics.setUserId({
        userId: '123456',
      })
    }

    void init()
  }, [])

  return (
    <ApiProvider>
      <AppConfigProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ProjectProvider>
              <Global styles={GLOBAL_STYLES} />
              <IonApp>
                <IAPListeners />
                <PushNotificationSetup />
                <Router />
              </IonApp>
            </ProjectProvider>
          </AuthProvider>
        </QueryClientProvider>
      </AppConfigProvider>
    </ApiProvider>
  )
}
