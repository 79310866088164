import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'
import { ProjectStatus } from '../types'

export interface UseProjectsGetArgs {
  /**
   * The number of items to show per page.
   */
  limit?: number

  /**
   * If true, will return only projects that the current user created.
   */
  createdByUserOnly?: boolean
}

/**
 * Fetch projects from the API.
 *
 * @param params
 */
export function useProjects(params?: UseProjectsGetArgs) {
  const { limit = 10, createdByUserOnly = false } = params || {}

  const { apiClient } = useApiContext()

  return useQuery(
    ['projects'],
    () =>
      apiClient.getProjects({
        page: 1,
        limit,
        status: [
          ProjectStatus.Planned,
          ProjectStatus.Draft,
          ProjectStatus.InProgress,
          ProjectStatus.Completed,
        ],
        createdByUserOnly,
      }),
    {
      retry: 2,
    },
  )
}

/**
 * Fetch projects from the API in an infinite scroll fashion.
 *
 * @param queryName
 * @param params
 */
export function useInfiniteProjects(queryName: string, params?: UseProjectsGetArgs) {
  const { limit = 10, createdByUserOnly = false } = params || {}

  const { apiClient } = useApiContext()

  return useInfiniteQuery(
    [queryName],
    ({ pageParam = 1 }) =>
      apiClient.getProjects({
        page: pageParam as number,
        limit,
        createdByUserOnly,
      }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.items.length < limit ? undefined : pages.length + 1,
    },
  )
}
