import styled from '@emotion/styled'
import { IonButton, IonContent, IonHeader, IonModal, IonSpinner } from '@ionic/react'
import Lottie from 'lottie-react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import giftAnimation from '../../../animations/gift.json'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'

import type { LottieRefCurrentProps } from 'lottie-react'

const IonModalStyled = styled(IonModal)`
  --height: 50%;
  --width: 80%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --background: rgb(14 116 144);
  --color: white;

  ::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
`

interface Props {
  show: boolean
  setShow: (show: boolean) => void
}

export function ClaimFreeCredits(props: Props) {
  const { show, setShow } = props
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const { apiClient } = useApiContext()
  const { fetchAvailableProductionJobs } = useAuthContext()
  const [segments, setSegments] = useState<[number, number] | undefined>([0, 14.5])
  const [loop, setLoop] = useState(true)
  const [isClaiming, setIsClaiming] = useState(false)

  const onClaim = async () => {
    setIsClaiming(true)
    setSegments(undefined)
    setLoop(false)
    await apiClient.triggerClaimFreeCredits().catch(() => {
      return null
    })
  }
  const onComplete = () => {
    setShow(false)
    setIsClaiming(false)
    fetchAvailableProductionJobs()
  }

  const { t } = useTranslation()

  return (
    <IonModalStyled id="example-modal" isOpen={show} trigger="open-modal">
      <IonContent>
        <div className="relative flex h-full flex-col justify-between px-8 pb-8 pt-16 text-center">
          <IonHeader className="flex justify-center text-2xl font-semibold">
            {t('Dashboard.claimFreeCredits')}
          </IonHeader>
          <div className="flex flex-1 justify-center">
            <Lottie
              initialSegment={segments}
              lottieRef={lottieRef}
              animationData={giftAnimation}
              autoplay={true}
              loop={loop}
              onComplete={onComplete}
            />
          </div>
          <IonButton
            className={`${isClaiming && 'opacity-50'} text-xl font-semibold`}
            onClick={() => void onClaim()}
          >
            {isClaiming ? <IonSpinner name={DEFAULT_SPINNER} /> : t('Dashboard.claimButton')}
          </IonButton>
        </div>
      </IonContent>
    </IonModalStyled>
  )
}
