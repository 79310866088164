import { css } from '@emotion/css'
import { useTranslation } from 'react-i18next'

import { CheckBold } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { NO_MUSIC_TRACK_ID } from '../../../views/NewProject/NewProjectWizard/NewProjectWizard'
import MusicTrackItem, { MusicItemStyled } from '../../common/MusicTrackItem'
import { FadingEnterLeaveTransition } from '../../common/Transitions'
import Flex from '../../ui/Flex'
import Text from '../../ui/Text'

import type { MusicTrackDto } from '../../../types'

interface Props {
  musicTracks?: MusicTrackDto[]
  activeMusicTrackId?: string
  onSelect: (value?: string) => void
}

export default function MusicTrackSelection(props: Props) {
  const { musicTracks = [], activeMusicTrackId, onSelect } = props

  const { t } = useTranslation()

  if (!musicTracks.length) return null

  const noMusicTrackSelected = !activeMusicTrackId || activeMusicTrackId === NO_MUSIC_TRACK_ID

  return (
    <Flex direction="column">
      <MusicItemStyled isSelected={noMusicTrackSelected} onClick={() => onSelect()}>
        <Flex alignItems="center">
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
              width: 20,
              height: 20,
              borderRadius: '50%',
              backgroundColor: noMusicTrackSelected ? COLOR.primary.DEFAULT : COLOR.white,
              border: `1px solid ${
                noMusicTrackSelected ? COLOR.primary.DEFAULT : COLOR.neutral.light
              }`,
            })}
          >
            <FadingEnterLeaveTransition show={noMusicTrackSelected}>
              <CheckBold height={12} width={12} />
            </FadingEnterLeaveTransition>
          </div>
          <Text size={FONT.size.base}>{t('NewProject.MusicTrack.noMusicTrack')}</Text>
        </Flex>
      </MusicItemStyled>
      {musicTracks.map((musicTrack) => (
        <MusicTrackItem
          key={musicTrack.id}
          musicTrack={musicTrack}
          isSelected={activeMusicTrackId === musicTrack.id}
          onClick={() => onSelect(musicTrack.id)}
        />
      ))}
    </Flex>
  )
}
