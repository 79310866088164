import styled from '@emotion/styled'
import { IonContent, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import Text from '../../../../components/ui/Text'
import { useApiContext } from '../../../../context/ApiContext'
import { useAppConfig } from '../../../../context/AppConfigContext'
import { useAuthContext } from '../../../../context/AuthContext'
import { CheckBold } from '../../../../icons'
import { FONT } from '../../../../theme'

const SupportedLanguages = [
  { code: 'cs', name: 'Česky' },
  { code: 'da', name: 'Dansk' },
  { code: 'de', name: 'Deutsch' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'it', name: 'Italiano' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'nb', name: 'Norsk' },
  { code: 'pl', name: 'Polski' },
  { code: 'pt', name: 'Português' },
  { code: 'fi', name: 'Suomalainen' },
  { code: 'sv', name: 'Svenska' },
]

const LanguageStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  font-size: ${FONT.size.base};
  line-height: ${FONT.lineHeight.xl};
  margin-bottom: 10px;
`

export default function LanguageSettings() {
  const { t, i18n } = useTranslation()
  const ionRouter = useIonRouter()

  const { user } = useAuthContext()
  const { apiClient } = useApiContext()
  const { refetchConfig } = useAppConfig()

  if (!user) {
    ionRouter.push('/login', 'none')
    return null
  }

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language)
    await refetchConfig()
    console.log('user?.id', user?.id, language)
    await apiClient.updateUser(user?.id, { lang: language })
  }

  return (
    <Page>
      <SubpageHeader
        title={t('Profile.AppSettings.Language.title')}
        showBackButton={true}
        backButtonRoute={ROUTES.appSettings}
      />
      <IonContent className="ion-padding">
        {SupportedLanguages.map((language) => (
          <LanguageStyled key={language.code} onClick={() => void changeLanguage(language.code)}>
            <Text
              weight={
                i18n.resolvedLanguage === language.code ? FONT.weight.semibold : FONT.weight.normal
              }
            >
              {t(language.name)}
            </Text>
            {i18n.resolvedLanguage === language.code ? <CheckBold /> : ''}
          </LanguageStyled>
        ))}
      </IonContent>
    </Page>
  )
}
