import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, FONT, SHADOW } from '../../theme'

import Flex from './Flex'
import Text from './Text'

import type { ReactNode } from 'react'

interface Props {
  icon: ReactNode
  message: string
}

interface MessageCardIconProps {
  children: ReactNode
  backgroundColor?: string
}

const MessageCardStyled = styled.div`
  border-radius: ${BORDER_RADIUS.base};
  border: 1px solid ${COLOR.neutral.lighter};
  box-shadow: ${SHADOW.base};
  padding: 16px;
`

const MessageStyled = styled(Text)`
  padding-top: 6px;
  flex: 1;
`

const StyledDiv = styled.div<MessageCardIconProps>`
  flex-shrink: 0;
  padding: 8px;
  border-radius: 12px;
  background: ${(props) => props?.backgroundColor ?? COLOR.neutral.lighter};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
`

export function MessageCardIconStyled(props: MessageCardIconProps) {
  return <StyledDiv {...props} />
}

export default function MessageCard(props: Props) {
  const { icon, message } = props

  return (
    <MessageCardStyled>
      <Flex alignItems="flex-start" gap={12}>
        <MessageCardIconStyled>{icon}</MessageCardIconStyled>
        <MessageStyled weight={FONT.weight.medium} style={{ lineHeight: FONT.lineHeight.base }}>
          {message}
        </MessageStyled>
      </Flex>
    </MessageCardStyled>
  )
}
