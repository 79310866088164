import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCloseBold(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M3.31599 19.0779C3.2106 19.182 3.12693 19.306 3.06982 19.4427C3.01271 19.5794 2.98331 19.726 2.98331 19.8742C2.98331 20.0223 3.01271 20.169 3.06982 20.3057C3.12693 20.4424 3.2106 20.5663 3.31599 20.6705C3.52727 20.8815 3.81367 21 4.11227 21C4.41088 21 4.69728 20.8815 4.90856 20.6705L11.8648 13.7142C11.8823 13.6961 11.9033 13.6816 11.9265 13.6717C11.9498 13.6618 11.9748 13.6568 12 13.6568C12.0253 13.6568 12.0502 13.6618 12.0735 13.6717C12.0967 13.6816 12.1177 13.6961 12.1352 13.7142L19.0915 20.6705C19.3027 20.8815 19.5891 21 19.8877 21C20.1863 21 20.4727 20.8815 20.684 20.6705C20.7894 20.5663 20.8731 20.4424 20.9302 20.3057C20.9873 20.169 21.0167 20.0223 21.0167 19.8742C21.0167 19.726 20.9873 19.5794 20.9302 19.4427C20.8731 19.306 20.7894 19.182 20.684 19.0779L13.7278 12.1217C13.7096 12.1041 13.6952 12.0831 13.6853 12.0599C13.6754 12.0367 13.6703 12.0117 13.6703 11.9864C13.6703 11.9612 13.6754 11.9362 13.6853 11.913C13.6952 11.8897 13.7096 11.8687 13.7278 11.8512L20.684 4.895C20.8831 4.68139 20.9914 4.39886 20.9863 4.10694C20.9811 3.81502 20.8629 3.53649 20.6564 3.33004C20.45 3.12359 20.1714 3.00533 19.8795 3.00018C19.5876 2.99502 19.3051 3.10338 19.0915 3.30243L12.1352 10.2587C12.1177 10.2768 12.0967 10.2913 12.0735 10.3012C12.0502 10.311 12.0253 10.3161 12 10.3161C11.9748 10.3161 11.9498 10.311 11.9265 10.3012C11.9033 10.2913 11.8823 10.2768 11.8648 10.2587L4.90856 3.30243C4.69495 3.10338 4.41243 2.99502 4.1205 3.00018C3.82858 3.00533 3.55005 3.12359 3.3436 3.33004C3.13715 3.53649 3.01889 3.81502 3.01374 4.10694C3.00859 4.39886 3.11695 4.68139 3.31599 4.895L10.2722 11.8512C10.2904 11.8687 10.3049 11.8897 10.3147 11.913C10.3246 11.9362 10.3297 11.9612 10.3297 11.9864C10.3297 12.0117 10.3246 12.0367 10.3147 12.0599C10.3049 12.0831 10.2904 12.1041 10.2722 12.1217L3.31599 19.0779Z" />
    </svg>
  )
}
const Memo = memo(SvgCloseBold)
export default Memo
