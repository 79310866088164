import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import { useAuthContext } from '../../context/AuthContext'
import ProjectDashboardItem from '../../views/Project/Dashboard/ProjectDashboardItem'
import UploadedAnswerItem from '../../views/Project/Studio/UploadedAnswerItem'
import Button from '../ui/Button/Button'
import Flex from '../ui/Flex'

import type { AnswerWithQuestionId, ProjectDto, ProjectQuestionDto } from '../../types'

interface Props {
  project: ProjectDto
  questions?: ProjectQuestionDto[]
  onDeleted?: () => void
}

export default function Answers(props: Props) {
  const { questions = [], project, onDeleted } = props

  const { t } = useTranslation()
  const { user } = useAuthContext()

  const PER_PAGE = 5
  const { value: showAll, setTrue: showAllTrue, setFalse: showAllFalse } = useBoolean(false)

  const answers = useMemo<AnswerWithQuestionId[]>(
    () =>
      questions
        .map(
          (question) =>
            question.answers
              ?.filter((answer) => answer.answerBy.id === user?.id)
              .map((answer) => ({
                ...answer,
                questionId: question.id,
                questionTitle: question.title,
              })) || [],
        )
        .flat() || [],
    [questions, user?.id],
  )

  const answersToShow = useMemo(() => {
    if (answers.length === 0) return []

    const answersSorted = answers.slice().sort((a, b) => {
      const createdA = new Date(a.created || '')
      const createdB = new Date(b.created || '')

      return createdB.getTime() - createdA.getTime()
    })

    if (showAll) return answersSorted

    return answersSorted.slice(0, PER_PAGE)
  }, [answers, showAll])

  if (questions.length === 0 || answersToShow.length === 0) return null

  return (
    <ProjectDashboardItem title={t('ProjectDashboard.Answers.title')}>
      <Flex direction="column" gap={16}>
        {answersToShow.map((answer) => (
          <UploadedAnswerItem
            key={answer.id}
            answer={answer}
            question={
              questions.find((question) => question.id === answer.questionId) as ProjectQuestionDto
            }
            project={project}
            onDeleted={onDeleted}
          />
        ))}
        {answers.length > PER_PAGE &&
          (showAll ? (
            <Button variant="secondary" onClick={showAllFalse}>
              {t('General.showLess')}
            </Button>
          ) : (
            <Button variant="secondary" onClick={showAllTrue}>
              {t('General.showAll')}
            </Button>
          ))}
      </Flex>
    </ProjectDashboardItem>
  )
}
