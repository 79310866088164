import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    supportedLngs: ['de', 'en', 'fr', 'it', 'es', 'pt', 'pl', 'cs', 'da', 'fi', 'sv', 'nb', 'nl'],
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .catch(() => {
    // no-op
  })

i18next.on('languageChanged', (lng: string) => {
  document.documentElement.setAttribute('lang', lng)
  document.documentElement.setAttribute('dir', i18next.dir(lng))
})

export default i18next
