import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { IonLabel, useIonLoading, useIonRouter } from '@ionic/react'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ROUTES } from '../../Router'
import AuthPage from '../../components/layout/AuthPage'
import Button from '../../components/ui/Button/Button'
import GhostButton from '../../components/ui/Button/GhostButton'
import Flex from '../../components/ui/Flex'
import FormInput from '../../components/ui/Input/FormInput'
import AppLink from '../../components/ui/Link/AppLink'
import { useAuthContext } from '../../context/AuthContext'

import { FormStyled } from './Auth.styled'

import type { FieldValues } from 'react-hook-form'

export default function Login() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'LoginEmail', nameOverride: 'Auth' })
  })

  const { t } = useTranslation()
  const [present, dismiss] = useIonLoading()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onSubmit' })

  const { login } = useAuthContext()

  const ionRouter = useIonRouter()
  const history = useHistory()

  const passwordRef = useRef<HTMLInputElement>(null)

  const loginErrorMessage = t('Login.error')

  const resetErrors = () => {
    if (errors.submitError) clearErrors('submitError')
  }

  const loginUser = (data: FieldValues) => {
    void present(t('Login.loading'))

    void FirebaseAnalytics.logEvent({ name: 'submitLoginEmail', params: {} })
    login(data.email as string, data.password as string)
      .then(() => {
        void dismiss()
        history.replace(ROUTES.dashboard)
      })
      .catch(() => {
        void dismiss()
        setError('submitError', { message: loginErrorMessage })
      })
  }

  return (
    <AuthPage title={t('Login.login')}>
      <Flex direction="column" gap={12}>
        {}
        <FormStyled onSubmit={handleSubmit(loginUser)}>
          <Flex direction="column" gap={12}>
            <FormInput
              control={control}
              inputName="email"
              type="email"
              errorMessage={loginErrorMessage}
              label={t('Login.email')}
              placeholder={t('Login.emailPlaceholder')}
              autoComplete="username"
            />
            <FormInput
              ref={passwordRef}
              control={control}
              inputName="password"
              type="password"
              errorMessage={loginErrorMessage}
              label={t('Login.password')}
              autoComplete="current-password"
            />
          </Flex>
          {errors.email || errors.password || errors.submitError ? (
            <IonLabel color="danger">
              {errors.submitError ? (errors.submitError.message as string) : loginErrorMessage}
            </IonLabel>
          ) : null}
          <AppLink to={ROUTES.passwordReset}>{t('PasswordReset.title')}</AppLink>
          <Button type="submit" onClick={resetErrors}>
            {t('Login.login')}
          </Button>
        </FormStyled>
        <GhostButton
          variant="secondary"
          onClick={async () => {
            await FirebaseAnalytics.logEvent({ name: 'backToLogin', params: {} })
            ionRouter.push(ROUTES.auth, 'back')
          }}
        >
          {t('Login.backToLogin')}
        </GhostButton>
      </Flex>
    </AuthPage>
  )
}
