import { Camera, CameraResultType } from '@capacitor/camera'
import styled from '@emotion/styled'
import { useIonLoading, useIonToast } from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { useApiContext } from '../../../context/ApiContext'
import { Plus } from '../../../icons'
import { BORDER_RADIUS, COLOR, TRANSITION } from '../../../theme'

export const ItemStyled = styled.button`
  position: relative;
  aspect-ratio: 1/1;
  border-radius: ${BORDER_RADIUS.base};
  background: ${COLOR.neutral.lighter};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${TRANSITION.base};
  overflow: hidden;

  &:active {
    background: ${COLOR.neutral.light};
    transform: scale(0.975);
  }
`

interface Props {
  projectId: string
  onSaved: () => void
}

export default function EmptyAttachmentItem(props: Props) {
  const { projectId, onSaved } = props

  const { t } = useTranslation()

  const { apiClient } = useApiContext()

  const [presentToast] = useIonToast()
  const [presentLoading, dismissLoading] = useIonLoading()

  const onClick = async () => {
    const { webPath } = await Camera.getPhoto({
      quality: 85,
      resultType: CameraResultType.Uri,
      saveToGallery: true,
      allowEditing: false,
      promptLabelHeader: t('General.CapCameraPrompt.header'),
      promptLabelCancel: t('General.CapCameraPrompt.cancel'),
      promptLabelPhoto: t('General.CapCameraPrompt.photo'),
      promptLabelPicture: t('General.CapCameraPrompt.picture'),
    })

    if (!webPath) return

    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('ProjectDashboard.Attachments.uploading'),
    })

    try {
      const { thumbnailUrl, smallThumbnailUrl } = await apiClient.uploadImage(webPath)

      await apiClient.createProjectAttachment(projectId, {
        media: {
          sourceUrl: thumbnailUrl,
          thumbnailUrl: smallThumbnailUrl,
        },
      })
    } catch {
      await dismissLoading()

      await presentToast({
        message: t('ProjectDashboard.Attachments.uploadFailed'),
        duration: 2500,
        icon: alertCircle,
      })

      return
    }

    onSaved()
    await dismissLoading()
    await presentToast({
      message: t('ProjectDashboard.Attachments.uploadSuccessful'),
      duration: 2500,
      icon: checkmark,
    })
  }

  return (
    <ItemStyled onClick={() => void onClick()}>
      <Plus />
    </ItemStyled>
  )
}
