import { Capacitor } from '@capacitor/core'
import { Share as NativeShare } from '@capacitor/share'
import { cx } from '@emotion/css'
import styled from '@emotion/styled'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from '@ionic/react'
import { alertCircle, checkmark, checkmarkCircle } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingDialog from '../../../components/common/LoadingDialog'
import SupportButton from '../../../components/common/SupportButton'
import { buttonBaseStyles, buttonSizeStyles } from '../../../components/ui/Button/BaseButton'
import Button, { buttonStyles, variantStyles } from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import PausableVideo from '../../../components/video/PausableVideo'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { CloseCircle, Download, Play, Share, ShoppingBasketSmile } from '../../../icons'
import { PhoneError, VideoMagic } from '../../../illustrations'
import { FileDownload, FileDownloadEvent } from '../../../plugins/FileDownload'
import { COLOR, FONT } from '../../../theme'
import { ProductionJobStatus } from '../../../types'
import { buildUrl } from '../../../utils/api'
import { REACT_APP_WEBSITE_URL } from '../../../utils/env'
import { setNavigationBarBlack, setNavigationBarLight } from '../../../utils/navigationBar'
import { setStatusbarBlack, setStatusbarLight } from '../../../utils/statusBar'
import ProductsList from '../../Shop/ProductsList'

import type { ProjectDto } from '../../../types'
import type { FC } from 'react'

interface Props {
  project: ProjectDto
  isCreator?: boolean
}

const ImageStyled = styled.img`
  height: 100px;
`

type SubViewProps = Pick<Props, 'project'>

const CreatorCompleted: FC<SubViewProps> = (props) => {
  const { project } = props

  const { t } = useTranslation()

  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const { apiClient } = useApiContext()
  const { availableProductionJobs } = useAuthContext()

  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const openVideoModal = () => setVideoModalOpen(true)

  const [shopModalOpen, setShopModalOpen] = useState(false)
  const openShopModal = () => setShopModalOpen(true)
  const closeShopModal = () => setShopModalOpen(false)

  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const openLoadingDialog = () => setLoadingDialogOpen(true)
  const closeLoadingDialog = () => setLoadingDialogOpen(false)

  const [downloadBytes, setDownloadBytes] = useState(0)

  const downloadVideo = async () => {
    if (Capacitor.getPlatform() !== 'ios' || !project.finalVideo) return

    openLoadingDialog()

    await FileDownload.downloadFile({
      url: project.finalVideo.sourceUrl,
      fileName: project.finalVideo.sourceUrl.split('/').pop() ?? `${Date.now()}.mp4`,
      successMessage: t('ProjectDashboard.Completed.Download.success'),
      errorMessage: t('ProjectDashboard.Completed.Download.error'),
    })

    const progressListener = await FileDownload.addListener(
      FileDownloadEvent.Progress,
      ({ bytesAmount }) => {
        setDownloadBytes(bytesAmount)
      },
    )

    const errorListener = await FileDownload.addListener(FileDownloadEvent.Error, () => {
      void progressListener.remove()
      void errorListener.remove()
      // eslint-disable-next-line ts/no-use-before-define
      void successListener.remove()
      closeLoadingDialog()

      void presentToast({
        icon: alertCircle,
        message: t('ProjectDashboard.Completed.Download.error'),
        duration: 3000,
      })
    })

    const successListener = await FileDownload.addListener(FileDownloadEvent.Success, () => {
      void progressListener.remove()
      void successListener.remove()
      void errorListener.remove()
      closeLoadingDialog()

      void presentToast({
        icon: checkmark,
        message: t('ProjectDashboard.Completed.Download.success'),
        duration: 3000,
      })
    })
  }

  const shareVideo = () => {
    const url = new URL(buildUrl(REACT_APP_WEBSITE_URL, 'play'))
    url.searchParams.set('id', project.id)
    url.searchParams.set('source', 'share')

    return NativeShare.share({
      title: t('ProjectDashboard.Completed.Share.title', { projectTitle: project.title }),
      text: t('ProjectDashboard.Completed.Share.title', { projectTitle: project.title }),
      url: url.toString(),
    })
  }

  const { receiverName, productionJob } = project

  const noClips = !productionJob || productionJob?.status === ProductionJobStatus.NoClips
  const isSuccessful = productionJob?.status === ProductionJobStatus.Completed
  const isFailed = productionJob?.status === ProductionJobStatus.Failed
  const noCredits = productionJob?.status === ProductionJobStatus.NoCredits
  const hasAvailableProductionJobs = availableProductionJobs > 0
  const mustBuyCredits = noCredits && !hasAvailableProductionJobs
  const canProduce = noCredits && hasAvailableProductionJobs

  const [isInProduction, setIsInProduction] = useState(
    [ProductionJobStatus.InProgress, ProductionJobStatus.Queued].includes(
      productionJob?.status ?? ProductionJobStatus.Completed,
    ),
  )

  const triggerProduction = async () => {
    if (!productionJob) return

    await presentLoading()

    try {
      await apiClient.triggerProductionJob(productionJob.id)
      await presentToast({
        icon: checkmarkCircle,
        message: t('ProjectDashboard.Completed.TriggeredProduction.success'),
        duration: 3000,
      })
      setIsInProduction(true)
    } catch {
      await presentToast({
        icon: alertCircle,
        message: t('ProjectDashboard.Completed.TriggeredProduction.error'),
        duration: 3000,
      })
    } finally {
      await dismissLoading()
    }
  }

  if (noClips) {
    return (
      <Flex direction="column" alignItems="center" style={{ textAlign: 'center' }}>
        <PhoneError />
        <Text
          tag="h2"
          size={FONT.size.xl}
          weight={FONT.weight.semibold}
          color={COLOR.neutral.darkest}
        >
          {t('ProjectDashboard.Completed.NoAnswers.title')}
        </Text>
        <Text tag="p" color={COLOR.neutral.dark}>
          {t('ProjectDashboard.Completed.NoAnswers.Text.creator')}
        </Text>
      </Flex>
    )
  }

  if (isInProduction) {
    return (
      <Flex direction="column" alignItems="center" style={{ textAlign: 'center' }}>
        <VideoMagic />
        <Text
          tag="h2"
          size={FONT.size.xl}
          weight={FONT.weight.semibold}
          color={COLOR.neutral.darkest}
        >
          {t('ProjectDashboard.Completed.InProduction.title')}
        </Text>
        <Text tag="p" color={COLOR.neutral.dark}>
          {t('ProjectDashboard.Completed.InProduction.Text.creator')}
        </Text>
      </Flex>
    )
  }

  return (
    <>
      <Flex direction="column" alignItems="center" gap={32}>
        <Flex direction="column" alignItems="center" style={{ textAlign: 'center' }}>
          {isSuccessful && <ImageStyled src="./img/winkii.svg" alt="" />}
          {isFailed || (mustBuyCredits && <PhoneError />)}
          {canProduce && <VideoMagic />}
          <Text
            tag="h2"
            size={FONT.size.xl}
            weight={FONT.weight.semibold}
            color={COLOR.neutral.darkest}
          >
            {isSuccessful && t('ProjectDashboard.Completed.title')}
            {isFailed && t('ProjectDashboard.Completed.Failed.title')}
            {mustBuyCredits && t('ProjectDashboard.Completed.NoCredits.title')}
            {canProduce && t('ProjectDashboard.Completed.CanProduce.title')}
          </Text>
          <Text tag="p" color={COLOR.neutral.dark}>
            {isSuccessful && t('ProjectDashboard.Completed.Text.creator', { receiverName })}
            {isFailed && t('ProjectDashboard.Completed.Failed.Text.creator')}
            {mustBuyCredits && t('ProjectDashboard.Completed.NoCredits.Text.creator')}
            {canProduce && t('ProjectDashboard.Completed.CanProduce.Text.creator')}
          </Text>
        </Flex>
        {isFailed && (
          <ButtonGroup orientation="horizontal">
            <SupportButton />
          </ButtonGroup>
        )}
        {mustBuyCredits && (
          <ButtonGroup orientation="horizontal">
            <Button icon={<ShoppingBasketSmile />} onClick={openShopModal}>
              {t('ProjectDashboard.Completed.NoCredits.button')}
            </Button>
          </ButtonGroup>
        )}
        {canProduce && (
          <ButtonGroup orientation="horizontal">
            <Button onClick={() => void triggerProduction()}>
              {t('ProjectDashboard.Completed.CanProduce.button')}
            </Button>
          </ButtonGroup>
        )}
        <div style={{ width: '100%' }}>
          {project.finalVideo && isSuccessful && (
            <ButtonGroup buttonsGrow>
              <Button icon={<Share />} variant="primary" onClick={() => void shareVideo()}>
                {t('ProjectDashboard.Completed.Share.button')}
              </Button>
              {Capacitor.getPlatform() === 'ios' ? (
                <Button
                  variant="secondary"
                  icon={<Download />}
                  onClick={() => void downloadVideo()}
                >
                  {t('ProjectDashboard.Completed.download')}
                </Button>
              ) : (
                <a
                  download
                  href={project.finalVideo.sourceUrl}
                  style={{ textDecoration: 'none' }}
                  className={cx(
                    buttonBaseStyles,
                    buttonSizeStyles.default,
                    buttonStyles('default'),
                    variantStyles.secondary,
                  )}
                >
                  <Download />
                  <span>{t('ProjectDashboard.Completed.download')}</span>
                </a>
              )}
              <Button icon={<Play />} variant="secondary" onClick={openVideoModal}>
                {t('ProjectDashboard.Completed.button')}
              </Button>
            </ButtonGroup>
          )}
        </div>
      </Flex>
      <LoadingDialog
        text={t('ProjectDashboard.Completed.Download.downloading')}
        bytesAmount={downloadBytes}
        isOpen={loadingDialogOpen}
        onClose={closeLoadingDialog}
      />
      {project.finalVideo && (
        <IonModal
          isOpen={videoModalOpen}
          onWillPresent={() => {
            setStatusbarBlack()
            setNavigationBarBlack()
          }}
          onDidDismiss={() => {
            setNavigationBarLight()
            setVideoModalOpen(false)
          }}
        >
          <IonHeader>
            <IonToolbar style={{ '--background': 'black' }}>
              <IonButtons slot="end">
                <IconButton
                  icon={<CloseCircle />}
                  title={t('General.back')}
                  onDark
                  onClick={() => setVideoModalOpen(false)}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent style={{ '--background': 'black' }}>
            <PausableVideo
              video={project.finalVideo}
              layout="fullscreen"
              videoContain={true}
              loop={false}
            />
          </IonContent>
        </IonModal>
      )}
      {noCredits && (
        <IonModal
          isOpen={shopModalOpen}
          onWillDismiss={() => {
            setStatusbarBlack()
          }}
          onWillPresent={() => {
            setStatusbarLight()
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>{t('Shop.title')}</IonTitle>
              <IonButtons slot="end">
                <IconButton
                  icon={<CloseCircle />}
                  title={t('General.back')}
                  onClick={closeShopModal}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <ProductsList onPurchase={closeShopModal} />
          </IonContent>
        </IonModal>
      )}
    </>
  )
}

export default function Completed(props: Props) {
  const { project, isCreator = false } = props

  return isCreator ? <CreatorCompleted project={project} /> : <CollaboratorCompleted />
}

function CollaboratorCompleted() {
  const { t } = useTranslation()

  return (
    <Flex direction="column" alignItems="center" gap={32}>
      <Flex direction="column" alignItems="center" style={{ textAlign: 'center' }}>
        <ImageStyled src="./img/winkii.svg" alt="" />
        <Text tag="p" color={COLOR.neutral.dark}>
          {t('ProjectDashboard.Completed.Text.collaborator')}
        </Text>
      </Flex>
    </Flex>
  )
}
