import styled from '@emotion/styled'
import { CreateAnimation, IonSpinner } from '@ionic/react'
import { useRef, useState } from 'react'

import { DEFAULT_SPINNER } from '../../App'
import { Play } from '../../icons'
import { BORDER_RADIUS, COLOR, TRANSITION } from '../../theme'
import CameraIconButton from '../recording/CameraIconButton'
import ProgressBar from '../ui/ProgressBar/ProgressBar'

import type { MediaDto } from '../../types'

type VideoLayout = 'fullscreen' | 'inline'

interface PausableVideoProps {
  video: MediaDto
  layout?: VideoLayout
  showProgress?: boolean
  onEnded?: () => void
  loop?: boolean
  autoPlay?: boolean
  videoId?: string
  videoContain?: boolean
}

const VideoStyled = styled.video<{ isLoaded: boolean; layout: VideoLayout; videoContain: boolean }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: ${({ videoContain }) => (videoContain ? 'contain' : 'cover')};
  object-position: center;
  transition: ${TRANSITION.base};
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  border-radius: ${({ layout }) => (layout === 'inline' ? BORDER_RADIUS.base : 0)};
`

const VideoWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const VideoProgressStyled = styled(ProgressBar)<{ layout: VideoLayout }>`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: ${({ layout }) => (layout === 'fullscreen' ? 'none' : 'translateY(12px)')};
`

const PlayButtonWrapperStyled = styled.div`
  position: absolute;
  inset: 0;
  background-color: transparent;
  border: none;
  color: ${COLOR.white};
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    pointer-events: none;
  }

  .icon {
    height: 24px;
    width: 24px;
  }
`

const LoadingWrapperStyled = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-spinner {
    color: ${COLOR.white};
    --color: ${COLOR.white};

    height: 64px;
    width: 64px;
  }
`

export default function PausableVideo(props: PausableVideoProps) {
  const {
    video,
    layout = 'inline',
    showProgress = true,
    onEnded,
    loop = true,
    autoPlay = true,
    videoId,
    videoContain = false,
  } = props

  const videoRef = useRef<HTMLVideoElement>(null)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [progress, setProgress] = useState(0)

  const playPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        void videoRef.current.play()
        setIsPaused(false)
      } else {
        videoRef.current.pause()
        setIsPaused(true)
      }
    }
  }

  return (
    <VideoWrapperStyled>
      {!isLoaded && (
        <LoadingWrapperStyled>
          <IonSpinner name={DEFAULT_SPINNER} color="white" />
        </LoadingWrapperStyled>
      )}
      <VideoStyled
        ref={videoRef}
        videoContain={videoContain}
        id={videoId}
        preload="auto"
        layout={layout}
        isLoaded={isLoaded}
        src={video.sourceUrl}
        controls={false}
        autoPlay={autoPlay}
        loop={loop}
        poster={video.thumbnailUrl}
        playsInline={true}
        onClick={playPause}
        onTimeUpdate={(event) => {
          const videoElement = event.target as HTMLVideoElement
          if (Number.isNaN(videoElement.duration)) return

          setProgress(videoElement.currentTime / videoElement.duration)
        }}
        onCanPlay={() => {
          setIsLoaded(true)
          setIsPaused(true)
        }}
        onEnded={onEnded}
        onPlay={() => {
          setIsPaused(false)
        }}
      />
      {showProgress && <VideoProgressStyled layout={layout} value={progress} max={1} />}
      {isPaused && (
        <PlayButtonWrapperStyled onClick={playPause}>
          <CreateAnimation
            duration={100}
            fromTo={[
              { property: 'transform', fromValue: 'scale(0.8)', toValue: 'scale(1)' },
              { property: 'opacity', fromValue: '0', toValue: '1' },
            ]}
            play={true}
            easing="ease-out"
          >
            <CameraIconButton icon={<Play />} title="play" />
          </CreateAnimation>
        </PlayButtonWrapperStyled>
      )}
    </VideoWrapperStyled>
  )
}
