import { css } from '@emotion/css'
import { useRef } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { TRANSITION } from '../../theme'

import type { FC, PropsWithChildren } from 'react'

interface TransitionProps {
  show: boolean
}

export const ScalingFadingEnterLeaveTransition: FC<PropsWithChildren<TransitionProps>> = ({
  show,
  children,
}) => (
  <CSSTransition
    unmountOnExit
    in={show}
    timeout={200}
    classNames={{
      enter: css({ opacity: 0.01, transform: 'scale(0.95)' }),
      enterActive: css({
        opacity: 1,
        transform: 'scale(1)',
        transition: TRANSITION.longer,
      }),
      exit: css({ opacity: 1, transform: 'scale(1)' }),
      exitActive: css({
        opacity: 0.01,
        transform: 'scale(1.05)',
        transition: TRANSITION.longer,
      }),
    }}
  >
    {children}
  </CSSTransition>
)

export const fadeTransitionClassNames = {
  enter: css({ opacity: 0.01 }),
  enterActive: css({
    opacity: 1,
    transition: TRANSITION.longer,
  }),
  exit: css({ opacity: 1 }),
  exitActive: css({
    opacity: 0.01,
    transition: TRANSITION.longer,
  }),
}

export const FadingEnterLeaveTransition: FC<PropsWithChildren<TransitionProps>> = ({
  show,
  children,
}) => (
  <CSSTransition unmountOnExit in={show} timeout={200} classNames={fadeTransitionClassNames}>
    {children}
  </CSSTransition>
)

interface SwitchTransitionProps {
  transitionKey: string
}

export const FadingSwitchTransition: FC<PropsWithChildren<SwitchTransitionProps>> = (props) => {
  const { transitionKey, children } = props
  const nodeRef = useRef<HTMLDivElement>(null)

  return (
    <SwitchTransition>
      <CSSTransition
        key={transitionKey}
        nodeRef={nodeRef}
        classNames={fadeTransitionClassNames}
        addEndListener={(done) => {
          nodeRef.current?.addEventListener('transitionend', done, false)
        }}
      >
        <div ref={nodeRef}>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  )
}
