import { useIonLoading } from '@ionic/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { useApiContext } from '../../../context/ApiContext'
import { useToast } from '../../../hooks/useToast'
import { parseReceiverName } from '../../../utils/textParsing'

import AnswerItem from './AnswerItem'

import type { ProjectAnswerDto, ProjectDto, ProjectQuestionDto } from '../../../types'

interface Props {
  answer: ProjectAnswerDto
  question: ProjectQuestionDto
  project: ProjectDto
  onDeleted?: () => void
}

export default function UploadedAnswerItem(props: Props) {
  const { answer, question, project, onDeleted } = props

  const { t } = useTranslation()
  const { apiClient } = useApiContext()

  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentSuccessToast, presentErrorToast } = useToast()

  const deleteAnswer = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER })

    try {
      await apiClient.deleteAnswer(answer.id)
      onDeleted?.()
      await dismissLoading()
      await presentSuccessToast(t('ProjectDashboard.Answers.deleted'))
    } catch {
      await dismissLoading()
      await presentErrorToast()
    }
  }

  const date = useMemo(() => {
    if (answer.created) return new Date(answer.created)

    return undefined
  }, [answer.created])

  return (
    <AnswerItem
      title={parseReceiverName(question.title, project.receiverName)}
      date={date}
      thumbnailUrl={answer.video.thumbnailUrl}
      video={answer.video}
      onDelete={() => void deleteAnswer()}
    />
  )
}
