import styled from '@emotion/styled'
import { IonDatetime } from '@ionic/react'
import { addDays, formatISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { BORDER_RADIUS, COLOR, SHADOW } from '../../theme'

export interface DatePickerProps {
  selectedDate: Date | null
  setSelectedDate: (date: Date) => void
}

const DateTime = styled(IonDatetime)`
  border-radius: ${BORDER_RADIUS.base};
  border: 1px solid ${COLOR.neutral.light};
  box-shadow: ${SHADOW.base};
  padding: 10px;
  width: 100% !important;

  .calendar-day-today {
    display: none;
    background: ${COLOR.primary.DEFAULT};
  }
`

export default function DatePicker(props: DatePickerProps) {
  const { selectedDate, setSelectedDate } = props

  const { i18n } = useTranslation()

  const parsedDate = formatISO(selectedDate || new Date())
  const min = formatISO(addDays(new Date(), 1))

  return (
    <DateTime
      presentation="date"
      color="primarydark"
      value={parsedDate}
      min={min}
      locale={i18n.language}
      onIonChange={(e) => setSelectedDate(new Date(e.detail.value as string))}
    />
  )
}
