import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgShoppingBasketSmile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width={24}
      height={24}
      className="icon"
      {...props}
    >
      <path d="M22,20.47h0l-.89-13A1.58,1.58,0,0,0,19.5,6H17.75a.25.25,0,0,1-.25-.25V5.5a5.5,5.5,0,0,0-11,0v.25A.25.25,0,0,1,6.25,6H4.5A1.58,1.58,0,0,0,2.93,7.46L2,20.47c0,.34,0,.69,0,1A2.5,2.5,0,0,0,4.5,24h15A2.5,2.5,0,0,0,22,21.5C22,21.16,22,20.81,22,20.47ZM8,12a1,1,0,1,1-1,1A1,1,0,0,1,8,12Zm-.16,4.88a.75.75,0,0,1,1,.2,3.75,3.75,0,0,0,6.24,0,.75.75,0,0,1,1.25.84,5.26,5.26,0,0,1-8.74,0A.75.75,0,0,1,7.84,16.88ZM16,14a1,1,0,1,1,1-1A1,1,0,0,1,16,14ZM8.5,5.5a3.5,3.5,0,0,1,7,0v.25a.25.25,0,0,1-.25.25H8.75a.25.25,0,0,1-.25-.25Z" />
    </svg>
  )
}
const Memo = memo(SvgShoppingBasketSmile)
export default Memo
