import styled from '@emotion/styled'
import { IonButtons, IonContent, IonToolbar } from '@ionic/react'

import { COLOR, FONT, PADDING } from '../../theme'

export const HeaderStyled = styled(IonToolbar)`
  --border-style: none;
  border: none;

  ion-title {
    --color: ${COLOR.black};
    font-size: ${FONT.size.sm};
    font-weight: ${FONT.weight.medium};
  }
`

export const ButtonsStartStyled = styled(IonButtons)`
  [dir='rtl'] & .icon {
    transform: scaleX(-1);
  }
`

export const ContentStyled = styled(IonContent)`
  video {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`

export const ButtonsStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${PADDING.DEFAULT};
`
