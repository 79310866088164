import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgPhoneFace(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        d="M50 84.536C58.6985 84.536 65.75 83.5958 65.75 82.436C65.75 81.2762 58.6985 80.336 50 80.336C41.3015 80.336 34.25 81.2762 34.25 82.436C34.25 83.5958 41.3015 84.536 50 84.536Z"
      />
      <path
        fill={COLOR.white}
        d="M30.05 14.3C30.0494 12.8274 30.6338 11.4149 31.6746 10.3732C32.7154 9.33146 34.1274 8.74579 35.6 8.745H64.4C65.1292 8.74526 65.8511 8.88916 66.5247 9.16848C67.1983 9.4478 67.8102 9.85706 68.3256 10.3729C68.841 10.8887 69.2497 11.5011 69.5284 12.1749C69.8071 12.8487 69.9504 13.5708 69.95 14.3V76.053C69.95 77.5251 69.3653 78.937 68.3245 79.9781C67.2838 81.0193 65.8721 81.6045 64.4 81.605H35.6C34.1275 81.6045 32.7155 81.0192 31.6743 79.9779C30.6332 78.9366 30.0482 77.5245 30.048 76.052L30.05 14.3Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M30.05 14.3C30.0494 12.8274 30.6338 11.4149 31.6746 10.3732C32.7154 9.33146 34.1274 8.74579 35.6 8.745H64.4C65.1292 8.74526 65.8511 8.88916 66.5247 9.16848C67.1983 9.4478 67.8102 9.85706 68.3256 10.3729C68.841 10.8887 69.2497 11.5011 69.5284 12.1749C69.8071 12.8487 69.9504 13.5708 69.95 14.3V76.053C69.95 77.5251 69.3653 78.937 68.3245 79.9781C67.2838 81.0193 65.8721 81.6045 64.4 81.605H35.6C34.1275 81.6045 32.7155 81.0192 31.6743 79.9779C30.6332 78.9366 30.0482 77.5245 30.048 76.052L30.05 14.3Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M66.481 19.153H33.52V70.329H66.481V19.153Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.265 13.95L57.807 13.949"
      />
      <path
        fill={COLOR.black}
        d="M44.334 13.949C44.3342 14.201 44.2596 14.4475 44.1197 14.6571C43.9798 14.8668 43.7808 15.0302 43.548 15.1268C43.3152 15.2233 43.0589 15.2487 42.8117 15.1995C42.5645 15.1504 42.3374 15.0291 42.1592 14.8508C41.9809 14.6726 41.8596 14.4455 41.8104 14.1983C41.7613 13.951 41.7866 13.6948 41.8832 13.462C41.9797 13.2291 42.1432 13.0302 42.3528 12.8903C42.5625 12.7503 42.809 12.6758 43.061 12.676C43.2283 12.6757 43.3939 12.7084 43.5485 12.7723C43.703 12.8362 43.8435 12.93 43.9617 13.0482C44.08 13.1665 44.1738 13.3069 44.2376 13.4615C44.3015 13.6161 44.3343 13.7817 44.334 13.949Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50 78.515C51.4067 78.515 52.547 77.3747 52.547 75.968C52.547 74.5614 51.4067 73.421 50 73.421C48.5933 73.421 47.453 74.5614 47.453 75.968C47.453 77.3747 48.5933 78.515 50 78.515Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M66.481 70.3291C65.3653 66.8343 63.1675 63.7846 60.2051 61.6207C57.2427 59.4567 53.6691 58.2905 50.0005 58.2905C46.3319 58.2905 42.7583 59.4567 39.7959 61.6207C36.8336 63.7846 34.6357 66.8343 33.52 70.3291H66.481Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54.312 58.847C54.0956 58.7928 53.9035 58.6678 53.7663 58.4918 53.6292 58.3159 53.5548 58.0991 53.555 57.876V54.176H46.449V57.876C46.4488 58.0986 46.3744 58.3149 46.2374 58.4904 46.1005 58.6659 45.9089 58.7907 45.693 58.845 45.1034 58.9905 44.5225 59.1691 43.953 59.38 44.2093 60.798 44.9554 62.0809 46.0612 63.0047 47.1669 63.9286 48.5621 64.4347 50.003 64.4347 51.4439 64.4347 52.8391 63.9286 53.9448 63.0047 55.0506 62.0809 55.7968 60.798 56.053 59.38 55.4832 59.1697 54.9019 58.9918 54.312 58.847ZM60.757 47.71C60.7533 47.2724 60.5981 46.8496 60.3179 46.5134 60.0377 46.1772 59.6498 45.9485 59.22 45.866V44.636C59.1947 42.2075 58.2122 39.887 56.486 38.1786 54.7597 36.4703 52.4292 35.512 50.0005 35.512 47.5718 35.512 45.2412 36.4703 43.515 38.1786 41.7888 39.887 40.8063 42.2075 40.781 44.636V45.866C40.3326 45.9497 39.9299 46.1933 39.6477 46.5516 39.3655 46.9099 39.223 47.3585 39.2467 47.8139 39.2704 48.2694 39.4587 48.7008 39.7766 49.0278 40.0945 49.3549 40.5204 49.5554 40.975 49.592 41.4073 51.666 42.5407 53.5279 44.1846 54.8644 45.8284 56.201 47.8824 56.9306 50.001 56.9306 52.1196 56.9306 54.1736 56.201 55.8174 54.8644 57.4613 53.5279 58.5947 51.666 59.027 49.592 59.4978 49.5497 59.9359 49.3335 60.2558 48.9855 60.5757 48.6374 60.7544 48.1827 60.757 47.71Z"
      />
      <path
        fill={COLOR.black}
        d="M46.543 46.941C47.1792 46.941 47.695 46.4253 47.695 45.789 47.695 45.1528 47.1792 44.637 46.543 44.637 45.9068 44.637 45.391 45.1528 45.391 45.789 45.391 46.4253 45.9068 46.941 46.543 46.941ZM53.458 46.941C54.0943 46.941 54.61 46.4253 54.61 45.789 54.61 45.1528 54.0943 44.637 53.458 44.637 52.8218 44.637 52.306 45.1528 52.306 45.789 52.306 46.4253 52.8218 46.941 53.458 46.941Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M45.007 50.014C45.6432 50.014 46.159 49.4983 46.159 48.862 46.159 48.2258 45.6432 47.71 45.007 47.71 44.3707 47.71 43.855 48.2258 43.855 48.862 43.855 49.4983 44.3707 50.014 45.007 50.014ZM54.995 50.014C55.6313 50.014 56.147 49.4983 56.147 48.862 56.147 48.2258 55.6313 47.71 54.995 47.71 54.3588 47.71 53.843 48.2258 53.843 48.862 53.843 49.4983 54.3588 50.014 54.995 50.014Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M47.7 51.622C48.3826 52.0769 49.1846 52.3196 50.005 52.3196C50.8253 52.3196 51.6273 52.0769 52.31 51.622"
      />
      <path
        fill={COLOR.white}
        d="M59.9879 42.332C60.0012 41.012 59.7526 39.7025 59.2566 38.4792C58.7606 37.2559 58.027 36.143 57.0983 35.2049C56.1695 34.2668 55.0641 33.5222 53.8458 33.014C52.6275 32.5058 51.3205 32.2441 50.0005 32.2441C48.6804 32.2441 47.3734 32.5058 46.1551 33.014C44.9368 33.5222 43.8314 34.2668 42.9026 35.2049C41.9739 36.143 41.2403 37.2559 40.7443 38.4792C40.2483 39.7025 39.9997 41.012 40.013 42.332V45.405C41.2053 45.1059 42.3118 44.5337 43.2451 43.7337C44.1785 42.9336 44.9131 41.9276 45.3909 40.795C49.6999 43.693 55.3789 43.1 59.9879 42.332Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M59.9879 42.332C60.0012 41.012 59.7526 39.7025 59.2566 38.4792 58.7606 37.2559 58.027 36.143 57.0983 35.2049 56.1695 34.2668 55.0641 33.5222 53.8458 33.014 52.6275 32.5058 51.3205 32.2441 50.0005 32.2441 48.6804 32.2441 47.3734 32.5058 46.1551 33.014 44.9368 33.5222 43.8314 34.2668 42.9026 35.2049 41.9739 36.143 41.2403 37.2559 40.7443 38.4792 40.2483 39.7025 39.9997 41.012 40.013 42.332V45.405C41.2053 45.1059 42.3118 44.5337 43.2451 43.7337 44.1785 42.9336 44.9131 41.9276 45.3909 40.795 49.6999 43.693 55.3789 43.1 59.9879 42.332ZM43.854 39.258C44.2447 39.8804 44.7699 40.4073 45.391 40.8"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M59.4689 29.038C61.0557 29.038 62.3419 27.7517 62.3419 26.165C62.3419 24.5783 61.0557 23.292 59.4689 23.292C57.8822 23.292 56.5959 24.5783 56.5959 26.165C56.5959 27.7517 57.8822 29.038 59.4689 29.038Z"
      />
    </svg>
  )
}
const Memo = memo(SvgPhoneFace)
export default Memo
