import { css } from '@emotion/css'
import styled from '@emotion/styled'

import Flex from '../../../../components/ui/Flex'
import Text from '../../../../components/ui/Text'
import PausableVideo from '../../../../components/video/PausableVideo'
import { BORDER_RADIUS, COLOR, FONT } from '../../../../theme'
import { ONBOARDING_SLIDE_TYPE } from '../../../../types'

import type { OnboardingSlideDto } from '../../../../types'
import type { FC } from 'react'

interface Props {
  slide: OnboardingSlideDto
}

export const OnboardingStepImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

const OnboardingStepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${BORDER_RADIUS.base};
`

const SlideMediaItem: FC<Props> = ({ slide }) => {
  const { mediaType, mediaUrl } = slide

  if (mediaType === ONBOARDING_SLIDE_TYPE.IMAGE)
    return <OnboardingStepImage src={mediaUrl} alt="" />

  if (mediaType === ONBOARDING_SLIDE_TYPE.VIDEO) {
    return (
      <div className={css({ background: COLOR.black, width: '100%', height: '100%' })}>
        <PausableVideo
          autoPlay={true}
          layout="inline"
          video={{ sourceUrl: mediaUrl, thumbnailUrl: '', id: '' }}
        />
      </div>
    )
  }

  return <></>
}

export default function OnboardingWizardStep(props: Props) {
  const { slide } = props

  return (
    <Flex style={{ flex: 1, height: '100%' }} direction="column" gap={32}>
      <OnboardingStepContent>
        <SlideMediaItem slide={slide} />
      </OnboardingStepContent>
      <Flex direction="column" alignItems="center" gap={8} className={css({ paddingBottom: 20 })}>
        <Text tag="h2" weight={FONT.weight.semibold} size={FONT.size.xl}>
          {slide.title}
        </Text>
        <Text tag="p" style={{ textAlign: 'center' }}>
          {slide.text}
        </Text>
      </Flex>
    </Flex>
  )
}
