import { useQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'

export function useSingleBlogPost(id: string) {
  const { apiClient } = useApiContext()

  return useQuery(['singleBlogPost'], () => apiClient.getSinglePost(id), {
    retry: 2,
  })
}
