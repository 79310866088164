import { Capacitor } from '@capacitor/core'

import type { UserDto } from '../types'
import type { TFunction } from 'i18next'

export function getFullName(user: UserDto, currentUser?: UserDto | null, t?: TFunction) {
  if (!user.firstName && !user.lastName) {
    if (user.email) return user.email

    if (t) return t('General.unknown')

    return ''
  }

  if (user.id === currentUser?.id && t) return t('General.you')

  return [user.firstName, user.lastName].filter(Boolean).join(' ')
}

export const isDevMode = process.env.NODE_ENV === 'development' // check if we are in dev mode or not (production)

export function isInWeb() {
  // check if we are in web or not
  // if (isDevMode) return false
  return !Capacitor.isNativePlatform()
}
