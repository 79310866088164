import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, FONT } from '../../theme'
import { alpha } from '../../utils/colors'

import type { PropsWithChildren } from 'react'

type TextOverlaySize = 'sm' | 'md' | 'lg'

interface TextOverlayProps {
  size?: TextOverlaySize
  opacity?: number
  className?: string
}

const padding: Record<TextOverlaySize, string> = {
  sm: '10px 18px',
  md: '20px 24px',
  lg: '10px 32px',
}

const fontSize: Record<TextOverlaySize, string> = {
  sm: FONT.size.sm,
  md: FONT.size['2xl'],
  lg: '64px',
}

const borderRadius: Record<TextOverlaySize, string> = {
  sm: BORDER_RADIUS.base,
  md: BORDER_RADIUS.base,
  lg: '24px',
}

const TextOverlayStyled = styled.div<TextOverlayProps>`
  display: inline-flex;
  align-items: center;
  font-weight: ${FONT.weight.medium};
  color: ${COLOR.white};
  background-color: ${({ opacity = 0.55 }) => alpha(COLOR.black, opacity)};
  padding: ${({ size }) => padding[size || 'md']};
  font-size: ${({ size }) => fontSize[size || 'md']};
  border-radius: ${({ size }) => borderRadius[size || 'md']};
  backdrop-filter: blur(4px);
  font-family: ${FONT.family.sans};
`

export default function TextOverlay(props: PropsWithChildren<TextOverlayProps>) {
  const { children, ...rest } = props

  return <TextOverlayStyled {...rest}>{children}</TextOverlayStyled>
}
