import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgDotsVertical(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M12 20.0409C13.0362 20.0409 13.8762 19.2009 13.8762 18.1647 13.8762 17.1285 13.0362 16.2885 12 16.2885 10.9638 16.2885 10.1237 17.1285 10.1237 18.1647 10.1237 19.2009 10.9638 20.0409 12 20.0409ZM12 7.71144C13.0362 7.71144 13.8762 6.87143 13.8762 5.83521 13.8762 4.799 13.0362 3.95898 12 3.95898 10.9638 3.95898 10.1237 4.799 10.1237 5.83521 10.1237 6.87143 10.9638 7.71144 12 7.71144ZM12 13.8762C13.0362 13.8762 13.8762 13.0362 13.8762 12 13.8762 10.9638 13.0362 10.1238 12 10.1238 10.9638 10.1238 10.1237 10.9638 10.1237 12 10.1237 13.0362 10.9638 13.8762 12 13.8762Z" />
    </svg>
  )
}
const Memo = memo(SvgDotsVertical)
export default Memo
