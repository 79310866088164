import { useTranslation } from 'react-i18next'

import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Text from '../../../components/ui/Text'
import { Finish } from '../../../icons'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  buttonClicked: () => void
}

export default function FinishWinkiiModal(props: Props) {
  const { open, setOpen, buttonClicked } = props

  const { t } = useTranslation()

  return (
    <ActionSheetModal isOpen={open} onClose={() => setOpen(false)}>
      <Text className="flex justify-center pb-10 text-center text-lg">
        {t('ProjectDashboard.Tiles.completion.description')}
      </Text>
      <ButtonGroup>
        <Button
          icon={<Finish />}
          variant="primary"
          onClick={() => {
            buttonClicked()
            setOpen(false)
          }}
        >
          {t('ProjectDashboard.Tiles.completion.approve')}
        </Button>
      </ButtonGroup>
    </ActionSheetModal>
  )
}
