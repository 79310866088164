import styled from '@emotion/styled'
import { useState } from 'react'

import { TRANSITION } from '../../theme'

import type { ImgHTMLAttributes } from 'react'

const Image = styled.img<{ loaded: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: ${TRANSITION.longer};
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export default function LazyImage(props: ImgHTMLAttributes<HTMLImageElement>) {
  const [isLoaded, setIsLoaded] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  return <Image loaded={isLoaded} {...props} loading="lazy" onLoad={onLoad} />
}
