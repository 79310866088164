import styled from '@emotion/styled'

import { COLOR, PADDING } from '../../theme'

import type { PropsWithChildren } from 'react'

export interface ListProps {
  fullWidth?: boolean
  divider?: boolean
}

const ListStyled = styled.ul<ListProps>`
  list-style: none;
  margin: 0 ${(props) => (props.fullWidth ? `-${PADDING.DEFAULT}` : 0)};
  padding: 0;
  display: flex;
  flex-direction: column;

  li:not(:first-of-type) {
    border-top: ${(props) => (props.divider ? `1px solid ${COLOR.neutral.light}` : 'none')};
  }
`

export default function List(props: PropsWithChildren<ListProps>) {
  const { children, fullWidth = true, divider = true } = props

  return (
    <ListStyled fullWidth={fullWidth} divider={divider}>
      {children}
    </ListStyled>
  )
}
