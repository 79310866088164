import 'swiper/swiper.min.css'
import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import { IonContent, useIonLoading, useIonRouter } from '@ionic/react'
import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'swiper'
import { useBoolean } from 'usehooks-ts'

import { ROUTES } from '../../../Router'
import { SwiperPagination, swiperBulletStyles } from '../../../components/common/SwiperStyles'
import { FadingEnterLeaveTransition } from '../../../components/common/Transitions'
import Page from '../../../components/layout/Page'
import SubpageHeader from '../../../components/layout/SubpageHeader'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Dialog from '../../../components/ui/Dialog'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useProjectContext } from '../../../context/ProjectContext'
import { useInvitationCode } from '../../../hooks/useInvitationCode'
import { useMusicTracks } from '../../../hooks/useMusicTracks'
import { useToast } from '../../../hooks/useToast'
import { CloseCircle } from '../../../icons'
import {
  InterstitialPlacement,
  IronSourceIntegration,
} from '../../../plugins/IronSourceIntegration'

import AddPersonStep from './AddPersonStep'
import AddQuestionsStep from './AddQuestionsStep'
import ChooseCategoryStep from './ChooseCategoryStep'
import ChooseDateStep from './ChooseDateStep'
import FinishedStep from './FinishedStep'
import ImageUploadStep from './ImageUploadStep'
import InsertTextStep from './InsertTextStep'
import { SwiperSlideStyled, SwiperStyled } from './NewProjectWizard.styled'
import SelectMusicStep from './SelectMusicStep'

import type { CreateProjectDto, CreateProjectQuestionDto } from '../../../types'
import type { Swiper } from 'swiper/types'

export const NO_MUSIC_TRACK_ID = 'no-music-track'

export interface StepProps<T = string> {
  value: T
  setValue: (value: T) => void
}

const initialProject: CreateProjectDto = {
  projectCategoryId: '',
  title: '',
  thumbnail: {
    sourceUrl: '',
    thumbnailUrl: '',
  },
  receiverName: '',
  dueAt: '',
  collaborators: [],
  musicTrackId: NO_MUSIC_TRACK_ID,
  invitationCode: '',
}

export default function NewProjectWizard() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'NewProject', nameOverride: 'NewProject' })
  }, [])

  const { t } = useTranslation()

  const [swiper, setSwiper] = useState<Swiper | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isLastStep, setIsLastStep] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [project, setProject] = useState<CreateProjectDto>(initialProject)
  const [invitationMails, setInvitationMails] = useState<string[]>([])
  const [createdProjectId, setCreatedProjectId] = useState<string>('')

  const { authReady } = useAuthContext()
  const invitationCodeContext = useInvitationCode(authReady)
  if (project.invitationCode === '' && invitationCodeContext?.invitationCode) {
    setProject({
      ...project,
      invitationCode: invitationCodeContext?.invitationCode,
    })
  }
  const [categoryImage, setCategoryImage] = useState(<></>)
  const [categoryTitle, setCategoryTitle] = useState('')

  const paginationRef = useRef<HTMLDivElement>(null)

  const ionRouter = useIonRouter()
  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast } = useToast()

  const {
    setProject: setContextProject,
    project: contextProject,
    setQuestions,
  } = useProjectContext()
  const [questionsToCreate, setQuestionsToCreate] = useState<CreateProjectQuestionDto[]>([])
  const { apiClient } = useApiContext()

  const { data: musicTracks, refetch: refetchMusikTracks } = useMusicTracks(
    project.projectCategoryId,
  )

  useEffect(() => {
    void refetchMusikTracks()
  }, [project.projectCategoryId, refetchMusikTracks])

  const {
    value: abortDialogActive,
    setTrue: openAbortDialog,
    setFalse: closeAbortDialog,
  } = useBoolean(false)

  const finishWizard = async () => {
    await presentLoading()

    const createdProject = await apiClient.getProject(createdProjectId)

    try {
      if (createdProject?.id && invitationMails.length > 0)
        await apiClient.inviteCollaborators(
          createdProject.id,
          invitationMails.map((email) => {
            return {
              email,
            }
          }),
        )

      setIsFinished(true)
      setProject(initialProject)
    } catch {
      await presentErrorToast(t('General.error'))
    }
    await dismissLoading()
  }

  const createProject = async () => {
    await presentLoading(t('NewProject.loading'))

    let projectImageUrl = ''

    try {
      const { thumbnailUrl } = await apiClient.uploadImage(
        project.thumbnail.sourceUrl,
        'square',
        800,
      )
      projectImageUrl = thumbnailUrl
    } catch {
      await dismissLoading()
      await presentErrorToast(t('NewProject.error'))
    }

    const postData = {
      ...project,
      description: '', // TODO - remove as soon as backend won't require it
      dueAt: format(new Date(project.dueAt), 'yyyy-MM-dd'),
      thumbnail: { thumbnailUrl: projectImageUrl, sourceUrl: projectImageUrl },
    }

    if (!project.musicTrackId || project.musicTrackId === NO_MUSIC_TRACK_ID)
      delete postData.musicTrackId

    try {
      const createProjectReturn = await apiClient.createProject(postData)
      setCreatedProjectId(createProjectReturn.id)
      await apiClient.addQuestionToProject(createProjectReturn.id, questionsToCreate)
      const createdProject = await apiClient.getProject(createProjectReturn.id)
      await setContextProject(createdProject)
      setQuestions(createdProject.questions || [])
      swiper?.slideNext()
    } catch (e) {
      console.error(e)
      await presentErrorToast(t('NewProject.error'))
    }
    await dismissLoading()
  }

  const setCategory = async (categoryId: string, categoryName: string) => {
    setProject((prev) => ({ ...prev, projectCategoryId: categoryId }))
    setCategoryTitle(categoryName)

    if (!swiper) return

    try {
      if (Capacitor.isNativePlatform())
        await IronSourceIntegration.showInterstitial({
          placement: InterstitialPlacement.CreateProject,
        })
    } catch {
      /* ignore */
    }

    swiper.slideNext()
  }

  const getTitle = (receiverName: string) => {
    const name =
      receiverName.endsWith('s') || receiverName.endsWith('x')
        ? `${receiverName}'`
        : `${receiverName}'s`
    return `${name} ${categoryTitle}`
  }

  const stepsValid: Record<number, boolean> = {
    0: project.projectCategoryId !== '',
    1: project.title !== '',
    2: questionsToCreate.length > 0,
    3: project.dueAt !== '',
    4: project.thumbnail.sourceUrl !== '',
    5: true,
    6: true,
  }

  const resetWizard = () => {
    setProject(initialProject)
    setCategoryImage(<></>)
    setIsFinished(false)
  }

  const pageTitle = () => {
    let title = t('NewProject.title')

    if (categoryTitle && activeIndex !== 0) title = `${t('NewProject.title')}: ${categoryTitle}`

    return title
  }

  return (
    <Page>
      <SubpageHeader
        title={pageTitle()}
        button={
          <IconButton
            icon={<CloseCircle />}
            title=""
            onClick={() =>
              isLastStep ? ionRouter.push(ROUTES.dashboard, 'back', 'pop') : openAbortDialog()
            }
          />
        }
      />
      <IonContent className="ion-padding" scrollY={false}>
        {isFinished ? (
          <FinishedStep projectId={contextProject?.id || ''} cleanWizard={resetWizard} />
        ) : (
          <Flex className={css({ height: '100%' })} direction="column" gap={20}>
            <div className={css({ flex: 1, height: '100%', overflowY: 'hidden' })}>
              <SwiperStyled
                allowSlideNext={stepsValid[activeIndex]}
                allowTouchMove={false}
                modules={[Pagination]}
                pagination={{
                  el: paginationRef.current,
                  type: 'bullets',
                  bulletActiveClass: swiperBulletStyles(true),
                  bulletClass: swiperBulletStyles(),
                  bulletElement: 'div',
                }}
                onSwiper={setSwiper}
                onActiveIndexChange={(swiperOnChange) => {
                  setActiveIndex(swiperOnChange.activeIndex)
                  setIsLastStep(swiperOnChange.isEnd)
                }}
              >
                <SwiperSlideStyled>
                  <ChooseCategoryStep
                    value={project.projectCategoryId}
                    setValue={(value, categoryName) => void setCategory(value, categoryName)}
                    setCategoryImage={setCategoryImage}
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <InsertTextStep
                    image={categoryImage}
                    title={t('NewProject.receiverTitle')}
                    placeholder={t('NewProject.receiverPlaceholder')}
                    value={project.receiverName}
                    setValue={(value) =>
                      setProject({
                        ...project,
                        receiverName: value,
                        title: getTitle(value),
                      })
                    }
                    description={t('NewProject.receiverDescription')}
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <AddQuestionsStep
                    receiverName={project.receiverName}
                    projectCategoryId={project.projectCategoryId}
                    setQuestions={setQuestionsToCreate}
                    questions={questionsToCreate}
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <ChooseDateStep
                    image={categoryImage}
                    title={t('NewProject.projectCompletionDateTitle')}
                    placeholder={t('NewProject.projectCompletionDatePlaceholder')}
                    description={t('NewProject.projectCompletionDateInfo')}
                    value={project.dueAt}
                    setValue={(value) => setProject({ ...project, dueAt: value })}
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <ImageUploadStep
                    value={project.thumbnail.sourceUrl}
                    setValue={(value) =>
                      setProject({ ...project, thumbnail: { sourceUrl: value } })
                    }
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <SelectMusicStep
                    musicTracks={musicTracks}
                    activeMusicTrackId={project.musicTrackId}
                    onSelect={(value) => setProject({ ...project, musicTrackId: value })}
                  />
                </SwiperSlideStyled>
                <SwiperSlideStyled>
                  <AddPersonStep
                    invitationMails={invitationMails}
                    setInvitationMails={setInvitationMails}
                    project={project ?? undefined}
                    invitationCode={project.invitationCode}
                    onSelect={(value) => setProject({ ...project, collaborators: value })}
                  />
                </SwiperSlideStyled>
                {/* <SwiperSlideStyled>
                  <SummaryStep project={project} />
                </SwiperSlideStyled> */}
              </SwiperStyled>
            </div>
            <Flex direction="column" gap={20} className={css({ flexShrink: 0 })}>
              <FadingEnterLeaveTransition show={activeIndex !== 0}>
                <ButtonGroup buttonsGrow orientation="horizontal">
                  <Button variant="secondary" onClick={() => swiper?.slidePrev()}>
                    {t('General.back')}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!isLastStep && !stepsValid[activeIndex]}
                    onClick={() => {
                      if (activeIndex === 5) {
                        void createProject()
                        return
                      }

                      if (isLastStep) {
                        void finishWizard()
                        return
                      }

                      swiper?.slideNext()
                    }}
                  >
                    {isLastStep ? t('General.complete') : t('General.next')}
                  </Button>
                </ButtonGroup>
              </FadingEnterLeaveTransition>
              <SwiperPagination ref={paginationRef} />
            </Flex>
          </Flex>
        )}
        <Dialog isOpen={abortDialogActive} onClose={closeAbortDialog}>
          <Dialog.Title>{t('NewProject.Abort.title')}</Dialog.Title>
          <Dialog.Body>{t('NewProject.Abort.text')}</Dialog.Body>
          <Dialog.Buttons buttonsGrow={true}>
            <Button
              variant="danger"
              onClick={() => {
                void FirebaseAnalytics.logEvent({ name: 'abortNewProject', params: {} })

                closeAbortDialog()
                ionRouter.push(ROUTES.dashboard, 'back', 'pop')
              }}
            >
              {t('NewProject.Abort.confirm')}
            </Button>
            <Button variant="secondary" onClick={closeAbortDialog}>
              {t('NewProject.Abort.cancel')}
            </Button>
          </Dialog.Buttons>
        </Dialog>
      </IonContent>
    </Page>
  )
}
