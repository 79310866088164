import { getFullName } from '../../utils/user'
import Avatar from '../ui/Avatar/Avatar'

import type { UserDto } from '../../types'
import type { AvatarSize } from '../ui/Avatar/Avatar'

interface UserAvatarProps {
  size?: AvatarSize
  user: UserDto
}

export default function UserAvatar(props: UserAvatarProps) {
  const { size = 'md', user } = props

  return <Avatar alt={getFullName(user)} size={size} src={user.avatar?.sourceUrl} />
}
