import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { useIonLoading } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import ProgressDialog from '../../../components/common/ProgressDialog'
import { useApiContext } from '../../../context/ApiContext'
import { useFileUpload } from '../../../hooks/useFileUpload'
import { useLocalAnswers } from '../../../hooks/useLocalAnswers'
import { useToast } from '../../../hooks/useToast'
import {
  InterstitialPlacement,
  IronSourceIntegration,
} from '../../../plugins/IronSourceIntegration'
import { REACT_APP_SUPABASE_BUCKET, REACT_APP_SUPABASE_URL } from '../../../utils/env'
import { parseReceiverName } from '../../../utils/textParsing'

import AnswerItem from './AnswerItem'

import type { LocalAnswer } from '../../../hooks/useLocalAnswers'

interface Props {
  answer: LocalAnswer
  receiverName: string
  projectId?: string
  canUpload?: boolean
  isUploading?: boolean
  onStartUpload?: () => void
  onDoneUpload?: () => void
  onDelete?: () => void
}

export default function LocalAnswerItem(props: Props) {
  const { answer, receiverName, onDoneUpload, projectId, onDelete } = props

  const { t } = useTranslation()

  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast, presentSuccessToast } = useToast()

  const { apiClient } = useApiContext()
  const { updateLocalAnswer, deleteLocalAnswer } = useLocalAnswers(projectId || '')
  const { upload, progressPercent, isUploading } = useFileUpload()

  const deleteAnswer = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER })
    void deleteLocalAnswer(answer)
    onDelete?.()
    await dismissLoading()
  }

  const uploadAnswer = async () => {
    if (!projectId) return

    await presentLoading({
      message: t('RecordingStudio.Upload.preparing'),
      spinner: DEFAULT_SPINNER,
    })

    try {
      if (Capacitor.isNativePlatform()) await IronSourceIntegration.loadInterstitial()
    } catch {
      // no-op
    }

    let thumbnailUrl: string

    if (answer.remoteThumbnailUrl) {
      thumbnailUrl = answer.remoteThumbnailUrl
    } else {
      const result = await apiClient
        .uploadImage(answer.thumbnailUrl)
        .then((r) => r)
        .catch(() => {
          return { thumbnailUrl: '' }
        })
      thumbnailUrl = result.thumbnailUrl
      void updateLocalAnswer({ ...answer, remoteThumbnailUrl: thumbnailUrl })
      await Filesystem.deleteFile({
        path: answer.thumbnailUrl.split('/').pop() || '',
        directory: Directory.Data,
      }).catch(() => {
        /* empty */
      })
    }

    const fileName = answer.sourceUrl.split('/').pop() || ''
    const apiUrl = `${REACT_APP_SUPABASE_URL}/storage/v1/object/${REACT_APP_SUPABASE_BUCKET}/videos/${fileName}`

    let sourceUrl = ''

    await dismissLoading()

    if (answer.remoteSourceUrl) {
      sourceUrl = answer.remoteSourceUrl
    } else {
      try {
        sourceUrl = await upload(fileName, apiUrl)
      } catch {
        await presentErrorToast(t('RecordingStudio.Upload.error'))
        return
      }
    }

    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('RecordingStudio.Upload.finishing'),
    })

    void updateLocalAnswer({ ...answer, remoteSourceUrl: sourceUrl })

    try {
      await apiClient.createAnswer(projectId, answer.questionId, {
        video: { sourceUrl, thumbnailUrl },
      })
    } catch {
      await dismissLoading()
      await presentErrorToast(t('RecordingStudio.Upload.error'))
      return
    }

    await deleteAnswer()
    await dismissLoading()
    await presentSuccessToast(t('RecordingStudio.Upload.success'))

    if (Capacitor.isNativePlatform())
      await IronSourceIntegration.showInterstitial({
        placement: InterstitialPlacement.UploadAnswer,
      })

    onDoneUpload?.()
  }

  return (
    <>
      <AnswerItem
        title={parseReceiverName(answer.questionTitle, receiverName)}
        thumbnailUrl={answer.remoteThumbnailUrl || answer.thumbnailUrl}
        video={{
          id: '',
          sourceUrl: answer.remoteSourceUrl || answer.sourceUrl,
          thumbnailUrl: answer.remoteThumbnailUrl || answer.thumbnailUrl,
        }}
        onDelete={() => void deleteAnswer()}
        onUpload={() => void uploadAnswer()}
      />
      <ProgressDialog
        isOpen={isUploading}
        progressPercent={progressPercent}
        text={t('RecordingStudio.Upload.uploading')}
      />
    </>
  )
}
