import { css } from '@emotion/css'

import { BORDER_RADIUS, COLOR, FONT } from '../../theme'

import Flex from './Flex'
import Text from './Text'

import type { PropsWithChildren } from 'react'

export default function InfoBox(props: PropsWithChildren) {
  const { children } = props

  return (
    <Flex
      gap={8}
      className={css({
        padding: 16,
        background: COLOR.neutral.lighter,
        borderRadius: BORDER_RADIUS.base,
      })}
    >
      <svg
        aria-hidden={true}
        className={css({ flexShrink: 0, color: COLOR.neutral.DEFAULT })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        height={18}
        width={18}
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          clipRule="evenodd"
        />
      </svg>
      <Text size={FONT.size.sm}>{children}</Text>
    </Flex>
  )
}
