import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'

export default function PlayStoreRedirect() {
  if (!Capacitor.isNativePlatform())
    window.location.href = 'https://play.google.com/store/apps/details?id=com.winkii.android'

  const ionRouter = useIonRouter()
  ionRouter.push('/')

  return <div></div>
}
