import { BORDER_RADIUS, COLOR, FONT, PADDING, SHADOW } from './theme'
import { hexToRgbString } from './utils/colors'

import type { Interpolation } from '@emotion/styled'

export const GLOBAL_STYLES: Interpolation<unknown> = {
  'button, a': {
    color: 'inherit',
  },

  '.ion-safe-area-bottom': {
    'paddingBottom': 'var(--ion-safe-area-bottom)',
    '--padding-bottom': 'var(--ion-safe-area-bottom)',
  },

  '.ion-safe-area-top': {
    'paddingTop': 'var(--ion-safe-area-top)',
    '--padding-top': 'var(--ion-safe-area-top)',
  },

  'ion-content': {
    '--keyboard-offset': '0px',
  },

  '.ion-color-primarydark': {
    '--ion-color-base': 'var(--ion-color-primarydark)',
    '--ion-color-base-rgb': 'var(--ion-color-primarydark-rgb)',
    '--ion-color-contrast': 'var(--ion-color-primarydark-contrast)',
    '--ion-color-contrast-rgb': 'var(--ion-color-primarydark-contrast-rgb)',
    '--ion-color-shade': 'var(--ion-color-primarydark-shade)',
    '--ion-color-tint': 'var(--ion-color-primarydark-tint)',
  },

  ':root': {
    // Font
    '--ion-font-family': FONT.family.sans,

    // Global Colors
    '--ion-background-color': COLOR.neutral.lightest,
    '--ion-background-color-rgb': hexToRgbString(COLOR.neutral.lightest),

    '--ion-text-color': COLOR.neutral.darkest,
    '--ion-text-color-rgb': hexToRgbString(COLOR.neutral.darkest),

    '--ion-toolbar-background': COLOR.neutral.lightest,
    '--ion-toolbar-color': COLOR.neutral.darkest,
    '--ion-toolbar-border-color': 'transparent',

    // Color Primary
    '--ion-color-primary': COLOR.primary.DEFAULT,
    '--ion-color-primary-rgb': hexToRgbString(COLOR.primary.DEFAULT),
    '--ion-color-primary-shade': COLOR.primary.dark,
    '--ion-color-primary-shade-rgb': hexToRgbString(COLOR.primary.dark),
    '--ion-color-primary-tint': COLOR.primary.light,
    '--ion-color-primary-tint-rgb': hexToRgbString(COLOR.primary.light),
    '--ion-color-primary-contrast': COLOR.neutral.darkest,
    '--ion-color-primary-contrast-rgb': hexToRgbString(COLOR.neutral.darkest),

    // Color Primary Dark
    '--ion-color-primarydark': COLOR.primary.darker,
    '--ion-color-primarydark-rgb': hexToRgbString(COLOR.primary.darker),
    '--ion-color-primarydark-shade': COLOR.primary.darker,
    '--ion-color-primarydark-shade-rgb': hexToRgbString(COLOR.primary.darker),
    '--ion-color-primarydark-tint': COLOR.primary.dark,
    '--ion-color-primarydark-tint-rgb': hexToRgbString(COLOR.primary.dark),
    '--ion-color-primarydark-contrast': COLOR.white,
    '--ion-color-primarydark-contrast-rgb': hexToRgbString(COLOR.white),

    // Color Danger
    '--ion-color-danger': COLOR.danger.DEFAULT,
    '--ion-color-danger-rgb': hexToRgbString(COLOR.danger.DEFAULT),
    '--ion-color-danger-shade': COLOR.danger.dark,
    '--ion-color-danger-shade-rgb': hexToRgbString(COLOR.danger.dark),
    '--ion-color-danger-tint': COLOR.danger.light,
    '--ion-color-danger-tint-rgb': hexToRgbString(COLOR.danger.light),
    '--ion-color-danger-contrast': COLOR.black,
    '--ion-color-danger-contrast-rgb': hexToRgbString(COLOR.black),

    // Spacing
    '--ion-padding': PADDING.DEFAULT,

    /*
     * Global Component Overrides
     */

    // IonToast
    'ion-toast': {
      '--border-radius': BORDER_RADIUS.base,
      '--background': COLOR.neutral.darkest,
      '--color': COLOR.white,
    },

    // IonAlert
    'ion-alert': {
      '--background': COLOR.white,
      '--max-width': `calc(100% - ${PADDING.DEFAULT} * 2)`,
    },

    'div.alert-wrapper': {
      boxShadow: SHADOW.base,
      borderRadius: BORDER_RADIUS.base,
    },

    'div.alert-message, div.alert-head': {
      textAlign: 'left',
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 8,
    },

    'div.alert-message': {
      fontSize: FONT.size.base,
      paddingBottom: 24,
    },

    'h2.alert-title': {
      fontSize: FONT.size.xl,
    },

    'div.alert-button-group': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: 8,
    },

    'button.alert-button': {
      'borderRadius': BORDER_RADIUS.base,
      'border': 'none',
      'minWidth': 'unset',
      'fontSize': '16px',
      'padding': '10px 20px',
      'width': 'auto',
      'height': 'auto',
      'flex': 'unset',
      'fontWeight': 500,

      '&.alert-button-role-cancel': {
        'color': COLOR.neutral.dark,

        ':active, :hover': {
          background: COLOR.primary.lighter,
        },
      },
    },

    // IonActionSheet
    'ion-action-sheet': {
      '--background': COLOR.white,
      '--max-width': '100%',
      'padding': 0,
    },

    'div.action-sheet-container': {
      padding: 0,
    },

    'div.action-sheet-group': {
      margin: '0 !important',
      borderTopRightRadius: BORDER_RADIUS.base,
      borderTopLeftRadius: BORDER_RADIUS.base,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: SHADOW.base,
      padding: '20px 24px',
    },

    'div.action-sheet-title': {
      paddingTop: 0,
      color: COLOR.neutral.darkest,
    },

    'button.action-sheet-button, button.action-sheet-button:active, button.action-sheet-button:hover, button.action-sheet-button:focus':
      {
        borderRadius: BORDER_RADIUS.base,
        boxShadow: SHADOW.base,
        background: COLOR.white,
        color: COLOR.neutral.darkest,
        fontSize: FONT.size.base,
        fontWeight: FONT.weight.medium,
      },

    // IonReorder
    '.reorder-selected': {
      background: `${COLOR.white} !important`,
    },
  },

  // IonDatetime
  ':host .calendar-day.calendar-day-today': {
    background: COLOR.primary.lighter,
  },
}
