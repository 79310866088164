import markerSDK from '@marker.io/browser'

import { MARKER_PROJECT_ID } from './utils/env'

import type { UserDto } from './types'

export default async function useMarkerio(user: UserDto | null) {
  // const context = useAuthContext()
  // const user = context?.user
  if (!user || !user.email.includes('falcondev')) {
    return null
  }
  const widget = await markerSDK.loadWidget({
    project: MARKER_PROJECT_ID,
    reporter: {
      email: user.email,
      fullName: `${user.firstName} ${user.lastName}`,
    },
  })
  if (process.env.NODE_ENV === 'development') {
    widget.setCustomData({
      env: 'dev',
    })
  }
  return widget
}
