import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g>
        <path
          d="M0,19.59a4,4,0,0,0,4,4H20a4,4,0,0,0,4-4V17.72a1.23,1.23,0,1,0-2.45,0v1.87A1.59,1.59,0,0,1,20,21.18H4a1.59,1.59,0,0,1-1.59-1.59V17.72a1.23,1.23,0,1,0-2.45,0Z"
          fill="currentColor"
        />
        <path
          d="M12,19.32a2,2,0,0,0,2-2V8.2A.25.25,0,0,1,14.21,8H16.9a1,1,0,0,0,.74-1.62L12.74.7a1,1,0,0,0-1.48,0L6.36,6.33A1,1,0,0,0,7.1,8H9.79A.25.25,0,0,1,10,8.2v9.16A2,2,0,0,0,12,19.32Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
const Memo = memo(SvgUpload)
export default Memo
