import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgInfoCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      className="icon"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth="1.2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M15 5l0 2" />
      <path d="M15 11l0 2" />
      <path d="M15 17l0 2" />
      <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" />
    </svg>
  )
}
const Memo = memo(SvgInfoCircle)
export default Memo
