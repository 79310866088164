import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgUserFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M17.5 6.25C17.5 9.28757 15.0376 11.75 12 11.75 8.96243 11.75 6.5 9.28757 6.5 6.25 6.5 3.21243 8.96243.75 12 .75 15.0376.75 17.5 3.21243 17.5 6.25ZM5.28541 16.0354C7.06643 14.2544 9.48126 13.2526 12 13.25 14.5187 13.2526 16.9336 14.2544 18.7146 16.0354 20.4956 17.8164 21.4974 20.2313 21.5 22.75 21.5 22.8826 21.4473 23.0098 21.3536 23.1036 21.2598 23.1973 21.1326 23.25 21 23.25H3C2.86739 23.25 2.74021 23.1973 2.64645 23.1036 2.55268 23.0098 2.5 22.8826 2.5 22.75 2.50265 20.2313 3.50439 17.8164 5.28541 16.0354Z" />
    </svg>
  )
}
const Memo = memo(SvgUserFill)
export default Memo
