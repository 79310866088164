import styled from '@emotion/styled'
import { IonContent, IonHeader, IonToolbar, NavContext } from '@ionic/react'
import Lottie from 'lottie-react'
import { useContext, useRef } from 'react'

import successAnimation from '../../animations/91662-mail-ib.json'
import { FONT } from '../../theme'
import { WinkiiSvgStyled } from '../../views/Auth/Auth.styled'

import Page from './Page'

import type { LottieRefCurrentProps } from 'lottie-react'

interface EmailSentPageProps {
  messageTitle: string
  message: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: ${FONT.lineHeight.xl};
  h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.semibold};
  }
  p {
    margin-top: 16px;
    margin-bottom: 36px;
    font-size: ${FONT.size.base};
  }
`

export default function EmailSentPage(props: EmailSentPageProps) {
  const { messageTitle, message } = props
  const { navigate } = useContext(NavContext)

  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const onComplete = () => {
    setTimeout(() => navigate('/login', 'back', 'pop'), 750)
  }

  return (
    <Page>
      <IonHeader>
        <IonToolbar />
      </IonHeader>
      <IonContent className="ion-padding">
        <Wrapper>
          <WinkiiSvgStyled size="sm" className="icon icon-default" />
          <Lottie
            lottieRef={lottieRef}
            animationData={successAnimation}
            loop={false}
            onComplete={onComplete}
          />
          <TitleWrapper>
            <h1>{messageTitle}</h1>
            <p>{message}</p>
          </TitleWrapper>
        </Wrapper>
      </IonContent>
    </Page>
  )
}
