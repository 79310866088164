import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgPhonePortraitOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M71.905 83.9C71.905 85.5178 71.2624 87.0694 70.1184 88.2133 68.9744 89.3573 67.4229 90 65.805 90H34.192C32.5742 90 31.0227 89.3573 29.8787 88.2133 28.7347 87.0694 28.092 85.5178 28.092 83.9V16.1C28.092 14.4822 28.7347 12.9306 29.8787 11.7866 31.0227 10.6427 32.5742 10 34.192 10H65.808C67.4259 10 68.9774 10.6427 70.1214 11.7866 71.2654 12.9306 71.908 14.4822 71.908 16.1L71.905 83.9ZM68.095 21.429H31.905V77.619H68.095V21.429ZM48.095 15.7151 58.571 15.7141"
      />
      <path
        stroke="currentColor"
        d="M42.5081 16.2141C42.2319 16.2141 42.0081 15.9903 42.0081 15.7141 42.0081 15.438 42.2319 15.2141 42.5081 15.2141M42.5081 16.2141C42.7842 16.2141 43.0081 15.9903 43.0081 15.7141 43.0081 15.438 42.7842 15.2141 42.5081 15.2141"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50 87.1431C51.8408 87.1431 53.333 85.6508 53.333 83.8101C53.333 81.9693 51.8408 80.4771 50 80.4771C48.1592 80.4771 46.667 81.9693 46.667 83.8101C46.667 85.6508 48.1592 87.1431 50 87.1431Z"
      />
    </svg>
  )
}
const Memo = memo(SvgPhonePortraitOutline)
export default Memo
