import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { BORDER_RADIUS, COLOR, TRANSITION } from '../../theme'

import type { ButtonHTMLAttributes } from 'react'

interface RecordingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isRecording: boolean
  maxRecordingTime: number
}

const size = (isRecording: boolean) => (isRecording ? '90px' : '80px')

const RecordingButtonStyled = styled.button<RecordingButtonProps>`
  position: relative;
  border-radius: ${BORDER_RADIUS.full};
  width: ${(props) => size(props.isRecording)};
  height: ${(props) => size(props.isRecording)};
  background-color: ${(props) => (props.isRecording ? COLOR.primary.DEFAULT : COLOR.white)};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${TRANSITION.base};
`

const RecordingButtonInnerStyled = styled.span<RecordingButtonProps>`
  width: 30px;
  height: 30px;
  border-radius: ${(props) => (props.isRecording ? BORDER_RADIUS.xs : BORDER_RADIUS.full)};
  background-color: ${(props) => (props.isRecording ? COLOR.black : COLOR.danger.dark)};
  transition: ${TRANSITION.base};
`

const CircleSvgStyled = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 120px;
  width: 120px;
  margin: -15px;
`

const CircleStyled = styled.circle`
  fill: none;
  stroke-linecap: round;
  transition:
    stroke-dasharray 1s linear,
    stroke 0.5s ease-in-out,
    stroke-width 0.5s ease-in-out;
`

export default function RecordingButton(props: RecordingButtonProps) {
  const { isRecording, maxRecordingTime, ...rest } = props
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        setSeconds((prev) => (prev < maxRecordingTime ? prev + 1 : 0))
      }, 1000)

      return () => clearInterval(interval)
    } else {
      setSeconds(0)
    }
  }, [isRecording])

  return (
    <RecordingButtonStyled isRecording={isRecording} maxRecordingTime={maxRecordingTime} {...rest}>
      <RecordingButtonInnerStyled isRecording={isRecording} maxRecordingTime={maxRecordingTime} />
      {isRecording && <ProgressCircle seconds={seconds} maxRecordingTime={maxRecordingTime} />}
    </RecordingButtonStyled>
  )
}

function ProgressCircle({
  seconds,
  maxRecordingTime,
}: {
  seconds: number
  maxRecordingTime: number
}) {
  const percentage = (seconds / maxRecordingTime) * 100

  const isLastTenSeconds = maxRecordingTime - seconds <= 10

  return (
    <CircleSvgStyled viewBox="0 0 100 100">
      <CircleStyled
        cx="50"
        cy="50"
        r="45"
        fill="transparent"
        stroke={isLastTenSeconds ? COLOR.danger.DEFAULT : COLOR.white}
        strokeWidth={isLastTenSeconds ? 8 : 4}
        strokeDasharray={`${percentage * 2.83} 283`}
      />
    </CircleSvgStyled>
  )
}
