import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { IonContent, useIonLoading, useIonRouter, useIonToast } from '@ionic/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import { UnStyledLink } from '../../../../components/common/UnStyledLink'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import Avatar from '../../../../components/ui/Avatar/Avatar'
import Button from '../../../../components/ui/Button/Button'
import Flex from '../../../../components/ui/Flex'
import FormInput from '../../../../components/ui/Input/FormInput'
import { useAuthContext } from '../../../../context/AuthContext'
import { LogoApple } from '../../../../icons'
import GoogleIcon from '../../../../icons/custom/GoogleIcon'
import { flexGapString } from '../../../../utils/css'
import { FormStyled } from '../../../Auth/Auth.styled'

import type { UserDto } from '../../../../types'
import type { FieldValues } from 'react-hook-form'

const AvatarImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${flexGapString(20, 'row')}
`

const FormWrapper = styled(FormStyled)`
  margin-top: 32px;
`

export default function ProfileSettings() {
  const { t } = useTranslation()
  const [present, dismiss] = useIonLoading()
  const ionRouter = useIonRouter()
  const [presentToast] = useIonToast()

  const { user, updateUser } = useAuthContext()
  const { control, handleSubmit } = useForm({ mode: 'onSubmit' })

  function changeProfileInformationSubmit(data: FieldValues) {
    if (user) {
      const newUserData: UserDto = {
        ...user,
        firstName: data.firstName ? (data.firstName as string) : user.firstName,
        lastName: data.lastName ? (data.lastName as string) : user.lastName,
        email: data.email ? (data.email as string) : user.email,
        phone: data.phone ? (data.phone as string) : user.phone,
        avatar: {
          sourceUrl: user.avatar?.sourceUrl || '',
          thumbnailUrl: user.avatar?.thumbnailUrl || '',
          id: user.avatar?.id || '',
        },
      }
      void present(t('Profile.Edit.isEditing'))
      updateUser(newUserData)
        .then(() => {
          void dismiss()
          void presentToast({
            message: t('Profile.Edit.isEditingSuccess'),
            duration: 5000,
            color: 'success',
          })
          ionRouter.push(ROUTES.profile)
        })
        .catch(() => {
          void dismiss()
          void presentToast({
            message: t('Profile.Edit.isEditingError'),
            duration: 5000,
            color: 'error',
          })
        })
    }
  }

  return (
    <Page>
      <SubpageHeader
        title={t('Profile.Edit.title')}
        showBackButton={true}
        backButtonRoute={ROUTES.profile}
      />
      <IonContent className="ion-padding">
        <UnStyledLink to={ROUTES.changeAvatar}>
          <AvatarImageWrapper>
            <Avatar src={user?.avatar?.sourceUrl} size="2xl" alt="" />
            {t('Profile.Edit.changeAvatar')}
          </AvatarImageWrapper>
        </UnStyledLink>
        <FormWrapper onSubmit={() => handleSubmit(changeProfileInformationSubmit)}>
          <FormInput
            control={control}
            inputName="firstName"
            placeholder={user?.firstName}
            type="text"
            errorMessage=""
            label={t('Register.firstName')}
            required={false}
          />
          <FormInput
            control={control}
            inputName="lastName"
            placeholder={user?.lastName}
            type="text"
            errorMessage=""
            label={t('Register.lastName')}
            required={false}
          />
          <div className={css({ position: 'relative' })}>
            <FormInput
              control={control}
              inputName="email"
              placeholder={user?.email}
              type="email"
              errorMessage=""
              label={t('Login.email')}
              required={false}
              readOnly={user?.isRegisteredWithGoogle || user?.isRegisteredWithApple}
            />
            {(user?.isRegisteredWithGoogle || user?.isRegisteredWithApple) && (
              <Flex
                alignItems="center"
                gap={8}
                className={css({
                  position: 'absolute',
                  right: 1,
                  top: 1,
                  bottom: 1,
                  paddingRight: 20,
                  paddingLeft: 16,
                  background: 'white',
                  borderRadius: '9999px',
                })}
              >
                {user.isRegisteredWithApple && <LogoApple width={18} height={18} />}
                {user.isRegisteredWithGoogle && <GoogleIcon width={18} height={18} />}
              </Flex>
            )}
          </div>
          <FormInput
            control={control}
            inputName="phone"
            placeholder={user?.phone}
            type="text"
            errorMessage=""
            label={t('Register.phone')}
            required={false}
          />
          <Button type="submit" variant="primary">
            {t('General.save')}
          </Button>
        </FormWrapper>
      </IonContent>
    </Page>
  )
}
