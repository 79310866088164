import styled from '@emotion/styled'
import { IonContent, IonicSlides } from '@ionic/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'swiper'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'

import { SwiperPagination, swiperBulletStyles } from '../../../components/common/SwiperStyles'
import Button from '../../../components/ui/Button/Button'
import Flex from '../../../components/ui/Flex'
import { useAuthContext } from '../../../context/AuthContext'
import { PhoneFace, PhoneMessage, Question, Studio } from '../../../illustrations'
import { COLOR, FONT } from '../../../theme'

import type { ReactNode } from 'react'
import type { Swiper } from 'swiper/types'

import 'swiper/swiper.min.css'

interface Props {
  onDismiss: () => void
}

const SwiperStepStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;

  h2 {
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.semibold};
    color: ${COLOR.neutral.darkest};
    line-height: ${FONT.lineHeight.xl};
    margin: 0;
  }

  p {
    font-size: ${FONT.size.base};
    font-weight: ${FONT.weight.normal};
    color: ${COLOR.neutral.darkest};
    line-height: ${FONT.lineHeight.xl};
    margin: 0;
    margin-top: 16px;
  }
`

const SwiperStyled = styled(ReactSwiper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

function WizardStep({
  title,
  text,
  illustration,
}: {
  title: string
  text: string
  illustration: ReactNode
}) {
  return (
    <SwiperStepStyled>
      <div>{illustration}</div>
      <h2>{title}</h2>
      <p>{text}</p>
    </SwiperStepStyled>
  )
}

export default function ProjectStudioWizard(props: Props) {
  const { t } = useTranslation()
  const [swiper, setSwiper] = useState<Swiper | null>(null)
  const [lastIndex, setLastIndex] = useState(false)
  const { user, updateUser } = useAuthContext()

  const paginationRef = useRef<HTMLDivElement>(null)

  const finishWizard = () => {
    if (!user) return

    void updateUser({ hasProjectWizardSeen: true })

    props.onDismiss()
  }

  return (
    <>
      <IonContent className="ion-padding">
        <SwiperStyled
          modules={[Pagination, IonicSlides]}
          pagination={{
            el: paginationRef.current,
            type: 'bullets',
            bulletActiveClass: swiperBulletStyles(true),
            bulletClass: swiperBulletStyles(),
          }}
          onSwiper={(swipe: Swiper) => setSwiper(swipe)}
          onActiveIndexChange={() => {
            if (swiper) {
              if (swiper.isEnd) setLastIndex(true)
              else setLastIndex(false)
            }
          }}
        >
          <SwiperSlide>
            <WizardStep
              title={t('Project.Studio.Wizard.steps.1.title')}
              text={t('Project.Studio.Wizard.steps.1.text')}
              illustration={<Studio height={280} width={280} />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <WizardStep
              title={t('Project.Studio.Wizard.steps.2.title')}
              text={t('Project.Studio.Wizard.steps.2.text')}
              illustration={<Question height={280} width={280} />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <WizardStep
              title={t('Project.Studio.Wizard.steps.3.title')}
              text={t('Project.Studio.Wizard.steps.3.text')}
              illustration={<PhoneFace height={280} width={280} />}
            />
          </SwiperSlide>
          <SwiperSlide>
            <WizardStep
              title={t('Project.Studio.Wizard.steps.4.title')}
              text={t('Project.Studio.Wizard.steps.4.text')}
              illustration={<PhoneMessage height={280} width={280} />}
            />
          </SwiperSlide>
          <Flex direction="column" gap={32}>
            <SwiperPagination ref={paginationRef} />
            <Button variant={lastIndex ? 'primary' : 'secondary'} onClick={finishWizard}>
              {lastIndex ? t('Project.Studio.Wizard.finish') : t('Project.Studio.Wizard.skip')}
            </Button>
          </Flex>
        </SwiperStyled>
      </IonContent>
    </>
  )
}
