import styled from '@emotion/styled'
import { IonImg } from '@ionic/react'

import { BORDER_RADIUS, FONT } from '../../../../theme'
import { flexGapString } from '../../../../utils/css'

export const PostTitleStyled = styled.h1`
  font-size: ${FONT.size.xl};
  font-weight: ${FONT.weight.semibold};
  margin: 0;
`

export const PostImageStyled = styled(IonImg)`
  ::part(image) {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: ${BORDER_RADIUS.base};
  }
`

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(30, 'row')}
`

export const PostContentStyled = styled.div({
  'fontSize': FONT.size.base,
  'lineHeight': FONT.lineHeight.xl,

  '> :first-child': {
    marginTop: 0,
  },
  '> :last-child': {
    marginBottom: 0,
  },
  'p': {
    marginTop: 20,
    marginBottom: 20,
  },
  '[class~="lead"]': {
    fontSize: 20,
    lineHeight: 32 / 20,
    marginTop: 24,
    marginBottom: 24,
  },
  'blockquote': {
    marginTop: 32,
    marginBottom: 32,
    paddingLeft: 20,
  },
  'h1': {
    fontSize: 36,
    marginTop: '0',
    marginBottom: 32,
    lineHeight: 40 / 36,
  },
  'h2': {
    fontSize: 24,
    marginTop: 48,
    marginBottom: 24,
    lineHeight: 32 / 24,
  },
  'h3': {
    fontSize: 20,
    marginTop: 32,
    marginBottom: 12,
    lineHeight: 32 / 20,
  },
  'h4': {
    marginTop: 24,
    marginBottom: 8,
    lineHeight: 24 / 16,
  },
  'img': {
    marginTop: 32,
    marginBottom: 32,
    borderRadius: BORDER_RADIUS.base,
  },
  'video': {
    marginTop: 32,
    marginBottom: 32,
    borderRadius: BORDER_RADIUS.base,
  },
  'figure': {
    marginTop: 32,
    marginBottom: 32,
  },
  'figure > *': {
    marginTop: '0',
    marginBottom: '0',
  },
  'figcaption': {
    fontSize: 14,
    lineHeight: 20 / 14,
    marginTop: 12,
  },
  'code': {
    fontSize: 14,
  },
  'h2 code': {
    fontSize: 21,
  },
  'h3 code': {
    fontSize: 18,
  },
  'pre': {
    fontSize: 14,
    lineHeight: 24 / 14,
    marginTop: 24,
    marginBottom: 24,
    borderRadius: BORDER_RADIUS.base,
    paddingTop: 12,
    paddingRight: 16,
    paddingBottom: 12,
    paddingLeft: 16,
  },
  'ol': {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 26,
  },
  'ul': {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 26,
  },
  'li': {
    marginTop: 8,
    marginBottom: 8,
  },
  'ol > li': {
    paddingLeft: 6,
  },
  'ul > li': {
    paddingLeft: 6,
  },
  '> ul > li p': {
    marginTop: 12,
    marginBottom: 12,
  },
  '> ul > li > *:first-child': {
    marginTop: 20,
  },
  '> ul > li > *:last-child': {
    marginBottom: 20,
  },
  '> ol > li > *:first-child': {
    marginTop: 20,
  },
  '> ol > li > *:last-child': {
    marginBottom: 20,
  },
  'ul ul, ul ol, ol ul, ol ol': {
    marginTop: 12,
    marginBottom: 12,
  },
  'hr': {
    marginTop: 48,
    marginBottom: 48,
  },
  'hr + *': {
    marginTop: '0',
  },
  'h2 + *': {
    marginTop: '0',
  },
  'h3 + *': {
    marginTop: '0',
  },
  'h4 + *': {
    marginTop: '0',
  },
  'table': {
    fontSize: 14,
    lineHeight: 1.5,
  },
  'thead th': {
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  'thead th:first-child': {
    paddingLeft: '0',
  },
  'thead th:last-child': {
    paddingRight: '0',
  },
  'tbody td, tfoot td': {
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  'tbody td:first-child, tfoot td:first-child': {
    paddingLeft: '0',
  },
  'tbody td:last-child, tfoot td:last-child': {
    paddingRight: '0',
  },
})
