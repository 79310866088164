import ActionSheetModal from '../../../components/ui/ActionSheetModal'

import QuestionForm from './QuestionForm'

import type { ActionSheetModalProps } from '../../../components/ui/ActionSheetModal'
import type { CreateProjectQuestionDto } from '../../../types'

interface Props extends ActionSheetModalProps {
  question?: CreateProjectQuestionDto
  onSubmit: (values: CreateProjectQuestionDto) => void
}

export default function QuestionFormSheet(props: Props) {
  const { question, onSubmit, isOpen, onClose } = props

  return (
    <ActionSheetModal isOpen={isOpen} onClose={onClose}>
      <QuestionForm question={question} onSubmit={onSubmit} />
    </ActionSheetModal>
  )
}
