import { Preferences } from '@capacitor/preferences'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useApiContext } from './ApiContext'

import type { ProjectDto, ProjectQuestionDto } from '../types'
import type { PropsWithChildren } from 'react'

interface ProjectContextValue {
  project: ProjectDto | null
  setProject: (project: ProjectDto) => Promise<void>
  clearProject: () => Promise<void>
  questions: ProjectQuestionDto[]
  setQuestions: (questions: ProjectQuestionDto[]) => void
  fetchQuestions: () => Promise<void>
}

// @ts-expect-error default value will never be used
const defaultValue: ProjectContextValue = {}
export const ProjectContext = createContext<ProjectContextValue>(defaultValue)

const PROJECT_STORAGE_KEY = 'project'

export function ProjectProvider(props: PropsWithChildren) {
  const { children } = props

  const { apiClient } = useApiContext()

  const [project, setProject] = useState<ProjectDto | null>(null)
  const [questions, setQuestions] = useState<ProjectQuestionDto[]>([])

  const setLocalProject = useCallback(async (localProject: ProjectDto) => {
    await Preferences.set({ key: PROJECT_STORAGE_KEY, value: JSON.stringify(localProject) })
    setProject(localProject)
  }, [])

  const clearProject = useCallback(async () => {
    await Preferences.remove({ key: PROJECT_STORAGE_KEY })
    setProject(null)
  }, [])

  const fetchQuestions = async () => {
    if (!project) return

    const projectQuestions = await apiClient.getProjectQuestions(project.id)
    setQuestions(projectQuestions)
  }

  useEffect(() => {
    void clearProject()
  }, [clearProject])

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject: setLocalProject,
        clearProject,
        questions,
        fetchQuestions,
        setQuestions,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export const useProjectContext = () => useContext(ProjectContext)
