import styled from '@emotion/styled'
import { IonContent, IonToolbar } from '@ionic/react'

import { COLOR, PADDING } from '../../theme'
import { alpha } from '../../utils/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  inset: 0;
`

export const Content = styled(IonContent)`
  --padding-start: ${PADDING.DEFAULT};
  --padding-end: ${PADDING.DEFAULT};
  --background: transparent;

  ::part(scroll) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const Toolbar = styled(IonToolbar)`
  --border-style: none;
  --background: transparent;

  --color: ${COLOR.white};
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Footer = styled.div`
  padding: 0 ${PADDING.DEFAULT} var(--ion-safe-area-bottom);
  display: flex;
  align-items: center;
  height: 150px;
`

export const FooterElement = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  flex: 1;
  align-items: center;
  flex-shrink: 0;
`

export const ThumbnailBackground = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
  opacity: 0.75;
`

export const ThumbnailBackgroundOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${alpha(COLOR.black, 0.3)};
  z-index: -1;
  backdrop-filter: blur(24px);
`
