import { CapacitorHttp } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { SupabaseClient } from '@supabase/supabase-js'
import { differenceInDays } from 'date-fns'
import ky from 'ky'

import { USER_STORAGE_KEY, loginResponseDtoToUserDto } from '../context/AuthContext'

import {
  REACT_APP_API_URL,
  REACT_APP_SUPABASE_BUCKET,
  REACT_APP_SUPABASE_KEY,
  REACT_APP_SUPABASE_URL,
} from './env'
import { resizeImageTo720p, resizeImageToSquare } from './resizeImage'

import type {
  ActivityDto,
  AppConfigDto,
  BlockUserDto,
  BlogPostDto,
  CollaboratorDto,
  CreateInAppOrderDto,
  CreateProjectAnswerDto,
  CreateProjectAttachmentDto,
  CreateProjectDto,
  CreateProjectQuestionDto,
  CreateReportDto,
  GetProjectsQueryParams,
  LoginAsGuestDto,
  LoginResponseDto,
  LoginWithAppleDto,
  MusicTrackDto,
  OrderDto,
  PaginationDto,
  PaginationQueryParams,
  ProductionJobDto,
  ProjectAnswerDto,
  ProjectAttachmentDto,
  ProjectCategoryDto,
  ProjectDto,
  ProjectQuestionDto,
  PurchaseDto,
  QuestionOptionDto,
  RegisterRequestDto,
  UpdateInAppOrderDto,
  UpdateProjectAnswerDto,
  UpdateProjectAttachmentDto,
  UpdateProjectAttachmentsOrderDto,
  UpdateProjectDto,
  UpdateQuestionsOrderDto,
  UserDto,
} from '../types'
import type { HttpOptions, HttpResponse } from '@capacitor/core'

export const API_ROUTES = {
  accept: 'accept',
  activities: 'activities',
  answers: 'answers',
  attachments: 'attachments',
  attachmentsOrder: 'attachments/order',
  blocks: 'blocks',
  blogPosts: 'posts',
  collaborations: 'collaborations',
  forgotPassword: 'auth/forgot',
  inAppOrders: 'orders/inapp',
  jobs: 'jobs',
  joinProject: 'projects/join',
  leave: 'leave',
  login: 'auth/login',
  loginApple: 'auth/apple-native',
  loginGoogle: 'auth/google-native',
  guest: 'auth/guest',
  musicTracks: 'music-tracks',
  orders: 'orders',
  projectCategories: 'project-categories',
  projectInvites: 'collaborations/invites',
  projects: 'projects',
  purchases: 'purchases',
  questionOptions: 'question-options',
  questions: 'questions',
  questionsOrder: 'questions/order',
  registerUser: 'auth/register',
  report: 'report',
  start: 'start',
  users: 'users',
  refresh: 'auth/refresh',
  appConfig: 'app-config/auto',
  claimCredits: 'users/claim-credits',
  canClaimCredits: 'users/can-claim-credits',
  production: 'production',
}

/**
 * Join all arguments together and normalize the resulting URL path.
 *
 * @param paths - The URL path parts
 */
export const buildUrl = (...paths: (string | undefined)[]) => paths.filter(Boolean).join('/')

class CapacitorHttpClient {
  private readonly prefixUrl: string
  private token = ''

  constructor() {
    this.prefixUrl = REACT_APP_API_URL
  }

  private getTokenExpirationDate(token: string): Date | null {
    if (!token) return null

    const decoded = JSON.parse(window.atob(token.split('.')[1])) as { exp: number }

    if (!decoded.exp) return null

    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)
    return date
  }

  private async buildHeaders() {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    }

    const language = localStorage.getItem('i18nextLng')
    headers['Accept-Language'] = language || 'de'

    const token = await this.getToken()

    if (token) {
      headers.Authorization = `Bearer ${token}`
      const expirationDate = this.getTokenExpirationDate(token)
      const tokenWillExpireWithinFiveDays = expirationDate
        ? differenceInDays(expirationDate, new Date()) <= 5
        : false

      if (tokenWillExpireWithinFiveDays) {
        try {
          const refreshResponse = await CapacitorHttp.post({
            url: buildUrl(this.prefixUrl, API_ROUTES.refresh),
            headers,
          })

          if (refreshResponse.status >= 200 && refreshResponse.status < 300) {
            const user = refreshResponse.data as LoginResponseDto

            this.setToken(user.accessToken)
            await Preferences.set({
              key: USER_STORAGE_KEY,
              value: JSON.stringify(loginResponseDtoToUserDto(user)),
            })

            headers.Authorization = `Bearer ${user.accessToken}`
          }
        } catch {
          /* empty */
        }
      }
    }

    return headers
  }

  private async request<T = void>(
    method: string,
    url: string,
    options?: Omit<HttpOptions, 'url'>,
  ): Promise<T> {
    const headers = await this.buildHeaders()

    let response: HttpResponse

    try {
      response = await CapacitorHttp.request({
        ...options,
        method,
        url: buildUrl(this.prefixUrl, url),
        headers,
      })
    } catch {
      throw new Error('Network error')
    }

    if (response.status === 401 && !url.includes('auth')) this.clearToken()

    if (response.status >= 400) throw new Error(response.data as string)

    return response.data as T
  }

  private clearToken() {
    this.token = ''
    void Preferences.get({ key: 'user' }).then((user) => {
      if (user) void Preferences.remove({ key: USER_STORAGE_KEY })
    })
  }

  private async getToken() {
    if (this.token) return Promise.resolve(this.token)

    const result = await Preferences.get({ key: USER_STORAGE_KEY })

    if (!result.value) return null

    const user = JSON.parse(result.value) as UserDto
    this.setToken(user.accessToken)
    return user.accessToken
  }

  setToken(token: string) {
    this.token = token
  }

  get<T = void>(url: string, options?: Omit<HttpOptions, 'url'>): Promise<T> {
    return this.request<T>('GET', url, options)
  }

  post<T = void>(url: string, options?: Omit<HttpOptions, 'url'>): Promise<T> {
    return this.request<T>('POST', url, options)
  }

  patch<T = void>(url: string, options?: Omit<HttpOptions, 'url'>): Promise<T> {
    return this.request<T>('PATCH', url, options)
  }

  put<T = void>(url: string, options?: Omit<HttpOptions, 'url'>): Promise<T> {
    return this.request<T>('PUT', url, options)
  }

  delete<T = void>(url: string, options?: Omit<HttpOptions, 'url'>): Promise<T> {
    return this.request<T>('DELETE', url, options)
  }
}

export class ApiClient {
  supabaseClient: SupabaseClient
  supabaseBucket: string
  capacitorHttpClient: CapacitorHttpClient

  constructor() {
    this.capacitorHttpClient = new CapacitorHttpClient()
    this.supabaseClient = new SupabaseClient(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY)
    this.supabaseBucket = REACT_APP_SUPABASE_BUCKET
  }

  setToken(token: string) {
    this.capacitorHttpClient.setToken(token)
  }

  login(email: string, password: string) {
    return this.capacitorHttpClient.post<LoginResponseDto>(API_ROUTES.login, {
      data: { email, password },
    })
  }

  loginGoogle(token: string) {
    return this.capacitorHttpClient.post<LoginResponseDto>(API_ROUTES.loginGoogle, {
      data: { idToken: token },
    })
  }

  loginApple(data: LoginWithAppleDto) {
    return this.capacitorHttpClient.post<LoginResponseDto>(API_ROUTES.loginApple, { data })
  }

  loginGuest(data: LoginAsGuestDto) {
    return this.capacitorHttpClient.post<LoginResponseDto>(API_ROUTES.guest, { data })
  }

  fetchUser(userId: string) {
    return this.capacitorHttpClient.get<UserDto>(buildUrl(API_ROUTES.users, userId))
  }

  registerUser(user: RegisterRequestDto) {
    return this.capacitorHttpClient.post<LoginResponseDto>(API_ROUTES.registerUser, { data: user })
  }

  removeUser(userId: string) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.users, userId))
  }

  changePassword(userId: string, newPassword: string) {
    return this.capacitorHttpClient.patch(buildUrl(API_ROUTES.users, userId), {
      data: { password: newPassword },
    })
  }

  updateUser(userId: string, data: Partial<UserDto>) {
    return this.capacitorHttpClient.patch<UserDto>(buildUrl(API_ROUTES.users, userId), { data })
  }

  requestPasswordReset(email: string) {
    return this.capacitorHttpClient.post(API_ROUTES.forgotPassword, { data: { email } })
  }

  createProject(project: CreateProjectDto) {
    return this.capacitorHttpClient.post<ProjectDto>(API_ROUTES.projects, { data: project })
  }

  updateProject(id: string, project: UpdateProjectDto) {
    return this.capacitorHttpClient.patch<ProjectDto>(buildUrl(API_ROUTES.projects, id), {
      data: project,
    })
  }

  deleteProject(id: string) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.projects, id))
  }

  leaveProject(id: string, deleteAnswers = true) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.projects, id, API_ROUTES.leave), {
      params: { deleteAnswers: String(deleteAnswers) },
    })
  }

  reportProject(id: string, data: CreateReportDto) {
    return this.capacitorHttpClient.post(buildUrl('project', id, API_ROUTES.report), {
      data,
    })
  }

  getProjects(params: GetProjectsQueryParams) {
    const { limit = 10, page = 1, createdByUserOnly = false, status } = params

    const requestParams: Record<string, string> = {
      limit: String(limit),
      page: String(page),
      createdByUserOnly: String(createdByUserOnly),
    }

    if (status) requestParams.status = status.join(',')

    return this.capacitorHttpClient.get<PaginationDto<ProjectDto>>(API_ROUTES.projects, {
      params: requestParams,
    })
  }

  getProject(id: string) {
    return this.capacitorHttpClient.get<ProjectDto>(buildUrl(API_ROUTES.projects, id))
  }

  getProjectCategories() {
    return this.capacitorHttpClient.get<ProjectCategoryDto[]>(API_ROUTES.projectCategories)
  }

  getProjectInvites() {
    return this.capacitorHttpClient.get<CollaboratorDto[]>(API_ROUTES.projectInvites)
  }

  getSingleProjectInvite(id: string) {
    return this.capacitorHttpClient.get<CollaboratorDto>(buildUrl(API_ROUTES.collaborations, id))
  }

  joinProject(sharingCode: string) {
    return this.capacitorHttpClient.post<ProjectDto>(API_ROUTES.joinProject, {
      data: { sharingCode },
    })
  }

  inviteCollaborators(projectId: string, collaborators: { email: string }[]) {
    return this.capacitorHttpClient.post<ProjectDto>(buildUrl(API_ROUTES.collaborations), {
      data: { projectId, collaborators },
    })
  }

  addQuestionToProject(projectId: string, questions: CreateProjectQuestionDto[]) {
    return this.capacitorHttpClient.post<ProjectDto>(
      `${buildUrl(API_ROUTES.projects, projectId)}/questions`,
      { data: { questions } },
    )
  }

  deleteQuestionFromProject(projectId: string, questionId: string) {
    return this.capacitorHttpClient.delete<ProjectDto>(
      `${buildUrl(API_ROUTES.projects, projectId)}/questions/${questionId}`,
    )
  }

  updateQuestion(projectId: string, questionId: string, question: CreateProjectQuestionDto) {
    return this.capacitorHttpClient.patch<ProjectDto>(
      buildUrl(API_ROUTES.projects, projectId, 'questions', questionId),
      { data: question },
    )
  }

  getQuestionOptions(category: string) {
    return this.capacitorHttpClient.get<QuestionOptionDto[]>(API_ROUTES.questionOptions, {
      params: {
        category,
      },
    })
  }

  removeCollaborator(collaboratorId: string) {
    return this.capacitorHttpClient.delete<void>(
      buildUrl(API_ROUTES.collaborations, collaboratorId),
    )
  }

  acceptInvite(collaborationId: string) {
    return this.capacitorHttpClient.patch(
      buildUrl(API_ROUTES.collaborations, collaborationId, API_ROUTES.accept),
    )
  }

  getProjectQuestions(projectId: string) {
    return this.capacitorHttpClient.get<ProjectQuestionDto[]>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.questions),
    )
  }

  getSingleProjectQuestion(projectId: string, questionId: string) {
    return this.capacitorHttpClient.get<ProjectQuestionDto>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.questions, questionId),
    )
  }

  getAnswers(projectId: string, questionId: string) {
    return this.capacitorHttpClient.get<ProjectAnswerDto[]>(
      buildUrl(
        API_ROUTES.projects,
        projectId,
        API_ROUTES.questions,
        questionId,
        API_ROUTES.answers,
      ),
    )
  }

  getInvitationCode() {
    return this.capacitorHttpClient.get<string>(buildUrl(API_ROUTES.projects, 'invitation-code'))
  }

  createAnswer(projectId: string, questionId: string, data: CreateProjectAnswerDto) {
    return this.capacitorHttpClient.post(
      buildUrl(
        API_ROUTES.projects,
        projectId,
        API_ROUTES.questions,
        questionId,
        API_ROUTES.answers,
      ),
      { data },
    )
  }

  updateAnswer(answerId: string, data: UpdateProjectAnswerDto) {
    return this.capacitorHttpClient.patch<ProjectAnswerDto>(
      buildUrl(API_ROUTES.answers, answerId),
      { data },
    )
  }

  deleteAnswer(answerId: string) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.answers, answerId))
  }

  updateQuestionsOrder(projectId: string, questions: UpdateQuestionsOrderDto[]) {
    return this.capacitorHttpClient.patch<ProjectQuestionDto[]>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.questionsOrder),
      { data: { questions } },
    )
  }

  createProjectAttachment(projectId: string, data: CreateProjectAttachmentDto) {
    return this.capacitorHttpClient.post<ProjectAttachmentDto[]>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.attachments),
      { data },
    )
  }

  updateProjectAttachment(attachmentId: string, data: UpdateProjectAttachmentDto) {
    return this.capacitorHttpClient.patch<ProjectAttachmentDto>(
      buildUrl(API_ROUTES.attachments, attachmentId),
      { data },
    )
  }

  updateProjectAttachmentsOrder(projectId: string, data: UpdateProjectAttachmentsOrderDto) {
    return this.capacitorHttpClient.patch<ProjectAttachmentDto[]>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.attachmentsOrder),
      { data },
    )
  }

  getProjectActivities(projectId: string, params: PaginationQueryParams) {
    const { limit = 5, page = 1 } = params

    return this.capacitorHttpClient.get<PaginationDto<ActivityDto>>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.activities),
      {
        params: {
          limit: String(limit),
          page: String(page),
        },
      },
    )
  }

  async uploadVideo(videoPath: string, thumbnail: string) {
    const videoFileName = videoPath.split('/').pop()
    const thumbnailFileName = thumbnail.split('/').pop()

    const videoFile = await fetch(videoPath).catch((err) => {
      throw err
    })
    const videoBlob = await videoFile.blob()

    const file = new File([videoBlob], videoFileName as string, {
      type: videoBlob.type,
    })

    const { data: videoData, error: videoError } = await this.supabaseClient.storage
      .from(this.supabaseBucket)
      .upload(['videos', videoFileName].join('/'), file, { upsert: true })

    if (videoError || !videoData) throw videoError

    const imageBlob = await ky.get(thumbnail).blob()

    const { data: thumbnailData, error: thumbnailError } = await this.supabaseClient.storage
      .from(this.supabaseBucket)
      .upload(['images', thumbnailFileName].join('/'), imageBlob)

    if (thumbnailError || !thumbnailData) throw thumbnailError

    const {
      data: { publicUrl: videoUrl },
    } = this.supabaseClient.storage.from(this.supabaseBucket).getPublicUrl(videoData?.path)

    const {
      data: { publicUrl: thumbnailUrl },
    } = this.supabaseClient.storage.from(this.supabaseBucket).getPublicUrl(thumbnailData?.path, {
      transform: {
        width: 720,
        height: 1280,
        resize: 'cover',
      },
    })

    return { videoUrl, thumbnailUrl }
  }

  async uploadImage(thumbnail: string, transform: '720p' | 'square' = '720p', size = 300) {
    let imageBlob: Blob | null

    try {
      if (transform === '720p') imageBlob = (await resizeImageTo720p(thumbnail)) || null
      else imageBlob = (await resizeImageToSquare(thumbnail, size)) || null
    } catch {
      imageBlob = null
    }

    if (!imageBlob) imageBlob = await ky.get(thumbnail).blob()

    const fileExtension =
      thumbnail.match(/^data:image\/([^;]+);base64/)?.[1] ?? (thumbnail.split('.').pop() || 'jpg')
    const fileName = [Date.now(), fileExtension].join('.')

    const { data, error } = await this.supabaseClient.storage
      .from(this.supabaseBucket)
      .upload(['images', fileName].join('/'), imageBlob, { upsert: true })

    if (error || !data) throw error

    const {
      data: { publicUrl: thumbnailUrl },
    } = this.supabaseClient.storage.from(this.supabaseBucket).getPublicUrl(data?.path)

    return { thumbnailUrl, smallThumbnailUrl: thumbnailUrl }
  }

  createInAppOrder(data: CreateInAppOrderDto) {
    return this.capacitorHttpClient.post<OrderDto>(API_ROUTES.inAppOrders, { data })
  }

  updateOrder(orderId: string, data: UpdateInAppOrderDto) {
    return this.capacitorHttpClient.patch<OrderDto>(buildUrl(API_ROUTES.orders, orderId), {
      data,
    })
  }

  deleteOrder(orderId: string) {
    return this.capacitorHttpClient.delete<OrderDto>(buildUrl(API_ROUTES.orders, orderId))
  }

  fetchAvailableProductionJobs() {
    return this.capacitorHttpClient
      .get<PurchaseDto[]>(API_ROUTES.purchases)
      .then((res) => res.length)
  }

  triggerProductionJob(jobId: string) {
    return this.capacitorHttpClient.post(buildUrl(API_ROUTES.jobs, jobId, API_ROUTES.start))
  }

  getBlogPosts() {
    return this.capacitorHttpClient.get<PaginationDto<BlogPostDto>>(API_ROUTES.blogPosts)
  }

  getSinglePost(id: string) {
    return this.capacitorHttpClient.get<BlogPostDto>(buildUrl(API_ROUTES.blogPosts, id))
  }

  deleteAttachment(id: string) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.attachments, id))
  }

  blockUser(blockedId: string) {
    const data: BlockUserDto = { blockedId }

    return this.capacitorHttpClient.post(buildUrl(API_ROUTES.blocks), {
      data,
    })
  }

  unblockUser(id: string) {
    return this.capacitorHttpClient.delete(buildUrl(API_ROUTES.blocks, id))
  }

  getBlockedUsers() {
    return this.capacitorHttpClient.get<UserDto[]>(API_ROUTES.blocks)
  }

  getMusicTracks(categoryId: string) {
    return this.capacitorHttpClient.get<MusicTrackDto[]>(API_ROUTES.musicTracks, {
      params: {
        category: categoryId,
      },
    })
  }

  getAppConfig(preferredLang: string) {
    const response = this.capacitorHttpClient.get<AppConfigDto>(API_ROUTES.appConfig, {
      params: {
        lang: preferredLang,
      },
    })
    return response
  }

  triggerClaimFreeCredits() {
    return this.capacitorHttpClient.post<PurchaseDto>(API_ROUTES.claimCredits)
  }

  getCanClaimFreeCredits() {
    return this.capacitorHttpClient.post<boolean>(API_ROUTES.canClaimCredits)
  }

  createProductionJob(projectId: string) {
    return this.capacitorHttpClient.post<ProductionJobDto | undefined | null>(
      buildUrl(API_ROUTES.projects, projectId, API_ROUTES.production),
    )
  }
}
