import styled from '@emotion/styled'
import { useIonLoading, useIonToast } from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import Textarea from '../../../components/ui/Input/Textarea'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

import type { ProjectDto } from '../../../types'
import type { FormEventHandler } from 'react'

interface Props {
  isActive: boolean
  onClose: () => void
  project: ProjectDto
}

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  ${flexGapString(16)}
`

export default function ReportProjectModal(props: Props) {
  const { isActive, onClose, project } = props

  const { t } = useTranslation()

  const { apiClient } = useApiContext()

  const [reason, setReason] = useState('')
  const [presentToast] = useIonToast()
  const [presentLoading, dismissLoading] = useIonLoading()

  const close = () => {
    setReason('')
    onClose()
  }

  const reportProject: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    void presentLoading()

    apiClient
      .reportProject(project.id, { reason })
      .then(() => {
        void dismissLoading()

        void presentToast({
          message: t('ProjectDashboard.Report.success'),
          icon: checkmark,
          duration: 3000,
        })

        close()
      })
      .catch(() => {
        void dismissLoading()
        void presentToast({
          message: t('ProjectDashboard.Report.error'),
          icon: alertCircle,
          duration: 3000,
        })
      })
  }

  return (
    <ActionSheetModal isOpen={isActive} onClose={close}>
      <Flex direction="column" gap={12} style={{ paddingTop: 8 }}>
        <Flex gap={12} direction="column">
          <Text tag="h2" weight={FONT.weight.semibold} size={FONT.size.xl}>
            {t('ProjectDashboard.Report.title')}
          </Text>
          <Text tag="p" color={COLOR.neutral.dark}>
            {t('ProjectDashboard.Report.text')}
          </Text>
        </Flex>
        <FormStyled onSubmit={reportProject}>
          <Textarea
            inputName=""
            label=""
            placeholder={t('ProjectDashboard.Report.placeholder')}
            rows={4}
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
          <ButtonGroup>
            <Button disabled={!reason} type="submit">
              {t('ProjectDashboard.Report.submit')}
            </Button>
            <Button variant="secondary" type="button" onClick={onClose}>
              {t('ProjectDashboard.Report.cancel')}
            </Button>
          </ButtonGroup>
        </FormStyled>
      </Flex>
    </ActionSheetModal>
  )
}
