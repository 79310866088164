import type { PluginListenerHandle } from '@capacitor/core'

export interface DownloadFileOptions {
  fileName: string
  url: string
  errorMessage: string
  successMessage: string
}

export enum FileDownloadEvent {
  Success = 'success',
  Error = 'error',
  Progress = 'progress',
}

export interface ProgressEventPayload {
  bytesAmount: number
}

export interface FileDownloadPlugin {
  downloadFile: (options: DownloadFileOptions) => Promise<void>
  addListener: (
    eventName: FileDownloadEvent,
    listenerFunc: (payload: ProgressEventPayload) => void,
  ) => Promise<PluginListenerHandle> & PluginListenerHandle
}
