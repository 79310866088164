import { IonSpinner } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import Button from '../../../components/ui/Button/Button'
import Dialog from '../../../components/ui/Dialog'
import Flex from '../../../components/ui/Flex'
import Grid from '../../../components/ui/Grid'
import MessageCard from '../../../components/ui/MessageCard'
import { useApiContext } from '../../../context/ApiContext'
import {
  Bin,
  Cog,
  Finish,
  QuestionBubble,
  RefreshArrowsBold,
  UsersCircle,
  VideoClip,
} from '../../../icons'
import { COLOR } from '../../../theme'
import { ProjectStatus } from '../../../types'

import FinishWinkiiModal from './FinishWinkiiModal'
import ProjectDashboardItem from './ProjectDashboardItem'
import StatusItem from './StatusItem'

import type { ProjectDto, ProjectQuestionDto } from '../../../types'

interface Props {
  project: ProjectDto
  questions: ProjectQuestionDto[]
  refetch: () => Promise<void>
  onInviteVideoUploaded?: () => void
}

interface StatusOverride {
  status: ProjectStatus
  value: number
}

export default function Status(props: Props) {
  const { project, questions = [], refetch } = props

  const { t } = useTranslation()
  const [recoverDialogOpen, setRecoverDialogOpen] = useState(false)
  const [isPendingJobCompletion, setIsPendingJobCompletion] = useState(false)
  const [isFinishWinkiiModalOpen, setIsFinishWinkiiModalOpen] = useState(false)

  const hasQuestions = questions && questions.length > 0
  const hasCollaborators = project.collaborators && project.collaborators.length > 0
  const hasAnswers = questions.some((question) => question.answers && question.answers.length > 0)

  const revertDeletion = () => {
    // not implemented yet
  }

  const getStatus = (): StatusOverride => {
    switch (project.status) {
      case ProjectStatus.Planned:
        return { status: ProjectStatus.Planned, value: 25 }

      case ProjectStatus.QuestionSelected:
        return { status: ProjectStatus.QuestionSelected, value: 50 }

      case ProjectStatus.InProgress:
        if (hasQuestions && !hasCollaborators && !hasAnswers)
          return { status: ProjectStatus.QuestionSelected, value: 50 }

        return { status: ProjectStatus.InProgress, value: 70 }

      case ProjectStatus.Deleted:
        return { status: ProjectStatus.Deleted, value: 0 }

      default:
        return { status: project.status, value: 0 }
    }
  }

  const status = getStatus()

  if (status.status === ProjectStatus.Planned) {
    setIsPendingJobCompletion(true)
  }

  if (status.status === ProjectStatus.Deleted) {
    return (
      <>
        <Flex direction="column">
          <MessageCard icon={<Bin />} message={t('ProjectDashboard.MarkedForDeletion.creator')} />
          <Button
            variant="secondary"
            icon={<RefreshArrowsBold />}
            onClick={() => setRecoverDialogOpen(true)}
          >
            {t('ProjectDashboard.MarkedForDeletion.recover')}
          </Button>
        </Flex>
        <Dialog isOpen={recoverDialogOpen} onClose={() => setRecoverDialogOpen(false)}>
          <Dialog.Body>{t('ProjectDashboard.MarkedForDeletion.RecoverDialog.text')}</Dialog.Body>
          <Dialog.Buttons buttonsGrow={true}>
            <Button onClick={revertDeletion}>
              {t('ProjectDashboard.MarkedForDeletion.RecoverDialog.confirm')}
            </Button>
            <Button variant="secondary" onClick={() => setRecoverDialogOpen(false)}>
              {t('ProjectDashboard.MarkedForDeletion.RecoverDialog.cancel')}
            </Button>
          </Dialog.Buttons>
        </Dialog>
      </>
    )
  }

  const { apiClient } = useApiContext()
  const startProductionJob = async () => {
    try {
      setIsPendingJobCompletion(true)
      const job = await apiClient.createProductionJob(project.id)

      if (!job) return

      await apiClient.triggerProductionJob(job.id)
      await refetch()
    } catch (e) {
      await refetch()
      setIsPendingJobCompletion(false)

      console.error(e)
    }
  }

  return (
    <ProjectDashboardItem title={t('ProjectDashboard.Tiles.title')}>
      <Grid columns={2}>
        <StatusItem
          emphasized={status.status === ProjectStatus.Planned && questions.length === 0}
          title={t('ProjectDashboard.Tiles.questions')}
          icon={<QuestionBubble />}
          color={COLOR.primary.light}
          link={ROUTES.manageQuestions(project.id)}
        />
        <StatusItem
          emphasized={status.status === ProjectStatus.QuestionSelected}
          disabled={!hasQuestions}
          title={t('ProjectDashboard.Tiles.collaborators')}
          icon={<UsersCircle />}
          color={COLOR.salmon.light}
          link={ROUTES.collaboratorsList(project.id)}
        />
        <StatusItem
          title={t('ProjectDashboard.Tiles.answers')}
          icon={<VideoClip />}
          disabled={!hasAnswers}
          color={COLOR.turquoise.light}
          link={ROUTES.projectClips(project.id)}
        />
        <StatusItem
          title={t('ProjectDashboard.Tiles.settings')}
          icon={<Cog />}
          color={COLOR.neutral.lighter}
          link={ROUTES.editProject(project.id)}
        />

        <StatusItem
          title={t('ProjectDashboard.Tiles.startProduction')}
          disabled={isPendingJobCompletion}
          icon={isPendingJobCompletion ? <IonSpinner name={DEFAULT_SPINNER} /> : <Finish />}
          color={COLOR.turquoise.light}
          click={() => setIsFinishWinkiiModalOpen(true)}
        />

        <FinishWinkiiModal
          open={isFinishWinkiiModalOpen}
          setOpen={setIsFinishWinkiiModalOpen}
          buttonClicked={() => void startProductionJob()}
        />
      </Grid>
    </ProjectDashboardItem>
  )
}
