import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/ui/Button/Button'
import Flex from '../../../components/ui/Flex'
import TextField from '../../../components/ui/Input/TextField'
import Text from '../../../components/ui/Text'
import { COLOR, FONT } from '../../../theme'

import type { CreateProjectQuestionDto } from '../../../types'
import type { FormEventHandler } from 'react'

interface Props {
  question?: CreateProjectQuestionDto
  onSubmit: (values: CreateProjectQuestionDto) => void
}

const QUESTION_MAX_LENGTH = 150

export default function QuestionForm(props: Props) {
  const { question = null, onSubmit } = props

  const { t } = useTranslation()

  const isEditMode = !!question

  const initialTitle = question?.title || ''

  const [title, setTitle] = useState(initialTitle)

  const canSubmit = title.trim().length > 0

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    if (!canSubmit) return

    onSubmit({ title, multipleAnswers: true })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={20}>
        <Text tag="h2" size={FONT.size.xl} weight={FONT.weight.semibold}>
          {isEditMode
            ? t('ProjectDashboard.Questions.edit')
            : t('ProjectDashboard.Questions.AddQuestion.title')}
        </Text>
        <TextField
          autoFocus
          name="title"
          value={title}
          placeholder={t('ProjectDashboard.Questions.AddQuestion.placeholder')}
          onChange={setTitle}
        />
        <Flex justifyContent="flex-end">
          <Text
            size={FONT.size.sm}
            weight={FONT.weight.medium}
            color={title.length > QUESTION_MAX_LENGTH ? COLOR.danger.dark : COLOR.neutral.DEFAULT}
          >
            {title.length}/{QUESTION_MAX_LENGTH}
          </Text>
        </Flex>
        <Button type="submit" disabled={!canSubmit}>
          {isEditMode ? t('General.save') : t('ProjectDashboard.Questions.AddQuestion.add')}
        </Button>
      </Flex>
    </form>
  )
}
