/**
 * Stop all audio elements on the page
 */
export function stopAllAudio() {
  const audioElements = document.querySelectorAll('audio')
  if (!audioElements) return
  for (let i = 0; i < audioElements.length; i++) {
    audioElements[i].pause()
    audioElements[i].currentTime = 0
  }
}

/**
 * Stop all video elements on the page
 */
export function stopAllVideos() {
  const videoElements = document.querySelectorAll('video')
  if (!videoElements) return
  for (let i = 0; i < videoElements.length; i++) {
    videoElements[i].pause()
    videoElements[i].currentTime = 0
  }
}
