import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LazyImage from '../../../../components/common/LazyImage'
import WithLoading from '../../../../components/common/WithLoading'
import ScrollSnapContainer from '../../../../components/ui/ScrollSnapContainer'
import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../../../theme'
import DashboardSection from '../DashboardSection'

import type { BlogPostDto } from '../../../../types'

interface BlogPostsProps {
  posts?: BlogPostDto[]
  isLoading: boolean
}

const PostCardStyled = styled(Link)`
  border-radius: ${BORDER_RADIUS.base};
  background-color: #fff;
  border: 1px solid ${COLOR.neutral.lighter};
  display: flex;
  flex-direction: column;
  box-shadow: ${SHADOW.base};
  color: ${COLOR.black};
  text-decoration: none;
  width: 246px;
  overflow: hidden;
  transition: ${TRANSITION.base};

  :active {
    box-shadow: ${SHADOW.lg};
    background-color: ${COLOR.primary.lighter};
    transform: scale(0.975);
  }
`

const ImageWrapper = styled.div`
  aspect-ratio: 16 / 9;
  position: relative;
`

const PostCardContentStyled = styled.div`
  padding: 16px 20px;
  font-size: ${FONT.size.base};
  font-weight: ${FONT.weight.medium};
`

export default function BlogPosts(props: BlogPostsProps) {
  const { posts = [], isLoading } = props
  const { t } = useTranslation()

  return (
    <DashboardSection title={t('Dashboard.BlogPosts.title')}>
      <WithLoading isLoading={isLoading}>
        <ScrollSnapContainer childrenWidth={250}>
          {posts.map((post) => (
            <PostCardStyled key={post.id} to={`/blog/${post.id}`}>
              <ImageWrapper>
                <LazyImage alt="" src={post.featuredImage.sourceUrl} />
              </ImageWrapper>
              <PostCardContentStyled>{post.title}</PostCardContentStyled>
            </PostCardStyled>
          ))}
        </ScrollSnapContainer>
      </WithLoading>
    </DashboardSection>
  )
}
