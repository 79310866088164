import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { IonContent } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Page from '../../components/layout/Page'
import PageHeader from '../../components/layout/PageHeader'

import ProductsList from './ProductsList'

export default function Shop() {
  const { t } = useTranslation()

  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'Shop', nameOverride: 'Shop' })
  }, [])

  return (
    <Page>
      <PageHeader title={t('Shop.title')} />
      <IonContent className="ion-padding">
        <ProductsList />
      </IonContent>
    </Page>
  )
}
