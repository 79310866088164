/**
 * Button Component.
 * We don't use `IonButton` here because transitions are not working at the moment.
 */
import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import { forwardRef, useState } from 'react'

import { View, ViewOff } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../../theme'
import button from '../Button/Button'
import { iconButtonStyles } from '../IconButton/IconButton'

import type { Dispatch, InputHTMLAttributes, MouseEventHandler, SetStateAction } from 'react'

export interface BaseInputProps {
  inputName: string
  label: string
  error?: boolean
  errorMessage?: string
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {
  variant?: 'primary' | 'ghost'
  backgroundColor?: string
}

type InputVariant = 'primary' | 'ghost'

export const inputVariantStyles: Record<InputVariant, string> = {
  primary: css({
    fontSize: FONT.size.base,
    borderRadius: BORDER_RADIUS.base,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.neutral.light,
    boxShadow: SHADOW.base,
  }),
  ghost: css({
    background: 'transparent',
    width: '100%',
    border: 'none',
    textAlign: 'center',
    fontSize: FONT.size['2xl'],
  }),
}

export function inputStyles(hasError = false, isPassword = false) {
  return css`
    width: 100%;
    padding: 24px 20px 10px;
    font-family: ${isPassword ? 'monospace' : FONT.family.sans};
    padding-inline-end: ${isPassword ? '48px' : '20px'};
    outline: ${hasError ? `2px solid ${COLOR.danger.lighter}` : 'none'};
    transition: ${TRANSITION.base};

    :focus {
      outline: 2px solid ${COLOR.primary.DEFAULT};
    }

    :read-only:focus {
      outline: none;
    }
  `
}

export const InputLabelStyled = styled.label<{ hasError: boolean; backgroundColor?: string }>`
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  padding-inline-start: 20px;
  color: ${COLOR.neutral.DEFAULT};
  font-size: ${FONT.size.xs};
  font-weight: ${FONT.weight.medium};
`

export const InputWrapperStyled = styled.div`
  position: relative;
  width: 100%;
`

const ShowPasswordButtonStyled = styled(button)`
  box-shadow: none;
  pointer-events: all;

  :active {
    box-shadow: none;
  }
`

const PasswordButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  inset: 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
`

function ShowPasswordButton({
  passwordShown,
  setPasswordShown,
}: {
  passwordShown: boolean
  setPasswordShown: Dispatch<SetStateAction<boolean>>
}) {
  const onMouseDown: MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setPasswordShown(!passwordShown)
  }

  return (
    <PasswordButtonWrapper>
      <ShowPasswordButtonStyled
        icon={passwordShown ? <ViewOff /> : <View />}
        type="button"
        title="Show Password"
        className={iconButtonStyles()}
        onMouseDown={onMouseDown}
      />
    </PasswordButtonWrapper>
  )
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    variant = 'primary',
    value,
    inputName,
    label,
    type,
    error = false,
    onChange,
    onBlur,
    ...rest
  } = props

  const [passwordShown, setPasswordShown] = useState(false)

  return (
    <InputWrapperStyled>
      <InputLabelStyled hasError={error} htmlFor={inputName}>
        {label}
      </InputLabelStyled>
      <input
        ref={ref}
        id={inputName}
        placeholder={rest.placeholder}
        type={passwordShown ? 'text' : type}
        name={inputName}
        value={value}
        className={cx(inputVariantStyles[variant], inputStyles(error, type === 'password'))}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
      />
      {type === 'password' && (
        <ShowPasswordButton passwordShown={passwordShown} setPasswordShown={setPasswordShown} />
      )}
    </InputWrapperStyled>
  )
})

Input.displayName = 'Input'

export default Input
