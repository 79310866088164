import { useEffect, useState } from 'react'

export function useVideoThumbnail(videoUrl: string, offsetSeconds = 0) {
  const [thumbnail, setThumbnail] = useState('')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    let video: HTMLVideoElement
    let canvas: HTMLCanvasElement

    const getThumbnailForVideo = async () => {
      video = document.createElement('video')
      canvas = document.createElement('canvas')
      video.style.display = 'none'
      canvas.style.display = 'none'

      await new Promise<void>((resolve) => {
        video.muted = true
        video.autoplay = true
        video.playsInline = true

        video.addEventListener('loadedmetadata', () => {
          video.currentTime = offsetSeconds
          video.width = video.videoWidth
          video.height = video.videoHeight
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
        })

        video.addEventListener('seeked', () => {
          resolve()
        })

        video.crossOrigin = 'anonymous'
        video.src = videoUrl
      })

      try {
        canvas.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
        const dataURL = canvas.toDataURL('image/png')
        setThumbnail(dataURL)
        video.remove()
      } catch {
        setIsError(true)
      }
    }

    void getThumbnailForVideo()

    return () => {
      video.remove()
      canvas.remove()
    }
  }, [offsetSeconds, videoUrl])

  return { thumbnail, isError }
}
