import styled from '@emotion/styled'

import { PADDING } from '../../theme'
import { flexGapString } from '../../utils/css'

import type { PropsWithChildren } from 'react'

const ScrollSnapContainerStyled = styled.div<{ childrenWidth?: number }>`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 8px ${PADDING.DEFAULT};
  margin: -8px -${PADDING.DEFAULT};
  scroll-padding: 0 ${PADDING.DEFAULT};
  ${flexGapString(12, 'column')}

  > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    ${({ childrenWidth }) => (childrenWidth ? `min-width: ${childrenWidth}px;` : '')}
    ${({ childrenWidth }) => (childrenWidth ? `max-width: ${childrenWidth}px;` : '')}
`

interface Props {
  childrenWidth?: number
}

export default function ScrollSnapContainer(props: PropsWithChildren<Props>) {
  const { childrenWidth, children } = props

  return (
    <ScrollSnapContainerStyled childrenWidth={childrenWidth}>{children}</ScrollSnapContainerStyled>
  )
}
