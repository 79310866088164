import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import { useEffect, useState } from 'react'

import DatePicker from '../../../components/common/DatePicker'
import Flex from '../../../components/ui/Flex'
import InfoBox from '../../../components/ui/InfoBox'
import Text from '../../../components/ui/Text'

import type { StepProps } from './NewProjectWizard'
import type { ReactNode } from 'react'

interface ChooseDateStepProps extends StepProps {
  title: string
  placeholder: string
  image: ReactNode
  description: string
}

export default function ChooseDateStep(props: ChooseDateStepProps) {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({
      screenName: 'ChooseDateStep',
      nameOverride: 'NewProject',
    })
  }, [])

  const { title, description, setValue } = props
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const handleSelectDate = (date: Date) => {
    setSelectedDate(date)
    setValue(date.toISOString())
  }

  return (
    <Flex direction="column" gap={20} alignItems="center">
      <Text className={css({ textAlign: 'center' })}>{title}</Text>
      <DatePicker selectedDate={selectedDate} setSelectedDate={handleSelectDate} />
      <InfoBox>{description}</InfoBox>
    </Flex>
  )
}
