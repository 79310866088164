import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import styled from '@emotion/styled'
import { IonLabel, useIonLoading, useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import AuthPage from '../../components/layout/AuthPage'
import EmailSentPage from '../../components/layout/EmailSentPage'
import Button from '../../components/ui/Button/Button'
import GhostButton from '../../components/ui/Button/GhostButton'
import Flex from '../../components/ui/Flex'
import FormInput from '../../components/ui/Input/FormInput'
import { useAppConfig } from '../../context/AppConfigContext'
import { useAuthContext } from '../../context/AuthContext'
import { FONT } from '../../theme'
import { flexGapString } from '../../utils/css'

import { FormStyled } from './Auth.styled'

import type { FieldValues } from 'react-hook-form'

const PasswordAgbWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${flexGapString(16, 'row')}
  p {
    margin: 0;
    font-size: ${FONT.size.sm};
    line-height: ${FONT.lineHeight.sm};
  }
`

export default function Register() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'Register', nameOverride: 'Auth' })
  }, [])

  const { t } = useTranslation()
  const ionRouter = useIonRouter()

  const [authSuccess, setAuthSuccess] = useState(false)
  const [present, dismiss] = useIonLoading()
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onSubmit' })

  const { registerUser } = useAuthContext()
  const { linkTerms } = useAppConfig()

  const loginErrorMessage = t('Login.error')

  const resetErrors = () => {
    if (errors.submitError) clearErrors('submitError')
  }

  const handleRegister = (data: FieldValues) => {
    void present(t('Register.loading'))

    void FirebaseAnalytics.logEvent({ name: 'submitRegisterEmail', params: {} })

    registerUser({
      firstName: data.firstName as string,
      lastName: data.lastName as string,
      email: data.email as string,
      password: data.password as string,
    })
      .then(() => {
        void dismiss()
        setAuthSuccess(true)
      })
      .catch(() => {
        void dismiss()
        setError('submitError', { message: loginErrorMessage })
      })
  }

  if (authSuccess) {
    return (
      <EmailSentPage messageTitle={t('Register.successTitle')} message={t('Register.success')} />
    )
  }

  return (
    <AuthPage title={t('Register.title')} subtitle={t('Register.subtitle')}>
      <Flex direction="column">
        {}
        <FormStyled onSubmit={handleSubmit(handleRegister)}>
          <Flex direction="column">
            <FormInput
              control={control}
              inputName="firstName"
              type="text"
              errorMessage={loginErrorMessage}
              label={t('Register.firstName')}
            />
            <FormInput
              control={control}
              inputName="lastName"
              type="text"
              errorMessage={loginErrorMessage}
              label={t('Register.lastName')}
            />
            <FormInput
              control={control}
              inputName="email"
              type="email"
              errorMessage={loginErrorMessage}
              label={t('Login.email')}
            />
            <FormInput
              control={control}
              inputName="password"
              type="password"
              errorMessage={loginErrorMessage}
              label={t('Login.password')}
            />
          </Flex>
          {errors.email ||
          errors.firstname ||
          errors.lastname ||
          errors.password ||
          errors.submitError ? (
            <IonLabel color="danger">
              {errors.submitError ? (errors.submitError.message as string) : loginErrorMessage}
            </IonLabel>
          ) : null}
          <PasswordAgbWrapper>
            <p>{t('Register.passwordRules')}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('Register.agb', {
                  url: linkTerms,
                }),
              }}
            />
          </PasswordAgbWrapper>
          <Button type="submit" onClick={resetErrors}>
            {t('Login.register')}
          </Button>
        </FormStyled>
        <GhostButton variant="secondary" onClick={() => ionRouter.push(ROUTES.auth, 'back')}>
          {t('Login.backToLogin')}
        </GhostButton>
      </Flex>
    </AuthPage>
  )
}
