import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import WithLoading from '../../../components/common/WithLoading'
import SelectionButton from '../../../components/ui/Button/SelectionButton'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { useProjectCategories } from '../../../hooks/useProjectCategories'
import { BirthdayCake, Party, WeddingCake } from '../../../illustrations'
import { COLOR, FONT } from '../../../theme'

import type { ProjectCategoryDto } from '../../../types'

interface ChooseCategoryStepProps {
  setCategoryImage: (image: JSX.Element) => void
  value: string
  setValue: (value: string, categoryName: string) => void
}

export enum ProjectCategoryType {
  Birthday = 'birthday',
  Wedding = 'wedding',
}

function getCategoryIllustration(categoryType: string) {
  switch (categoryType) {
    case ProjectCategoryType.Birthday:
      return <BirthdayCake />
    case ProjectCategoryType.Wedding:
      return <WeddingCake />
    default:
      return <Party />
  }
}

export default function ChooseCategoryStep(props: ChooseCategoryStepProps) {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'CategoryStep', nameOverride: 'NewProject' })
  }, [])

  const { setValue, setCategoryImage } = props
  const { data: projectCategories = [], isError, isInitialLoading } = useProjectCategories()
  const { t } = useTranslation()

  const selectCategory = (category: ProjectCategoryDto) => {
    setValue(category.id, category.title)
    setCategoryImage(getCategoryIllustration(category.type))
  }

  if (isError) return <div>Error</div>

  return (
    <WithLoading isLoading={isInitialLoading}>
      <Flex direction="column" gap={32}>
        <Text className={css({ textAlign: 'center' })}>{t('NewProject.event')}</Text>
        <Flex direction="column">
          {projectCategories.map((category, index) => (
            <SelectionButton
              key={category.id}
              image={
                <Text
                  color={COLOR.primary.darker}
                  size={FONT.size.sm}
                  className={css({ width: 20 })}
                >
                  {index + 1}
                </Text>
              }
              title={category.title}
              onClick={() => selectCategory(category)}
            />
          ))}
        </Flex>
      </Flex>
    </WithLoading>
  )
}
