import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react'
import { formatDistanceToNow } from 'date-fns'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import LazyImage from '../../../components/common/LazyImage'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import PausableVideo from '../../../components/video/PausableVideo'
import { CloseCircle, DotsVertical, Play, Upload } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, PADDING, SHADOW, TRANSITION } from '../../../theme'
import { alpha } from '../../../utils/colors'
import { lineClamp } from '../../../utils/css'
import { getDateFnsLocale } from '../../../utils/date'
import { setNavigationBarBlack, setNavigationBarLight } from '../../../utils/navigationBar'
import { setStatusbarBlack, setStatusbarLight } from '../../../utils/statusBar'

import type { IconButtonEventHandler } from '../../../components/ui/IconButton/IconButton'
import type { MediaDto } from '../../../types'

interface Props {
  title: string
  date?: Date
  thumbnailUrl: string
  video: MediaDto
  onDelete: () => void
  onUpload?: () => void
}

const ModalToolbarStyled = styled(IonToolbar)`
  --background: ${COLOR.black};
  color: ${COLOR.white};
`

const ModalContent = styled(IonContent)`
  --background: ${COLOR.black};
`

export const AnswerItemStyled = styled.button`
  transition: ${TRANSITION.base};
  box-shadow: ${SHADOW.base};
  border: 1px solid ${COLOR.neutral.lighter};
  border-radius: ${BORDER_RADIUS.sm};
  background-color: ${COLOR.white};

  &:active {
    transform: scale(0.98);
  }
`

export const AnswerThumbnailStyled = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: ${BORDER_RADIUS.sm};
`

export const AnswerThumbnailOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: ${alpha(COLOR.black, 0.3)};

  svg {
    padding: 2px;
  }
`

const QuestionOverlay = styled.div`
  position: absolute;
  border-radius: ${BORDER_RADIUS.base};
  bottom: ${PADDING.DEFAULT};
  left: ${PADDING.DEFAULT};
  right: ${PADDING.DEFAULT};
  padding: ${PADDING.DEFAULT};
  background: ${alpha(COLOR.black, 0.5)};
  backdrop-filter: blur(8px);
  box-shadow: ${SHADOW.lg};
`

export default function AnswerItem(props: Props) {
  const { title, thumbnailUrl, video, onDelete, onUpload, date } = props

  const { t, i18n } = useTranslation()
  const locale = getDateFnsLocale(i18n.language)

  const [viewModalActive, setViewModalActive] = useState(false)
  const modal = useRef<HTMLIonModalElement>(null)
  const {
    value: actionSheetOpen,
    setTrue: openActionSheet,
    setFalse: closeActionSheet,
  } = useBoolean(false)

  const formattedDate = useMemo(() => {
    if (!date) return ''

    return formatDistanceToNow(date, { addSuffix: true, locale })
  }, [date, locale])

  const onClick = () => setViewModalActive(true)

  const onSettingsClick: IconButtonEventHandler = (event) => {
    event.stopPropagation()
    openActionSheet()
  }

  const onUploadClick: IconButtonEventHandler = (event) => {
    event.stopPropagation()
    onUpload?.()
  }

  const deleteAnswer = () => {
    closeActionSheet()
    onDelete()
  }

  return (
    <>
      <AnswerItemStyled onClick={onClick}>
        <Flex alignItems="center" className={css({ padding: '8px 4px 8px 12px' })}>
          <AnswerThumbnailStyled>
            <LazyImage
              className={css({ inset: 0, width: '100%', height: '100%' })}
              src={thumbnailUrl}
              alt=""
            />
            <AnswerThumbnailOverlay>
              <Play height={20} width={20} color={COLOR.white} />
            </AnswerThumbnailOverlay>
          </AnswerThumbnailStyled>
          <Flex
            className={css({ textAlign: 'start', flex: '1', lineHeight: '1.25' })}
            direction="column"
            gap={2}
          >
            <Text size={FONT.size.base} weight={FONT.weight.normal} className={lineClamp(1)}>
              {title}
            </Text>
            {!!date && (
              <Text size={FONT.size.sm} color={COLOR.neutral.DEFAULT}>
                {formattedDate}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center" gap={1}>
            {!!onUpload && (
              <IconButton
                variant="primary"
                icon={<Upload width={16} height={16} />}
                title=""
                onClick={onUploadClick}
              />
            )}
            <IconButton icon={<DotsVertical />} title="" onClick={onSettingsClick} />
          </Flex>
        </Flex>
      </AnswerItemStyled>
      <IonModal
        ref={modal}
        className="ion-safe-area-bottom"
        isOpen={viewModalActive}
        onWillPresent={() => {
          setStatusbarBlack()
          setNavigationBarBlack()
          setNavigationBarBlack()
        }}
        onWillDismiss={() => setStatusbarLight()}
        onDidDismiss={() => {
          setViewModalActive(false)
          setNavigationBarLight()
        }}
      >
        <IonHeader>
          <ModalToolbarStyled>
            <IonButtons slot="end">
              <IconButton
                icon={<CloseCircle />}
                title=""
                onClick={() => void modal.current?.dismiss()}
                onDark={true}
              />
            </IonButtons>
          </ModalToolbarStyled>
        </IonHeader>
        <ModalContent className="">
          <PausableVideo layout="fullscreen" video={video} />
          <QuestionOverlay>
            <Text size={FONT.size.xl} weight={FONT.weight.medium} color={COLOR.white}>
              {title}
            </Text>
          </QuestionOverlay>
        </ModalContent>
      </IonModal>
      <ActionSheetModal isOpen={actionSheetOpen} onClose={closeActionSheet}>
        <Flex direction="column" gap={20}>
          <Text color={COLOR.neutral.DEFAULT}>{title}</Text>
          <ButtonGroup>
            <Button variant="danger" onClick={deleteAnswer}>
              {t('General.delete')}
            </Button>
          </ButtonGroup>
        </Flex>
      </ActionSheetModal>
    </>
  )
}
