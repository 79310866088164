import type { SVGProps } from 'react'

export default function GoogleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      role="img"
      {...props}
    >
      <g clipPath="url(#clip0_21_2479)">
        <path
          d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
          fill="#4285F4"
        />
        <path
          d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.125 19.25 6.475 17.14 5.525 14.29H1.545V17.38C3.515 21.3 7.565 24 12.255 24Z"
          fill="#34A853"
        />
        <path
          d="M5.525 14.29C5.275 13.57 5.145 12.7999 5.145 11.9999C5.145 11.1999 5.285 10.4299 5.525 9.70994V6.61994H1.545C0.725004 8.23994 0.255005 10.0599 0.255005 11.9999C0.255005 13.9399 0.725001 15.76 1.545 17.38L5.525 14.29Z"
          fill="#FBBC05"
        />
        <path
          d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.565 0 3.515 2.69995 1.545 6.61994L5.525 9.70994C6.475 6.85994 9.125 4.75 12.255 4.75Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_2479">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
