import styled from '@emotion/styled'
import { IonImg } from '@ionic/react'

import { BORDER_RADIUS, COLOR, FONT, PADDING } from '../../theme'
import { flexGapString } from '../../utils/css'
import { getFullName } from '../../utils/user'
import UserAvatar from '../common/UserAvatar'
import ActionSheetModal from '../ui/ActionSheetModal'
import Flex from '../ui/Flex'

import type { ProjectDto } from '../../types'

interface Props {
  project: ProjectDto
}

const DetailsImageStyled = styled(IonImg)`
  width: 100%;
  aspect-ratio: 4 / 3;
  background-color: ${COLOR.primary.lighter};
  border-radius: ${BORDER_RADIUS.base};
  overflow: hidden;

  ::part(image) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const DetailsTitleStyled = styled.h2`
  margin: 0;
  font-size: ${FONT.size.xl};
  font-weight: ${FONT.weight.semibold};
  line-height: ${FONT.lineHeight['2xl']};
`

const DetailsOwnerStyled = styled.span`
  font-weight: ${FONT.weight.medium};
  color: ${COLOR.black};
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexGapString(6, 'column')}
`

export function ProjectDetails({ project }: Props) {
  return (
    <Flex direction="column" gap={32} alignItems="center" style={{ padding: PADDING.DEFAULT }}>
      <DetailsImageStyled src={project.thumbnail?.sourceUrl || ''} alt="" />
      <Flex direction="column" gap={8} alignItems="center">
        <DetailsTitleStyled>{project.title}</DetailsTitleStyled>
      </Flex>
      <DetailsOwnerStyled>
        <UserAvatar user={project.creator} size="xs" />
        {getFullName(project.creator)}
      </DetailsOwnerStyled>
    </Flex>
  )
}

interface SheetProps extends Props {
  isOpen: boolean
  onDismiss: () => void
}

export default function ProjectDetailsSheet(props: SheetProps) {
  const { project, isOpen, onDismiss } = props

  return (
    <ActionSheetModal isOpen={isOpen} onClose={onDismiss}>
      <ProjectDetails project={project} />
    </ActionSheetModal>
  )
}
