import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'

import { FONT } from '../../../theme'

import type { FormEventHandler, TextareaHTMLAttributes } from 'react'

interface Props {
  name: string
  placeholder?: string
  value: string
  onChange: (value: string) => void
  focusInitially?: boolean
  textAlign?: 'start' | 'center' | 'end'
}

const TextFieldStyled = styled.textarea<{ textAlign: string }>`
  width: 100%;
  border: none;
  font-size: ${FONT.size.xl};
  resize: none;
  text-align: ${({ textAlign }) => textAlign};

  :focus,
  :active {
    outline: none;
  }
`

export default function TextField(
  props: Props & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>,
) {
  const { placeholder, value, onChange, name, textAlign = 'start', rows = 1, ...rest } = props

  const handleChange: FormEventHandler<HTMLTextAreaElement> = (event) => {
    onChange(event.currentTarget.value || '')
  }

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`

      if (textAreaRef.current.scrollHeight === 0) textAreaRef.current.style.height = 'auto'
    }
  }, [value])

  return (
    <TextFieldStyled
      ref={textAreaRef}
      id={name}
      name={name}
      value={value}
      placeholder={placeholder}
      rows={rows}
      textAlign={textAlign}
      onChange={handleChange}
      {...rest}
    />
  )
}
