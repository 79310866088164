import { IonSpinner } from '@ionic/react'
import { formatDistanceToNow } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { FadingEnterLeaveTransition } from '../../../components/common/Transitions'
import WithLoading from '../../../components/common/WithLoading'
import Button from '../../../components/ui/Button/Button'
import Flex from '../../../components/ui/Flex'
import { MessageCardIconStyled } from '../../../components/ui/MessageCard'
import Text from '../../../components/ui/Text'
import { useAuthContext } from '../../../context/AuthContext'
import i18n from '../../../services/i18n'
import { COLOR, FONT } from '../../../theme'
import { getActivityInformation } from '../../../utils/activity'
import { getDateFnsLocale } from '../../../utils/date'

import ProjectDashboardItem from './ProjectDashboardItem'

import type { ActivityDto, ProjectDto } from '../../../types'

interface ActivitySectionProps {
  project: ProjectDto
  infiniteQuery: {
    items: ActivityDto[] | undefined
    isLoading: boolean
    fetchNextPage: () => void
    hasNextPage?: boolean
    isFetchingNextPage: boolean
  }
}

function ActivityItem({ activity, projectName }: { activity: ActivityDto; projectName: string }) {
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const information = getActivityInformation(activity.action)
  const ownActions = user?.id === activity.user.id
  const useMoreTranslations = activity.actionCount > 1
  const dateDiff = formatDistanceToNow(new Date(activity.created), {
    addSuffix: true,
    locale: getDateFnsLocale(i18n.language),
  })
  if (information === undefined || information === null || !information) return null

  return (
    <Flex direction="row" alignItems="flex-start" gap={12}>
      <MessageCardIconStyled>{information.icon}</MessageCardIconStyled>
      <Flex direction="column" gap={6}>
        {ownActions && (
          <Text size={FONT.size.base} style={{ lineHeight: FONT.lineHeight.base }}>
            {useMoreTranslations ? (
              t(information.youTranslations.translationMore, { count: activity.actionCount })
            ) : (
              <Trans
                i18nKey={information.youTranslations.translationOne}
                values={{ projectName }}
              />
            )}
          </Text>
        )}
        {!ownActions && (
          <Text size={FONT.size.base} style={{ lineHeight: FONT.lineHeight.base }}>
            {useMoreTranslations ? (
              t(information.otherTranslations.translationMore, {
                count: activity.actionCount,
                name: `${activity.user.firstName} ${activity.user.lastName}`,
              })
            ) : (
              <Trans
                i18nKey={information.otherTranslations.translationOne}
                values={{
                  projectName,
                  name: `${activity.user.firstName} ${activity.user.lastName}`,
                }}
              />
            )}
          </Text>
        )}
        <Text
          size={FONT.size.sm}
          color={COLOR.neutral.DEFAULT}
          style={{ lineHeight: FONT.lineHeight.sm }}
        >
          {dateDiff}
        </Text>
      </Flex>
    </Flex>
  )
}

export default function Activity(props: ActivitySectionProps) {
  const { project, infiniteQuery } = props
  const { t } = useTranslation()

  const {
    items: activities = [],
    hasNextPage = false,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = infiniteQuery

  if (!isLoading && !isFetchingNextPage && activities.length === 0) return null

  return (
    <ProjectDashboardItem title={t('ProjectDashboard.Activities.title')}>
      <WithLoading isLoading={isLoading}>
        <Flex direction="column" gap={20}>
          {activities.map((activity) => (
            <ActivityItem key={activity.id} activity={activity} projectName={project.title} />
          ))}
          <FadingEnterLeaveTransition show={isFetchingNextPage}>
            <IonSpinner name={DEFAULT_SPINNER} />
          </FadingEnterLeaveTransition>
          {hasNextPage && (
            <Button variant="secondary" onClick={fetchNextPage}>
              {t('ProjectDashboard.Activities.showMore')}
            </Button>
          )}
        </Flex>
      </WithLoading>
    </ProjectDashboardItem>
  )
}
