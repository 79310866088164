import styled from '@emotion/styled'
import { IonContent, IonSpinner, IonToolbar, useIonRouter, useIonViewWillEnter } from '@ionic/react'
import { useLocation } from 'react-router'

import { DEFAULT_SPINNER } from '../../App'
import { ROUTES } from '../../Router'
import Page from '../../components/layout/Page'
import { useApiContext } from '../../context/ApiContext'
import { useAuthContext } from '../../context/AuthContext'
import { setStatusbarLight } from '../../utils/statusBar'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Spinner = styled(IonSpinner)`
  height: 56px;
  width: 56px;
`

export default function InstantJoin() {
  const { search } = useLocation()
  const ionRouter = useIonRouter()

  const { user } = useAuthContext()
  const { apiClient } = useApiContext()

  const searchParams = new URLSearchParams(search)
  const code = searchParams.get('code')

  useIonViewWillEnter(() => {
    setStatusbarLight()

    if (!user || !code) return

    apiClient
      .joinProject(code)
      .then(({ id }) => ionRouter.push(ROUTES.projectDashboard(id)))
      .catch(() => ionRouter.push(ROUTES.dashboard))
  })

  return (
    <Page>
      <IonToolbar />
      <IonContent>
        <Wrapper>
          <Spinner name={DEFAULT_SPINNER} />
        </Wrapper>
      </IonContent>
    </Page>
  )
}
