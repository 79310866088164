export async function resizeImageTo720p(image: string) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    canvas.remove()
    return null
  }

  const img = new Image()
  img.src = image

  return await new Promise<Blob | null>((resolve) => {
    img.onload = () => {
      if (img.width < 1280 && img.height < 720) {
        canvas.remove()
        img.remove()
        return resolve(null)
      }

      const ratio = img.width / img.height

      if (ratio < 9 / 16) {
        canvas.height = 1280
        canvas.width = 1280 * ratio
      } else {
        canvas.width = 720
        canvas.height = 720 / ratio
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/jpeg')
      canvas.remove()
      img.remove()
    }
  })
}

export async function resizeImageToSquare(image: string, size = 300) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    canvas.remove()
    return null
  }

  ctx.imageSmoothingQuality = 'high'
  ctx.imageSmoothingEnabled = true
  const img = new Image()
  img.src = image

  return await new Promise<Blob | null>((resolve) => {
    img.onload = () => {
      canvas.width = size
      canvas.height = size

      if (img.width < size && img.height < size) {
        canvas.width = img.width
        canvas.height = img.height
      }

      const scaleFactor = Math.max(canvas.width / img.width, canvas.height / img.height)

      const newWidth = img.width * scaleFactor
      const newHeight = img.height * scaleFactor

      const x = canvas.width / 2 - newWidth / 2
      const y = canvas.height / 2 - newHeight / 2

      ctx.drawImage(img, x, y, newWidth, newHeight)
      canvas.toBlob((blob) => {
        resolve(blob)
      }, 'image/jpeg')
      canvas.remove()
      img.remove()
    }
  })
}
