import styled from '@emotion/styled'
import { IonContent, createAnimation, useIonRouter } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../Router'
import Page from '../components/layout/Page'
import Button from '../components/ui/Button/Button'
import { ButtonGroup } from '../components/ui/Button/ButtonGroup'
import Flex from '../components/ui/Flex'
import Text from '../components/ui/Text'
import { useAppConfig } from '../context/AppConfigContext'
import LogoWinkii from '../icons/custom/LogoWinkii'
import { COLOR, FONT } from '../theme'
import { alpha } from '../utils/colors'

import OnboardingWizard from './Tabs/Dashboard/OnboardingWizard/OnboardingWizard'

const Content = styled(IonContent)`
  --color: ${COLOR.white};
  --background: transparent;

  text-align: center;
`

const VideoWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: ${COLOR.black};
`

const Video = styled.video<{ canPlay: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 500ms ease-out;
  opacity: ${({ canPlay }) => (canPlay ? 1 : 0)};
`

const VideoOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, ${alpha(COLOR.black, 0)} 30%, ${COLOR.black});
`

export default function AppStart() {
  const { t } = useTranslation()
  const ionRouter = useIonRouter()

  const { appStartVideoUrl } = useAppConfig()

  const [canPlay, setCanPlay] = useState(false)
  const [onboardingVisible, setOnboardingVisible] = useState(false)

  const animation = (baseEl: Element) =>
    createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(200)
      .fromTo('opacity', '1', '0')

  const onLoginClick = () => {
    setOnboardingVisible(false)
    ionRouter.push(ROUTES.auth, undefined, undefined, undefined, animation)
  }

  return (
    <Page pageStyle={onboardingVisible ? 'light' : 'dark'}>
      <VideoWrapper>
        <Video
          autoPlay
          loop
          muted
          playsInline={true}
          controls={false}
          src={appStartVideoUrl}
          canPlay={canPlay}
          onCanPlay={() => setCanPlay(true)}
        />
        <VideoOverlay />
      </VideoWrapper>
      <Content className="ion-padding">
        <Flex direction="column" justifyContent="flex-end" gap={48} style={{ height: '100%' }}>
          <Flex direction="column" alignItems="center" gap={20}>
            <LogoWinkii height={64} />
            <Text size={FONT.size.xl}>{t('AppStart.text')}</Text>
          </Flex>
          <ButtonGroup>
            <Button onClick={() => setOnboardingVisible(true)}>{t('AppStart.login')}</Button>
          </ButtonGroup>
        </Flex>
      </Content>
      <OnboardingWizard isOpen={onboardingVisible} onDismiss={() => onLoginClick()} />
    </Page>
  )
}
