import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { IonContent, useIonLoading, useIonRouter, useIonToast } from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import Page from '../../components/layout/Page'
import SubpageHeader from '../../components/layout/SubpageHeader'
import Button from '../../components/ui/Button/Button'
import Flex from '../../components/ui/Flex'
import Text from '../../components/ui/Text'
import { useApiContext } from '../../context/ApiContext'
import { BORDER_RADIUS, COLOR, FONT, PADDING } from '../../theme'

import type { ChangeEventHandler, FormEventHandler } from 'react'

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

const PinInput = styled.input({
  'textAlign': 'center',
  'fontFamily': FONT.family.mono,
  'fontSize': FONT.size['2xl'],
  'letterSpacing': '0.25em',
  'outline': 'none',
  'width': '14.5rem',
  'border': `1px solid ${COLOR.neutral.light}`,
  'borderRadius': BORDER_RADIUS.base,
  'padding': PADDING.DEFAULT,

  ':focus': {
    boxShadow: `0 0 0 2px ${COLOR.primary.DEFAULT}`,
  },
})

const CODE_LENGTH = 8

export default function JoinProject() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'JoinProject', nameOverride: 'JoinProject' })
  }, [])

  const { t } = useTranslation()
  const [presentToast] = useIonToast()
  const ionRouter = useIonRouter()

  const { apiClient } = useApiContext()

  const [presentLoading, dismissLoading] = useIonLoading()

  const [projectCode, setProjectCode] = useState('')

  const canSubmit = projectCode.length === CODE_LENGTH

  const onInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    setProjectCode(event.target.value.toUpperCase())
  }

  const showErrorToast = () =>
    presentToast({
      message: t('JoinProjects.error'),
      icon: alertCircle,
      duration: 3000,
      position: 'middle',
    })

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    await FirebaseAnalytics.logEvent({ name: 'submitJoinProject', params: {} })

    if (!canSubmit) {
      await showErrorToast()
    } else {
      await presentLoading(t('JoinProjects.loading'))

      try {
        const project = await apiClient.joinProject(projectCode)
        await dismissLoading()
        ionRouter.push(ROUTES.projectDashboard(project.id))
      } catch {
        await showErrorToast()
        await dismissLoading()
      }
    }
  }

  return (
    <Page>
      <SubpageHeader
        showBackButton={true}
        backButtonRoute={ROUTES.dashboard}
        title={t('JoinProjects.header')}
      />
      <IonContent className="ion-padding">
        <Flex
          direction="column"
          gap={56}
          justifyContent="space-between"
          className={css({ height: '100%' })}
        >
          <Text tag="h1" className={css({ textAlign: 'center' })} size={FONT.size.xl}>
            {t('JoinProjects.title')}
          </Text>
          <FormStyled
            className={css({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
            })}
            onSubmit={onSubmit}
          >
            <Flex justifyContent="center" alignItems="center" gap={4}>
              <PinInput
                id="code"
                name="code"
                placeholder={t('JoinProjects.placeholder')}
                maxLength={CODE_LENGTH}
                autoFocus={true}
                value={projectCode}
                onInput={onInput}
              />
            </Flex>
            <Button type="submit" disabled={!canSubmit}>
              {t('JoinProjects.join')}
            </Button>
          </FormStyled>
        </Flex>
      </IonContent>
    </Page>
  )
}
