import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import styled from '@emotion/styled'
import { IonContent, IonHeader, IonModal } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import CameraIconButton from '../../components/recording/CameraIconButton'
import PausableVideo from '../../components/video/PausableVideo'
import { CheckBold, CloseBold } from '../../icons'
import { COLOR, FONT, PADDING } from '../../theme'
import Flex from '../ui/Flex'

import {
  Footer,
  FooterElement,
  ThumbnailBackground,
  ThumbnailBackgroundOverlay,
  Toolbar,
} from './RecordingView.styled'
import TextOverlay from './TextOverlay'

interface ViewRecordedProps {
  isOpen: boolean
  onSave: () => void
  onDismiss: () => void
  webPath: string
  videoPath: string
  thumbnail: string
}

const TitleStyled = styled.h1`
  margin: 0;
  font-size: ${FONT.size.xl};
  font-weight: ${FONT.weight.medium};
  color: white;
  text-align: center;
`

const ContentStyled = styled(IonContent)`
  --background: transparent;
  --padding-start: ${PADDING.DEFAULT};
  --padding-end: ${PADDING.DEFAULT};
  --padding-top: 40px;
`

const VideoWrapperStyled = styled.div`
  flex: 1;
`

const FooterTextOverlay = styled(TextOverlay)`
  white-space: nowrap;
`

export default function ViewRecorded(props: ViewRecordedProps) {
  const { isOpen, webPath, videoPath, onDismiss, onSave, thumbnail } = props
  const { t } = useTranslation()

  const dismissVideo = () => {
    if (Capacitor.isNativePlatform()) {
      void Filesystem.deleteFile({
        path: videoPath,
        directory: Directory.Data,
      }).then(() => {
        onDismiss()
      })
    } else {
      onDismiss()
    }
  }

  return (
    <IonModal isOpen={isOpen} style={{ backgroundColor: COLOR.black }}>
      <IonHeader>
        <Toolbar>
          <TitleStyled>{t('RecordingStudio.ViewRecorded.title')}</TitleStyled>
        </Toolbar>
      </IonHeader>
      <ContentStyled className="ion-padding">
        <Flex direction="column" gap={32} style={{ height: '100%' }}>
          <VideoWrapperStyled>
            {!!webPath && (
              <PausableVideo
                video={{
                  sourceUrl: webPath,
                  id: 'new',
                  thumbnailUrl: '',
                  created: new Date().toISOString(),
                  updated: new Date().toISOString(),
                }}
              />
            )}
          </VideoWrapperStyled>
        </Flex>
      </ContentStyled>
      <ThumbnailBackground src={thumbnail} alt="" />
      <ThumbnailBackgroundOverlay />
      <Footer>
        <FooterElement justifyContent="flex-start">
          <CameraIconButton icon={<CloseBold />} title="Dismiss video" onClick={dismissVideo} />
        </FooterElement>
        <FooterElement justifyContent="center">
          <FooterTextOverlay size="sm">{t('RecordingStudio.ViewRecorded.save')}</FooterTextOverlay>
        </FooterElement>
        <FooterElement justifyContent="flex-end">
          <CameraIconButton
            variant="primary"
            icon={<CheckBold />}
            title="Upload video"
            onClick={onSave}
          />
        </FooterElement>
      </Footer>
    </IonModal>
  )
}
