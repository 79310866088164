import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgBell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M12.005 24C11.3917 23.9994 10.7949 23.8006 10.3039 23.4331 9.81277 23.0656 9.45363 22.5493 9.28002 21.961 9.22406 21.77 9.24625 21.5647 9.3417 21.3901 9.43716 21.2155 9.59806 21.086 9.78902 21.03 9.97998 20.974 10.1853 20.9962 10.3599 21.0917 10.5345 21.1871 10.6641 21.348 10.72 21.539 10.8016 21.8159 10.9706 22.059 11.2018 22.2318 11.4329 22.4047 11.7139 22.4982 12.0025 22.4982 12.2912 22.4982 12.5721 22.4047 12.8033 22.2318 13.0344 22.059 13.2034 21.8159 13.285 21.539 13.341 21.348 13.4705 21.1871 13.6451 21.0917 13.8197 20.9962 14.0251 20.974 14.216 21.03 14.407 21.086 14.5679 21.2155 14.6633 21.3901 14.7588 21.5647 14.781 21.77 14.725 21.961 14.5528 22.5491 14.1947 23.0656 13.7044 23.4332 13.214 23.8008 12.6178 23.9996 12.005 24ZM21.47 18.17C21.46 18.16 20.25 16.79 20.25 10.5 20.2467 8.44232 19.4764 6.45979 18.0895 4.93975 16.7026 3.4197 14.7988 2.47136 12.75 2.28V.75C12.75.551088 12.671.360322 12.5304.21967 12.3897.0790176 12.1989 0 12 0 11.8011 0 11.6103.0790176 11.4697.21967 11.329.360322 11.25.551088 11.25.75V2.28C9.20126 2.47136 7.29745 3.4197 5.91054 4.93975 4.52364 6.45979 3.7533 8.44232 3.75002 10.5 3.75002 16.39 2.42002 18.28 2.41002 18.29 2.32446 18.4008 2.27157 18.5333 2.25732 18.6726 2.24307 18.8118 2.26803 18.9523 2.32939 19.0782 2.39075 19.204 2.48605 19.3102 2.60455 19.3847 2.72305 19.4592 2.86003 19.4992 3.00002 19.5H21C21.1554 19.4999 21.307 19.4518 21.4341 19.3623 21.5612 19.2728 21.6575 19.1463 21.71 19 21.7609 18.8538 21.7652 18.6955 21.7222 18.5468 21.6792 18.3981 21.5911 18.2664 21.47 18.17ZM4.21002 18C4.99858 15.5814 5.35073 13.0419 5.25002 10.5 5.25002 8.70979 5.96118 6.9929 7.22705 5.72703 8.49292 4.46116 10.2098 3.75 12 3.75 13.7902 3.75 15.5071 4.46116 16.773 5.72703 18.0389 6.9929 18.75 8.70979 18.75 10.5 18.6246 13.0371 18.9496 15.5763 19.71 18H4.21002Z" />
    </svg>
  )
}
const Memo = memo(SvgBell)
export default Memo
