/**
 * Button Component.
 * We don't use `IonButton` here because transitions are not working at the moment.
 */
import { css, cx } from '@emotion/css'
import { forwardRef } from 'react'

import { COLOR, SHADOW, TRANSITION } from '../../../theme'

import BaseButton from './BaseButton'

import type { BaseButtonProps, ButtonSize, ButtonVariant } from './BaseButton'

export const variantStyles: Record<ButtonVariant, string> = {
  primary: css({
    'background': COLOR.primary.DEFAULT,
    'color': COLOR.neutral.darkest,
    'border': `1px solid transparent`,
    ':active': {
      'background': COLOR.primary.dark,
      ':disabled': {
        background: COLOR.primary.DEFAULT,
      },
    },
  }),
  secondary: css({
    'background': COLOR.white,
    'color': COLOR.neutral.darkest,
    'border': `1px solid ${COLOR.neutral.light}`,
    ':active': {
      'background': COLOR.primary.lighter,
      ':disabled': {
        background: COLOR.white,
      },
    },
  }),
  danger: css({
    'background': COLOR.white,
    'color': COLOR.danger.darker,
    'border': `1px solid ${COLOR.danger.light}`,
    ':active': {
      'background': COLOR.danger.lighter,
      ':disabled': {
        background: COLOR.white,
      },
    },
  }),
}

export function buttonStyles(size: ButtonSize) {
  return css({
    'transition': TRANSITION.base,
    'boxShadow': size === 'small' ? SHADOW.sm : SHADOW.base,

    ':active': {
      boxShadow: size === 'small' ? SHADOW.base : SHADOW.lg,
    },

    ':disabled': {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  })
}

const Button = forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const { children, variant = 'primary', size = 'default', className, ...rest } = props

  return (
    <BaseButton
      {...rest}
      ref={ref}
      className={cx(buttonStyles(size), variantStyles[variant], className)}
      size={size}
    >
      {children}
    </BaseButton>
  )
})

Button.displayName = 'Button'
export default Button
