import styled from '@emotion/styled'

import { COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

import type { ReactNode } from 'react'

interface EmptyStateProps {
  children?: ReactNode
  illustration?: ReactNode
}

const EmptyStateStyled = styled.div`
  display: flex;
  align-items: center;
  ${flexGapString(16, 'column')}
  color: ${COLOR.neutral.dark};
  font-size: ${FONT.size.sm};

  .illustration {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }
`

export default function EmptyState(props: EmptyStateProps) {
  const { children, illustration } = props

  return (
    <EmptyStateStyled>
      {illustration}
      {children}
    </EmptyStateStyled>
  )
}
