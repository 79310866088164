import { Network } from '@capacitor/network'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { css } from '@emotion/css'
import {
  IonButtons,
  IonFooter,
  IonHeader,
  useIonLoading,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from '@ionic/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean, useCountdown, useEffectOnce } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../App'
import { useToast } from '../../hooks/useToast'
import { CloseBold, InfoCircle, RefreshArrowsBold } from '../../icons'
import { VideoRecorder, VideoRecorderEvent } from '../../plugins/VideoRecorder'
import { ScalingFadingEnterLeaveTransition } from '../common/Transitions'
import Page from '../layout/Page'
import Button from '../ui/Button/Button'
import Dialog from '../ui/Dialog'
import Flex from '../ui/Flex'

import CameraErrorView from './CameraErrorView'
import CameraIconButton from './CameraIconButton'
import RecordingButton from './RecordingButton'
import { Content, Footer, FooterElement, Toolbar } from './RecordingView.styled'
import RecordingWizard from './RecordingWizard'
import TextOverlay from './TextOverlay'
import ViewRecorded from './ViewRecorded'

import type { RecordingProcessedResponse } from '../../plugins/VideoRecorder'
import type { PluginListenerHandle } from '@capacitor/core'

interface RecordingViewProps {
  onDismiss: () => void
  onSave: (video: string, thumbnail: string, saveImmediately: boolean) => Promise<void>
  firstAnsweredQuestion?: boolean
  question: string
}

const COUNTDOWN_LENGTH_SECONDS = 3
const MAX_RECORDING_TIME_SECONDS = 30

export default function RecordingView(props: RecordingViewProps) {
  const { onDismiss, onSave, question, firstAnsweredQuestion } = props

  const { t } = useTranslation()

  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast } = useToast()

  const {
    value: isCameraReady,
    setTrue: setIsCameraReadyTrue,
    setFalse: setIsCameraReadyFalse,
  } = useBoolean(false)

  const {
    value: isStartCameraError,
    setTrue: setIsStartCameraErrorTrue,
    setFalse: setIsStarCameraErrorFalse,
  } = useBoolean(false)

  const {
    value: isRecordingError,
    setTrue: setIsRecordingErrorTrue,
    setFalse: setIsRecordingErrorFalse,
  } = useBoolean(false)

  const isRecording = useRef(false)
  const setIsRecordingTrue = () => (isRecording.current = true)
  const setIsRecordingFalse = () => (isRecording.current = false)

  const {
    value: isSaving,
    setTrue: setIsSavingTrue,
    setFalse: setIsSavingFalse,
  } = useBoolean(false)

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: COUNTDOWN_LENGTH_SECONDS,
    intervalMs: 1000,
    countStop: 1,
  })

  const {
    value: isCountdownActive,
    setTrue: setCountdownActive,
    setFalse: setCountdownInactive,
  } = useBoolean(false)

  const {
    value: isWizardActive,
    setTrue: presentWizard,
    setFalse: dismissWizard,
  } = useBoolean(false)

  const {
    value: isViewRecordedActive,
    setTrue: presentViewRecorded,
    setFalse: dismissViewRecorded,
  } = useBoolean(false)

  const {
    value: isNetworkDialogActive,
    setTrue: presentNetworkDialog,
    setFalse: dismissNetworkDialog,
  } = useBoolean(false)

  const [video, setVideo] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [webPath, setWebPath] = useState('')

  const onRecordingErrorListener = useRef<PluginListenerHandle | null>(null)
  const onRecordingStoppedListener = useRef<PluginListenerHandle | null>(null)
  const onRecordingProcessedListener = useRef<PluginListenerHandle | null>(null)

  const recordingTimeout = useRef<number | undefined>(undefined)
  const clearRecordingTimeout = () => {
    clearTimeout(recordingTimeout.current)
    recordingTimeout.current = undefined
  }

  const countdownTimeout = useRef<number | undefined>(undefined)
  const clearCountdownTimeout = () => {
    clearTimeout(countdownTimeout.current)
    countdownTimeout.current = undefined
  }

  const removeListeners = async () => {
    await Promise.all([
      onRecordingErrorListener.current?.remove(),
      onRecordingStoppedListener.current?.remove(),
      onRecordingProcessedListener.current?.remove(),
    ])

    onRecordingErrorListener.current = null
    onRecordingStoppedListener.current = null
    onRecordingProcessedListener.current = null
  }

  const destroyCamera = async () => {
    clearCountdownTimeout()
    clearRecordingTimeout()
    try {
      await VideoRecorder.destroyCamera()
      await removeListeners()
    } catch {
      /* empty */
    }
  }

  const onRecordingProcessed = async (event: RecordingProcessedResponse) => {
    setWebPath(event.webPath)
    setThumbnail(event.thumbnailUrl)
    setVideo(event.video)
    setIsSavingFalse()

    await destroyCamera()
    await dismissLoading()
    presentViewRecorded()
  }

  const onRecordingStopped = async () => {
    if (!isRecording.current) return
    clearRecordingTimeout()
    setIsRecordingFalse()
    setIsSavingTrue()
    await presentLoading({ message: t('RecordingStudio.processing'), spinner: DEFAULT_SPINNER })
  }

  const onRecordingError = async () => {
    clearRecordingTimeout()
    setIsRecordingErrorTrue()
    setIsRecordingFalse()
    await dismissLoading()
    await destroyCamera()
  }

  const addListeners = async () => {
    if (onRecordingErrorListener.current === null) {
      onRecordingErrorListener.current = await VideoRecorder.addListener(
        VideoRecorderEvent.RecordingError,
        () => void onRecordingError(),
      )
    }
    if (onRecordingStoppedListener.current === null) {
      onRecordingStoppedListener.current = await VideoRecorder.addListener(
        VideoRecorderEvent.RecordingStopped,
        () => void onRecordingStopped(),
      )
    }
    if (onRecordingProcessedListener.current === null) {
      onRecordingProcessedListener.current = await VideoRecorder.addListener(
        VideoRecorderEvent.RecordingProcessed,
        (e) => void onRecordingProcessed(e),
      )
    }
  }

  const startCamera = async (onSuccess?: () => void) => {
    await presentLoading({ spinner: DEFAULT_SPINNER })
    setIsCameraReadyFalse()
    setIsStarCameraErrorFalse()
    setIsRecordingErrorFalse()
    setIsRecordingFalse()
    setIsSavingFalse()
    setCountdownInactive()

    try {
      await VideoRecorder.startCamera()
      await KeepAwake.keepAwake()
      await addListeners()
      setIsCameraReadyTrue()

      if (onSuccess) onSuccess()
    } catch {
      setIsStartCameraErrorTrue()
      await removeListeners()
    } finally {
      await dismissLoading()
    }
  }

  useIonViewWillLeave(() => {
    clearRecordingTimeout()
    clearCountdownTimeout()
    void removeListeners()
    dismissWizard()
    void destroyCamera()
    void KeepAwake.allowSleep()
  })

  useIonViewDidLeave(() => {
    void destroyCamera()
  })

  const activateWizard = () => {
    if (firstAnsweredQuestion) presentWizard()
  }

  useEffectOnce(() => {
    void startCamera(activateWizard)

    return () => {
      void removeListeners()
    }
  })

  const flipCamera = async () => {
    try {
      await VideoRecorder.flipCamera()
    } catch {
      /* empty */
    }
  }

  const stopRecording = async () => {
    clearRecordingTimeout()
    clearCountdownTimeout()

    if (!isRecording.current) return

    try {
      await onRecordingStopped()
      await VideoRecorder.stopRecording()
    } catch {
      await onRecordingError()
    }
  }

  const startRecording = async () => {
    resetCountdown()
    setCountdownActive()
    startCountdown()

    countdownTimeout.current = window.setTimeout(async () => {
      try {
        await VideoRecorder.startRecording()
        setIsRecordingTrue()
        setCountdownInactive()

        recordingTimeout.current = window.setTimeout(() => {
          void stopRecording()
        }, MAX_RECORDING_TIME_SECONDS * 1000)

        clearCountdownTimeout()
      } catch {
        setCountdownInactive()
        await presentErrorToast(t('General.error'))
      }
    }, COUNTDOWN_LENGTH_SECONDS * 1000)
  }

  const onRecordingButtonClick = async () => {
    if (isCountdownActive) return

    if (isRecording.current) await stopRecording()
    else await startRecording()
  }

  const onViewRecordedDismiss = () => {
    setWebPath('')
    setThumbnail('')
    setVideo('')
    dismissViewRecorded()
    void startCamera()
  }

  const onViewRecordedSave = async () => {
    const { connectionType } = await Network.getStatus()

    switch (connectionType) {
      case 'wifi':
        await onSave(video, thumbnail, true)
        dismissViewRecorded()
        break
      case 'cellular':
        presentNetworkDialog()
        break
      default:
        await onSave(video, thumbnail, true)
        dismissNetworkDialog()
    }
  }

  const onNetworkDialogSaveImmediately = async () => {
    dismissNetworkDialog()
    await onSave(video, thumbnail, true)
    dismissViewRecorded()
  }
  const onNetworkDialogSaveLater = async () => {
    dismissNetworkDialog()
    await onSave(video, thumbnail, false)
    dismissViewRecorded()
  }

  const canShowCameraPreview = isCameraReady && !isRecordingError && !isStartCameraError
  const shouldShowFlipButton =
    isCameraReady && !isRecording.current && !isCountdownActive && !isSaving

  return (
    <Page pageStyle={canShowCameraPreview ? 'darkTransparent' : 'dark'}>
      <IonHeader>
        <Toolbar>
          <IonButtons slot="start">
            <CameraIconButton
              icon={<InfoCircle />}
              title={t('General.back')}
              size="sm"
              onClick={isWizardActive ? dismissWizard : presentWizard}
            />
          </IonButtons>
          <IonButtons slot="end">
            {!isWizardActive && (
              <CameraIconButton
                icon={<CloseBold />}
                title={t('General.back')}
                size="sm"
                onClick={onDismiss}
              />
            )}
          </IonButtons>
        </Toolbar>
      </IonHeader>
      <Content>
        {isStartCameraError && (
          <CameraErrorView
            message={t('RecordingStudio.ErrorView.startCameraError')}
            onDismiss={() => void startCamera()}
          />
        )}
        {isRecordingError && (
          <CameraErrorView
            message={t('RecordingStudio.ErrorView.recordingError')}
            onDismiss={() => void startCamera()}
          />
        )}
        {canShowCameraPreview && (
          <>
            {!isWizardActive && <TextOverlay>{question}</TextOverlay>}
            {!isRecording.current && !isWizardActive && (
              <TextOverlay size="sm" opacity={0.35}>
                {t('RecordingStudio.info')}
              </TextOverlay>
            )}
          </>
        )}
        <ScalingFadingEnterLeaveTransition show={isCountdownActive}>
          <Flex
            justifyContent="center"
            alignItems="center"
            className={css({ position: 'absolute', inset: 0 })}
          >
            <TextOverlay
              size="lg"
              className={css({
                width: 100,
                height: 100,
                textAlign: 'center',
                justifyContent: 'center',
              })}
            >
              {count}
            </TextOverlay>
          </Flex>
        </ScalingFadingEnterLeaveTransition>
      </Content>
      <IonFooter>
        {canShowCameraPreview && !isWizardActive && (
          <Footer>
            <FooterElement justifyContent="flex-start" />
            <FooterElement justifyContent="center">
              <RecordingButton
                maxRecordingTime={MAX_RECORDING_TIME_SECONDS}
                isRecording={isRecording.current}
                onClick={() => void onRecordingButtonClick()}
              />
            </FooterElement>
            <FooterElement justifyContent="flex-end">
              <ScalingFadingEnterLeaveTransition show={shouldShowFlipButton}>
                <CameraIconButton
                  title={t('RecordingStudio.swapCamera')}
                  icon={<RefreshArrowsBold />}
                  onClick={() => void flipCamera()}
                />
              </ScalingFadingEnterLeaveTransition>
            </FooterElement>
          </Footer>
        )}
      </IonFooter>
      <RecordingWizard isActive={isWizardActive && isCameraReady} onDismiss={dismissWizard} />
      <ViewRecorded
        isOpen={isViewRecordedActive}
        webPath={webPath || ''}
        videoPath={video || ''}
        thumbnail={thumbnail || ''}
        onDismiss={onViewRecordedDismiss}
        onSave={() => void onViewRecordedSave()}
      />
      <Dialog isOpen={isNetworkDialogActive} backdropDismiss={false} onClose={dismissNetworkDialog}>
        <Dialog.Title>{t('RecordingStudio.CellularDialog.title')}</Dialog.Title>
        <Dialog.Body>{t('RecordingStudio.CellularDialog.body')}</Dialog.Body>
        <Dialog.Buttons>
          <Button variant="primary" onClick={() => void onNetworkDialogSaveLater()}>
            {t('RecordingStudio.CellularDialog.saveLater')}
          </Button>
          <Button
            disabled={isSaving}
            variant="secondary"
            onClick={() => void onNetworkDialogSaveImmediately()}
          >
            {t('RecordingStudio.CellularDialog.saveNow')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </Page>
  )
}
