import { Camera, CameraResultType } from '@capacitor/camera'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { css } from '@emotion/css'
import { useTranslation } from 'react-i18next'

import Flex from '../../../components/ui/Flex'
import InfoBox from '../../../components/ui/InfoBox'
import Text from '../../../components/ui/Text'
import { Camera as CameraIcon } from '../../../icons'

import { ImageUploadImageFrame, ImageUploadImageFramePlaceholder } from './NewProjectWizard.styled'

import type { StepProps } from './NewProjectWizard'

export default function ImageUploadStep(props: StepProps) {
  void FirebaseAnalytics.setScreenName({
    screenName: 'ImageUploadStep',
    nameOverride: 'NewProject',
  })

  const { value: image, setValue: setImage } = props

  const { t } = useTranslation()

  const takePicture = async () => {
    const photo = await Camera.getPhoto({
      quality: 90,
      resultType: CameraResultType.Uri,
    })

    const imageUrl = photo.webPath
    if (!imageUrl) return

    setImage(imageUrl)
  }
  return (
    <Flex direction="column" gap={24}>
      <Text className={css({ textAlign: 'center' })}>{t('NewProject.projectTitleImageTitle')}</Text>
      {image ? (
        <ImageUploadImageFrame src={image} onClick={() => void takePicture()} />
      ) : (
        <ImageUploadImageFramePlaceholder onClick={() => void takePicture()}>
          <CameraIcon />
        </ImageUploadImageFramePlaceholder>
      )}
      <InfoBox>{t('NewProject.projectTitleImageDescription')}</InfoBox>
    </Flex>
  )
}
