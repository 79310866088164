import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { useIonLoading, useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import ProgressDialog from '../../../components/common/ProgressDialog'
import RecordingView from '../../../components/recording/RecordingView'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useFileUpload } from '../../../hooks/useFileUpload'
import { useLocalAnswers } from '../../../hooks/useLocalAnswers'
import { useProjectQuestions } from '../../../hooks/useProjectQuestions'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { useToast } from '../../../hooks/useToast'
import {
  InterstitialPlacement,
  IronSourceIntegration,
} from '../../../plugins/IronSourceIntegration'
import { REACT_APP_SUPABASE_BUCKET, REACT_APP_SUPABASE_URL } from '../../../utils/env'
import { parseReceiverName } from '../../../utils/textParsing'

import type { LocalAnswer } from '../../../hooks/useLocalAnswers'

export default function RecordAnswer() {
  const { t } = useTranslation()
  const { projectId, questionId } = useParams<{ projectId: string; questionId: string }>()

  const ionRouter = useIonRouter()
  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast, presentSuccessToast } = useToast()

  const { apiClient } = useApiContext()
  const { project } = useSingleProject(projectId)
  const { questions, isError, refetch: refetchQuestions } = useProjectQuestions(projectId)
  const { user } = useAuthContext()
  const question = questions?.find((q) => q.id === questionId)
  const { createLocalAnswer, updateLocalAnswer, deleteLocalAnswer } = useLocalAnswers(projectId)
  const { upload, progressPercent, isUploading } = useFileUpload()

  const isFirstAnsweredQuestionByUser = !questions?.some(
    (q) => q.answers?.some((a) => a.answerBy.id === user?.id) ?? false,
  )

  if (isError || !question) return <Redirect to={ROUTES.projectRecordingStudio(projectId)} />

  const onDismiss = () => {
    ionRouter.goBack()
  }

  const onSave = async (video: string, thumbnail: string, saveImmediately: boolean) => {
    await presentLoading({
      message: t('RecordingStudio.Upload.preparing'),
      spinner: DEFAULT_SPINNER,
    })
    try {
      if (Capacitor.isNativePlatform()) await IronSourceIntegration.loadInterstitial()
    } catch {
      // no-op
    }

    const localAnswer: LocalAnswer = {
      questionTitle: question.title || '',
      questionId: question.id || '',
      sourceUrl: video,
      thumbnailUrl: thumbnail,
    }

    try {
      await refetchQuestions()
    } catch {
      /* empty */
    }

    await createLocalAnswer(localAnswer)

    if (!saveImmediately) {
      await dismissLoading()
      ionRouter.push(ROUTES.projectRecordingStudio(projectId), 'back', 'pop')

      return Promise.resolve()
    }

    const { thumbnailUrl } = await apiClient
      .uploadImage(thumbnail)
      .then((r) => r)
      .catch(() => {
        return { thumbnailUrl: '' }
      })

    if (thumbnailUrl) {
      await updateLocalAnswer({
        ...localAnswer,
        remoteThumbnailUrl: thumbnailUrl,
      })
      await Filesystem.deleteFile({
        path: thumbnail.split('/').pop() || '',
        directory: Directory.Data,
      }).catch(() => {
        /* empty */
      })
    }

    await dismissLoading()

    const fileName = video.split('/').pop() || ''
    const apiUrl = `${REACT_APP_SUPABASE_URL}/storage/v1/object/${REACT_APP_SUPABASE_BUCKET}/videos/${fileName}`

    let sourceUrl = ''
    try {
      sourceUrl = await upload(fileName, apiUrl)
    } catch {
      await presentErrorToast(t('RecordingStudio.Upload.error'))

      ionRouter.push(ROUTES.projectRecordingStudio(projectId), 'back', 'pop')
      return Promise.resolve()
    }

    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('RecordingStudio.Upload.finishing'),
    })

    await updateLocalAnswer({
      ...localAnswer,
      sourceUrl,
    })

    try {
      await apiClient.createAnswer(projectId, questionId, { video: { sourceUrl, thumbnailUrl } })
    } catch {
      await dismissLoading()
      await presentErrorToast(t('RecordingStudio.Upload.error'))
      ionRouter.push(ROUTES.projectRecordingStudio(projectId), 'back', 'pop')

      return Promise.resolve()
    }

    await deleteLocalAnswer(localAnswer)
    await dismissLoading()
    await presentSuccessToast(t('RecordingStudio.Upload.success'))

    if (Capacitor.isNativePlatform())
      await IronSourceIntegration.showInterstitial({
        placement: InterstitialPlacement.UploadAnswer,
      })

    ionRouter.push(ROUTES.projectRecordingStudio(projectId), 'back', 'pop')

    return Promise.resolve()
  }

  return (
    <>
      <RecordingView
        question={parseReceiverName(question.title, project?.receiverName || '')}
        onDismiss={onDismiss}
        onSave={onSave}
        firstAnsweredQuestion={isFirstAnsweredQuestionByUser}
      />
      <ProgressDialog
        isOpen={isUploading}
        progressPercent={progressPercent}
        text={t('RecordingStudio.Upload.uploading')}
      />
    </>
  )
}
