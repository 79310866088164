import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import LazyImage from '../../../../components/common/LazyImage'
import IconButton from '../../../../components/ui/IconButton/IconButton'
import PausableVideo from '../../../../components/video/PausableVideo'
import { useVideoThumbnail } from '../../../../hooks/useVideoThumbnail'
import { CloseCircle, Play } from '../../../../icons'
import { BORDER_RADIUS, COLOR, SHADOW, TRANSITION } from '../../../../theme'
import { BRAND_COLORS } from '../../../../utils/colors'
import { setNavigationBarBlack, setNavigationBarLight } from '../../../../utils/navigationBar'
import { setStatusbarBlack, setStatusbarLight } from '../../../../utils/statusBar'

interface Props {
  url: string
  index: number
}

const ExampleVideoButton = styled.button<{ backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  height: 200px;
  border-radius: ${BORDER_RADIUS.base};
  overflow: hidden;
  box-shadow: ${SHADOW.base};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${TRANSITION.base};

  :active {
    transform: scale(0.975);
    box-shadow: ${SHADOW.lg};
  }
`

const PlayButton = styled.div`
  position: relative;
  z-index: 10;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: ${COLOR.white};
  box-shadow: ${SHADOW.lg};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function ExampleVideo(props: Props) {
  const { url, index } = props

  const { t } = useTranslation()
  const { thumbnail, isError } = useVideoThumbnail(url, 7.5)
  const { value: modalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)

  const backgroundColor =
    COLOR[BRAND_COLORS[BRAND_COLORS.length - 1 - (index % BRAND_COLORS.length)]].light

  return (
    <>
      <ExampleVideoButton backgroundColor={backgroundColor} onClick={openModal}>
        <LazyImage loading="lazy" src={isError ? './img/default-thumbnail.png' : thumbnail} />
        <PlayButton>
          <Play width={20} height={20} />
        </PlayButton>
      </ExampleVideoButton>
      <IonModal
        isOpen={modalOpen}
        onWillPresent={() => {
          setStatusbarBlack()
          setNavigationBarBlack()
        }}
        onWillDismiss={() => {
          setStatusbarLight()
          setNavigationBarLight()
        }}
        onDidDismiss={closeModal}
      >
        <IonHeader
          style={{
            'backgroundColor': COLOR.black,
            '--background': COLOR.black,
            '--color': COLOR.white,
            'color': COLOR.white,
          }}
        >
          <IonToolbar
            style={{
              'backgroundColor': COLOR.black,
              '--background': COLOR.black,
              '--color': COLOR.white,
              'color': COLOR.white,
            }}
          >
            <IonButtons slot="end">
              <IconButton
                icon={<CloseCircle />}
                title={t('General.back')}
                onDark
                onClick={closeModal}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          className="ion-safe-area-bottom"
          style={{
            'backgroundColor': COLOR.black,
            '--background': COLOR.black,
            '--color': COLOR.white,
            'color': COLOR.white,
          }}
        >
          <div className={css({ position: 'relative', height: '100%', width: '100%' })}>
            <PausableVideo
              video={{ sourceUrl: url, thumbnailUrl: '', id: '' }}
              layout="fullscreen"
            />
          </div>
        </IonContent>
      </IonModal>
    </>
  )
}
