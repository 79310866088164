import styled from '@emotion/styled'
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import WithLoading from '../../../components/common/WithLoading'
import NoProjects from '../../../components/projects/NoProjects'
import ProjectListItem from '../../../components/projects/ProjectListItem'
import EmptyState from '../../../components/ui/EmptyState/EmptyState'
import Grid from '../../../components/ui/Grid'
import { Clapperboard } from '../../../illustrations'

import type { ProjectDto } from '../../../types'
import type { InfiniteScrollCustomEvent } from '@ionic/react'
import type { InfiniteData } from '@tanstack/react-query'

interface ProjectsInfiniteListProps {
  data?: InfiniteData<{ items: ProjectDto[] }>
  isError?: boolean
  isLoading?: boolean
  fetchNextPage?: () => Promise<unknown>
  hasNextPage?: boolean
  createdByUserOnly?: boolean
  perPage?: number
}

const ProjectsInfiniteListStyled = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  min-height: 100%;
`

export default function ProjectsInfiniteList(props: ProjectsInfiniteListProps) {
  const { data, isError = false, isLoading = false, fetchNextPage, hasNextPage = false } = props
  const { t } = useTranslation()

  const handleInfiniteScroll = (event: InfiniteScrollCustomEvent) =>
    fetchNextPage?.().then(() => event.target.complete())

  if (isError) {
    return <EmptyState illustration={<Clapperboard />}>{t('MyProjects.error')}</EmptyState>
  }

  const projects = data?.pages?.map((page) => page.items).flat() || []

  return (
    <ProjectsInfiniteListStyled className="ion-padding">
      <WithLoading isLoading={isLoading}>
        {projects.length === 0 ? (
          <NoProjects />
        ) : (
          <>
            <Grid gap={8}>
              {projects.map((project) => (
                <ProjectListItem key={project.id} project={project} />
              ))}
            </Grid>
            <IonInfiniteScroll
              threshold="0px"
              disabled={!hasNextPage}
              onIonInfinite={(e) => void handleInfiniteScroll(e)}
            >
              <IonInfiniteScrollContent loadingSpinner={DEFAULT_SPINNER} />
            </IonInfiniteScroll>
          </>
        )}
      </WithLoading>
    </ProjectsInfiniteListStyled>
  )
}
