import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { BORDER_RADIUS, FONT, TRANSITION } from '../../../theme'
import { flexGapString } from '../../../utils/css'

import type { ButtonHTMLAttributes, ReactNode } from 'react'

export type ButtonVariant = 'primary' | 'secondary' | 'danger'
export type ButtonSize = 'small' | 'default'

export interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  size?: ButtonSize
  icon?: ReactNode
  iconPosition?: 'leading' | 'trailing'
}

export const buttonBaseStyles = css`
  transition: ${TRANSITION.base};
  font-weight: ${FONT.weight.medium};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const buttonSizeStyles: Record<ButtonSize, string> = {
  small: css`
    border-radius: ${BORDER_RADIUS.sm};
    padding: 8px 14px;
    font-size: ${FONT.size.sm};
    line-height: ${FONT.lineHeight.sm};
    ${flexGapString(4, 'column')}

    .icon {
      height: 12px;
      width: 12px;
    }
  `,
  default: css`
    border-radius: ${BORDER_RADIUS.base};
    padding: 14px 24px;
    font-size: ${FONT.size.base};
    line-height: ${FONT.lineHeight.xl};
    ${flexGapString(8, 'column')}

    .icon {
      height: 16px;
      width: 16px;
    }
  `,
}

const IconWrapperStyled = styled.span`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const {
    children,
    size = 'default',
    icon,
    className = '',
    iconPosition = 'leading',
    ...rest
  } = props

  return (
    <button {...rest} ref={ref} className={cx(buttonBaseStyles, buttonSizeStyles[size], className)}>
      {!!icon && iconPosition === 'leading' && <IconWrapperStyled>{icon}</IconWrapperStyled>}
      {!!children && <span>{children}</span>}
      {!!icon && iconPosition === 'trailing' && <IconWrapperStyled>{icon}</IconWrapperStyled>}
    </button>
  )
})

BaseButton.displayName = 'BaseButton'
export default BaseButton
