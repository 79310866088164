import { Camera, CameraResultType } from '@capacitor/camera'
import { cx } from '@emotion/css'
import styled from '@emotion/styled'
import { IonContent, useIonAlert, useIonLoading, useIonRouter, useIonToast } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import Avatar from '../../../../components/ui/Avatar/Avatar'
import Button from '../../../../components/ui/Button/Button'
import { ghostButtonVariantStyles } from '../../../../components/ui/Button/GhostButton'
import { useApiContext } from '../../../../context/ApiContext'
import { useAuthContext } from '../../../../context/AuthContext'
import {
  ImageUploadImageFrame,
  ImageUploadImageFramePlaceholder,
} from '../../../NewProject/NewProjectWizard/NewProjectWizard.styled'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;
  }
`

export default function ChangeAvatar() {
  const { t } = useTranslation()
  const { user, updateUser } = useAuthContext()
  const [present, dismiss] = useIonLoading()
  const ionRouter = useIonRouter()
  const [presentToast] = useIonToast()
  const [presentDialog, dismissDialog] = useIonAlert()

  const [image, setImage] = useState(user?.avatar?.sourceUrl || '')
  const [isChanged, setIsChanged] = useState(false)
  const { apiClient } = useApiContext()

  const editAvatar = () => {
    void presentDialog({
      header: t('Profile.Edit.changeAvatar'),
      buttons: [
        {
          text: t('Profile.Edit.newAvatar'),

          cssClass: cx(ghostButtonVariantStyles.secondary),
          handler: async () => {
            const photo = await Camera.getPhoto({
              quality: 90,
              allowEditing: true,
              resultType: CameraResultType.Uri,
            })

            const imageUrl = photo.webPath
            if (!imageUrl) return

            setImage(imageUrl)
            setIsChanged(true)
            void dismissDialog()
          },
        },
        {
          text: t('Profile.Edit.removeAvatar'),
          role: 'destructive',
          cssClass: cx(ghostButtonVariantStyles.danger),
          handler: () => {
            setImage('')
            setIsChanged(true)
            void dismissDialog()
          },
        },
      ],
    })
  }

  async function saveAvatarImage() {
    void present(t('Profile.Edit.changeAvatarButtonLoading'))
    let newSourceUrl = ''
    let newThumbnail = ''
    let newAvatarId = ''

    if (user) {
      if (image.length === 0) {
        newSourceUrl = ''
        newThumbnail = ''
        newAvatarId = user?.avatar?.id || ''
      } else {
        await apiClient
          .uploadImage(image, 'square')
          .then(({ thumbnailUrl }) => {
            newSourceUrl = thumbnailUrl
            newThumbnail = thumbnailUrl
            newAvatarId = user?.avatar?.id || ''
          })
          .catch(() => {
            void presentToast({
              message: t('Profile.Edit.changeAvatarButtonError'),
              duration: 5000,
              color: 'error',
            })
            void dismiss()
          })
      }
      updateUser({
        ...user,
        avatar: {
          sourceUrl: newSourceUrl,
          thumbnailUrl: newThumbnail,
          id: newAvatarId,
        },
      })
        .then(() => {
          void presentToast({
            message: t('Profile.Edit.changeAvatarButtonSuccess'),
            duration: 5000,
            color: 'success',
          })
          void dismiss()
          ionRouter.push(ROUTES.profile, 'back', 'pop')
        })
        .catch(() => {
          void presentToast({
            message: t('Profile.Edit.changeAvatarButtonError'),
            duration: 5000,
            color: 'error',
          })
          void dismiss()
        })
    }
  }
  return (
    <Page>
      <SubpageHeader title={t('Profile.AccountSettings.title')} showBackButton={true} />
      <IonContent className="ion-padding">
        <Wrapper>
          {image && image.length > 0 ? (
            <ImageUploadImageFrame src={image} onClick={() => editAvatar()} />
          ) : (
            <ImageUploadImageFramePlaceholder onClick={() => editAvatar()}>
              <Avatar size="2xl" alt="" />
            </ImageUploadImageFramePlaceholder>
          )}
          <Button disabled={!isChanged} onClick={() => void saveAvatarImage()}>
            {t('General.save')}
          </Button>
        </Wrapper>
      </IonContent>
    </Page>
  )
}
