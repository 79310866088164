import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { useIonToast } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface AndroidNotification {
  id: string
  data: {
    custom: {
      i: string
    }
    pri: string
    vis: string
    alert: string
    title: string
  }
}

interface IOSNotification {
  id: string
  body: string
  title: string
  data: {
    aps: {
      alert: {
        title: string
        body: string
      }
    }
  }
}

export default function PushNotificationSetup() {
  const [present] = useIonToast()
  const { t } = useTranslation()

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    void PushNotifications.addListener('registration', () => {
      console.log('Push registration success')
    })

    void PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') void PushNotifications.register()
    })

    void PushNotifications.addListener('pushNotificationReceived', (notification) => {
      let header = ''
      let message = ''

      if (Capacitor.getPlatform() === 'android') {
        const androidNotification = notification as AndroidNotification

        header = androidNotification.data.title
        message = androidNotification.data.alert
      }

      if (Capacitor.getPlatform() === 'ios') {
        const iosNotification = notification as IOSNotification

        header = iosNotification.data.aps.alert.title || iosNotification.title
        message = iosNotification.data.aps.alert.body || iosNotification.body
      }

      if (header || message) {
        void present({
          header,
          message,
          duration: 5000,
          position: 'top',
          buttons: [
            {
              text: t('General.dismiss'),
              role: 'cancel',
            },
          ],
        })
      }
    })
  }, [present, t])

  return null
}
