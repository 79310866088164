import { IonRouterOutlet, IonTabBar, IonTabButton } from '@ionic/react'
import { Redirect, Route } from 'react-router-dom'

import { ROUTES } from '../../Router'
import {
  House,
  HouseFill,
  ShoppingBag,
  ShoppingBagFill,
  User,
  UserFill,
  Videos,
  VideosFill,
} from '../../icons'
import { isInWeb } from '../../utils/user'
import Shop from '../Shop/Shop'

import Dashboard from './Dashboard/Dashboard'
import MyProjects from './MyProjects/MyProjects'
import Profile from './Profile/Profile'
import { TabButtonIconWrapper, TabsStyled } from './TabsStyled'

export default function MainTabs() {
  // const { t } = useTranslation()

  // const [present] = useNewProjectModal()

  return (
    <TabsStyled>
      <IonRouterOutlet onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
        <Route exact path={ROUTES.dashboard} render={() => <Dashboard />} />
        <Route exact path={ROUTES.projects} render={() => <MyProjects />} />
        <Route exact path={ROUTES.shop} render={() => <Shop />} />
        <Route exact path={ROUTES.profile} render={() => <Profile />} />
        <Route exact path={ROUTES.tabs} render={() => <Redirect to={ROUTES.dashboard} />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="dashboard" href={ROUTES.dashboard}>
          <TabButtonIconWrapper>
            <House className="icon icon-default" />
            <HouseFill className="icon icon-active" />
          </TabButtonIconWrapper>
        </IonTabButton>
        <IonTabButton tab="projects" href={ROUTES.projects}>
          <TabButtonIconWrapper>
            <Videos className="icon icon-default" />
            <VideosFill className="icon icon-active" />
          </TabButtonIconWrapper>
        </IonTabButton>
        <IonTabButton tab="shop" href={isInWeb() ? ROUTES.downloadsLinks : ROUTES.shop}>
          <TabButtonIconWrapper>
            <ShoppingBag className="icon icon-default" />
            <ShoppingBagFill className="icon icon-active" />
          </TabButtonIconWrapper>
        </IonTabButton>
        <IonTabButton tab="profile" href={ROUTES.profile}>
          <TabButtonIconWrapper>
            <User className="icon icon-default" />
            <UserFill className="icon icon-active" />
          </TabButtonIconWrapper>
        </IonTabButton>
      </IonTabBar>
    </TabsStyled>
  )
}
