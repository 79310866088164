import { css } from '@emotion/css'

import type { CSSProperties } from 'react'

type FlexType = 'column' | 'row' | 'all'

export function flexGapObject(gap: string | number, type: FlexType = 'all') {
  const gapString = typeof gap === 'number' ? `${gap}px` : gap

  if (checkFlexGapSupported()) {
    if (type === 'column') return { columnGap: gapString }

    if (type === 'row') return { rowGap: gapString }

    return { gap: gapString }
  }

  return flexGapPolyfillObject(gapString, type)
}

export function flexGapString(gap: string | number, type: FlexType = 'all') {
  const gapString = typeof gap === 'number' ? `${gap}px` : gap

  if (checkFlexGapSupported()) {
    if (type === 'column') {
      return `
        column-gap: ${gapString};
      `
    }

    if (type === 'row') {
      return `
        row-gap: ${gapString};
      `
    }

    return `
      gap: ${gapString};
    `
  }

  return flexGapPolyfillString(gapString, type)
}

function flexGapPolyfillString(gap: string, type: FlexType = 'all') {
  let styles = ''

  if (type === 'column') styles = `margin-inline-start: ${gap};`

  if (type === 'row') styles = `margin-top: ${gap};`

  if (type === 'all') styles = `margin-top: ${gap}; margin-inline-start: ${gap};`

  return `
    & > *:not([hidden]) + *:not([hidden]) {
      ${styles};
    };
  `
}
function flexGapPolyfillObject(gap: string, type: FlexType = 'all') {
  const styles: Pick<CSSProperties, 'marginTop' | 'marginInlineStart'> = {}

  if (type === 'column') styles.marginInlineStart = gap

  if (type === 'row') styles.marginTop = gap

  if (type === 'all') {
    styles.marginTop = gap
    styles.marginInlineStart = gap
  }

  return {
    '& > * + *': {
      ...styles,
    },
  }
}

let _detectedFlexGapSupported = false
let _isFlexGapSupported = false

function checkFlexGapSupported() {
  if (_detectedFlexGapSupported) return _isFlexGapSupported

  const flex = document.createElement('div')
  flex.style.display = 'flex'
  flex.style.flexDirection = 'column'
  flex.style.rowGap = '1px'

  flex.appendChild(document.createElement('div'))
  flex.appendChild(document.createElement('div'))

  document.body.appendChild(flex)
  const isSupported = flex.scrollHeight === 1
  flex?.parentNode?.removeChild(flex)

  _detectedFlexGapSupported = true
  _isFlexGapSupported = isSupported

  return isSupported
}

export function lineClamp(lines: number) {
  return css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
  `
}
