import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgVideosFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_42_229)">
        <path d="M22 0H5.5C4.96957 0 4.46086.210714 4.08579.585786 3.71071.960859 3.5 1.46957 3.5 2V18.5C3.5 19.0304 3.71071 19.5391 4.08579 19.9142 4.46086 20.2893 4.96957 20.5 5.5 20.5H22C22.5304 20.5 23.0391 20.2893 23.4142 19.9142 23.7893 19.5391 24 19.0304 24 18.5V2C24 1.46957 23.7893.960859 23.4142.585786 23.0391.210714 22.5304 0 22 0ZM18 11.28 12.08 14.67C11.928 14.7578 11.7555 14.804 11.58 14.804 11.4045 14.804 11.232 14.7578 11.08 14.67 10.9192 14.5821 10.7856 14.4516 10.6939 14.2929 10.6022 14.1341 10.5559 13.9533 10.56 13.77V7C10.5477 6.82454 10.5819 6.64894 10.6592 6.49093 10.7364 6.33291 10.854 6.19807 11 6.1 11.152 6.01223 11.3245 5.96603 11.5 5.96603 11.6755 5.96603 11.848 6.01223 12 6.1L18 9.48C18.1691 9.56188 18.3117 9.68974 18.4114 9.84891 18.5112 10.0081 18.5641 10.1921 18.5641 10.38 18.5641 10.5679 18.5112 10.7519 18.4114 10.9111 18.3117 11.0703 18.1691 11.1981 18 11.28ZM19.5 22H2.5C2.36739 22 2.24021 21.9473 2.14645 21.8536 2.05268 21.7598 2 21.6326 2 21.5V4.5C2 4.23478 1.89464 3.98043 1.70711 3.79289 1.51957 3.60536 1.26522 3.5 1 3.5.734784 3.5.48043 3.60536.292893 3.79289.105357 3.98043 0 4.23478 0 4.5V22C0 22.5304.210714 23.0391.585786 23.4142.960859 23.7893 1.46957 24 2 24H19.5C19.7652 24 20.0196 23.8946 20.2071 23.7071 20.3946 23.5196 20.5 23.2652 20.5 23 20.5 22.7348 20.3946 22.4804 20.2071 22.2929 20.0196 22.1054 19.7652 22 19.5 22Z" />
      </g>
      <defs>
        <clipPath id="clip0_42_229">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgVideosFill)
export default Memo
