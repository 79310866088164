import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgPicture(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_246_471)">
        <path
          fillRule="evenodd"
          d="M16.5 3.75C14.4289 3.75 12.75 5.42893 12.75 7.5C12.75 9.57107 14.4289 11.25 16.5 11.25C18.5711 11.25 20.25 9.57107 20.25 7.5C20.25 5.42893 18.5711 3.75 16.5 3.75ZM14.25 7.5C14.25 6.25736 15.2574 5.25 16.5 5.25C17.7426 5.25 18.75 6.25736 18.75 7.5C18.75 8.74264 17.7426 9.75 16.5 9.75C15.2574 9.75 14.25 8.74264 14.25 7.5Z"
          clipRule="evenodd"
        />
        <path d="M10.5073 15.0625C9.49909 14.7048 8.42564 14.5686 7.36005 14.6632C6.29447 14.7578 5.26178 15.0809 4.33235 15.6106C3.97248 15.8157 3.51448 15.6902 3.30939 15.3303C3.10429 14.9705 3.22976 14.5125 3.58964 14.3074C4.70635 13.671 5.94713 13.2827 7.22743 13.1691C8.50774 13.0554 9.7975 13.2191 11.0089 13.6488C12.2202 14.0786 13.3247 14.7645 14.2471 15.6596C14.3255 15.7357 14.4024 15.8131 14.4778 15.8918C15.2996 15.3391 16.2673 15.0296 17.268 15.0082C18.5252 14.9814 19.7495 15.411 20.7141 16.2176C21.0319 16.4834 21.0741 16.9564 20.8084 17.2741C20.5427 17.5919 20.0697 17.6341 19.7519 17.3684C19.0656 16.7945 18.1945 16.4888 17.3001 16.5079C16.6324 16.5221 15.9855 16.7167 15.4253 17.0651C15.7991 17.6173 16.1109 18.2111 16.354 18.8361C16.5041 19.2222 16.3129 19.6568 15.9268 19.807C15.5408 19.9571 15.1061 19.7659 14.956 19.3798C14.6803 18.6709 14.2983 18.0102 13.8245 17.4198C13.8069 17.4001 13.7904 17.3798 13.7752 17.3589C13.5966 17.141 13.4055 16.9331 13.2024 16.736C12.4348 15.991 11.5155 15.4202 10.5073 15.0625Z" />
        <path
          fillRule="evenodd"
          d="M2.25 0C1.00736 0 0 1.00736 0 2.25V21.75C0 22.9926 1.00736 24 2.25 24H21.75C22.9926 24 24 22.9926 24 21.75V2.25C24 1.00736 22.9926 0 21.75 0H2.25ZM1.5 2.25C1.5 1.83579 1.83579 1.5 2.25 1.5H21.75C22.1642 1.5 22.5 1.83579 22.5 2.25V21.75C22.5 22.1642 22.1642 22.5 21.75 22.5H2.25C1.83579 22.5 1.5 22.1642 1.5 21.75V2.25Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_246_471">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgPicture)
export default Memo
