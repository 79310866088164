import { css, cx } from '@emotion/css'
import { Global } from '@emotion/react'
import { IonPage, useIonViewWillEnter } from '@ionic/react'
import { useEffect } from 'react'

import { COLOR } from '../../theme'
import { setNavigationBarBlack, setNavigationBarLight } from '../../utils/navigationBar'
import { setStatusbarBlack, setStatusbarLight } from '../../utils/statusBar'

import type { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string
  pageStyle?: 'light' | 'dark' | 'darkTransparent'
  safeArea?: boolean
}

const backgroundColors = {
  light: COLOR.white,
  dark: COLOR.black,
  darkTransparent: 'transparent',
}

function pageStyles(backgroundColor: string) {
  return css`
    ion-toolbar {
      --background: ${backgroundColor};
      --color: ${backgroundColor === COLOR.white ? COLOR.black : COLOR.white};
    }

    ion-content {
      --color: ${backgroundColor === COLOR.white ? COLOR.black : COLOR.white};
    }
  `
}

export default function Page(props: Props) {
  const { children, className, pageStyle = 'light', safeArea = true, ...rest } = props

  const backgroundColor = backgroundColors[pageStyle]

  useIonViewWillEnter(() => {
    if (pageStyle === 'light') {
      setStatusbarLight()
      setNavigationBarLight()
    } else {
      setStatusbarBlack()
      setNavigationBarBlack()
    }
  })

  useEffect(() => {
    if (pageStyle === 'light') {
      setStatusbarLight()
      setNavigationBarLight()
    } else {
      setStatusbarBlack()
      setNavigationBarBlack()
    }
  }, [pageStyle])

  return (
    <IonPage
      className={cx(safeArea ? 'ion-safe-area-bottom' : '', pageStyles(backgroundColor), className)}
      {...rest}
    >
      <Global
        styles={{
          'background': backgroundColor,
          ':root': { '--ion-background-color': backgroundColor },
        }}
      />
      {children}
    </IonPage>
  )
}
