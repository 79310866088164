import {
  CreateAnimation,
  IonFab,
  IonModal,
  IonSpinner,
  useIonLoading,
  useIonToast,
} from '@ionic/react'
import { checkmark } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import ProjectSubpage from '../../../components/layout/ProjectSubpage'
import ViewAttachmentsModal from '../../../components/projects/attachments/ViewAttachmentsModal'
import FAB from '../../../components/ui/Button/FAB'
import EmptyState from '../../../components/ui/EmptyState/EmptyState'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useProjectQuestions } from '../../../hooks/useProjectQuestions'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { CheckBold, VideoClip } from '../../../icons'
import { Clapperboard } from '../../../illustrations'
import { COLOR } from '../../../theme'
import { reorder } from '../../../utils/array'
import ProjectDashboardItem from '../Dashboard/ProjectDashboardItem'

import DraggableAttachmentItem from './DraggableAttachmentItem'
import ExpandableDraggableQuestionItem from './ExpandableDraggableQuestionItem'
import VideoPreview from './VideoPreview'

import type {
  AttachmentOrderDto,
  ProjectAttachmentDto,
  ProjectDto,
  ProjectQuestionDto,
  UpdateQuestionsOrderDto,
} from '../../../types'
import type { FC } from 'react'
import type { DropResult } from 'react-beautiful-dnd'

const Content: FC<{
  questionsLoading: boolean
  questionsError: boolean
  hasAnswers: boolean
  stateQuestions: ProjectQuestionDto[]
  project: ProjectDto
  stateAttachments: ProjectAttachmentDto[]

  onQuestionAnswerDragEnd: (result: DropResult) => void
  onAnswerUpdated: (id: string, isExcluded: boolean) => void
  onAttachmentDragEnd: (result: DropResult) => void
  onAttachmentUpdate: (id: string, isExcluded: boolean) => void
  openAttachmentsViewModal: (index: number) => void
}> = (props) => {
  const {
    questionsLoading,
    questionsError,
    hasAnswers,
    onQuestionAnswerDragEnd,
    project,
    stateQuestions,
    onAnswerUpdated,
    onAttachmentDragEnd,
    stateAttachments,
    onAttachmentUpdate,
    openAttachmentsViewModal,
  } = props

  const { t } = useTranslation()

  if (questionsLoading) return <IonSpinner name={DEFAULT_SPINNER} />

  if (questionsError || !hasAnswers) {
    return <EmptyState illustration={<Clapperboard />}>{t('Project.Clips.empty')}</EmptyState>
  }

  return (
    <Flex direction="column" gap={40}>
      <DragDropContext onDragEnd={onQuestionAnswerDragEnd}>
        <Droppable droppableId={project.id} type="question">
          {(provided) => (
            <Flex
              direction="column"
              gap={6}
              style={{ margin: '0 -10px' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {stateQuestions.map((question, index) => (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                  {(providedDrag, snapshot) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                    >
                      <ExpandableDraggableQuestionItem
                        question={question}
                        isDragging={snapshot.isDragging}
                        receiverName={project.receiverName}
                        onAnswerUpdated={onAnswerUpdated}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
      <ProjectDashboardItem
        title={t('ProjectDashboard.Attachments.title')}
        subtitle={t('Project.Clips.attachmentsDescription')}
      >
        <DragDropContext onDragEnd={onAttachmentDragEnd}>
          <Droppable droppableId={`attachments-${project.id}`} type="attachment">
            {(provided) => (
              <Flex direction="column" gap={6} {...provided.droppableProps} ref={provided.innerRef}>
                {stateAttachments.map((attachment, index) => (
                  <Draggable key={attachment.id} draggableId={attachment.id} index={index}>
                    {(providedDrag, snapshot) => (
                      <div
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                      >
                        <DraggableAttachmentItem
                          attachment={attachment}
                          isDragging={snapshot.isDragging}
                          index={index}
                          openModal={openAttachmentsViewModal}
                          onAttachmentUpdated={onAttachmentUpdate}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </ProjectDashboardItem>
    </Flex>
  )
}

export default function ProjectClips() {
  const { user } = useAuthContext()
  const { apiClient } = useApiContext()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const [presentToast] = useIonToast()
  const [presentLoading, dismissLoading] = useIonLoading()

  const { project } = useSingleProject(id)

  const {
    questions,
    refetch: refetchQuestions,
    isLoading: questionsLoading,
    isError: questionsError,
  } = useProjectQuestions(id)

  const hasQuestions = !!questions && questions.length > 0
  const hasAnswers =
    hasQuestions && questions.some((question) => !!question.answers && question.answers.length > 0)
  const hasAttachments = !!project?.attachments && project?.attachments?.length > 0

  const [stateQuestions, setStateQuestions] = useState<ProjectQuestionDto[]>([])
  const [stateAttachments, setStateAttachments] = useState<ProjectAttachmentDto[]>([])

  const onAnswerUpdated = (answerId: string, isExcluded: boolean) => {
    void apiClient.updateAnswer(answerId, { isExcluded })

    setStateQuestions((prev) => {
      const question = prev.find((q) => q.answers?.some((answer) => answer.id === answerId))
      if (question) {
        question.answers = question.answers?.map((answer) => {
          if (answer.id === answerId) answer.isExcluded = isExcluded

          return answer
        })
      }
      return [...prev]
    })
  }

  const onAttachmentUpdate = (attachmentId: string, isExcluded: boolean) => {
    void apiClient.updateProjectAttachment(attachmentId, { isExcluded })

    setStateAttachments((prev) => {
      const attachment = prev.find((a) => a.id === attachmentId)
      if (attachment) attachment.isExcluded = isExcluded

      return [...prev]
    })
  }

  const getAttachmentsOrderJSON = (): AttachmentOrderDto[] =>
    stateAttachments?.map((attachment) => ({ id: attachment.id })) || []

  const getQuestionsAnswersJSON = (): UpdateQuestionsOrderDto[] =>
    stateQuestions?.map((question) => ({
      id: question.id,
      answers: question.answers?.map((answer) => ({ id: answer.id })) || [],
    })) || []

  const [initialQuestionsOrder, setInitialQuestionsOrder] = useState<UpdateQuestionsOrderDto[]>([])
  const [initialAttachmentsOrder, setInitialAttachmentsOrder] = useState<AttachmentOrderDto[]>([])

  useEffect(() => {
    if (!hasQuestions || initialQuestionsOrder.length > 0) return

    setStateQuestions(questions)

    setInitialQuestionsOrder(
      questions.map((question) => ({
        id: question.id,
        answers: question.answers?.map((answer) => ({ id: answer.id })) || [],
      })),
    )
  }, [hasQuestions, initialQuestionsOrder.length, questions])

  useEffect(() => {
    if (!hasAttachments || initialAttachmentsOrder?.length > 0) return

    setStateAttachments(project?.attachments || [])

    setInitialAttachmentsOrder(
      project?.attachments?.map((attachment) => ({ id: attachment.id })) || [],
    )
  }, [hasAttachments, initialAttachmentsOrder?.length, project?.attachments])

  const answersOrder = getQuestionsAnswersJSON()
  const attachmentsOrder = getAttachmentsOrderJSON()

  const questionsOrderHasChanged =
    JSON.stringify(initialQuestionsOrder) !== JSON.stringify(answersOrder)

  const attachmentsOrderHasChanged =
    JSON.stringify(initialAttachmentsOrder) !== JSON.stringify(attachmentsOrder)

  const orderHasChanged = questionsOrderHasChanged || attachmentsOrderHasChanged

  const [videoPreviewModalOpen, setVideoPreviewModalOpen] = useState(false)
  const presentModal = () => setVideoPreviewModalOpen(true)
  const dismissModal = () => setVideoPreviewModalOpen(false)

  const [attachmentsViewModalOpen, setAttachmentsViewModalOpen] = useState(false)
  const [attachmentsIndex, setAttachmentsIndex] = useState(0)
  const openAttachmentsViewModal = (index: number) => {
    setAttachmentsIndex(index)
    setAttachmentsViewModalOpen(true)
  }
  const closeAttachmentsViewModal = () => setAttachmentsViewModalOpen(false)

  if (!project) return null

  const onQuestionAnswerDragEnd = (result: DropResult) => {
    if (!result.destination) return

    if (result.type === 'question')
      setStateQuestions(reorder(stateQuestions, result.source.index, result.destination.index))

    if (result.type === 'answer') {
      const newQuestions = [...stateQuestions]
      const question = newQuestions.find(
        (question) => question.id === result.source.droppableId,
      ) as ProjectQuestionDto

      question.answers = reorder(
        question.answers || [],
        result.source.index,
        result.destination.index,
      )

      setStateQuestions(newQuestions)
    }
  }

  const onAttachmentDragEnd = (result: DropResult) => {
    if (!result.destination) return

    setStateAttachments(reorder(stateAttachments, result.source.index, result.destination.index))
  }

  const saveOrder = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER })

    if (questionsOrderHasChanged) {
      try {
        await apiClient.updateQuestionsOrder(id, answersOrder)
      } catch {
        await dismissLoading()
        return
      }

      setInitialQuestionsOrder(answersOrder)
    }

    if (attachmentsOrderHasChanged) {
      try {
        await apiClient.updateProjectAttachmentsOrder(id, { attachments: attachmentsOrder })
      } catch {
        await dismissLoading()
        return
      }

      setInitialAttachmentsOrder(attachmentsOrder)
    }

    void refetchQuestions()

    await presentToast({
      message: t('Project.Clips.orderSaved'),
      duration: 2000,
      icon: checkmark,
    })

    await dismissLoading()
  }

  if (!user) return null

  const filteredAttachments = stateAttachments.filter((attachment) => !attachment.isExcluded)

  return (
    <ProjectSubpage
      title={t('Project.Clips.title')}
      project={project}
      headerButton={
        hasAnswers ? (
          <IconButton
            icon={<VideoClip />}
            title=""
            onClick={() => {
              if (hasAnswers) presentModal()
            }}
          />
        ) : null
      }
    >
      <Flex direction="column" gap={40}>
        {hasAnswers && <Text color={COLOR.neutral.dark}>{t('Project.Clips.text')}</Text>}
        <Content
          questionsLoading={questionsLoading}
          stateQuestions={stateQuestions}
          hasAnswers={hasAnswers}
          project={project}
          stateAttachments={stateAttachments}
          openAttachmentsViewModal={openAttachmentsViewModal}
          questionsError={questionsError}
          onQuestionAnswerDragEnd={onQuestionAnswerDragEnd}
          onAnswerUpdated={onAnswerUpdated}
          onAttachmentDragEnd={onAttachmentDragEnd}
          onAttachmentUpdate={onAttachmentUpdate}
        />
      </Flex>
      {orderHasChanged && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <CreateAnimation
            duration={100}
            fromTo={[
              { property: 'transform', fromValue: 'scale(0.8)', toValue: 'scale(1)' },
              { property: 'opacity', fromValue: '0', toValue: '1' },
            ]}
            play={true}
            easing="ease-out"
          >
            <FAB icon={<CheckBold />} onClick={() => void saveOrder()}>
              {t('Project.Clips.saveOrder')}
            </FAB>
          </CreateAnimation>
        </IonFab>
      )}
      <IonModal
        isOpen={videoPreviewModalOpen}
        style={{
          'ion-content': { '--color': COLOR.white },
          'ion-toolbar': { '--color': COLOR.white, '--background': COLOR.black },
        }}
      >
        <VideoPreview
          attachments={filteredAttachments}
          questions={stateQuestions}
          project={project}
          onDismiss={dismissModal}
        />
      </IonModal>
      <ViewAttachmentsModal
        attachments={filteredAttachments}
        isOpen={attachmentsViewModalOpen}
        initialActiveIndex={attachmentsIndex}
        onDismiss={closeAttachmentsViewModal}
      />
    </ProjectSubpage>
  )
}
