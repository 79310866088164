import tinycolor from 'tinycolor2'

/**
 * Convert a hex color to an RGB string
 *
 * @param hex - The hex of the color to convert
 */
export function hexToRgbString(hex: string) {
  const rgb = tinycolor(hex).toRgb()
  return [rgb.r, rgb.g, rgb.b].join(', ')
}

/**
 * Convert a color to an RGBA string
 *
 * @param color
 * @param alphaValue
 */
export function alpha(color: string, alphaValue: number) {
  return tinycolor(color).setAlpha(alphaValue).toRgbString()
}

export function darken(color: string, amount: number) {
  return tinycolor(color).darken(amount).toHexString()
}

export const BRAND_COLORS = ['primary', 'orange', 'salmon', 'turquoise'] as const
export type BrandColor = (typeof BRAND_COLORS)[number]

export function getBrandColor(input: string) {
  let sum = 0

  for (let i = 0; i < input.length; i++) sum += input.charCodeAt(i)

  return BRAND_COLORS[sum % BRAND_COLORS.length]
}
