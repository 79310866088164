import { Capacitor } from '@capacitor/core'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import styled from '@emotion/styled'
import { useIonLoading, useIonToast } from '@ionic/react'
import Lottie from 'lottie-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../App'
import successAnimation from '../../animations/98807-check-right-tick.json'
import SupportButton from '../../components/common/SupportButton'
import Dialog from '../../components/ui/Dialog'
import Flex from '../../components/ui/Flex'
import Text from '../../components/ui/Text'
import { useApiContext } from '../../context/ApiContext'
import { useAuthContext } from '../../context/AuthContext'
import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../theme'
import { OrderStatus, StoreType } from '../../types'
import { flexGapString } from '../../utils/css'
import { wait } from '../../utils/wait'

import type { BrandColor } from '../../utils/colors'
import type { LottieRefCurrentProps } from 'lottie-react'

interface Props {
  product: CdvPurchase.Product
  color?: BrandColor
  onPurchase?: () => void
}

const ProductItemStyled = styled.button<{ color: BrandColor; canPurchase: boolean }>`
  background-color: ${({ color }) => COLOR[color].light};
  border-radius: ${BORDER_RADIUS.base};
  padding: 24px 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  ${flexGapString(24, 'row')}
  pointer-events: ${({ canPurchase }) => (canPurchase ? 'auto' : 'none')};
  opacity: ${({ canPurchase }) => (canPurchase ? 1 : 0.4)};
  transition: ${TRANSITION.base};
  box-shadow: ${SHADOW.base};

  :active {
    background-color: ${({ color }) => COLOR[color].DEFAULT};
    transform: scale(0.975);
    box-shadow: ${SHADOW.lg};
  }
`

const Price = styled.span`
  display: inline-flex;
  background: ${COLOR.white};
  font-size: ${FONT.size['2xl']};
  padding: 8px 14px;
  border-radius: ${BORDER_RADIUS.full};
  font-weight: ${FONT.weight.semibold};
`

export default function ProductItem(props: Props) {
  const { product, color = 'primary', onPurchase } = props

  const [actualProduct, setActualProduct] = useState(product)

  const { fetchAvailableProductionJobs } = useAuthContext()
  const { apiClient } = useApiContext()

  const { t } = useTranslation()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const openErrorDialog = () => setErrorDialogOpen(true)
  const closeErrorDialog = () => setErrorDialogOpen(false)

  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const openSuccessDialog = () => setSuccessDialogOpen(true)
  const closeSuccessDialog = () => setSuccessDialogOpen(false)

  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const onSuccessAnimationComplete = () => {
    void wait(1000).then(() => closeSuccessDialog())
  }

  useEffect(() => {
    const update = (p: CdvPurchase.Product) => setActualProduct(p)

    CdvPurchase.store.when().productUpdated((updatedProduct) => update(updatedProduct))

    return () => {
      CdvPurchase.store.off(update)
    }
  }, [product.id])

  const onClick = async () => {
    const platform = Capacitor.getPlatform()
    const storeType = platform === 'ios' ? StoreType.AppStore : StoreType.GooglePlay

    await presentLoading({ spinner: DEFAULT_SPINNER })
    void FirebaseAnalytics.logEvent({
      name: 'selectPurchase',
      params: {
        item_id: product.id,
        item_name: product.title,
        value: (product.pricing?.priceMicros ?? 0) / 1000000,
      },
    })

    let currentOrderId = ''

    try {
      const order = await apiClient.createInAppOrder({
        inAppId: product.id,
        orderedFrom: storeType,
        status: OrderStatus.Pending,
        totalAmount: (product.pricing?.priceMicros ?? 0) / 1000000,
      })

      currentOrderId = order.id
    } catch {
      currentOrderId = ''
    }

    if (!currentOrderId) {
      await dismissLoading()
      await presentToast({ message: t('Shop.initialError'), duration: 2000 })
      return
    }

    const offer = product.getOffer() as CdvPurchase.Offer
    await CdvPurchase.store.order(offer).then(async (error) => {
      if (error && error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
        // purchase cancelled
        await dismissLoading()

        if (currentOrderId) {
          await apiClient.deleteOrder(currentOrderId)
          currentOrderId = ''
        }

        setActualProduct(product)
      }
    })

    CdvPurchase.store
      .when()
      .pending((transaction) =>
        setActualProduct(CdvPurchase.store.get(transaction.products[0].id) as CdvPurchase.Product),
      )

      .approved(async () => {
        await dismissLoading()
        await presentLoading({ spinner: DEFAULT_SPINNER, message: t('Shop.verifyingPurchase') })
      })

      .finished(async () => {
        try {
          await apiClient.updateOrder(currentOrderId, { status: OrderStatus.Completed })
          openSuccessDialog()
        } catch {
          openErrorDialog()
        } finally {
          await dismissLoading()
          fetchAvailableProductionJobs()
          onPurchase?.()
          void CdvPurchase.store.update()
        }
      })
  }

  return (
    <ProductItemStyled
      color={color}
      canPurchase={actualProduct.canPurchase}
      onClick={() => void onClick()}
    >
      <Flex direction="column" gap={12} alignItems="flex-start" style={{ flex: 1 }}>
        <Text size={FONT.size.xl} weight={FONT.weight.semibold}>
          {product.title}
        </Text>
        {!!actualProduct.description && (
          <Text size={FONT.size.base}>{actualProduct.description}</Text>
        )}
      </Flex>
      <Flex justifyContent="flex-end" style={{ width: '100%' }}>
        <Price>{actualProduct.pricing?.price}</Price>
      </Flex>
      <Dialog isOpen={errorDialogOpen} onClose={closeErrorDialog}>
        <Dialog.Title>{t('Shop.Failed.title')}</Dialog.Title>
        <Dialog.Body>{t('Shop.Failed.text')}</Dialog.Body>
        <Dialog.Buttons>
          <SupportButton />
        </Dialog.Buttons>
      </Dialog>
      <Dialog isOpen={successDialogOpen} onClose={closeSuccessDialog}>
        <Dialog.Body>
          <Lottie
            lottieRef={lottieRef}
            animationData={successAnimation}
            loop={false}
            onComplete={onSuccessAnimationComplete}
          />
          {t('Shop.success')}
        </Dialog.Body>
      </Dialog>
    </ProductItemStyled>
  )
}
