import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

export function setStatusbarBlack() {
  if (!Capacitor.isNativePlatform()) return

  void StatusBar.setStyle({ style: Style.Dark })
}

export function setStatusbarLight() {
  if (!Capacitor.isNativePlatform()) return

  void StatusBar.setStyle({ style: Style.Light })
}
