import { useIonLoading } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../../App'
import QuestionItem from '../../../components/projects/questions/QuestionItem'
import Button from '../../../components/ui/Button/Button'
import Dialog from '../../../components/ui/Dialog'
import IconButton from '../../../components/ui/IconButton/IconButton'
import { useApiContext } from '../../../context/ApiContext'
import { useToast } from '../../../hooks/useToast'
import { DotsVertical } from '../../../icons'

import QuestionActionSheet from './QuestionActionSheet'
import QuestionFormSheet from './QuestionFormSheet'

import type { CreateProjectQuestionDto, ProjectAnswerDto, ProjectQuestionDto } from '../../../types'

interface Props {
  projectId: string
  question: ProjectQuestionDto
  receiverName: string
  onUpdate: () => void
  onDelete: () => void
}

export default function SavedQuestionItem(props: Props) {
  const { projectId, question, receiverName, onUpdate, onDelete } = props

  const { t } = useTranslation()

  const { apiClient } = useApiContext()

  const { presentErrorToast, presentSuccessToast } = useToast()
  const [presentLoading, dismissLoading] = useIonLoading()

  const {
    value: deleteDialogOpen,
    setTrue: openDeleteDialog,
    setFalse: closeDeleteDialog,
  } = useBoolean(false)

  const {
    value: editSheetOpen,
    setTrue: openEditSheet,
    setFalse: closeEditSheet,
  } = useBoolean(false)

  const {
    value: actionSheetOpen,
    setTrue: openActionSheet,
    setFalse: closeActionSheet,
  } = useBoolean(false)

  const onSelectEdit = () => {
    closeActionSheet()
    openEditSheet()
  }

  const onSelectDelete = async () => {
    closeActionSheet()
    openDeleteDialog()
  }

  const handleUpdate = async (values: CreateProjectQuestionDto) => {
    closeEditSheet()
    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('ProjectDashboard.Questions.isEditing'),
    })

    try {
      await apiClient.updateQuestion(projectId, question.id, values)
      await dismissLoading()
      await presentSuccessToast(t('ProjectDashboard.Questions.edited'))
      onUpdate()
    } catch {
      await dismissLoading()
      await presentErrorToast(t('ProjectDashboard.Questions.error'))
    }
  }

  const confirmDelete = async () => {
    closeDeleteDialog()
    await presentLoading({
      spinner: DEFAULT_SPINNER,
      message: t('ProjectDashboard.Questions.isDeleting'),
    })

    let answers: ProjectAnswerDto[] = []

    try {
      answers = await apiClient.getAnswers(projectId, question.id)
    } catch {
      await dismissLoading()
      await presentErrorToast(t('Dashboard.error'))
    }

    if (answers.length > 0) {
      await dismissLoading()
      await presentErrorToast(t('ProjectDashboard.Questions.deleteNotAllowed'))
      return
    }

    try {
      await apiClient.deleteQuestionFromProject(projectId, question.id)
      await dismissLoading()
      await presentSuccessToast(t('ProjectDashboard.Questions.deleted'))
      onDelete()
    } catch {
      await dismissLoading()
      await presentErrorToast(t('Dashboard.error'))
    }
  }

  return (
    <>
      <QuestionItem
        questionId={question.id}
        questionTitle={question.title}
        receiverName={receiverName}
        button={<IconButton icon={<DotsVertical />} title="" onClick={openActionSheet} />}
      />
      <QuestionActionSheet
        isOpen={actionSheetOpen}
        onClose={closeActionSheet}
        onSelectDelete={() => void onSelectDelete()}
        onSelectEdit={() => void onSelectEdit()}
      />
      <QuestionFormSheet
        question={question}
        isOpen={editSheetOpen}
        onClose={closeEditSheet}
        onSubmit={(e) => void handleUpdate(e)}
      />
      <Dialog isOpen={deleteDialogOpen} onClose={closeDeleteDialog}>
        <Dialog.Title>{t('ProjectDashboard.Questions.Abort.titleDelete')}</Dialog.Title>
        <Dialog.Body>{t('ProjectDashboard.Questions.Abort.textDelete')}</Dialog.Body>
        <Dialog.Buttons>
          <Button variant="danger" onClick={() => void confirmDelete()}>
            {t('ProjectDashboard.Questions.Abort.confirmDelete')}
          </Button>
          <Button variant="secondary" onClick={closeDeleteDialog}>
            {t('ProjectDashboard.Questions.Abort.cancelDelete')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </>
  )
}
