import styled from '@emotion/styled'
import { IonTabs } from '@ionic/react'

import { BORDER_RADIUS, COLOR, PADDING, SHADOW, TRANSITION } from '../../theme'

export const TabsStyled = styled(IonTabs)`
  ion-tab-bar {
    --background: ${COLOR.neutral.lightest};
    --border: none;
    height: 64px;
    padding-inline-end: ${PADDING.DEFAULT};
    padding-inline-start: ${PADDING.DEFAULT};
    box-shadow: ${SHADOW.base};
  }

  ion-tab-button {
    --background: ${COLOR.neutral.lightest};

    .icon.icon-active {
      opacity: 0;
    }

    &.tab-selected {
      .icon.icon-active {
        opacity: 1;
      }
      .icon.icon-default {
        opacity: 0;
      }
    }
  }
`

export const TabButtonIconWrapper = styled.span`
  width: 56px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDER_RADIUS.base};
  transition: ${TRANSITION.base};
  position: relative;

  .icon {
    height: 24px;
    width: 24px;
    color: ${COLOR.black};
    transition: ${TRANSITION.base};
  }

  .icon.icon-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const TabsFabStyled = styled.button`
  background-color: ${COLOR.primary.DEFAULT};
  color: ${COLOR.black};
  width: 56px;
  height: 56px;
  border-radius: ${BORDER_RADIUS.full};
  box-shadow: ${SHADOW.lg};
  transition: ${TRANSITION.base};

  :active {
    transform: scale(0.975);
  }
`
