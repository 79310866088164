import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgClapperboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M69.354 19.966C70.1092 19.7208 70.9309 19.7849 71.6389 20.1445C72.3469 20.504 72.8834 21.1295 73.131 21.884L74.468 25.978C74.5011 26.0781 74.514 26.1838 74.5061 26.2889C74.4982 26.3941 74.4696 26.4966 74.4219 26.5907C74.3743 26.6847 74.3085 26.7684 74.2283 26.837C74.1482 26.9055 74.0553 26.9575 73.955 26.99L33.415 40.236L29.436 33L69.354 19.966Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.932 29.241 35.594 30.985 42.69 37.207 48.028 35.463 40.932 29.241ZM52.37 25.502 47.032 27.247 54.129 33.47 59.467 31.726 52.37 25.502ZM63.808 21.766 58.471 23.51 65.567 29.732 70.905 27.987 63.808 21.766Z"
      />
      <path
        fill={COLOR.black}
        d="M24.087 77.366C24.087 77.6294 24.7441 77.8903 26.0208 78.1336C27.2974 78.377 29.1687 78.5982 31.5277 78.7844C33.8866 78.9707 36.6872 79.1185 39.7693 79.2193C42.8515 79.3201 46.1549 79.372 49.491 79.372C52.8271 79.372 56.1305 79.3201 59.2127 79.2193C62.2948 79.1185 65.0954 78.9707 67.4543 78.7844C69.8133 78.5982 71.6846 78.377 72.9612 78.1336C74.2379 77.8903 74.895 77.6294 74.895 77.366C74.895 77.1026 74.2379 76.8417 72.9612 76.5983C71.6846 76.3549 69.8133 76.1338 67.4543 75.9475C65.0954 75.7613 62.2948 75.6135 59.2127 75.5127C56.1305 75.4119 52.8271 75.36 49.491 75.36C46.1549 75.36 42.8515 75.4119 39.7693 75.5127C36.6872 75.6135 33.8866 75.7613 31.5277 75.9475C29.1687 76.1338 27.2974 76.3549 26.0208 76.5983C24.7441 76.8417 24.087 77.1026 24.087 77.366V77.366Z"
        opacity={0.15}
      />
      <path
        fill={COLOR.white}
        d="M74.767 74.748H25.82C25.2896 74.748 24.7809 74.5373 24.4058 74.1622C24.0307 73.7872 23.82 73.2784 23.82 72.748V47.471H76.767V72.748C76.767 73.2784 76.5563 73.7872 76.1812 74.1622C75.8061 74.5373 75.2974 74.748 74.767 74.748Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M74.767 74.748H25.82C25.2896 74.748 24.7809 74.5373 24.4058 74.1622C24.0307 73.7872 23.82 73.2784 23.82 72.748V47.471H76.767V72.748C76.767 73.2784 76.5563 73.7872 76.1812 74.1622C75.8061 74.5373 75.2974 74.748 74.767 74.748Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M35.853 39.449 39.864 47.471H76.764V40.252C76.764 40.0398 76.6797 39.8363 76.5297 39.6863 76.3797 39.5363 76.1762 39.452 75.964 39.452L35.853 39.449ZM39.864 47.471H23.82V34.635C23.82 33.7842 24.158 32.9682 24.7596 32.3666 25.3612 31.765 26.1772 31.427 27.028 31.427H31.016C31.265 31.4261 31.5093 31.4948 31.7214 31.6254 31.9334 31.756 32.1047 31.9432 32.216 32.166L39.864 47.471Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M29.435 55.494H71.151M49.491 55.494V71.538"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M44.678 39.449H50.294L45.48 47.471H39.864L44.678 39.449ZM56.711 39.449H62.327L57.514 47.471H51.898L56.711 39.449ZM68.745 39.449H74.361L69.547 47.471H63.931L68.745 39.449Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M27.831 43.861C27.831 43.9401 27.8075 44.0174 27.7636 44.0832 27.7196 44.149 27.6572 44.2003 27.5841 44.2306 27.511 44.2608 27.4306 44.2687 27.353 44.2533 27.2754 44.2379 27.2041 44.1998 27.1482 44.1438 27.0922 44.0879 27.0541 44.0166 27.0387 43.939 27.0233 43.8614 27.0312 43.781 27.0615 43.7079 27.0917 43.6348 27.143 43.5724 27.2088 43.5284 27.2746 43.4845 27.3519 43.461 27.431 43.461 27.5371 43.461 27.6388 43.5031 27.7138 43.5782 27.7889 43.6532 27.831 43.7549 27.831 43.861ZM27.831 35.037C27.831 35.1161 27.8075 35.1934 27.7636 35.2592 27.7196 35.325 27.6572 35.3763 27.5841 35.4065 27.511 35.4368 27.4306 35.4447 27.353 35.4293 27.2754 35.4139 27.2041 35.3758 27.1482 35.3198 27.0922 35.2639 27.0541 35.1926 27.0387 35.115 27.0233 35.0374 27.0312 34.957 27.0615 34.8839 27.0917 34.8108 27.143 34.7484 27.2088 34.7044 27.2746 34.6605 27.3519 34.637 27.431 34.637 27.5371 34.637 27.6388 34.6791 27.7138 34.7542 27.7889 34.8292 27.831 34.9309 27.831 35.037ZM34.249 43.861C34.249 43.9401 34.2255 44.0174 34.1816 44.0832 34.1376 44.149 34.0752 44.2003 34.0021 44.2306 33.929 44.2608 33.8486 44.2687 33.771 44.2533 33.6934 44.2379 33.6221 44.1998 33.5662 44.1438 33.5102 44.0879 33.4721 44.0166 33.4567 43.939 33.4413 43.8614 33.4492 43.781 33.4795 43.7079 33.5097 43.6348 33.561 43.5724 33.6268 43.5284 33.6926 43.4845 33.7699 43.461 33.849 43.461 33.9551 43.461 34.0568 43.5031 34.1319 43.5782 34.2069 43.6532 34.249 43.7549 34.249 43.861Z"
      />
    </svg>
  )
}
const Memo = memo(SvgClapperboard)
export default Memo
