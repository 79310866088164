import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgQuestion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        d="M68.783 35.9919C68.8042 35.7674 68.781 35.5409 68.7147 35.3254 68.6484 35.1099 68.5402 34.9095 68.3965 34.7358 68.2528 34.562 68.0762 34.4183 67.8769 34.3128 67.6776 34.2073 67.4595 34.1421 67.235 34.1209L48.435 32.3519 46.28 25.8359C46.2093 25.6218 46.0971 25.4237 45.9498 25.253 45.8025 25.0823 45.623 24.9422 45.4216 24.8409 45.2201 24.7396 45.0007 24.679 44.7758 24.6625 44.5509 24.646 44.325 24.674 44.111 24.7449L9.87597 36.0649C9.66185 36.1356 9.46377 36.2478 9.29304 36.3951 9.12231 36.5424 8.98228 36.7219 8.88096 36.9233 8.77964 37.1247 8.71901 37.3442 8.70254 37.5691 8.68607 37.7939 8.71408 38.0199 8.78497 38.2339L24.417 85.5079C24.4876 85.7221 24.5997 85.9203 24.7469 86.0911 24.8942 86.262 25.0737 86.4021 25.2751 86.5035 25.4765 86.605 25.696 86.6657 25.9209 86.6822 26.1459 86.6988 26.3719 86.6708 26.586 86.5999L33.277 84.3879 62.249 87.1139C62.4735 87.135 62.6999 87.1116 62.9154 87.0452 63.1309 86.9788 63.3311 86.8705 63.5048 86.7267 63.6784 86.5829 63.822 86.4062 63.9274 86.2069 64.0328 86.0076 64.0979 85.7894 64.119 85.5649L68.783 35.9919ZM15.8775 27.4737 55.3651 27.0354 55.9559 80.2581 16.4683 80.6964 15.8775 27.4737Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.0734 80.4922L28.877 29.2077L66.4853 32.7303L61.6818 84.0148L24.0734 80.4922Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.525 34.816 40.363 35.456M33.045 39.944 48.43 41.386M32.565 45.0729 42.821 46.0329M32.084 50.2009 44.05 51.3219M31.604 55.33 36.732 55.81M31.123 60.4581 49.928 62.2191M30.643 65.5861 42.609 66.7071M30.163 70.715 52.386 72.796M29.682 75.8431 39.939 76.8041M43.782 35.777 52.329 36.577M40.151 56.13 48.698 56.931M43.358 77.124 51.906 77.925"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.55589 34.5131L45.5822 23.1596L61.0644 72.2868L25.0381 83.6403L9.55589 34.5131Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.017 37.8769 22.567 35.8129M17.565 42.7899 32.303 38.1449M19.114 47.7031 28.939 44.6061M20.662 52.6151 32.125 49.0031M22.21 57.5279 27.123 55.9799M23.758 62.441 41.771 56.764M25.306 67.3531 36.769 63.7411M26.855 72.2661 48.143 65.5571M28.403 77.1789 38.228 74.0819M25.842 34.7809 34.03 32.2009M30.398 54.9479 38.586 52.3669M41.503 73.0499 49.691 70.4699"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.141 26.593H55.914V78.102H18.141V26.593Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M64.782 25.9689C64.782 28.9595 65.97 31.8276 68.0847 33.9423 70.1994 36.0569 73.0674 37.2449 76.058 37.2449 79.0486 37.2449 81.9167 36.0569 84.0314 33.9423 86.146 31.8276 87.334 28.9595 87.334 25.9689 87.334 22.9784 86.146 20.1103 84.0314 17.9956 81.9167 15.8809 79.0486 14.6929 76.058 14.6929 73.0674 14.6929 70.1994 15.8809 68.0847 17.9956 65.97 20.1103 64.782 22.9784 64.782 25.9689V25.9689ZM82.489 40.6221C82.4513 40.5541 82.3989 40.4953 82.3357 40.4499 82.2725 40.4046 82.2 40.3738 82.1235 40.3599 82.047 40.3459 81.9684 40.3491 81.8932 40.3692 81.8181 40.3893 81.7483 40.4258 81.689 40.4761 80.844 41.1861 79.172 42.7011 78.989 43.7041 78.8709 44.3512 79.0146 45.0187 79.3885 45.5599 79.7624 46.1011 80.336 46.4716 80.983 46.5901L80.17 46.4411C79.7385 46.3626 79.2936 46.4586 78.9328 46.708 78.5721 46.9573 78.3251 47.3397 78.246 47.7711L73.493 73.8001C73.4539 74.0137 73.4573 74.233 73.5029 74.4454 73.5486 74.6577 73.6357 74.859 73.7592 75.0377 73.8828 75.2164 74.0403 75.369 74.2228 75.4868 74.4053 75.6045 74.6093 75.6852 74.823 75.7241L76.45 76.0241C76.8815 76.1025 77.3264 76.0065 77.6872 75.7571 78.0479 75.5078 78.295 75.1254 78.374 74.6941L83.129 48.6651C83.1684 48.4513 83.1653 48.2319 83.1198 48.0193 83.0744 47.8068 82.9874 47.6053 82.864 47.4264 82.7405 47.2475 82.583 47.0947 82.4004 46.9768 82.2179 46.8588 82.0138 46.7781 81.8 46.7391L80.987 46.5911C81.3078 46.6495 81.6371 46.6441 81.9558 46.5752 82.2746 46.5062 82.5766 46.3751 82.8447 46.1894 83.1127 46.0036 83.3415 45.7669 83.518 45.4926 83.6944 45.2183 83.8151 44.912 83.873 44.5911 84.056 43.5931 83.028 41.5851 82.489 40.6221Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M76.3 74.5329C76.2212 74.9644 75.9742 75.347 75.6134 75.5964C75.2526 75.8458 74.8075 75.9417 74.376 75.8629L72.749 75.5629C72.5354 75.5239 72.3315 75.4432 72.1491 75.3254C71.9667 75.2076 71.8093 75.055 71.6859 74.8763C71.5625 74.6976 71.4754 74.4964 71.4298 74.2841C71.3842 74.0718 71.3809 73.8525 71.42 73.6389L76.175 47.6099C76.2545 47.1788 76.5019 46.7968 76.8628 46.548C77.2238 46.2992 77.6688 46.2039 78.1 46.2829L79.726 46.5829C79.9397 46.6218 80.1437 46.7025 80.3262 46.8202C80.5087 46.938 80.6663 47.0906 80.7898 47.2693C80.9133 47.448 81.0004 47.6493 81.0461 47.8616C81.0918 48.074 81.0951 48.2933 81.056 48.5069L76.3 74.5329Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M72.909 65.507 77.789 66.399M71.423 73.6409C70.9957 73.5629 70.588 73.4015 70.2231 73.1658 69.8583 72.9302 69.5434 72.625 69.2964 72.2677 69.0495 71.9104 68.8754 71.5079 68.7841 71.0833 68.6927 70.6586 68.6859 70.2202 68.764 69.7929L70.547 60.0319"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M81.8 44.4371C81.6817 45.0842 81.3112 45.6578 80.77 46.0318C80.2287 46.4057 79.5611 46.5494 78.914 46.4311C78.2669 46.3128 77.6932 45.9423 77.3193 45.401C76.9453 44.8598 76.8017 44.1922 76.92 43.5451C77.166 42.1971 80.103 39.9241 80.103 39.9241C80.103 39.9241 82.048 43.0891 81.8 44.4371Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.339 66.625C33.339 67.4583 33.67 68.2575 34.2593 68.8467 34.8485 69.4359 35.6477 69.767 36.481 69.767 37.3143 69.767 38.1135 69.4359 38.7027 68.8467 39.292 68.2575 39.623 67.4583 39.623 66.625 39.623 65.7917 39.292 64.9925 38.7027 64.4032 38.1135 63.814 37.3143 63.483 36.481 63.483 35.6477 63.483 34.8485 63.814 34.2593 64.4032 33.67 64.9925 33.339 65.7917 33.339 66.625V66.625ZM36.555 61.064C35.9478 61.0634 35.3656 60.822 34.9363 60.3927 34.507 59.9633 34.2655 59.3811 34.265 58.774V56.689C34.2726 55.3633 34.6889 54.0722 35.4571 52.9918 36.2254 51.9114 37.3083 51.0943 38.558 50.652 39.9239 50.1613 41.064 49.1892 41.7645 47.918 42.4649 46.6468 42.6777 45.1638 42.363 43.747 42.0409 42.32 41.2044 41.0618 40.0132 40.2127 38.8221 39.3635 37.3599 38.983 35.9059 39.1439 34.4519 39.3047 33.1082 39.9956 32.1315 41.0846 31.1547 42.1735 30.6134 43.5841 30.611 45.047 30.6174 45.3517 30.5629 45.6546 30.4507 45.9379 30.3385 46.2212 30.1708 46.4794 29.9576 46.6971 29.7444 46.9148 29.4898 47.0878 29.2089 47.2059 28.9279 47.324 28.6262 47.3848 28.3215 47.3848 28.0167 47.3848 27.7151 47.324 27.4341 47.2059 27.1532 47.0878 26.8986 46.9148 26.6854 46.6971 26.4722 46.4794 26.3045 46.2212 26.1923 45.9379 26.0801 45.6546 26.0256 45.3517 26.032 45.047 26.0315 43.6643 26.3037 42.2952 26.8331 41.0179 27.3625 39.7406 28.1387 38.5803 29.1172 37.6035 30.0958 36.6266 31.2574 35.8524 32.5355 35.3252 33.8137 34.798 35.1834 34.5281 36.566 34.531 43.798 34.531 46.401 40.684 46.844 42.814 47.3931 45.313 47.0109 47.9265 45.7691 50.1636 44.5272 52.4007 42.5113 54.1073 40.1 54.963 39.7387 55.0867 39.4244 55.3188 39.1998 55.6277 38.9752 55.9366 38.8513 56.3071 38.845 56.689V58.774C38.8445 59.3811 38.603 59.9633 38.1737 60.3927 37.7443 60.822 37.1622 61.0634 36.555 61.064V61.064Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M85.039 33.1001C85.4951 33.5388 86.1051 33.7811 86.7379 33.775 87.3707 33.7689 87.9759 33.5149 88.4234 33.0674 88.871 32.62 89.1252 32.0149 89.1315 31.3821 89.1378 30.7493 88.8956 30.1393 88.457 29.6831L84.47 25.6931 81.053 29.1101 85.039 33.1001ZM83.9 28.5399C82.4356 30.0043 80.5699 31.0016 78.5387 31.4056 76.5075 31.8097 74.4021 31.6024 72.4888 30.8098 70.5754 30.0173 68.9401 28.6752 67.7895 26.9533 66.6389 25.2313 66.0248 23.2068 66.0248 21.1359 66.0248 19.0649 66.6389 17.0404 67.7895 15.3185 68.9401 13.5965 70.5754 12.2544 72.4888 11.4619 74.4021 10.6694 76.5075 10.462 78.5387 10.8661 80.5699 11.2701 82.4356 12.2674 83.9 13.7319 84.8725 14.704 85.644 15.8583 86.1703 17.1287 86.6967 18.3991 86.9676 19.7607 86.9676 21.1359 86.9676 22.511 86.6967 23.8726 86.1703 25.143 85.644 26.4134 84.8725 27.5677 83.9 28.5399V28.5399Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M69.247 21.136C69.247 23.0586 70.0107 24.9024 71.3702 26.2618C72.7296 27.6213 74.5734 28.385 76.496 28.385C78.4185 28.385 80.2624 27.6213 81.6218 26.2618C82.9813 24.9024 83.745 23.0586 83.745 21.136C83.745 19.2135 82.9813 17.3697 81.6218 16.0102C80.2624 14.6508 78.4185 13.887 76.496 13.887C74.5734 13.887 72.7296 14.6508 71.3702 16.0102C70.0107 17.3697 69.247 19.2135 69.247 21.136V21.136Z"
      />
    </svg>
  )
}
const Memo = memo(SvgQuestion)
export default Memo
