import { Capacitor } from '@capacitor/core'
import { IonFab, IonSpinner, useIonLoading, useIonRouter, useIonToast } from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useBoolean } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import ProjectSubpage from '../../../components/layout/ProjectSubpage'
import Button from '../../../components/ui/Button/Button'
import FAB from '../../../components/ui/Button/FAB'
import Chip, { InteractiveChip } from '../../../components/ui/Chip'
import Dialog from '../../../components/ui/Dialog'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { useProjectQuestions } from '../../../hooks/useProjectQuestions'
import { useQuestionOptions } from '../../../hooks/useQuestionOptions'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { CheckBold, CloseCircle, Plus, PlusCircle } from '../../../icons'
import {
  InterstitialPlacement,
  IronSourceIntegration,
} from '../../../plugins/IronSourceIntegration'
import { COLOR, FONT } from '../../../theme'
import { parseReceiverName } from '../../../utils/textParsing'
import ProjectDashboardItem from '../Dashboard/ProjectDashboardItem'

import { CardButtonStyled } from './ManageQuestions.styled'
import QuestionFormSheet from './QuestionFormSheet'
import SavedQuestionItem from './SavedQuestionItem'
import UnsavedQuestionItem from './UnsavedQuestionItem'

import type { CreateProjectQuestionDto } from '../../../types'

export const MAX_QUESTIONS = 8
export const QUESTION_OPTIONS_PER_PAGE = 3

export default function ManageQuestions() {
  const { id: projectId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { apiClient } = useApiContext()

  const ionRouter = useIonRouter()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const { refetch: refetchProject, project } = useSingleProject(projectId)
  const { refetch: refetchQuestions } = useProjectQuestions(projectId)

  const { status, data: questionOptions } = useQuestionOptions(project?.category?.id as string)
  const hasQuestionOptions = status === 'success' && questionOptions && questionOptions.length > 0

  const contexts = questionOptions?.map((option) => option?.context?.trim()) || []
  const contextsUnique = Array.from(new Set(contexts)).sort((a, b) => a.localeCompare(b))

  const [activeContexts, setActiveContexts] = useState<Set<string>>(new Set())
  const [questionOptionsMax, setQuestionOptionsMax] = useState(QUESTION_OPTIONS_PER_PAGE)

  const toggleActiveContext = (context: string) => {
    const newSet = new Set(activeContexts)

    if (newSet.has(context)) newSet.delete(context)
    else newSet.add(context)

    setActiveContexts(new Set(newSet))
    setQuestionOptionsMax(QUESTION_OPTIONS_PER_PAGE)
  }

  const [usedQuestionOptionIds, setUsedQuestionOptionIds] = useState<Set<string>>(new Set())
  const addUsedQuestionOptionId = (id: string) => {
    const newSet = new Set(usedQuestionOptionIds)
    newSet.add(id)
    setUsedQuestionOptionIds(newSet)
  }

  const filteredQuestionOptions =
    questionOptions?.filter((option) => {
      if (usedQuestionOptionIds.has(option.id)) return false

      if (activeContexts.size === 0) return true

      return activeContexts.has(option.context.trim())
    }) || []

  const [unsavedQuestions, setUnsavedQuestions] = useState<CreateProjectQuestionDto[]>([])

  const updateUnsavedQuestion = (index: number, data: CreateProjectQuestionDto) => {
    const newUnsavedQuestions = [...unsavedQuestions]
    newUnsavedQuestions[index] = data
    setUnsavedQuestions(newUnsavedQuestions)
  }

  const deleteUnsavedQuestion = (index: number) => {
    const newUnsavedQuestions = [...unsavedQuestions]
    newUnsavedQuestions.splice(index, 1)
    setUnsavedQuestions(newUnsavedQuestions)
  }

  const {
    value: createQuestionSheetOpen,
    setTrue: openCreateQuestionSheet,
    setFalse: closeCreateQuestionSheet,
  } = useBoolean(false)

  const onAddSubmit = (data: CreateProjectQuestionDto) => {
    setUnsavedQuestions([...unsavedQuestions, data])
    closeCreateQuestionSheet()
  }

  const saveQuestions = async () => {
    if (unsavedQuestions.length === 0) return

    await presentLoading({ message: t('ProjectDashboard.Questions.saving') })

    try {
      if (Capacitor.isNativePlatform()) await IronSourceIntegration.loadInterstitial()
    } catch {
      // no-op
    }

    try {
      await apiClient.addQuestionToProject(projectId, unsavedQuestions)
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.Questions.saved'),
        icon: checkmark,
        duration: 2500,
      })
      await refetchProject()
      void refetchQuestions()
      setUnsavedQuestions([])
    } catch {
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.Questions.error'),
        icon: alertCircle,
        duration: 2500,
      })
    }

    await dismissLoading()
    if (Capacitor.isNativePlatform())
      await IronSourceIntegration.showInterstitial({
        placement: InterstitialPlacement.SaveQuestions,
      })
  }

  const { questions = [] } = project || {}
  const sortedQuestions = questions
    .slice()
    .sort((a, b) => new Date(a.created || '').getTime() - new Date(b.created || '').getTime())

  const numberOfQuestions = [...sortedQuestions, ...unsavedQuestions].filter(Boolean).length || 0
  const canAddQuestions = numberOfQuestions < MAX_QUESTIONS

  const [abortDialogOpen, setAbortDialogOpen] = useState(false)
  const openAbortDialog = () => setAbortDialogOpen(true)
  const dismissAbortDialog = () => setAbortDialogOpen(false)

  const handleAbort = () => {
    if (unsavedQuestions.length > 0) openAbortDialog()
    else ionRouter.push(`/projects/${project?.id}`, 'back', 'pop')
  }

  const handleAbortConfirm = () => {
    dismissAbortDialog()
    setUnsavedQuestions([])
    ionRouter.push(ROUTES.projectDashboard(project?.id), 'back', 'pop')
  }

  const refetch = () => {
    void refetchProject()
    void refetchQuestions()
  }

  return (
    <ProjectSubpage
      title={`${t('ProjectDashboard.Questions.title')} (${numberOfQuestions}/${MAX_QUESTIONS})`}
      showBackButton={false}
      project={project}
      headerButton={<IconButton icon={<CloseCircle />} title="" onClick={handleAbort} />}
    >
      <Flex direction="column" gap={40}>
        <Flex direction="column" gap={20}>
          <Text tag="p" color={COLOR.neutral.dark}>
            {t('ProjectDashboard.Questions.description')}
          </Text>
          <Flex direction="column" gap={20}>
            {sortedQuestions.map((question) => (
              <SavedQuestionItem
                key={question.id}
                projectId={projectId}
                question={question}
                receiverName={project?.receiverName || ''}
                onDelete={refetch}
                onUpdate={refetch}
              />
            ))}
            {unsavedQuestions.map((question, index) => (
              <UnsavedQuestionItem
                key={question.title}
                question={question}
                receiverName={project?.receiverName || ''}
                onUpdate={(data) => updateUnsavedQuestion(index, data)}
                onDelete={() => deleteUnsavedQuestion(index)}
              />
            ))}
            <Button
              icon={<PlusCircle />}
              variant="secondary"
              disabled={!canAddQuestions}
              onClick={openCreateQuestionSheet}
            >
              {t('ProjectDashboard.Questions.createNewQuestion')}
            </Button>
          </Flex>
        </Flex>
        {status === 'loading' && <IonSpinner name={DEFAULT_SPINNER} />}
        {hasQuestionOptions && (
          <ProjectDashboardItem title={t('ProjectDashboard.Questions.suggestedQuestions')}>
            <div style={{ margin: -4, lineHeight: 2.15 }}>
              {contextsUnique.map((context) => (
                <span key={context} style={{ padding: 4 }}>
                  <InteractiveChip
                    text={context}
                    variant={activeContexts.has(context) ? 'colorful' : 'secondary'}
                    onClick={() => toggleActiveContext(context)}
                  />
                </span>
              ))}
            </div>
            <Flex direction="column" gap={8}>
              {filteredQuestionOptions.slice(0, questionOptionsMax).map((questionOption) => {
                const title = parseReceiverName(questionOption.title, project?.receiverName || '')
                return (
                  <CardButtonStyled
                    key={questionOption.id}
                    icon={<Plus />}
                    iconPosition="trailing"
                    disabled={!canAddQuestions}
                    onClick={() => {
                      addUsedQuestionOptionId(questionOption.id)
                      onAddSubmit({ title, multipleAnswers: true })
                    }}
                  >
                    <Flex direction="column" gap={12} alignItems="flex-start">
                      <Chip variant="secondary" text={questionOption.context} />
                      <Text size={FONT.size.base}>{title}</Text>
                    </Flex>
                  </CardButtonStyled>
                )
              })}
            </Flex>
            {filteredQuestionOptions.length > questionOptionsMax && (
              <Button
                variant="secondary"
                onClick={() =>
                  setQuestionOptionsMax(questionOptionsMax + QUESTION_OPTIONS_PER_PAGE)
                }
              >
                {t('ProjectDashboard.Questions.loadMore')}
              </Button>
            )}
          </ProjectDashboardItem>
        )}
      </Flex>
      {unsavedQuestions.length > 0 && (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <FAB icon={<CheckBold />} onClick={() => void saveQuestions()}>
            {t('ProjectDashboard.Questions.saveQuestions')}
          </FAB>
        </IonFab>
      )}
      <QuestionFormSheet
        isOpen={createQuestionSheetOpen}
        onSubmit={onAddSubmit}
        onClose={closeCreateQuestionSheet}
      />
      <Dialog isOpen={abortDialogOpen} onClose={() => setAbortDialogOpen(false)}>
        <Dialog.Title>{t('ProjectDashboard.Questions.Abort.titleAbort')}</Dialog.Title>
        <Dialog.Body>{t('ProjectDashboard.Questions.Abort.textAbort')}</Dialog.Body>
        <Dialog.Buttons>
          <Button variant="danger" onClick={handleAbortConfirm}>
            {t('ProjectDashboard.Questions.Abort.confirmAbort')}
          </Button>
          <Button variant="secondary" onClick={dismissAbortDialog}>
            {t('ProjectDashboard.Questions.Abort.cancelAbort')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </ProjectSubpage>
  )
}
