import { css, cx } from '@emotion/css'

import { FONT } from '../../theme'

import type { HTMLAttributes } from 'react'

interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  tag?: keyof JSX.IntrinsicElements
  color?: string
  size?: string
  weight?: number
}

export default function Text(props: TextProps) {
  const {
    tag: Component = 'span',
    color = 'currentColor',
    size = FONT.size.base,
    weight = FONT.weight.normal,
    children,
    className,
    ...rest
  } = props

  const textStyles = css`
    color: ${color};
    font-size: ${size};
    font-weight: ${weight};
    margin: 0;
  `

  return (
    <Component className={cx(textStyles, className)} {...rest}>
      {children}
    </Component>
  )
}
