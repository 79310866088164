import styled from '@emotion/styled'

import CardButton from '../../../components/ui/CardButton/CardButton'

export const CardButtonStyled = styled(CardButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 20px;

  svg.icon {
    height: 24px;
    width: 24px;
  }
`
