import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { IonSpinner } from '@ionic/react'
import { t } from 'i18next'
import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../../App'
import WithLoading from '../../../components/common/WithLoading'
import Button from '../../../components/ui/Button/Button'
import Chip, { InteractiveChip } from '../../../components/ui/Chip'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { useQuestionOptions } from '../../../hooks/useQuestionOptions'
import { Plus, PlusCircle } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { parseReceiverName } from '../../../utils/textParsing'
import ProjectDashboardItem from '../../Project/Dashboard/ProjectDashboardItem'
import { MAX_QUESTIONS, QUESTION_OPTIONS_PER_PAGE } from '../../Project/Questions/ManageQuestions'
import { CardButtonStyled } from '../../Project/Questions/ManageQuestions.styled'
import QuestionFormSheet from '../../Project/Questions/QuestionFormSheet'
import UnsavedQuestionItem from '../../Project/Questions/UnsavedQuestionItem'

import type { CreateProjectQuestionDto } from '../../../types'

interface AddQuestionsStepProps {
  projectCategoryId: string
  receiverName?: string
  setQuestions: (questions: CreateProjectQuestionDto[]) => void
  questions: CreateProjectQuestionDto[]
}

export default function AddQuestionsStep({
  setQuestions,
  projectCategoryId,
  receiverName,
  questions,
}: AddQuestionsStepProps) {
  void FirebaseAnalytics.setScreenName({
    screenName: 'AddQuestionsStep',
    nameOverride: 'NewProject',
  })

  const { status, data: questionOptions, isLoading } = useQuestionOptions(projectCategoryId)
  const hasQuestionOptions = status === 'success' && questionOptions && questionOptions.length > 0

  const contexts = questionOptions?.map((option) => option?.context?.trim()) || []
  const contextsUnique = Array.from(new Set(contexts)).sort((a, b) => a.localeCompare(b))

  const [activeContexts, setActiveContexts] = useState<Set<string>>(new Set())
  const [questionOptionsMax, setQuestionOptionsMax] = useState(QUESTION_OPTIONS_PER_PAGE)

  const [usedQuestionOptionIds, setUsedQuestionOptionIds] = useState<Set<string>>(new Set())
  const addUsedQuestionOptionId = (id: string) => {
    const newSet = new Set(usedQuestionOptionIds)
    newSet.add(id)
    setUsedQuestionOptionIds(newSet)
  }

  const filteredQuestionOptions =
    questionOptions?.filter((option) => {
      if (usedQuestionOptionIds.has(option.id)) return false

      if (activeContexts.size === 0) return true

      return activeContexts.has(option.context.trim())
    }) || []

  const numberOfQuestions = questions.length || 0
  const canAddQuestions = numberOfQuestions < MAX_QUESTIONS

  const toggleActiveContext = (context: string) => {
    const newSet = new Set(activeContexts)

    if (newSet.has(context)) newSet.delete(context)
    else newSet.add(context)

    setActiveContexts(new Set(newSet))
    setQuestionOptionsMax(QUESTION_OPTIONS_PER_PAGE)
  }

  const {
    value: createQuestionSheetOpen,
    setTrue: openCreateQuestionSheet,
    setFalse: closeCreateQuestionSheet,
  } = useBoolean(false)

  const updateUnsavedQuestion = (index: number, data: CreateProjectQuestionDto) => {
    const newUnsavedQuestions = [...questions]
    newUnsavedQuestions[index] = data
    setQuestions(newUnsavedQuestions)
  }

  const deleteUnsavedQuestion = (index: number) => {
    const newUnsavedQuestions = [...questions]
    newUnsavedQuestions.splice(index, 1)
    setQuestions(newUnsavedQuestions)
  }

  return (
    <WithLoading isLoading={isLoading}>
      <Flex direction="column" gap={40}>
        <Flex direction="column" gap={20}>
          <Text tag="p" color={COLOR.neutral.dark}>
            {t('ProjectDashboard.Questions.description')}
          </Text>
          <Flex direction="column" gap={20}>
            {questions.map((question, index) => (
              <UnsavedQuestionItem
                key={question.title}
                question={question}
                receiverName={receiverName || ''}
                onUpdate={(data) => updateUnsavedQuestion(index, data)}
                onDelete={() => deleteUnsavedQuestion(index)}
              />
            ))}
            <Button
              icon={<PlusCircle />}
              variant="secondary"
              disabled={!canAddQuestions}
              onClick={openCreateQuestionSheet}
            >
              {t('ProjectDashboard.Questions.createNewQuestion')}
            </Button>
          </Flex>
        </Flex>
        {status === 'loading' && <IonSpinner name={DEFAULT_SPINNER} />}
        {hasQuestionOptions && (
          <ProjectDashboardItem title={t('ProjectDashboard.Questions.suggestedQuestions')}>
            <div style={{ margin: -4, lineHeight: 2.15 }}>
              {contextsUnique.map((context) => (
                <span key={context} style={{ padding: 4 }}>
                  <InteractiveChip
                    text={context}
                    variant={activeContexts.has(context) ? 'colorful' : 'secondary'}
                    onClick={() => toggleActiveContext(context)}
                  />
                </span>
              ))}
            </div>
            <Flex direction="column" gap={8}>
              {filteredQuestionOptions.slice(0, questionOptionsMax).map((questionOption) => {
                const title = parseReceiverName(questionOption.title, receiverName || '')
                return (
                  <CardButtonStyled
                    key={questionOption.id}
                    icon={<Plus />}
                    iconPosition="trailing"
                    disabled={!canAddQuestions}
                    onClick={() => {
                      addUsedQuestionOptionId(questionOption.id)
                      setQuestions([...questions, { title, multipleAnswers: true }])
                    }}
                  >
                    <Flex direction="column" gap={12} alignItems="flex-start">
                      <Chip variant="secondary" text={questionOption.context} />
                      <Text size={FONT.size.base}>{title}</Text>
                    </Flex>
                  </CardButtonStyled>
                )
              })}
            </Flex>
            {filteredQuestionOptions.length > questionOptionsMax && (
              <Button
                variant="secondary"
                onClick={() =>
                  setQuestionOptionsMax(questionOptionsMax + QUESTION_OPTIONS_PER_PAGE)
                }
              >
                {t('ProjectDashboard.Questions.loadMore')}
              </Button>
            )}
          </ProjectDashboardItem>
        )}
      </Flex>
      <QuestionFormSheet
        isOpen={createQuestionSheetOpen}
        onSubmit={(values) => {
          setQuestions([...questions, values])
          closeCreateQuestionSheet()
        }}
        onClose={closeCreateQuestionSheet}
      />
    </WithLoading>
  )
}
