import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgVideos(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_42_451)">
        <path
          fillRule="evenodd"
          d="M5.25 1.49597C4.83579 1.49597 4.5 1.83176 4.5 2.24597V18.746C4.5 19.1602 4.83579 19.496 5.25 19.496H21.75C22.1642 19.496 22.5 19.1602 22.5 18.746V2.24597C22.5 1.83176 22.1642 1.49597 21.75 1.49597H5.25ZM3 2.24597C3 1.00333 4.00736-.00402832 5.25-.00402832H21.75C22.9926-.00402832 24 1.00333 24 2.24597V18.746C24 19.9886 22.9926 20.996 21.75 20.996H5.25C4.00736 20.996 3 19.9886 3 18.746V2.24597ZM.75 2.99597C1.16421 2.99597 1.5 3.33176 1.5 3.74597V21.746C1.5 21.9449 1.57902 22.1356 1.71967 22.2763 1.86032 22.417 2.05109 22.496 2.25 22.496H20.25C20.6642 22.496 21 22.8318 21 23.246 21 23.6602 20.6642 23.996 20.25 23.996H2.25C1.65326 23.996 1.08097 23.7589.65901 23.337.237053 22.915 0 22.3427 0 21.746V3.74597C0 3.33176.335786 2.99597.75 2.99597ZM9.76336 5.44452C9.9939 5.31444 10.2547 5.24764 10.5194 5.25089 10.7841 5.25414 11.0432 5.32732 11.2705 5.46303L11.2834 5.47092 17.2787 9.217C17.4978 9.35006 17.6791 9.53703 17.8053 9.76013 17.9329 9.98548 17.9999 10.24 17.9999 10.499 17.9999 10.7579 17.9329 11.0125 17.8053 11.2378 17.6791 11.4609 17.4978 11.6479 17.2787 11.7809L11.2721 15.5341C11.0445 15.6707 10.7846 15.7444 10.5192 15.7478 10.2537 15.7512 9.99214 15.6841 9.76112 15.5533 9.5301 15.4226 9.33791 15.2328 9.2042 15.0035 9.07048 14.7742 9.00002 14.5135 9 14.248V6.74418C9.00134 6.47948 9.07266 6.21984 9.20673 5.99159 9.34079 5.76334 9.53282 5.5746 9.76336 5.44452ZM10.5005 6.7509 10.5 14.2478 16.4998 10.4989 16.4885 10.4921 10.5005 6.7509Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_451">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgVideos)
export default Memo
