import { IonContent, isPlatform } from '@ionic/react'
import Lottie from 'lottie-react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../Router'
import smartphone from '../animations/smartphone.json'
import Page from '../components/layout/Page'
import SubpageHeader from '../components/layout/SubpageHeader'
import Button from '../components/ui/Button/Button'
import Text from '../components/ui/Text'
import { COLOR, FONT } from '../theme'

import type { LottieRefCurrentProps } from 'lottie-react'

export default function DownloadLinks() {
  const { t } = useTranslation()

  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  const openApp = () => {
    if (isPlatform('android')) {
      window.open(`https://app.winkii.de${ROUTES.openAndroidApp}`)
    } else if (isPlatform('ios')) {
      window.open(`https://app.winkii.de${ROUTES.openIosApp}`)
    }
  }

  return (
    <Page safeArea className="ion-safe-area-top">
      <SubpageHeader
        title={t('DownloadLinks.title')}
        showBackButton={true}
        backButtonRoute={ROUTES.dashboard}
      />
      <IonContent className="ion-padding">
        <div className="flex h-full flex-col items-center justify-evenly">
          <Text
            className="text-center"
            tag="p"
            color={COLOR.neutral.darkest}
            size={FONT.size['2xl']}
            weight={FONT.weight.medium}
          >
            {t('DownloadLinks.description')}
          </Text>

          <Lottie lottieRef={lottieRef} animationData={smartphone} />

          <div className="flex w-full flex-col items-center gap-6">
            {!isPlatform('desktop') && (
              <Button className="w-full" onClick={() => openApp()}>
                {t('DownloadLinks.openApp')}
              </Button>
            )}

            {isPlatform('desktop') && (
              <>
                <Button
                  className="w-full"
                  onClick={() => {
                    window.location.href =
                      'https://play.google.com/store/apps/details?id=com.winkii.android'
                  }}
                >
                  <div className="flex items-center gap-4">
                    <img src="/img/playstore.png" alt="playstore" className="h-8 w-8" />
                    <span>{t('DownloadLinks.android')}</span>
                  </div>
                </Button>

                <Button
                  className="w-full"
                  onClick={() => {
                    window.location.href = 'https://apps.apple.com/us/app/winkii/id1667873462'
                  }}
                >
                  <div className="flex items-center gap-4">
                    <img src="/img/appstore.png" alt="appstore" className="h-8 w-8" />
                    <span>{t('DownloadLinks.ios')}</span>
                  </div>
                </Button>
              </>
            )}
          </div>
        </div>
      </IonContent>
    </Page>
  )
}
