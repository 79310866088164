import { createAnimation, useIonModal } from '@ionic/react'

import ProjectStudioWizard from '../views/Project/Studio/ProjectStudioWizard'

export function enterAnimation(baseEl: HTMLElement) {
  const root = baseEl.shadowRoot

  if (!root) return createAnimation()

  const backdrop = root.querySelector('ion-backdrop')
  const wrapper = root.querySelector('.modal-wrapper')

  if (!backdrop || !wrapper) return createAnimation()

  const backdropAnimation = createAnimation()
    .addElement(backdrop)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

  const wrapperAnimation = createAnimation()
    .addElement(wrapper)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'scale(1)' },
      { offset: 1, opacity: '0.99', transform: 'scale(1)' },
    ])

  return createAnimation()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(300)
    .addAnimation([backdropAnimation, wrapperAnimation])
}

export function leaveAnimation(baseEl: HTMLElement) {
  return enterAnimation(baseEl).direction('reverse')
}

export function useProjectStudioWizard() {
  const [presentModal, dismiss] = useIonModal(ProjectStudioWizard, {
    onDismiss: () => dismiss(),
  })

  const present = () => {
    presentModal({
      enterAnimation,
      leaveAnimation,
    })
  }

  return [present, dismiss]
}
