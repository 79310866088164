import styled from '@emotion/styled'
import { IonRefresher } from '@ionic/react'

import { UnStyledLink } from '../../../components/common/UnStyledLink'
import { HEADER_HEIGHT } from '../../../components/layout/ContentWithImageHeader'
import { COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

export const RowsWithDividingWrapper = styled.div<{ topBorder?: boolean }>(
  ({ topBorder = false }) => `
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${topBorder ? `1px solid ${COLOR.neutral.light}` : 'none'};

  h2 {
    margin: 0;
    color: ${COLOR.neutral.darkest};
    line-height: 25px;
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.semibold};
  }
`,
)

export const RowsWithDividingSectionItem = styled(UnStyledLink)<{ disabled?: boolean }>(
  ({ disabled = false }) => `
    display: flex;
  flex-direction: row;
  ${flexGapString(20, 'column')}
  padding: 10px 0;
  border-bottom: 1px solid ${COLOR.neutral.light};
  align-items: center;
  opacity: ${disabled ? '0.4' : '1'};

  > {
    :nth-child(1) {
      order: 0;
      flex-grow: 0;
    }
    :nth-child(2) {
      order: 1;
      flex-grow: 1;
    }
    :nth-child(3) {
      order: 2;
      flex-grow: 0;
    }
  }`,
)

export const RefresherStyled = styled(IonRefresher)`
  top: ${HEADER_HEIGHT - 50}px;
  z-index: 1;
`
