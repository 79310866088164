import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgBin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_197_494)">
        <path
          fillRule="evenodd"
          d="M9.75 9.5C10.1642 9.5 10.5 9.83579 10.5 10.25V17.75C10.5 18.1642 10.1642 18.5 9.75 18.5 9.33579 18.5 9 18.1642 9 17.75V10.25C9 9.83579 9.33579 9.5 9.75 9.5ZM14.25 9.5C14.6642 9.5 15 9.83579 15 10.25V17.75C15 18.1642 14.6642 18.5 14.25 18.5 13.8358 18.5 13.5 18.1642 13.5 17.75V10.25C13.5 9.83579 13.8358 9.5 14.25 9.5ZM8.15901.90901C8.58097.487053 9.15326.25 9.75.25H14.25C14.8467.25 15.419.487053 15.841.90901 16.2629 1.33097 16.5 1.90326 16.5 2.5V4.25H20.2498 23C23.4142 4.25 23.75 4.58579 23.75 5 23.75 5.41421 23.4142 5.75 23 5.75H20.9399C20.5009 11.0014 19.7033 20.5532 19.6159 21.6355 19.5516 22.4332 19.1697 22.9878 18.688 23.3246 18.2367 23.6401 17.7295 23.7449 17.3737 23.7461 14.9231 23.7527 10.3612 23.7494 7.99146 23.7477 7.34723 23.7473 6.86462 23.7469 6.63088 23.7469 5.8558 23.7469 5.28109 23.3917 4.91592 22.9551 4.5848 22.5592 4.39919 22.0653 4.38866 21.6528L3.06348 5.75H1C.585786 5.75.25 5.41421.25 5 .25 4.58579.585786 4.25 1 4.25H7.5V2.5C7.5 1.90326 7.73705 1.33097 8.15901.90901ZM15 2.5V4.25H9V2.5C9 2.30109 9.07902 2.11032 9.21967 1.96967 9.36032 1.82902 9.55109 1.75 9.75 1.75H14.25C14.4489 1.75 14.6397 1.82902 14.7803 1.96967 14.921 2.11032 15 2.30109 15 2.5ZM5.8856 21.5537 4.56899 5.75371 19.4347 5.75019C18.9937 11.0253 18.2077 20.4384 18.1208 21.5149 18.0937 21.8501 17.9575 22.0051 17.8286 22.0952 17.6694 22.2065 17.4743 22.2457 17.3691 22.2461 14.9215 22.2527 10.3671 22.2494 7.99599 22.2477L7.9859 22.2477C7.34489 22.2473 6.86474 22.2469 6.63088 22.2469 6.35712 22.2469 6.18547 22.1349 6.06651 21.9927 6.00231 21.9159 5.95442 21.8299 5.92369 21.7503 5.89218 21.6685 5.88843 21.6192 5.88819 21.616 5.88819 21.5952 5.88732 21.5744 5.8856 21.5537Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_197_494">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgBin)
export default Memo
