import { css } from '@emotion/css'
import { IonSpinner } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTimeout } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../App'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

import { FadingSwitchTransition } from './Transitions'

import type { FC, PropsWithChildren } from 'react'

interface Props {
  isLoading: boolean
  loadingText?: string
}

export const Loading: FC<Pick<Props, 'loadingText'>> = ({ loadingText }) => {
  const [hasReachedTimeout, setHasReachedTimeout] = useState(false)
  const { t } = useTranslation()

  const transitionKey = hasReachedTimeout ? 'reachedTimeout' : 'default'

  useTimeout(() => {
    if (loadingText) setHasReachedTimeout(true)
  }, 10000)

  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <IonSpinner name={DEFAULT_SPINNER} />
      {loadingText && (
        <FadingSwitchTransition transitionKey={transitionKey}>
          {hasReachedTimeout ? (
            <Text className={css({ textAlign: 'center' })} tag="p">
              {t('General.loadingLong')}
            </Text>
          ) : (
            <Text className={css({ textAlign: 'center' })} tag="p">
              {loadingText}
            </Text>
          )}
        </FadingSwitchTransition>
      )}
    </Flex>
  )
}

export default function WithLoading(props: PropsWithChildren<Props>) {
  const { children, isLoading, loadingText } = props

  return (
    <FadingSwitchTransition transitionKey={isLoading ? 'loading' : 'content'}>
      {isLoading ? <Loading loadingText={loadingText} /> : children}
    </FadingSwitchTransition>
  )
}
