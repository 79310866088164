import styled from '@emotion/styled'

import { COLOR, TRANSITION } from '../../theme'

export const DraggableStyled = styled.div<{ isDragging: boolean }>`
  transition: ${TRANSITION.base};
  background-color: ${({ isDragging }) =>
    isDragging ? `${COLOR.white} !important` : 'transparent'};
  box-shadow: ${({ isDragging }) => (isDragging ? `0 0 13px 0 rgba(0, 0, 0, 0.25)` : 'none')};
`
