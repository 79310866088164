import { App } from '@capacitor/app'
import styled from '@emotion/styled'
import { IonContent, IonModal } from '@ionic/react'
import { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CloseCircle } from '../../../icons'
import { COLOR } from '../../../theme'
import { setNavigationBarBlack, setNavigationBarLight } from '../../../utils/navigationBar'
import { setStatusbarBlack } from '../../../utils/statusBar'
import LazyImage from '../../common/LazyImage'
import SubpageHeader from '../../layout/SubpageHeader'
import IconButton from '../../ui/IconButton/IconButton'

import type { ProjectAttachmentDto } from '../../../types'
import type { PluginListenerHandle } from '@capacitor/core'

interface Props {
  attachments: ProjectAttachmentDto[]
  initialActiveIndex?: number
  isOpen?: boolean
  onDismiss?: () => void
}

export const ModalImage = styled(LazyImage)`
  object-fit: contain;
  background: ${COLOR.black};
`

const ModalContent = styled(IonContent)`
  --background: ${COLOR.black};
`

export const SwiperStyled = styled(Swiper)`
  width: 100%;
  height: 100%;
`

const ModalStyled = styled(IonModal)`
  --background: ${COLOR.black};
  --ion-toolbar-background: ${COLOR.black};
`

export default function ViewAttachmentsModal(props: Props) {
  const { attachments, initialActiveIndex = 0, isOpen = true, onDismiss } = props

  const backButtonListener = useRef<PluginListenerHandle>()

  return (
    <ModalStyled
      isOpen={isOpen}
      onWillPresent={async () => {
        setStatusbarBlack()
        setNavigationBarBlack()

        backButtonListener.current = await App.addListener('backButton', () => {
          onDismiss?.()
        })
      }}
      onWillDismiss={() => {
        setStatusbarBlack()
        setNavigationBarLight()

        void backButtonListener.current?.remove()
        backButtonListener.current = undefined
      }}
    >
      <SubpageHeader
        button={
          <IconButton icon={<CloseCircle />} title="close" onDark={true} onClick={onDismiss} />
        }
      />
      <ModalContent>
        <SwiperStyled initialSlide={initialActiveIndex}>
          {attachments.map((attachment) => (
            <SwiperSlide key={attachment.id}>
              <ModalImage src={attachment.media.sourceUrl} />
            </SwiperSlide>
          ))}
        </SwiperStyled>
      </ModalContent>
    </ModalStyled>
  )
}
