import { css } from '@emotion/css'
import { IonContent } from '@ionic/react'

import LogoWinkii from '../../icons/custom/LogoWinkii'
import { COLOR, FONT } from '../../theme'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

import Page from './Page'

import type { PropsWithChildren } from 'react'

interface AuthPageProps {
  title: string
  subtitle?: string
}

export default function AuthPage(props: PropsWithChildren<AuthPageProps>) {
  const { title, subtitle, children } = props

  return (
    <Page safeArea className="ion-safe-area-top">
      <IonContent className="ion-padding">
        <Flex direction="column" gap={32} className={css({ height: '100%' })}>
          <LogoWinkii height={28} />
          <Flex direction="column" alignItems="center" className={css({ textAlign: 'center' })}>
            <Text tag="h1" weight={FONT.weight.semibold} size={FONT.size.xl}>
              {title}
            </Text>
            {subtitle && (
              <Text tag="p" color={COLOR.neutral.dark}>
                {subtitle}
              </Text>
            )}
          </Flex>
          <div className={css({ flex: 1 })}>{children}</div>
        </Flex>
      </IonContent>
    </Page>
  )
}
