import styled from '@emotion/styled'
import { IonModal } from '@ionic/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MicOutline, PhoneFaceOutline, PhonePortraitOutline, SunOutline } from '../../illustrations'
import { BORDER_RADIUS, COLOR, FONT, PADDING } from '../../theme'
import { alpha } from '../../utils/colors'
import { flexGapString } from '../../utils/css'
import GhostButton from '../ui/Button/GhostButton'
import Flex from '../ui/Flex'

import type { ReactNode } from 'react'

export interface RecordingWizardProps {
  isActive: boolean
  onDismiss: () => void
}

const WizardModal = styled(IonModal)`
  --height: fit-content;
  --border-radius: ${BORDER_RADIUS.base};
  --background: ${alpha(COLOR.black, 0.75)};
  --backdrop-opacity: 0;

  color: white;
  padding: ${PADDING.DEFAULT} ${PADDING.DEFAULT} 132px;
  align-items: flex-end;
  pointer-events: none;

  ::part(content) {
    padding: 20px 24px;
    text-align: center;
    font-family: ${FONT.family.sans};
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.normal};
    transition: all 0.3s ease-in-out;
  }

  ::part(backdrop) {
    pointer-events: none;
  }

  p {
    margin: 0;
  }
`

const WizardContent = styled.div`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  ${flexGapString(24, 'row')}
`

const WizardGhostButton = styled(GhostButton)`
  color: ${({ variant }) => (variant === 'secondary' ? alpha(COLOR.white, 0.5) : COLOR.white)};
  margin: -6px -14px;

  :active {
    background: ${alpha(COLOR.white, 0.1)};
  }
`

interface WizardStep {
  text: string
  illustration: ReactNode
}

const steps: WizardStep[] = [
  {
    text: 'RecordingStudio.Wizard.steps.1',
    illustration: <PhonePortraitOutline width={175} height={175} />,
  },
  {
    text: 'RecordingStudio.Wizard.steps.2',
    illustration: <SunOutline width={175} height={175} />,
  },
  {
    text: 'RecordingStudio.Wizard.steps.3',
    illustration: <PhoneFaceOutline width={175} height={175} />,
  },
  {
    text: 'RecordingStudio.Wizard.steps.4',
    illustration: <MicOutline width={175} height={175} />,
  },
]

export default function RecordingWizard(props: RecordingWizardProps) {
  const { t } = useTranslation()
  const { isActive, onDismiss } = props

  const [currentStep, setCurrentStep] = useState(1)

  const modalRef = useRef<HTMLIonModalElement>(null)

  return (
    <WizardModal ref={modalRef} isOpen={isActive} backdropDismiss={false} onWillDismiss={onDismiss}>
      <WizardContent>
        <Flex direction="column" alignItems="center" gap={24}>
          {steps[currentStep - 1].illustration}
          <p>{t(steps[currentStep - 1].text)}</p>
        </Flex>
        <Flex direction="row" justifyContent="space-between">
          <WizardGhostButton
            variant="secondary"
            size="small"
            onClick={() => void modalRef.current?.dismiss()}
          >
            {t('RecordingStudio.Wizard.close')}
          </WizardGhostButton>
          {currentStep < steps.length ? (
            <WizardGhostButton size="small" onClick={() => setCurrentStep(currentStep + 1)}>
              {t('RecordingStudio.Wizard.next')}
            </WizardGhostButton>
          ) : (
            <WizardGhostButton size="small" onClick={() => void modalRef.current?.dismiss()}>
              {t('RecordingStudio.Wizard.ready')}
            </WizardGhostButton>
          )}
        </Flex>
      </WizardContent>
    </WizardModal>
  )
}
