import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { useIonRouter } from '@ionic/react'
import Lottie from 'lottie-react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../Router'
import successAnimation from '../../../animations/98807-check-right-tick.json'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'

import { FinishedStepWrapper } from './NewProjectWizard.styled'

import type { LottieRefCurrentProps } from 'lottie-react'

interface FinishedStepProps {
  projectId: string
  cleanWizard: () => void
}

export default function FinishedStep({ projectId, cleanWizard }: FinishedStepProps) {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'FinishedStep', nameOverride: 'NewProject' })
  }, [])

  const { t } = useTranslation()
  const lottieRef = useRef<LottieRefCurrentProps | null>(null)
  const ionRouter = useIonRouter()

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      style={{ height: '100%', paddingBottom: 40 }}
    >
      <FinishedStepWrapper>
        <Lottie
          lottieRef={lottieRef}
          animationData={successAnimation}
          loop={false}
          style={{ height: 300 }}
        />
        <h1>{t('NewProject.finishedHeadline')}</h1>
        <p>{t('NewProject.finishedText')}</p>
      </FinishedStepWrapper>
      <ButtonGroup buttonsGrow>
        <Button
          onClick={() => {
            cleanWizard()
            ionRouter.push(ROUTES.projectDashboard(projectId), 'back', 'pop')
          }}
        >
          {t('General.next')}
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
