import { useTranslation } from 'react-i18next'

import ProjectInviteListItem from '../../../../components/projects/ProjectInviteListItem'
import List from '../../../../components/ui/List'
import DashboardSection, { DashboardSectionContentWrapper } from '../DashboardSection'

import type { CollaboratorDto } from '../../../../types'

interface DashboardInvitesProps {
  invites?: CollaboratorDto[]
}

export default function DashboardInvites(props: DashboardInvitesProps) {
  const { invites = [] } = props
  const { t } = useTranslation()

  if (!invites || invites.length === 0) return null

  return (
    <DashboardSection title={t('Dashboard.Invites.title')} id="invites">
      <DashboardSectionContentWrapper>
        <List divider={false}>
          {invites.map((invite) => (
            <ProjectInviteListItem key={invite.id} invite={invite} />
          ))}
        </List>
      </DashboardSectionContentWrapper>
    </DashboardSection>
  )
}
