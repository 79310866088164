import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import styled from '@emotion/styled'
import { IonContent, useIonRouter, useIonViewWillEnter } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../Router'
import Credits from '../../../components/Credits'
import Page from '../../../components/layout/Page'
import PageHeader from '../../../components/layout/PageHeader'
import Avatar from '../../../components/ui/Avatar/Avatar'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { useAuthContext } from '../../../context/AuthContext'
import { ArrowCircleRight } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { isInWeb } from '../../../utils/user'
import {
  RowsWithDividingSectionItem,
  RowsWithDividingWrapper,
} from '../../Project/Dashboard/ProjectDasboard.styled'

export const ProfileSectionItem = styled(RowsWithDividingSectionItem)<{ danger?: boolean }>(
  ({ danger = false }) => `
  justify-content: space-between;
  padding: 22px 15px;
  color: ${danger ? COLOR.danger.DEFAULT : COLOR.neutral.darkest};

  svg {
    color: ${COLOR.neutral.darkest};
  }
`,
)

export default function Profile() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'Profile', nameOverride: 'Profile' })
  }, [])

  const { t } = useTranslation()
  const ionRouter = useIonRouter()
  const { user, fetchAvailableProductionJobs, refetchUser, userIsGuest } = useAuthContext()

  useIonViewWillEnter(() => {
    if (!user) return

    fetchAvailableProductionJobs()
    void refetchUser()
  }, [user])

  if (!user) {
    ionRouter.push('/login', 'none')
    return null
  } else {
    return (
      <Page>
        <PageHeader title={t('Profile.title')} showAvatar={true} />
        <IonContent className="ion-padding">
          <Flex direction="column" alignItems="center" gap={24}>
            <Flex alignItems="center" gap={12} direction="column">
              <Avatar src={user.avatar?.sourceUrl} size="xl" alt="" />
              <Text tag="h2" size={FONT.size['2xl']} weight={FONT.weight.medium}>
                {user.firstName} {user.lastName}
              </Text>
            </Flex>
            <Credits />
            <ButtonGroup orientation="horizontal">
              <Button
                variant="secondary"
                onClick={() =>
                  ionRouter.push(
                    userIsGuest ? ROUTES.downloadsLinks : ROUTES.profileSettings,
                    'forward',
                  )
                }
              >
                {t('Profile.Edit.title')}
              </Button>
              <Button
                variant="primary"
                onClick={() => ionRouter.push(isInWeb() ? ROUTES.downloadsLinks : ROUTES.shop)}
              >
                {t('Profile.linkToShop')}
              </Button>
            </ButtonGroup>
          </Flex>
          <RowsWithDividingWrapper topBorder={true}>
            <ProfileSectionItem to={ROUTES.appSettings}>
              {t('Profile.AppSettings.title')}
              <ArrowCircleRight />
            </ProfileSectionItem>
            <ProfileSectionItem to={ROUTES.accountSettings}>
              {t('Profile.AccountSettings.title')}
              <ArrowCircleRight />
            </ProfileSectionItem>
            {!userIsGuest && (
              <>
                <ProfileSectionItem to={ROUTES.blockedUsers}>
                  {t('Profile.BlockedUsers.title')}
                  <ArrowCircleRight />
                </ProfileSectionItem>
              </>
            )}
          </RowsWithDividingWrapper>
        </IonContent>
      </Page>
    )
  }
}
