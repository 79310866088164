import styled from '@emotion/styled'

import LogoWinkii from '../../icons/custom/LogoWinkii'
import { flexGapObject } from '../../utils/css'

export type LogoSizes = 'sm' | 'md'

const LOGO_SIZES: Record<LogoSizes, number> = {
  sm: 80,
  md: 100,
}

export const FormStyled = styled.form({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  ...flexGapObject(24, 'row'),
})

export const WinkiiSvgStyled = styled(LogoWinkii)<{ size: LogoSizes }>`
  overflow: visible !important;
  margin-bottom: ${({ size }) => (size === 'md' ? '65' : '24')}px;
  align-self: center;
  width: ${({ size }) => LOGO_SIZES[size]}px;
`
