import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCopyFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M17.16 2.51 20 5.31C20.0812 5.39327 20.1452 5.49183 20.1881 5.59997 20.231 5.7081 20.252 5.82368 20.25 5.94V17.25C20.25 17.486 20.1562 17.7124 19.9893 17.8793 19.8224 18.0462 19.596 18.14 19.36 18.14H8.14C7.90396 18.14 7.67758 18.0462 7.51067 17.8793 7.34377 17.7124 7.25 17.486 7.25 17.25V3.14C7.25 2.90396 7.34377 2.67758 7.51067 2.51068 7.67758 2.34377 7.90396 2.25 8.14 2.25H16.53C16.6469 2.24982 16.7628 2.2727 16.8709 2.31731 16.979 2.36192 17.0772 2.4274 17.16 2.51ZM6.25 19.75H17.25C17.5152 19.75 17.7696 19.8554 17.9571 20.0429 18.1446 20.2304 18.25 20.4848 18.25 20.75 18.25 21.0152 18.1446 21.2696 17.9571 21.4571 17.7696 21.6446 17.5152 21.75 17.25 21.75H5.75C5.21957 21.75 4.71086 21.5393 4.33579 21.1642 3.96071 20.7891 3.75 20.2804 3.75 19.75V6.25C3.75 5.98478 3.85536 5.73043 4.04289 5.54289 4.23043 5.35536 4.48478 5.25 4.75 5.25 5.01522 5.25 5.26957 5.35536 5.45711 5.54289 5.64464 5.73043 5.75 5.98478 5.75 6.25V19.25C5.75 19.3826 5.80268 19.5098 5.89645 19.6036 5.99021 19.6973 6.11739 19.75 6.25 19.75Z" />
    </svg>
  )
}
const Memo = memo(SvgCopyFill)
export default Memo
