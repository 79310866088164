import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgArrowCircleLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_60_169)">
        <path d="M11.7813 8.77723C12.0742 8.48433 12.0742 8.00946 11.7813 7.71657C11.4884 7.42367 11.0135 7.42367 10.7207 7.71657L6.97065 11.4666C6.83 11.6072 6.75098 11.798 6.75098 11.9969C6.75098 12.1958 6.83 12.3866 6.97065 12.5272L10.7207 16.2772C11.0135 16.5701 11.4884 16.5701 11.7813 16.2772C12.0742 15.9843 12.0742 15.5095 11.7813 15.2166L9.31164 12.7469H16.501C16.9152 12.7469 17.251 12.4111 17.251 11.9969C17.251 11.5827 16.9152 11.2469 16.501 11.2469H9.31164L11.7813 8.77723Z" />
        <path
          fillRule="evenodd"
          d="M12.001 2.24695C6.61623 2.24695 2.25101 6.61217 2.25101 11.9969C2.25101 17.3817 6.61623 21.7469 12.001 21.7469C17.3858 21.7469 21.751 17.3817 21.751 11.9969C21.751 6.61217 17.3858 2.24695 12.001 2.24695ZM0.751007 11.9969C0.751007 5.78374 5.7878 0.746948 12.001 0.746948C18.2142 0.746948 23.251 5.78374 23.251 11.9969C23.251 18.2102 18.2142 23.2469 12.001 23.2469C5.7878 23.2469 0.751007 18.2102 0.751007 11.9969Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_60_169">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgArrowCircleLeft)
export default Memo
