import { IonSpinner } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import { UnStyledLink } from '../../../components/common/UnStyledLink'
import Answers from '../../../components/projects/Answers'
import OpenQuestions from '../../../components/projects/questions/OpenQuestions'
import Flex from '../../../components/ui/Flex'
import MessageCard, { MessageCardIconStyled } from '../../../components/ui/MessageCard'
import Text from '../../../components/ui/Text'
import { useAuthContext } from '../../../context/AuthContext'
import { Bin, InfoCircle } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { ProductionJobStatus, ProjectStatus } from '../../../types'

import Activity from './Activity'
import Attachments from './Attachments'
import Completed from './Completed'
import GeneralInfo from './GeneralInfo'
import Status from './Status'

import type { LocalAnswer } from '../../../hooks/useLocalAnswers'
import type { ActivityDto, ProjectDto, ProjectQuestionDto } from '../../../types'

interface Props {
  project: ProjectDto
  isCreator: boolean
  isLoadingQuestions?: boolean
  questions?: ProjectQuestionDto[]
  localAnswers?: LocalAnswer[]
  onAttachmentUploaded: () => void
  onAnswerDeleted?: () => void
  activitiesInfiniteQuery: {
    items: ActivityDto[] | undefined
    isLoading: boolean
    fetchNextPage: () => void
    hasNextPage?: boolean
    isFetchingNextPage: boolean
  }
  refetch: () => Promise<void>
}

export default function ProjectDashboardContent(props: Props) {
  const {
    project,
    isCreator,
    isLoadingQuestions = false,
    questions = [],
    localAnswers = [],
    onAttachmentUploaded,
    onAnswerDeleted,
    activitiesInfiniteQuery,
    refetch,
  } = props

  const { t } = useTranslation()

  const { user, availableProductionJobs = 0 } = useAuthContext()

  const showCreditsMessage =
    isCreator && availableProductionJobs === 0 && project?.status !== ProjectStatus.Completed

  if (project.status === ProjectStatus.Deleted && !isCreator) {
    return (
      <MessageCard icon={<Bin />} message={t('ProjectDashboard.MarkedForDeletion.collaborator')} />
    )
  }

  if (
    project.status === ProjectStatus.Completed ||
    [
      ProductionJobStatus.Queued,
      ProductionJobStatus.InProgress,
      ProductionJobStatus.NoClips,
    ].includes(project.productionJob?.status || ('' as ProductionJobStatus))
  )
    return <Completed project={project} isCreator={isCreator} />

  if (!user) return null

  return (
    <Flex direction="column" gap={40}>
      <GeneralInfo project={project} isCreator={isCreator} />
      {isLoadingQuestions ? (
        <Flex alignItems="center" justifyContent="center">
          <IonSpinner name={DEFAULT_SPINNER} />
        </Flex>
      ) : (
        <>
          <OpenQuestions project={project} questions={questions} localAnswers={localAnswers} />
          <Answers project={project} questions={questions} onDeleted={onAnswerDeleted} />
        </>
      )}
      {project.status === ProjectStatus.InProgress && (
        <Attachments
          attachments={project.attachments}
          projectId={project.id}
          onSaved={onAttachmentUploaded}
        />
      )}
      {isCreator && <Status project={project} questions={questions} refetch={refetch} />}
      {showCreditsMessage && (
        <UnStyledLink to={ROUTES.shop}>
          <Flex alignItems="flex-start">
            <MessageCardIconStyled>
              <InfoCircle />
            </MessageCardIconStyled>
            <Flex direction="column" gap={8}>
              <Text weight={FONT.weight.medium}>{t('ProjectDashboard.NoCreditsDialog.title')}</Text>
              <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.DEFAULT}>
                {t('ProjectDashboard.NoCreditsDialog.content')}
              </Text>
            </Flex>
          </Flex>
        </UnStyledLink>
      )}
      <Activity project={project} infiniteQuery={activitiesInfiniteQuery} />
    </Flex>
  )
}
