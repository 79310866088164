import { useInfiniteQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'

export function useInfiniteProjectActivities(projectId: string) {
  const { apiClient } = useApiContext()

  return useInfiniteQuery(
    ['project-activities', projectId],
    ({ pageParam = 1 }) => apiClient.getProjectActivities(projectId, { page: pageParam as number }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.items.length < 5 ? undefined : pages.length + 1,
      retry: 2,
    },
  )
}
