import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgHouseFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_42_230)">
        <path d="M2.64 10.59 11.64 3.11C11.7267 3.0367 11.8365 2.99647 11.95 2.99647 12.0635 2.99647 12.1733 3.0367 12.26 3.11L21.26 10.59C21.4089 10.7127 21.5808 10.8045 21.7656 10.8599 21.9504 10.9154 22.1444 10.9334 22.3362 10.9129 22.5281 10.8924 22.7139 10.8338 22.8828 10.7406 23.0518 10.6474 23.2004 10.5214 23.32 10.37 23.5668 10.0603 23.6829 9.66642 23.6437 9.27234 23.6045 8.87825 23.413 8.51504 23.11 8.26L14.11.770002C13.5007.271198 12.7375-.00134277 11.95-.00134277 11.1625-.00134277 10.3993.271198 9.79.770002L.790002 8.26C.48702 8.51504.295539 8.87825.256317 9.27234.217096 9.66642.333231 10.0603.580002 10.37.699614 10.5214.848236 10.6474 1.01716 10.7406 1.18609 10.8338 1.37192 10.8924 1.56377 10.9129 1.75562 10.9334 1.94963 10.9154 2.13443 10.8599 2.31924 10.8045 2.49112 10.7127 2.64 10.59ZM22.28 22V15C22.2724 14.6993 22.2037 14.4034 22.0782 14.1301 21.9528 13.8567 21.773 13.6118 21.55 13.41L13.25 6.41C12.9002 6.11526 12.4574 5.95361 12 5.95361 11.5426 5.95361 11.0998 6.11526 10.75 6.41L2.45 13.41C2.22697 13.6118 2.04725 13.8567 1.92176 14.1301 1.79628 14.4034 1.72763 14.6993 1.72 15V22C1.72 22.5304 1.93072 23.0391 2.30579 23.4142 2.68086 23.7893 3.18957 24 3.72 24H9.06C9.32522 24 9.57957 23.8946 9.76711 23.7071 9.95465 23.5196 10.06 23.2652 10.06 23V17.5C10.06 17.2348 10.1654 16.9804 10.3529 16.7929 10.5404 16.6054 10.7948 16.5 11.06 16.5H13.06C13.3252 16.5 13.5796 16.6054 13.7671 16.7929 13.9546 16.9804 14.06 17.2348 14.06 17.5V23C14.06 23.2652 14.1654 23.5196 14.3529 23.7071 14.5404 23.8946 14.7948 24 15.06 24H20.44C20.9422 23.9597 21.4107 23.7313 21.7519 23.3605 22.093 22.9897 22.2816 22.5038 22.28 22Z" />
      </g>
      <defs>
        <clipPath id="clip0_42_230">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgHouseFill)
export default Memo
