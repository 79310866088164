import { createContext, useContext, useMemo } from 'react'

import { ApiClient } from '../utils/api'

import type { PropsWithChildren } from 'react'

interface ApiContextValue {
  apiClient: ApiClient
}

// @ts-expect-error default value will never be used
const defaultValue: ApiContextValue = {}
export const ApiContext = createContext<ApiContextValue>(defaultValue)

export function ApiProvider(props: PropsWithChildren) {
  const { children } = props

  const apiContextValue = useMemo(() => ({ apiClient: new ApiClient() }), [])

  return <ApiContext.Provider value={apiContextValue}>{children}</ApiContext.Provider>
}

export const useApiContext = () => useContext(ApiContext)
