import { Preferences } from '@capacitor/preferences'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

import { useApiContext } from './ApiContext'

import type {
  LoginAsGuestDto,
  LoginResponseDto,
  LoginWithAppleDto,
  RegisterRequestDto,
  UserDto,
} from '../types'
import type { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'
import type { User } from '@codetrix-studio/capacitor-google-auth'
import type { MutableRefObject, PropsWithChildren } from 'react'

interface AuthContextValue {
  user: UserDto | null
  login: (email: string, password: string) => Promise<void>
  loginWithGoogle: (googleUser: User) => Promise<void>
  loginWithApple: (appleUser: SignInWithAppleResponse) => Promise<void>
  removeUser: (userId: string) => Promise<void>
  registerUser: (payload: RegisterRequestDto) => Promise<void>
  loginAsGuest: (payload: LoginAsGuestDto) => Promise<void>
  changePassword: (userId: string, newPassword: string) => Promise<void>
  forgotPassword: (email: string) => Promise<void>
  updateUser: (data: Partial<UserDto>) => Promise<void>
  authReady: boolean
  refetchUser: () => Promise<void>
  availableProductionJobs: number
  fetchAvailableProductionJobs: () => void
  userIsGuest?: boolean
  redirectToAfterLogin: MutableRefObject<{ path: string; search?: string } | undefined>
  setRedirectToAfterLogin: (value: { path: string; search?: string } | undefined) => void
}

// @ts-expect-error default value will never be used
const defaultValue: AuthContextValue = {}
export const AuthContext = createContext<AuthContextValue>(defaultValue)

export const USER_STORAGE_KEY = 'user'

const AVAILABLE_PRODUCTION_JOBS_KEY = 'availableProductionJobs'

export function loginResponseDtoToUserDto(loginResponseDto: LoginResponseDto): UserDto {
  return {
    id: loginResponseDto.userId,
    email: loginResponseDto.email,
    firstName: loginResponseDto.firstName,
    lastName: loginResponseDto.lastName,
    accessToken: loginResponseDto.accessToken,
    emailValidated: loginResponseDto.emailValidated,
    avatar: { id: '', ...loginResponseDto.avatar },
    hasProjectWizardSeen: loginResponseDto.hasProjectWizardSeen,
    hasAppOnboardingWizardSeen: loginResponseDto.hasAppOnboardingWizardSeen,
    status: loginResponseDto.status,
  }
}

export function AuthProvider(props: PropsWithChildren) {
  const { children } = props

  const { apiClient } = useApiContext()

  const [stateUser, setStateUser] = useState<UserDto | null>(null)
  const userIsGuest = stateUser?.status === 'guest' // check if user is guest in production only to avoid breaking changes in development dev mode
  const [availableProductionJobs, setStateAvailableProductionJobs] = useState<number>(0)
  const [authReady, setAuthReady] = useState(false)
  const redirectToAfterLogin = useRef<{ path: string; search?: string } | undefined>(undefined)

  const setRedirectToAfterLogin = (value: { path: string; search?: string } | undefined) => {
    redirectToAfterLogin.current = value
  }

  const setAvailableProductionJobs = (jobs: number) => {
    setStateAvailableProductionJobs(jobs)
    return Preferences.set({ key: AVAILABLE_PRODUCTION_JOBS_KEY, value: String(jobs) })
  }

  const fetchAvailableProductionJobs = () => {
    if (!stateUser) return

    apiClient
      .fetchAvailableProductionJobs()
      .then((jobs) => setAvailableProductionJobs(jobs))
      .catch(() => setStateAvailableProductionJobs(0))
  }

  const setUser = (user: UserDto) => {
    if (!user) return

    return Preferences.set({ key: USER_STORAGE_KEY, value: JSON.stringify(user) }).then(() => {
      apiClient.setToken(user.accessToken)
      setStateUser(user)

      fetchAvailableProductionJobs()
    })
  }

  const refetchUser = () => {
    if (!stateUser) return Promise.resolve()

    return apiClient
      .fetchUser(stateUser?.id || '')
      .then((user) => setUser({ ...stateUser, ...user }))
  }

  const login = (email: string, password: string) =>
    apiClient.login(email, password).then((response) => {
      apiClient.setToken(response.accessToken)

      return setUser(loginResponseDtoToUserDto(response))?.then(() =>
        fetchAvailableProductionJobs(),
      )
    })

  const loginWithApple = async (appleResponse: SignInWithAppleResponse) => {
    const dto: LoginWithAppleDto = {
      idToken: appleResponse.response.identityToken,
    }

    if (appleResponse.response.givenName) dto.firstName = appleResponse.response.givenName

    if (appleResponse.response.familyName) dto.lastName = appleResponse.response.familyName

    const loginResponse = await apiClient.loginApple(dto)
    apiClient.setToken(loginResponse.accessToken)
    await setUser(loginResponseDtoToUserDto(loginResponse))
    fetchAvailableProductionJobs()
  }

  const loginWithGoogle = (googleResponse: User) =>
    apiClient.loginGoogle(googleResponse.authentication.idToken).then((response) => {
      apiClient.setToken(response.accessToken)

      return setUser(loginResponseDtoToUserDto(response))?.then(() =>
        fetchAvailableProductionJobs(),
      )
    })

  const registerUser = (user: RegisterRequestDto) =>
    apiClient.registerUser(user).then((response) => setUser(loginResponseDtoToUserDto(response)))

  const loginAsGuest = (name: LoginAsGuestDto) => {
    const guestName = {
      firstName: name.firstName,
      lastName: name.lastName ?? null,
    }

    return apiClient
      .loginGuest(guestName)
      .then((response) => setUser(loginResponseDtoToUserDto(response)))
  }

  const forgotPassword = (email: string) =>
    apiClient.requestPasswordReset(email).then(() => {
      // no-op
    })

  const removeUser = (userId: string) =>
    apiClient.removeUser(userId).then(() => {
      // no-op
    })

  const changePassword = (userId: string, newPassword: string) =>
    apiClient.changePassword(userId, newPassword).then(() => {
      // no-op
    })

  const updateUser = (data: Partial<UserDto>) =>
    apiClient.updateUser(stateUser?.id || '', data).then((response) => {
      void setUser({ ...stateUser, ...response })
    })

  useEffect(() => {
    void Preferences.get({ key: USER_STORAGE_KEY }).then((result) => {
      if (result.value) {
        const user = JSON.parse(result.value) as UserDto
        setStateUser(user)
        apiClient.setToken(user.accessToken)
      }
      setAuthReady(true)
    })

    void Preferences.get({ key: AVAILABLE_PRODUCTION_JOBS_KEY }).then((result) => {
      if (result.value) setStateAvailableProductionJobs(Number(result.value))
    })
  }, [apiClient])

  useEffect(() => {
    if (!stateUser) return

    apiClient
      .fetchAvailableProductionJobs()
      .then((jobs) => setAvailableProductionJobs(jobs))
      .catch(() => setStateAvailableProductionJobs(0))
  }, [stateUser, apiClient])

  return (
    <AuthContext.Provider
      value={{
        user: stateUser,
        login,
        loginWithGoogle,
        loginWithApple,
        registerUser,
        loginAsGuest,
        forgotPassword,
        authReady,
        removeUser,
        changePassword,
        updateUser,
        refetchUser,
        availableProductionJobs,
        fetchAvailableProductionJobs,
        userIsGuest,
        redirectToAfterLogin,
        setRedirectToAfterLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
