import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import {
  IonRefresherContent,
  useIonLoading,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react'
import { alertCircle, checkmark } from 'ionicons/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import WithLoading from '../../../components/common/WithLoading'
import ContentWithImageHeader from '../../../components/layout/ContentWithImageHeader'
import Page from '../../../components/layout/Page'
import ProjectDetailsSheet from '../../../components/projects/ProjectDetailsSheet'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Dialog from '../../../components/ui/Dialog'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Checkbox from '../../../components/ui/Input/Checkbox'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useInfiniteProjectActivities } from '../../../hooks/useInfiniteProjectActivities'
import { useLocalAnswers } from '../../../hooks/useLocalAnswers'
import { useProjectQuestions } from '../../../hooks/useProjectQuestions'
import { useProjectStudioWizard } from '../../../hooks/useProjectStudioWizard'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { CloseCircle, Cog, DotsVertical, Flag } from '../../../icons'
import { ProjectStatus } from '../../../types'
import { setStatusbarBlack } from '../../../utils/statusBar'

import { RefresherStyled } from './ProjectDasboard.styled'
import ProjectDashboardContent from './ProjectDashboardContent'
import ReportProjectModal from './ReportProjectModal'

import type { RefresherCustomEvent } from '@ionic/react'

export default function ProjectDashboard() {
  void FirebaseAnalytics.setScreenName({
    screenName: 'ProjectDashboard',
    nameOverride: 'ProjectDashboard',
  })

  const { t } = useTranslation()
  const { projectId } = useParams<{ projectId: string }>()

  const { apiClient } = useApiContext()
  const { user } = useAuthContext()
  const { localAnswers } = useLocalAnswers(projectId)
  const [presentStudioWizard] = useProjectStudioWizard()

  const ionRouter = useIonRouter()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentToast] = useIonToast()

  const {
    project,
    refetch: refetchProject,
    isLoading: isLoadingProject,
  } = useSingleProject(projectId)

  const {
    questions,
    isLoading: isLoadingQuestions,
    refetch: refetchQuestions,
  } = useProjectQuestions(projectId)

  const {
    data: activitiesPaginated,
    refetch: refetchActivities,
    isLoading: isLoadingActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
  } = useInfiniteProjectActivities(projectId)

  const isLoading = isLoadingProject
  const isCreator = project?.creator.id === user?.id

  const refetch = () => {
    void refetchQuestions()
    void refetchActivities()
    return refetchProject()
  }

  const handleRefresh = (event: RefresherCustomEvent) => {
    void refetch().then(() => {
      event.detail.complete()
    })
  }

  useIonViewWillEnter(() => {
    setStatusbarBlack()
  })

  useIonViewDidEnter(() => {
    if (!user?.hasProjectWizardSeen) presentStudioWizard()
  }, [user])

  const [detailsVisible, setDetailsVisible] = useState(false)

  const [actionsOpen, setActionsOpen] = useState(false)
  const openActions = () => setActionsOpen(true)
  const closeActions = () => setActionsOpen(false)

  const [reportProjectOpen, setReportProjectOpen] = useState(false)
  const openReportProject = () => {
    closeActions()
    setReportProjectOpen(true)
  }
  const closeReportProject = () => setReportProjectOpen(false)

  const [leaveProjectDialogOpen, setLeaveProjectDialogOpen] = useState(false)
  const openLeaveProjectDialog = () => setLeaveProjectDialogOpen(true)

  const DELETE_ANSWERS_DEFAULT = true
  const [deleteAnswers, setDeleteAnswers] = useState(DELETE_ANSWERS_DEFAULT)
  const toggleDeleteAnswers = () => setDeleteAnswers(!deleteAnswers)
  const resetDeleteAnswers = () => setDeleteAnswers(DELETE_ANSWERS_DEFAULT)

  const closeLeaveProjectDialog = () => {
    setLeaveProjectDialogOpen(false)
    resetDeleteAnswers()
  }

  const leaveProject = () => {
    closeActions()
    openLeaveProjectDialog()
  }

  const confirmLeaveProject = async () => {
    await presentLoading()
    closeLeaveProjectDialog()

    try {
      await apiClient.leaveProject(projectId, deleteAnswers)
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.leaveSuccess'),
        duration: 2500,
        icon: checkmark,
      })
      ionRouter.push(ROUTES.dashboard, 'root')
    } catch {
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.leaveError'),
        duration: 2500,
        icon: alertCircle,
      })
    }
  }

  return (
    <Page pageStyle="light">
      <ContentWithImageHeader
        image={project?.thumbnail?.sourceUrl || './default-thumbnail.png'}
        title={project?.title}
        backButtonRoute={ROUTES.dashboard}
        button={<IconButton icon={<DotsVertical />} title="" onDark onClick={openActions} />}
        onSubtitleClick={() => setDetailsVisible(true)}
      >
        {project?.status !== ProjectStatus.Completed && (
          <RefresherStyled slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent refreshingSpinner={DEFAULT_SPINNER} />
          </RefresherStyled>
        )}
        <WithLoading isLoading={isLoading} loadingText={t('ProjectDashboard.loading')}>
          {!!project && (
            <ProjectDashboardContent
              refetch={refetch}
              project={project}
              isCreator={isCreator}
              isLoadingQuestions={isLoadingQuestions}
              questions={questions}
              localAnswers={localAnswers}
              activitiesInfiniteQuery={{
                items: activitiesPaginated?.pages.flatMap((page) => page.items) || [],
                isLoading: isLoadingActivities,
                hasNextPage: hasNextPageActivities,
                fetchNextPage: () => void fetchNextPageActivities(),
                isFetchingNextPage: isFetchingNextPageActivities,
              }}
              onAttachmentUploaded={() => void refetchProject()}
              onAnswerDeleted={() => void refetchQuestions()}
            />
          )}
        </WithLoading>
      </ContentWithImageHeader>
      {project && (
        <>
          <ProjectDetailsSheet
            project={project}
            isOpen={detailsVisible}
            onDismiss={() => setDetailsVisible(false)}
          />
          <ActionSheetModal isOpen={actionsOpen} onClose={closeActions}>
            <ButtonGroup>
              {isCreator && (
                <Button
                  icon={<Cog />}
                  variant="secondary"
                  onClick={() => {
                    closeActions()
                    ionRouter.push(ROUTES.editProject(project.id))
                  }}
                >
                  {t('ProjectDashboard.EditProject.title')}
                </Button>
              )}
              <Button icon={<Flag />} variant="secondary" onClick={openReportProject}>
                {t('ProjectDashboard.reportProject')}
              </Button>
              {!isCreator && (
                <Button icon={<CloseCircle />} variant="danger" onClick={leaveProject}>
                  {t('ProjectDashboard.leaveProject')}
                </Button>
              )}
            </ButtonGroup>
          </ActionSheetModal>
          <ReportProjectModal
            isActive={reportProjectOpen}
            project={project}
            onClose={closeReportProject}
          />
          {!isCreator && (
            <Dialog isOpen={leaveProjectDialogOpen} onClose={closeLeaveProjectDialog}>
              <Dialog.Title>{t('ProjectDashboard.LeaveProjectDialog.title')}</Dialog.Title>
              <Dialog.Body>
                <Flex direction="column">
                  <Text>{t('ProjectDashboard.LeaveProjectDialog.body')}</Text>
                  <Checkbox
                    inputName="preserveAnswers"
                    label={t('ProjectDashboard.LeaveProjectDialog.deleteAnswers')}
                    checked={deleteAnswers}
                    onChange={toggleDeleteAnswers}
                  />
                </Flex>
              </Dialog.Body>
              <Dialog.Buttons>
                <Button variant="danger" onClick={() => void confirmLeaveProject()}>
                  {t('ProjectDashboard.LeaveProjectDialog.confirm')}
                </Button>
                <Button variant="secondary" onClick={closeLeaveProjectDialog}>
                  {t('ProjectDashboard.LeaveProjectDialog.cancel')}
                </Button>
              </Dialog.Buttons>
            </Dialog>
          )}
        </>
      )}
    </Page>
  )
}
