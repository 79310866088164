import styled from '@emotion/styled'
import { IonImg } from '@ionic/react'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'

import { BORDER_RADIUS, COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

export const SwiperStyled = styled(ReactSwiper)`
  height: 100%;
`

export const SwiperSlideStyled = styled(SwiperSlide)`
  overflow-y: auto;
  height: 100%;
`

export const CategoryImage = styled.div`
  margin-bottom: 40px;
  max-height: 100px;
  max-width: 100px;

  svg {
    height: 100%;
    width: 100%;
  }
`

export const InsertTextStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const ImageUploadStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  p {
    color: ${COLOR.neutral.dark};
    font-size: ${FONT.size.xl};
    margin: 0;
    margin-bottom: 43px;
    text-align: center;
  }
`

export const ImageUploadImageFrame = styled(IonImg)`
  height: auto;
  width: 100%;
  ::part(image) {
    aspect-ratio: 1;

    border-radius: ${BORDER_RADIUS.base};
    object-fit: cover;
    object-position: center;
  }
`

export const ImageUploadImageFramePlaceholder = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${BORDER_RADIUS.base};
  background-color: ${COLOR.primary.lighter};
`

export const SummaryStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  ${flexGapString(32, 'row')}

  p {
    font-size: ${FONT.size.xl};
    margin: 0;
  }
`

export const FinishedStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  text-align: center;

  h1 {
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.semibold};
    line-height: ${FONT.lineHeight.xl};
    color: ${COLOR.neutral.dark};
    margin: -20px 0 6px;
  }

  p {
    color: ${COLOR.neutral.dark};
    font-size: ${FONT.size.base};
    line-height: ${FONT.lineHeight.xl};
    margin: 0 0 20px;
    text-align: center;
  }

  > a {
    width: 100%;

    > button {
      width: 100%;
    }

    margin-top: auto;
    margin-bottom: 56px;
  }
`

export const SummaryStepImage = styled(IonImg)`
  height: 160px;
  width: 160px;
  ::part(image) {
    aspect-ratio: 1;

    border-radius: ${BORDER_RADIUS.base};
    object-fit: cover;
    object-position: center;
  }
`

export const SummaryStepContentWrapper = styled.div`
  p {
    font-size: ${FONT.size['2xl']};
    font-weight: ${FONT.weight.semibold};
    text-align: center;
`

export const SummaryStepContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(20, 'row')}
  margin-top: 20px;

  p {
    font-size: ${FONT.size.base};
    font-weight: ${FONT.weight.normal};
  }

  div {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    grid-gap: 20px;

    p:nth-of-type(1) {
      text-align: right;
      color: ${COLOR.neutral.DEFAULT};
      grid-column: 1;
    }

    p:nth-of-type(2) {
      text-align: left;
      grid-column: 2/5;
    }
  }
`
