import styled from '@emotion/styled'
import { IonImg, useIonRouter } from '@ionic/react'
import { formatDistanceToNow } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import { BORDER_RADIUS, COLOR, FONT, PADDING, TRANSITION } from '../../theme'
import { alpha } from '../../utils/colors'
import { flexGapString } from '../../utils/css'
import { getDateFnsLocale } from '../../utils/date'
import { getFullName } from '../../utils/user'

import type { CollaboratorDto } from '../../types'

interface ProjectInviteListItemProps {
  invite: CollaboratorDto
}

const ProjectInviteListItemStyled = styled.button`
  display: flex;
  align-items: stretch;
  padding: 14px ${PADDING.DEFAULT};
  background-color: transparent;
  transition: ${TRANSITION.base};
  ${flexGapString(16, 'column')}

  :active {
    background-color: ${alpha(COLOR.primary.lighter, 0.25)};
  }
`

const ThumbnailWrapperStyled = styled.div`
  width: 120px;
  min-height: 70px;
  border-radius: ${BORDER_RADIUS.base};
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
`

const ThumbnailStyled = styled(IonImg)`
  position: absolute;
  inset: 0;
  z-index: 0;

  ::part(image) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const InviteDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(6, 'row')}
  flex: 1;
  text-align: left;
  line-height: ${FONT.lineHeight.base};
  font-size: ${FONT.size.base};
  color: ${COLOR.neutral.dark};

  p {
    margin: 0;
  }

  strong {
    font-weight: ${FONT.weight.semibold};
    color: ${COLOR.neutral.darkest};
  }
`

const InviteTimeStyled = styled.span`
  font-size: ${FONT.size.sm};
  color: ${COLOR.neutral.DEFAULT};
  line-height: ${FONT.lineHeight.sm};
`

export default function ProjectInviteListItem(props: ProjectInviteListItemProps) {
  const { invite } = props
  const { t, i18n } = useTranslation()

  const ionRouter = useIonRouter()

  return (
    <li>
      <ProjectInviteListItemStyled
        onClick={() => ionRouter.push(ROUTES.viewProjectInvite(invite.id))}
      >
        <ThumbnailWrapperStyled>
          <ThumbnailStyled
            src={invite.project.thumbnail?.sourceUrl || './img/default-thumbnail.png'}
          />
        </ThumbnailWrapperStyled>
        <InviteDataStyled>
          <p
            dangerouslySetInnerHTML={{
              __html: t('Invites.inviteText', {
                name: `<strong>${getFullName(invite.project.creator)}</strong>`,
                projectName: `<strong>${invite.project.title}</strong>`,
              }),
            }}
          />
          <InviteTimeStyled>
            {formatDistanceToNow(new Date(invite.project.created), {
              addSuffix: true,
              locale: getDateFnsLocale(i18n.language),
            })}
          </InviteTimeStyled>
        </InviteDataStyled>
      </ProjectInviteListItemStyled>
    </li>
  )
}
