import { useTranslation } from 'react-i18next'

import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'

import type { ActionSheetModalProps } from '../../../components/ui/ActionSheetModal'

interface Props extends ActionSheetModalProps {
  onSelectEdit: () => void
  onSelectDelete: () => void
}

export default function QuestionActionSheet(props: Props) {
  const { isOpen, onClose, onSelectEdit, onSelectDelete } = props

  const { t } = useTranslation()

  return (
    <ActionSheetModal isOpen={isOpen} onClose={onClose}>
      <ButtonGroup>
        <Button variant="secondary" onClick={onSelectEdit}>
          {t('ProjectDashboard.Questions.edit')}
        </Button>
        <Button variant="danger" onClick={onSelectDelete}>
          {t('ProjectDashboard.Questions.delete')}
        </Button>
      </ButtonGroup>
    </ActionSheetModal>
  )
}
