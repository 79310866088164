import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgPlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      enableBackground="new 0 0 24 24"
      fill="currentColor"
      width={24}
      height={24}
      className="icon"
      {...props}
    >
      <g id="Layer_1">
        <g>
          <path d="M12.8,4.8C12.8,4.3,12.4,4,12,4s-0.8,0.3-0.8,0.8v6.5H4.8C4.3,11.3,4,11.6,4,12s0.3,0.8,0.8,0.8h6.5v6.5 c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-6.5h6.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-6.5V4.8z" />
        </g>
        <g>
          <path d="M12.8,4.8C12.8,4.3,12.4,4,12,4s-0.8,0.3-0.8,0.8v6.5H4.8C4.3,11.3,4,11.6,4,12s0.3,0.8,0.8,0.8h6.5v6.5 c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-6.5h6.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-6.5V4.8z" />
        </g>
      </g>
    </svg>
  )
}
const Memo = memo(SvgPlus)
export default Memo
