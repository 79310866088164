import { registerPlugin } from '@capacitor/core'

import type { OneSignalIntegrationPlugin } from './definitions'

const OneSignalIntegration = registerPlugin<OneSignalIntegrationPlugin>('OneSignalIntegration', {
  web: () => import('./web').then((m) => new m.OneSignalIntegrationWeb()),
})

export * from './definitions'
export { OneSignalIntegration }
