import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgFlag(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_152_339)">
        <path
          fillRule="evenodd"
          d="M1.50001 0.75C1.50001 0.335786 1.16423 0 0.750015 0C0.335801 0 1.46502e-05 0.335786 1.46502e-05 0.75V3.62761C-0.000248244 3.63901 -0.000249636 3.65045 1.46502e-05 3.66191V17.6906C-0.000248327 17.702 -0.00024972 17.7134 1.46502e-05 17.7249V23.25C1.46502e-05 23.6642 0.335801 24 0.750015 24C1.16423 24 1.50001 23.6642 1.50001 23.25V18.262L4.1247 17.4548C5.32924 17.084 6.59905 16.9744 7.84932 17.1334C9.09942 17.2923 10.3012 17.7161 11.3745 18.3765C12.5993 19.1314 13.9678 19.623 15.393 19.8201C16.8182 20.0172 18.2688 19.9155 19.6525 19.5213L19.6532 19.5211L22.6402 18.6671C23.032 18.5552 23.3767 18.3186 23.622 17.9933C23.8674 17.6679 24.0001 17.2713 24 16.8638V5.13701L24 5.13631C23.9997 4.84628 23.9322 4.56027 23.8028 4.30073C23.6733 4.0412 23.4855 3.81522 23.254 3.64053C23.0224 3.46584 22.7536 3.34721 22.4685 3.29396C22.1834 3.24071 21.8898 3.25428 21.6109 3.33361L19.2409 4.01088C18.0579 4.34878 16.8175 4.43672 15.5986 4.2691C14.3796 4.10147 13.209 3.68195 12.161 3.03722C10.9059 2.2649 9.50047 1.76925 8.03854 1.58335C6.57674 1.39746 5.09211 1.52556 3.68376 1.95908L1.50001 2.62999V0.75ZM1.50001 4.19919V16.6927L3.68334 16.0212C5.09175 15.5876 6.57667 15.4594 8.03854 15.6453C9.50049 15.8312 10.9059 16.3269 12.1611 17.0993L12.1615 17.0995C13.2091 17.7452 14.3796 18.1657 15.5985 18.3343C16.8174 18.5028 18.058 18.4158 19.2414 18.0787L22.2278 17.2249C22.3062 17.2025 22.3753 17.1552 22.4244 17.0901C22.4735 17.025 22.5 16.9457 22.5 16.8642V5.13771C22.5 5.07958 22.4864 5.02226 22.4605 4.97024C22.4345 4.91822 22.3969 4.87293 22.3505 4.83792C22.3041 4.80291 22.2502 4.77913 22.1931 4.76846C22.136 4.75781 22.0774 4.76049 22.0216 4.77629L22.0212 4.7764L19.6531 5.45314C18.2698 5.84824 16.8194 5.9511 15.3942 5.75511C13.969 5.55912 12.6003 5.06862 11.375 4.3148C10.3016 3.65428 9.0996 3.23035 7.84932 3.07136C6.59905 2.91238 5.32924 3.02196 4.1247 3.39281L1.50001 4.19919Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_339">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgFlag)
export default Memo
