import { Capacitor } from '@capacitor/core'
import styled from '@emotion/styled'
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  useIonToast,
} from '@ionic/react'
import { alertCircle } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import { FadingEnterLeaveTransition } from '../../../components/common/Transitions'
import Page from '../../../components/layout/Page'
import PageHeader from '../../../components/layout/PageHeader'
import SelectionButton from '../../../components/ui/Button/SelectionButton'
import Flex from '../../../components/ui/Flex'
import { useApiContext } from '../../../context/ApiContext'
import { useAppConfig } from '../../../context/AppConfigContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useBlogPosts } from '../../../hooks/useBlogPosts'
import { useProjectInvites } from '../../../hooks/useProjectInvites'
import { useProjects } from '../../../hooks/useProjects'
import LogoWinkii from '../../../icons/custom/LogoWinkii'
import { Clapperboard, Teamwork } from '../../../illustrations'
import { IronSourceIntegration } from '../../../plugins/IronSourceIntegration'
import { COLOR } from '../../../theme'
import { ProjectStatus } from '../../../types'
import { flexGapString } from '../../../utils/css'
import {
  REACT_APP_IRON_SOURCE_APP_KEY_ANDROID,
  REACT_APP_IRON_SOURCE_APP_KEY_IOS,
} from '../../../utils/env'
import { setNavigationBarLight } from '../../../utils/navigationBar'
import { setStatusbarLight } from '../../../utils/statusBar'
import { isInWeb } from '../../../utils/user'
import { ClaimFreeCredits } from '../../Project/Dashboard/ClaimFreeCredits'

import BlogPosts from './BlogPosts/BlogPosts'
import DashboardInvites from './Invites/DashboardInvites'
import DashboardProjects from './Projects/DashboardProjects'

import type { RefresherEventDetail } from '@ionic/react'

const ContentStyled = styled(IonContent)`
  --background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.7)),
    url('/img/background-blobs.png') no-repeat top center / cover;
`

const DashboardContentStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${flexGapString(56, 'row')}
`

export default function Dashboard() {
  const { t } = useTranslation()

  const [presentToast] = useIonToast()

  const {
    data: posts,
    refetch: refetchPosts,
    isError: isErrorPosts,
    isInitialLoading: isLoadingPosts,
  } = useBlogPosts()

  const {
    data: projects,
    refetch: refetchProjects,
    isError: isErrorProjects,
    isInitialLoading: isLoadingProjects,
  } = useProjects({ limit: 4, createdByUserOnly: false })

  const { refetchConfig } = useAppConfig()
  const { userIsGuest, user, redirectToAfterLogin, setRedirectToAfterLogin } = useAuthContext()

  const { data: invites, refetch: refetchInvites, isError: isErrorInvites } = useProjectInvites()

  const hasInvites = !!invites && invites?.length > 0

  const refresh = async () => {
    await refetchConfig()
    return Promise.all([refetchPosts(), refetchProjects(), refetchInvites()])
  }

  async function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    await refresh()
    event.detail.complete()
  }

  useEffect(() => {
    if (isErrorPosts || isErrorProjects || isErrorInvites) {
      void presentToast({
        message: t('Dashboard.error'),
        icon: alertCircle,
        duration: 2000,
        position: 'middle',
      })
    }
  }, [isErrorInvites, isErrorPosts, isErrorProjects, presentToast, t])

  const hasBlogPosts = !!posts?.items.length && posts.items.length > 0

  useEffect(() => {
    const init = async () => {
      setStatusbarLight()
      setNavigationBarLight()
      await refresh()

      if (Capacitor.isNativePlatform())
        void IronSourceIntegration.initializeIronSource({
          appKey:
            Capacitor.getPlatform() === 'ios'
              ? REACT_APP_IRON_SOURCE_APP_KEY_IOS
              : REACT_APP_IRON_SOURCE_APP_KEY_ANDROID,
        })

      if (redirectToAfterLogin.current) {
        setRedirectToAfterLogin(undefined)
      }
    }

    void init()
  }, [])

  const filteredProjects =
    projects?.items.filter((project) => ![ProjectStatus.Deleted].includes(project.status)) || []

  const ionRouter = useIonRouter()

  const { apiClient } = useApiContext()
  const [showClaimCreditsModal, setShowClaimCreditsModal] = useState(false)

  useEffect(() => {
    if (user && !userIsGuest)
      void apiClient
        .getCanClaimFreeCredits()
        .catch(() => {
          return null
        })
        .then((res) => {
          if (res) setShowClaimCreditsModal(res)
        })
  }, [user])

  if (redirectToAfterLogin.current) {
    return (
      <Redirect
        to={{
          pathname: redirectToAfterLogin.current.path,
          search: redirectToAfterLogin.current.search,
        }}
      />
    )
  }

  return (
    <Page safeArea={false}>
      <PageHeader title={<LogoWinkii height={32} />} showAvatar={true} />
      <ContentStyled
        className="ion-padding"
        style={{
          height: 'max-content',
        }}
      >
        <IonRefresher slot="fixed" onIonRefresh={(e) => void handleRefresh(e)}>
          <IonRefresherContent refreshingSpinner={DEFAULT_SPINNER} />
        </IonRefresher>
        <DashboardContentStyled style={{ height: '100%' }}>
          <FadingEnterLeaveTransition show={hasInvites}>
            <DashboardInvites invites={invites} />
          </FadingEnterLeaveTransition>
          <FadingEnterLeaveTransition show={!isErrorProjects}>
            <DashboardProjects projects={filteredProjects} isLoading={isLoadingProjects} />
          </FadingEnterLeaveTransition>
          {filteredProjects.length !== 0 && <div style={{ flexGrow: 1 }} />}
          <Flex direction="column" gap={12}>
            <SelectionButton
              style={{
                background: COLOR.primary.DEFAULT,
                color: COLOR.neutral.darkest,
                border: `1px solid transparent`,
                boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                padding: '10px 6px',
              }}
              title={t('NewProject.createFromScratch')}
              image={<Clapperboard style={{ filter: 'drop-shadow(5px)' }} />}
              onClick={() =>
                ionRouter.push(isInWeb() ? ROUTES.downloadsLinks : ROUTES.createProject)
              }
            />
            <SelectionButton
              style={{
                border: `1px solid transparent`,
                boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                padding: '10px 6px',
              }}
              title={t('NewProject.joinProject')}
              image={<Teamwork />}
              onClick={() => ionRouter.push(ROUTES.joinProject)}
            />
            <ClaimFreeCredits show={showClaimCreditsModal} setShow={setShowClaimCreditsModal} />
          </Flex>
          <FadingEnterLeaveTransition show={!isErrorPosts && hasBlogPosts}>
            <BlogPosts posts={posts?.items} isLoading={isLoadingPosts} />
          </FadingEnterLeaveTransition>
        </DashboardContentStyled>
      </ContentStyled>
    </Page>
  )
}
