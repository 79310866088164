import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgPhoneError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.125 -0.125 100.25 100.25"
      height={100}
      width={100}
      strokeWidth="1.25"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M87.541 43.29C87.5055 43.5434 87.4204 43.7872 87.2907 44.0077C87.1609 44.2282 86.9889 44.4209 86.7846 44.5749C86.5803 44.7289 86.3476 44.8411 86.0999 44.9051C85.8522 44.9691 85.5943 44.9837 85.341 44.948L46.776 39.54C46.2644 39.4681 45.8023 39.1959 45.4914 38.7833C45.1804 38.3708 45.0461 37.8516 45.118 37.34L49.317 7.39998C49.4008 6.80112 49.623 6.23003 49.966 5.73198L51.231 3.89398C51.4362 3.59634 51.7201 3.36164 52.0511 3.21613C52.382 3.07061 52.7469 3.02001 53.105 3.06998L64.22 4.62698C64.579 4.67626 64.9171 4.82481 65.1963 5.05591C65.4755 5.28701 65.6845 5.59146 65.8 5.93498L66.8 8.92098L89.94 12.166C90.1933 12.2014 90.4372 12.2865 90.6576 12.4163C90.878 12.5461 91.0707 12.7181 91.2246 12.9224C91.3785 13.1268 91.4906 13.3594 91.5545 13.6071C91.6184 13.8548 91.6328 14.1127 91.597 14.366L87.541 43.29Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M48.5028 13.2882L44.9915 38.2966C44.9147 38.8434 45.2957 39.3489 45.8425 39.4256L86.2721 45.1021C86.8189 45.1789 87.3243 44.7979 87.4011 44.2511L90.9124 19.2427C90.9891 18.6959 90.6081 18.1905 90.0614 18.1137L49.6318 12.4372C49.085 12.3605 48.5795 12.7415 48.5028 13.2882Z"
      />
      <path
        stroke={COLOR.black}
        strokeDasharray="2 3"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M52.1 68.8829C61.119 63.0579 66.951 53.1449 67.818 44.4739"
      />
      <path
        fill={COLOR.white}
        d="M53.752 87.0809C53.874 87.5736 53.8978 88.0856 53.822 88.5875C53.7462 89.0894 53.5722 89.5715 53.3101 90.0061C53.0479 90.4408 52.7027 90.8196 52.2942 91.1209C51.8856 91.4221 51.4217 91.6399 50.929 91.7619L31.473 96.5769C30.9803 96.6989 30.4685 96.7227 29.9666 96.6469C29.4648 96.571 28.9828 96.3971 28.5482 96.1349C28.1136 95.8728 27.7349 95.5276 27.4337 95.119C27.1326 94.7105 26.9148 94.2466 26.793 93.7539L16.466 52.0279C16.344 51.5353 16.3202 51.0235 16.3961 50.5217C16.4719 50.02 16.6459 49.538 16.908 49.1035C17.1702 48.669 17.5154 48.2904 17.9239 47.9893C18.3325 47.6883 18.7963 47.4706 19.289 47.3489L38.745 42.5319C39.7399 42.2863 40.7916 42.4456 41.6692 42.975C42.5467 43.5043 43.1782 44.3603 43.425 45.3549L53.752 87.0809Z"
      />
      <path
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.752 87.0809C53.874 87.5736 53.8978 88.0856 53.822 88.5875C53.7462 89.0894 53.5722 89.5715 53.3101 90.0061C53.0479 90.4408 52.7027 90.8196 52.2942 91.1209C51.8856 91.4221 51.4217 91.6399 50.929 91.7619L31.473 96.5769C30.9803 96.6989 30.4685 96.7227 29.9666 96.6469C29.4648 96.571 28.9828 96.3971 28.5482 96.1349C28.1136 95.8728 27.7349 95.5276 27.4337 95.119C27.1326 94.7105 26.9148 94.2466 26.793 93.7539L16.466 52.0279C16.344 51.5353 16.3202 51.0235 16.3961 50.5217C16.4719 50.02 16.6459 49.538 16.908 49.1035C17.1702 48.669 17.5154 48.2904 17.9239 47.9893C18.3325 47.6883 18.7963 47.4706 19.289 47.3489L38.745 42.5319C39.7399 42.2863 40.7916 42.4456 41.6692 42.975C42.5467 43.5043 43.1782 44.3603 43.425 45.3549L53.752 87.0809Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M41.8934 49.2164L19.6224 54.7288L28.1808 89.3064L50.4517 83.794L41.8934 49.2164Z"
      />
      <path
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M41.8934 49.2164L19.6224 54.7288L28.1808 89.3064L50.4517 83.794L41.8934 49.2164Z"
      />
      <path
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M28.715 48.7469L35.162 47.1509"
      />
      <path
        fill={COLOR.black}
        d="M25.926 49.437C25.9616 49.581 25.9537 49.7324 25.9033 49.8719C25.853 50.0114 25.7623 50.1329 25.6429 50.2209C25.5235 50.3089 25.3807 50.3595 25.2325 50.3663C25.0843 50.3732 24.9374 50.3359 24.8104 50.2592C24.6834 50.1826 24.582 50.07 24.519 49.9357C24.456 49.8014 24.4342 49.6514 24.4564 49.5048C24.4786 49.3581 24.5438 49.2213 24.6438 49.1117C24.7438 49.0021 24.874 48.9246 25.018 48.889C25.1136 48.8651 25.213 48.8604 25.3105 48.875C25.4079 48.8896 25.5015 48.9234 25.5859 48.9743C25.6703 49.0252 25.7438 49.0923 25.8021 49.1717C25.8605 49.2511 25.9026 49.3413 25.926 49.437Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.259 92.4728C41.426 92.4728 42.372 91.5268 42.372 90.3598C42.372 89.1928 41.426 88.2468 40.259 88.2468C39.092 88.2468 38.146 89.1928 38.146 90.3598C38.146 91.5268 39.092 92.4728 40.259 92.4728Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.16 73.956C39.9124 74.0177 39.655 74.0298 39.4027 73.9915C39.1504 73.9532 38.9082 73.8654 38.69 73.733L35.7 71.927L33.894 74.921C33.628 75.3621 33.1976 75.6794 32.6977 75.8032C32.1977 75.9269 31.669 75.847 31.228 75.581C30.7869 75.315 30.4696 74.8847 30.3458 74.3847C30.2221 73.8847 30.302 73.3561 30.568 72.915L32.374 69.922L29.38 68.115C28.9499 67.8439 28.6432 67.4148 28.5259 66.9201C28.4087 66.4254 28.4901 65.9043 28.7528 65.469C29.0154 65.0336 29.4383 64.7186 29.9307 64.5917C30.4231 64.4647 30.9456 64.5359 31.386 64.79L34.377 66.6L36.183 63.6C36.312 63.3763 36.4844 63.1806 36.69 63.0244C36.8955 62.8681 37.1302 62.7544 37.3803 62.6899C37.6303 62.6254 37.8907 62.6115 38.1462 62.6489C38.4017 62.6862 38.6472 62.7742 38.8683 62.9076C39.0894 63.041 39.2817 63.2171 39.4339 63.4258C39.5861 63.6344 39.6952 63.8712 39.7548 64.1225C39.8143 64.3738 39.8232 64.6344 39.7808 64.8891C39.7384 65.1438 39.6457 65.3876 39.508 65.606L37.7 68.6L40.694 70.4C41.0223 70.5988 41.2845 70.8904 41.4474 71.238C41.6103 71.5856 41.6666 71.9737 41.6093 72.3532C41.5519 72.7328 41.3835 73.0869 41.1253 73.3709C40.867 73.6548 40.5304 73.856 40.158 73.949L40.16 73.956Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M62.093 37.8689C61.9029 37.8417 61.7215 37.7715 61.5627 37.6636C61.4039 37.5558 61.2717 37.4131 61.1764 37.2465C61.081 37.0798 61.0249 36.8936 61.0123 36.702C60.9998 36.5104 61.0311 36.3185 61.104 36.1409C61.6399 34.8573 62.5868 33.788 63.7961 33.1008C65.0054 32.4137 66.4087 32.1475 67.7856 32.344C69.1626 32.5406 70.4353 33.1888 71.404 34.187C72.3728 35.1851 72.9826 36.4766 73.138 37.8589C73.1581 38.0498 73.1345 38.2428 73.0688 38.4232C73.0032 38.6036 72.8972 38.7666 72.759 38.8999C72.6208 39.0331 72.4541 39.1331 72.2714 39.1922C72.0887 39.2513 71.895 39.2679 71.705 39.2409C70.171 38.677 68.5934 38.2399 66.988 37.9339C65.3608 37.7786 63.7237 37.7568 62.093 37.8689V37.8689Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M78.086 29.1561C77.8847 29.156 77.6861 29.1092 77.506 29.0193C77.3259 28.9294 77.1691 28.7989 77.048 28.6381L76.022 27.2711L74.655 28.3001C74.4314 28.4686 74.1589 28.5595 73.879 28.5591C73.8171 28.5593 73.7552 28.5549 73.694 28.5461C73.4411 28.5083 73.2049 28.3967 73.0151 28.2253C72.8253 28.054 72.6902 27.8304 72.6268 27.5827C72.5634 27.3349 72.5745 27.074 72.6586 26.8325C72.7428 26.591 72.8963 26.3798 73.1 26.2251L74.467 25.2001L73.441 23.8331C73.2628 23.5568 73.196 23.2232 73.2539 22.8996C73.3118 22.576 73.4901 22.2864 73.7531 22.089C74.016 21.8916 74.3439 21.8012 74.6708 21.8359C74.9978 21.8706 75.2994 22.0279 75.515 22.2761L76.541 23.6441L77.908 22.6181C78.1319 22.4496 78.4047 22.3587 78.685 22.3591C78.8862 22.3596 79.0846 22.4067 79.2646 22.4965C79.4447 22.5864 79.6016 22.7166 79.723 22.8771C79.9287 23.1525 80.0169 23.4982 79.9684 23.8386C79.9199 24.1789 79.7385 24.4861 79.464 24.6931L78.1 25.7151L79.125 27.0821C79.2679 27.275 79.3547 27.5037 79.3756 27.7428C79.3965 27.982 79.3508 28.2223 79.2435 28.437C79.1362 28.6518 78.9716 28.8327 78.7678 28.9596C78.564 29.0865 78.329 29.1545 78.089 29.1561H78.086Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M63.359 27.055C63.2981 27.0552 63.2372 27.0508 63.177 27.042C63.0081 27.0182 62.8456 26.9613 62.6989 26.8745C62.5521 26.7876 62.424 26.6726 62.322 26.536L61.3 25.17L59.93 26.2C59.7056 26.3684 59.4325 26.4593 59.152 26.459C59.0911 26.4592 59.0302 26.4548 58.97 26.446C58.7166 26.4091 58.4797 26.2982 58.2891 26.1272C58.0985 25.9563 57.9626 25.7328 57.8986 25.4849C57.8345 25.237 57.845 24.9757 57.9289 24.7337C58.0127 24.4918 58.1662 24.28 58.37 24.125L59.74 23.1L58.715 21.732C58.5717 21.539 58.4847 21.3101 58.4637 21.0707C58.4426 20.8313 58.4884 20.5908 58.5958 20.3758C58.7033 20.1608 58.8682 19.9798 59.0723 19.853C59.2764 19.7261 59.5116 19.6582 59.752 19.657C59.9533 19.6574 60.1517 19.7047 60.3317 19.7949C60.5116 19.8851 60.6682 20.0159 60.789 20.177L61.815 21.543L63.182 20.518C63.4056 20.3487 63.6785 20.2573 63.959 20.258C64.1604 20.2571 64.3594 20.3031 64.54 20.3923C64.7207 20.4815 64.8781 20.6115 65 20.772C65.2056 21.0475 65.2937 21.3933 65.2449 21.7337C65.1962 22.074 65.0146 22.3812 64.74 22.588L63.374 23.612L64.4 24.979C64.6057 25.2544 64.6939 25.6001 64.6454 25.9405C64.5969 26.2808 64.4155 26.588 64.141 26.795C63.9159 26.9652 63.6411 27.0565 63.359 27.055V27.055Z"
      />
    </svg>
  )
}
const Memo = memo(SvgPhoneError)
export default Memo
