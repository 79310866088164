import { IonSpinner, useIonRouter, useIonViewWillEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import ProjectSubpage from '../../../components/layout/ProjectSubpage'
import OpenQuestions from '../../../components/projects/questions/OpenQuestions'
import EmptyState from '../../../components/ui/EmptyState/EmptyState'
import Flex from '../../../components/ui/Flex'
import { useLocalAnswers } from '../../../hooks/useLocalAnswers'
import { useProjectQuestions } from '../../../hooks/useProjectQuestions'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { Clapperboard } from '../../../illustrations'
import ProjectDashboardItem from '../Dashboard/ProjectDashboardItem'

import LocalAnswerItem from './LocalAnswerItem'

import type { LocalAnswer } from '../../../hooks/useLocalAnswers'
import type { ProjectDto, ProjectQuestionDto } from '../../../types'
import type { FC } from 'react'

const Content: FC<{
  isLoading: boolean
  isError: boolean
  hasQuestions: boolean
  project?: ProjectDto
  questions?: ProjectQuestionDto[]
  localAnswers: LocalAnswer[]
}> = (props) => {
  const { isLoading, isError, hasQuestions, project, questions, localAnswers } = props

  const { t } = useTranslation()

  if (isLoading) return <IonSpinner name={DEFAULT_SPINNER} />

  if (isError || !hasQuestions || !project) {
    return (
      <EmptyState illustration={<Clapperboard />}>{t('Project.Studio.noQuestions')}</EmptyState>
    )
  }

  return (
    <OpenQuestions
      project={project}
      questions={questions}
      localAnswers={localAnswers}
      showLocalAnswersMessage={false}
    />
  )
}

export default function ProjectStudio() {
  const { id } = useParams<{ id: string }>()

  const ionRouter = useIonRouter()
  const { t } = useTranslation()

  const { project, refetch: refetchProject } = useSingleProject(id)

  const { questions, isLoading, isError, refetch: refetchQuestions } = useProjectQuestions(id)
  const hasQuestions = !!questions && questions.length > 0

  const { localAnswers, refetch: refetchLocalAnswers } = useLocalAnswers(id)

  const refetch = () => Promise.all([refetchQuestions(), refetchLocalAnswers(), refetchProject()])

  const hasLocalAnswers = localAnswers.length > 0

  useIonViewWillEnter(() => {
    if (!project) ionRouter.push(ROUTES.projectDashboard(id), 'back', 'pop')

    void refetch()
  })

  if (!project) return null

  return (
    <ProjectSubpage project={project}>
      <Flex gap={40} direction="column">
        <Content
          isLoading={isLoading}
          isError={isError}
          hasQuestions={hasQuestions}
          project={project}
          questions={questions}
          localAnswers={localAnswers}
        />
        {hasLocalAnswers && (
          <ProjectDashboardItem title={t('Project.Studio.recorded')}>
            <Flex direction="column" gap={16}>
              {localAnswers.map((localAnswer) => {
                return (
                  <LocalAnswerItem
                    key={localAnswer.questionId}
                    answer={localAnswer}
                    receiverName={project.receiverName}
                    projectId={id}
                    onDoneUpload={() => void refetch()}
                    onDelete={() => void refetch()}
                  />
                )
              })}
            </Flex>
          </ProjectDashboardItem>
        )}
      </Flex>
    </ProjectSubpage>
  )
}
