import { Capacitor } from '@capacitor/core'
import styled from '@emotion/styled'
import { IonContent } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'usehooks-ts'

import SubpageHeader from '../../components/layout/SubpageHeader'
import SelectionButton from '../../components/ui/Button/SelectionButton'
import Flex from '../../components/ui/Flex'
import IconButton from '../../components/ui/IconButton/IconButton'
import { CloseCircle } from '../../icons'
import { Clapperboard, Teamwork } from '../../illustrations'
import { IronSourceIntegration } from '../../plugins/IronSourceIntegration'
import { FONT } from '../../theme'

const NewProjectWrapper = styled(Flex)`
  padding-top: 32px;
`

const HeaderWrapper = styled(Flex)`
  text-align: center;
`

const TitleStyled = styled.h1`
  margin: 0;
  font-size: ${FONT.size.xl};
  font-weight: ${FONT.weight.semibold};
`

const SubtitleStyled = styled.p`
  margin: 0;
`

interface NewProjectProps {
  onDismiss: () => void
  onSelectCreate: () => void
  onSelectJoin: () => void
}

export default function NewProject(props: NewProjectProps) {
  const { t } = useTranslation()
  const { onDismiss, onSelectCreate, onSelectJoin } = props

  useEffectOnce(() => {
    if (Capacitor.isNativePlatform()) void IronSourceIntegration.loadInterstitial()
  })

  return (
    <>
      <SubpageHeader
        showBackButton={false}
        button={
          <IconButton
            icon={<CloseCircle />}
            title={t('General.back')}
            onClick={() => onDismiss()}
          />
        }
      />
      <IonContent className="ion-padding">
        <NewProjectWrapper direction="column" gap={32}>
          <HeaderWrapper direction="column" gap={8}>
            <TitleStyled>{t('NewProject.title')}</TitleStyled>
            <SubtitleStyled>{t('NewProject.chooseOption')}</SubtitleStyled>
          </HeaderWrapper>
          <Flex direction="column" gap={12}>
            <SelectionButton
              title={t('NewProject.createFromScratch')}
              image={<Clapperboard />}
              onClick={onSelectCreate}
            />
            <SelectionButton
              title={t('NewProject.joinProject')}
              image={<Teamwork />}
              onClick={onSelectJoin}
            />
          </Flex>
        </NewProjectWrapper>
      </IonContent>
    </>
  )
}
