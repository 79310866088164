import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCloseCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_58_1300)">
        <path
          fillRule="evenodd"
          d="M12 1.49902C6.20101 1.49902 1.5 6.20003 1.5 11.999C1.5 17.798 6.20101 22.499 12 22.499C17.799 22.499 22.5 17.798 22.5 11.999C22.5 6.20003 17.799 1.49902 12 1.49902ZM0 11.999C0 5.37161 5.37258 -0.000976562 12 -0.000976562C18.6274 -0.000976562 24 5.37161 24 11.999C24 18.6264 18.6274 23.999 12 23.999C5.37258 23.999 0 18.6264 0 11.999Z"
          clipRule="evenodd"
        />
        <path d="M17.0303 8.02938C17.3232 7.73648 17.3232 7.26161 17.0303 6.96872C16.7374 6.67582 16.2626 6.67582 15.9697 6.96872L12 10.9384L8.03033 6.96872C7.73744 6.67582 7.26256 6.67582 6.96967 6.96872C6.67678 7.26161 6.67678 7.73648 6.96967 8.02938L10.9393 11.999L6.96967 15.9687C6.67678 16.2616 6.67678 16.7365 6.96967 17.0294C7.26256 17.3223 7.73744 17.3223 8.03033 17.0294L12 13.0597L15.9697 17.0294C16.2626 17.3223 16.7374 17.3223 17.0303 17.0294C17.3232 16.7365 17.3232 16.2616 17.0303 15.9687L13.0607 11.999L17.0303 8.02938Z" />
      </g>
      <defs>
        <clipPath id="clip0_58_1300">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgCloseCircle)
export default Memo
