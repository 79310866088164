import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR } from '../../../theme'
import { alpha } from '../../../utils/colors'

import type { ProgressHTMLAttributes } from 'react'

const ProgressBarStyled = styled.progress`
  border-radius: ${BORDER_RADIUS.base};
  appearance: none;
  width: 100%;
  height: 4px;
  background-color: transparent;
  border: none;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ::-webkit-progress-bar {
    background-color: transparent;
  }

  ::-webkit-progress-value {
    background-color: ${alpha(COLOR.white, 0.5)};
    transition: all 0.1s ease-in-out;
    border-radius: ${BORDER_RADIUS.base};
  }

  ::-moz-progress-bar {
    background-color: ${alpha(COLOR.white, 0.5)};
    transition: all 0.1s ease-in-out;
    border-radius: ${BORDER_RADIUS.base};
  }
`

export default function ProgressBar(props: ProgressHTMLAttributes<HTMLProgressElement>) {
  return <ProgressBarStyled {...props} />
}
