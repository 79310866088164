import { useTranslation } from 'react-i18next'

import AttachmentItem from '../../../components/projects/attachments/AttachmentItem'
import EmptyAttachmentItem from '../../../components/projects/attachments/EmptyAttachmentItem'
import Grid from '../../../components/ui/Grid'
import { useAuthContext } from '../../../context/AuthContext'

import ProjectDashboardItem from './ProjectDashboardItem'

import type { ProjectAttachmentDto } from '../../../types'

interface Props {
  attachments: ProjectAttachmentDto[]
  projectId: string
  onSaved: () => void
}

export default function Attachments(props: Props) {
  const { attachments = [], projectId, onSaved } = props

  const { t } = useTranslation()

  const { user } = useAuthContext()

  if (!user) return null

  const filteredAttachments = attachments.filter(
    (attachment) => attachment.submittedBy.id === user.id,
  )

  const MAX_ATTACHMENTS = 3

  const availableAttachmentsAmount = MAX_ATTACHMENTS - filteredAttachments.length

  const range = Array.from(Array(availableAttachmentsAmount).keys())

  return (
    <ProjectDashboardItem
      title={t('ProjectDashboard.Attachments.title')}
      subtitle={t('ProjectDashboard.Attachments.description')}
    >
      <Grid columns={3} gap={8}>
        {filteredAttachments.map((attachment, index) => (
          <AttachmentItem
            key={attachment.id}
            attachment={attachment}
            attachments={filteredAttachments}
            index={index}
            onDelete={onSaved}
          />
        ))}
        {range.map((index) => (
          <EmptyAttachmentItem key={index} projectId={projectId} onSaved={onSaved} />
        ))}
      </Grid>
    </ProjectDashboardItem>
  )
}
