import { css } from '@emotion/css'
import { useMemo } from 'react'

import { ChevronRight } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../../theme'
import { BRAND_COLORS } from '../../../utils/colors'
import { parseReceiverName } from '../../../utils/textParsing'
import Flex from '../../ui/Flex'
import IconButton from '../../ui/IconButton/IconButton'
import { MessageCardIconStyled } from '../../ui/MessageCard'
import Text from '../../ui/Text'

import { VideoCameraIcon } from './OpenQuestions'

import type { ProjectQuestionDto } from '../../../types'

interface Props {
  question: ProjectQuestionDto
  receiverName: string
  onClick: () => void
}

export default function OpenQuestionItem(props: Props) {
  const { question, receiverName, onClick } = props

  const color = COLOR[BRAND_COLORS[0]].light

  const title = useMemo(
    () => parseReceiverName(question.title, receiverName || ''),
    [question.title, receiverName],
  )

  return (
    <button
      key={question.id}
      className={css({
        'width': '100%',
        'textAlign': 'left',
        'transition': TRANSITION.base,
        'border': `1px solid ${COLOR.neutral.lighter}`,
        'backgroundColor': COLOR.white,
        'box-shadow': SHADOW.base,
        ':active': { transform: 'scale(0.99)' },
        'borderRadius': BORDER_RADIUS.sm,
        'padding': '2px',
        'paddingRight': '10px',
      })}
      onClick={onClick}
    >
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          justifyContent="center"
          className={css({
            flexShrink: 0,
          })}
        >
          <IconButton
            variant="primary"
            title=""
            icon={
              <MessageCardIconStyled backgroundColor={color}>
                <VideoCameraIcon height={20} width={20} />
              </MessageCardIconStyled>
            }
            className={css({
              background: 'none',
            })}
          />
        </Flex>
        <Text
          size={FONT.size.base}
          weight={FONT.weight.normal}
          className={css({ flex: 1, lineHeight: 1.25 })}
        >
          {title}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          className={css({
            flexShrink: 0,
            borderRadius: BORDER_RADIUS.full,
            color: COLOR.neutral.dark,
            marginRight: '5px',
          })}
        >
          <ChevronRight height={20} width={20} />
        </Flex>
      </Flex>
    </button>
  )
}
