import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { BORDER_RADIUS, COLOR } from '../../../theme'

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

interface AvatarProps {
  src?: string
  alt: string
  size?: AvatarSize
}

const AVATAR_SIZES: Record<AvatarSize, number> = {
  'xs': 24,
  'sm': 32,
  'md': 40,
  'lg': 48,
  'xl': 64,
  '2xl': 128,
}

const AvatarStyled = styled.span<{ size: AvatarSize }>`
  width: ${({ size }) => AVATAR_SIZES[size]}px;
  height: ${({ size }) => AVATAR_SIZES[size]}px;
  border-radius: ${BORDER_RADIUS.full};
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    object-fit: cover;
  }
  * > {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export default function Avatar(props: AvatarProps) {
  const { src, alt, size = 'md' } = props

  const [hasImage, setHasImage] = useState(!!src)

  useEffect(() => {
    setHasImage(!!src)
  }, [src])

  return (
    <AvatarStyled size={size} aria-label={alt}>
      {hasImage ? (
        <img
          width={AVATAR_SIZES[size]}
          height={AVATAR_SIZES[size]}
          src={src}
          alt={alt}
          onError={() => setHasImage(false)}
        />
      ) : (
        <svg
          aria-hidden={true}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width={AVATAR_SIZES[size]}
          height={AVATAR_SIZES[size]}
        >
          <path
            d="M32 14.7692C28.9662 14.7692 26.2231 15.9062 24.2738 17.9723C22.3077 20.06 21.3446 22.8969 21.5708 25.9615C22.0169 32 26.7031 36.9231 32 36.9231C37.2969 36.9231 41.9738 32 42.4292 25.9631C42.6569 22.9231 41.6923 20.0954 39.7092 17.9908C37.7523 15.9138 35.0338 14.7692 32 14.7692Z"
            fill="white"
          />
          <path
            d="M21.4772 56.9494C24.807 58.3558 28.3853 59.0793 32 59.0769C35.6147 59.0797 39.1932 58.3563 42.523 56.9499C45.8529 55.5434 48.8664 53.4824 51.3846 50.8892C49.941 48.8317 48.1015 47.0827 45.9738 45.7446C42.0569 43.2308 37.0938 41.8461 32 41.8461C26.9062 41.8461 21.9431 43.2308 18.0215 45.7446C15.8956 47.0833 14.0578 48.8322 12.6154 50.8892C15.1339 53.4821 18.1473 55.5429 21.4772 56.9494Z"
            fill="white"
          />
          <path
            d="M32 0C14.3554 0 0 14.3554 0 32C0 49.6446 14.3554 64 32 64C49.6446 64 64 49.6446 64 32C64 14.3554 49.6446 0 32 0ZM24.2738 17.9723C26.2231 15.9062 28.9662 14.7692 32 14.7692C35.0338 14.7692 37.7523 15.9138 39.7092 17.9908C41.6923 20.0954 42.6569 22.9231 42.4292 25.9631C41.9738 32 37.2969 36.9231 32 36.9231C26.7031 36.9231 22.0169 32 21.5708 25.9615C21.3446 22.8969 22.3077 20.06 24.2738 17.9723ZM32 59.0769C28.3853 59.0793 24.807 58.3558 21.4772 56.9494C18.1473 55.5429 15.1339 53.4821 12.6154 50.8892C14.0578 48.8322 15.8956 47.0833 18.0215 45.7446C21.9431 43.2308 26.9062 41.8461 32 41.8461C37.0938 41.8461 42.0569 43.2308 45.9738 45.7446C48.1015 47.0827 49.941 48.8317 51.3846 50.8892C48.8664 53.4824 45.8529 55.5434 42.523 56.9499C39.1932 58.3563 35.6147 59.0797 32 59.0769Z"
            fill={COLOR.neutral.light}
          />
        </svg>
      )}
    </AvatarStyled>
  )
}
