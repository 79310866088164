import { IonContent, IonSpinner, useIonViewWillEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../../App'
import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import EmptyState from '../../../../components/ui/EmptyState/EmptyState'
import Flex from '../../../../components/ui/Flex'
import { useBlockedUsers } from '../../../../hooks/useBlockedUsers'
import { Blocked } from '../../../../illustrations'
import ProjectDashboardItem from '../../../Project/Dashboard/ProjectDashboardItem'

import BlockedUserItem from './BlockedUserItem'

export default function BlockedUsers() {
  const { t } = useTranslation()

  const { data: users, isLoading, refetch } = useBlockedUsers()

  useIonViewWillEnter(() => {
    void refetch()
  })

  return (
    <Page>
      <SubpageHeader
        title={t('Profile.title')}
        showBackButton={true}
        backButtonRoute={ROUTES.profile}
      />
      <IonContent className="ion-padding">
        <ProjectDashboardItem title={t('Profile.BlockedUsers.title')}>
          {isLoading && (
            <Flex alignItems="center" justifyContent="center" direction="column">
              <IonSpinner name={DEFAULT_SPINNER} />
            </Flex>
          )}
          {!!users && users.length > 0 ? (
            <Flex direction="column" gap={16}>
              {users.map((user) => (
                <BlockedUserItem key={user.id} user={user} onUnblock={() => void refetch()} />
              ))}
            </Flex>
          ) : (
            <EmptyState illustration={<Blocked />}>{t('Profile.BlockedUsers.empty')}</EmptyState>
          )}
        </ProjectDashboardItem>
      </IonContent>
    </Page>
  )
}
