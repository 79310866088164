import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgWeddingCake(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        d="M67.928 69.014H33.185C32.9198 69.014 32.6654 69.1193 32.4779 69.3069 32.2904 69.4944 32.185 69.7488 32.185 70.014V77H68.928V70.014C68.928 69.7488 68.8226 69.4944 68.6351 69.3069 68.4476 69.1193 68.1932 69.014 67.928 69.014ZM63.135 54.637H37.977C37.7118 54.637 37.4574 54.7424 37.2699 54.9299 37.0824 55.1175 36.977 55.3718 36.977 55.637V69.014H64.135V55.637C64.135 55.5057 64.1091 55.3757 64.0589 55.2543 64.0086 55.133 63.935 55.0228 63.8421 54.9299 63.7492 54.8371 63.639 54.7634 63.5177 54.7131 63.3964 54.6629 63.2663 54.637 63.135 54.637ZM59.94 43.454H41.172C40.9068 43.454 40.6524 43.5593 40.4649 43.7469 40.2774 43.9344 40.172 44.1888 40.172 44.454V54.637H60.94V44.454C60.94 44.1888 60.8346 43.9344 60.6471 43.7469 60.4596 43.5593 60.2052 43.454 59.94 43.454Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M44.965 31.473C44.965 31.7877 45.027 32.0992 45.1474 32.3899 45.2678 32.6806 45.4443 32.9448 45.6668 33.1673 45.8893 33.3897 46.1534 33.5662 46.4441 33.6866 46.7348 33.8071 47.0463 33.869 47.361 33.869 47.6756 33.869 47.9872 33.8071 48.2779 33.6866 48.5686 33.5662 48.8327 33.3897 49.0552 33.1673 49.2777 32.9448 49.4542 32.6806 49.5746 32.3899 49.695 32.0992 49.757 31.7877 49.757 31.473 49.757 31.1584 49.695 30.8468 49.5746 30.5561 49.4542 30.2654 49.2777 30.0013 49.0552 29.7788 48.8327 29.5563 48.5686 29.3798 48.2779 29.2594 47.9872 29.139 47.6756 29.077 47.361 29.077 47.0463 29.077 46.7348 29.139 46.4441 29.2594 46.1534 29.3798 45.8893 29.5563 45.6668 29.7788 45.4443 30.0013 45.2678 30.2654 45.1474 30.5561 45.027 30.8468 44.965 31.1584 44.965 31.473V31.473ZM52.953 29.875C52.953 30.1897 53.015 30.5012 53.1354 30.7919 53.2558 31.0826 53.4323 31.3467 53.6548 31.5692 53.8773 31.7917 54.1414 31.9682 54.4321 32.0886 54.7228 32.209 55.0344 32.271 55.349 32.271 55.6637 32.271 55.9752 32.209 56.2659 32.0886 56.5566 31.9682 56.8207 31.7917 57.0432 31.5692 57.2657 31.3467 57.4422 31.0826 57.5626 30.7919 57.683 30.5012 57.745 30.1897 57.745 29.875 57.745 29.5604 57.683 29.2488 57.5626 28.9581 57.4422 28.6674 57.2657 28.4033 57.0432 28.1808 56.8207 27.9583 56.5566 27.7818 56.2659 27.6614 55.9752 27.541 55.6637 27.479 55.349 27.479 55.0344 27.479 54.7228 27.541 54.4321 27.6614 54.1414 27.7818 53.8773 27.9583 53.6548 28.1808 53.4323 28.4033 53.2558 28.6674 53.1354 28.9581 53.015 29.2488 52.953 29.5604 52.953 29.875V29.875ZM51.355 43.454H43.367L46.562 33.869H48.16L51.355 43.454Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M57.874 35.4C57.9548 35.0234 57.9504 34.6334 57.861 34.2587C57.7716 33.884 57.5996 33.5341 57.3575 33.2345C57.1153 32.9348 56.8093 32.6932 56.4617 32.5272C56.114 32.3612 55.7337 32.275 55.3485 32.275C54.9633 32.275 54.583 32.3612 54.2354 32.5272C53.8877 32.6932 53.5817 32.9348 53.3395 33.2345C53.0974 33.5341 52.9254 33.884 52.836 34.2587C52.7466 34.6334 52.7422 35.0234 52.823 35.4L54.55 43.459H56.15L57.874 35.4Z"
      />
      <path
        fill={COLOR.white}
        d="M67.928 83.392H33.185C32.9198 83.392 32.6654 83.2866 32.4779 83.0991 32.2904 82.9116 32.185 82.6572 32.185 82.392V77H68.928V82.39C68.9283 82.5215 68.9026 82.6517 68.8525 82.7733 68.8023 82.8949 68.7287 83.0053 68.6358 83.0984 68.5429 83.1915 68.4326 83.2653 68.3111 83.3157 68.1897 83.3661 68.0595 83.392 67.928 83.392V83.392ZM40.172 49.844H60.94V54.637H40.172V49.844ZM36.977 62.624H64.135V69.014H36.977V62.624Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M59.94 43.454H41.172C40.9068 43.454 40.6524 43.5593 40.4649 43.7469 40.2774 43.9344 40.172 44.1888 40.172 44.454V54.637H60.94V44.454C60.94 44.1888 60.8346 43.9344 60.6471 43.7469 60.4596 43.5593 60.2052 43.454 59.94 43.454ZM32.185 69.014H68.928V83.392H32.185V69.014ZM63.135 54.637H37.977C37.7118 54.637 37.4574 54.7424 37.2699 54.9299 37.0824 55.1175 36.977 55.3718 36.977 55.637V69.014H64.135V55.637C64.135 55.5057 64.1091 55.3757 64.0589 55.2543 64.0086 55.133 63.935 55.0228 63.8421 54.9299 63.7492 54.8371 63.639 54.7634 63.5177 54.7131 63.3964 54.6629 63.2663 54.637 63.135 54.637ZM40.172 49.844H60.94M36.977 62.624H64.135M32.185 77.002H68.928M50.38 8.99999C55.857 2.71399 59.252 8.76999 57.933 12.713 56.777 16.166 50.456 20.291 50.456 20.291 50.456 20.291 44.08 16.251 42.878 12.814 41.506 8.88899 44.819 2.78699 50.38 8.99999ZM72.157 27.317C74.957 24.1 76.698 27.2 76.022 29.217 75.431 30.985 72.2 33.1 72.2 33.1 72.2 33.1 68.937 31.032 68.322 29.273 67.616 27.261 69.311 24.138 72.157 27.317ZM22.86 50.943C25.66 47.726 27.401 50.826 26.725 52.843 26.134 54.61 22.899 56.721 22.899 56.721 22.899 56.721 19.636 54.654 19.021 52.895 18.319 50.887 20.014 47.764 22.86 50.943ZM23.126 26.226C27.396 21.326 30.044 26.047 29.015 29.121 28.115 31.813 23.185 35.03 23.185 35.03 23.185 35.03 18.213 31.88 17.276 29.2 16.206 26.14 18.79 21.382 23.126 26.226ZM76.607 49.351C80.877 44.451 83.525 49.173 82.496 52.251 81.596 54.943 76.666 58.16 76.666 58.16 76.666 58.16 71.694 55.01 70.757 52.33 69.687 49.266 72.271 44.508 76.607 49.351Z"
      />
    </svg>
  )
}
const Memo = memo(SvgWeddingCake)
export default Memo
