import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import styled from '@emotion/styled'
import { IonContent, IonHeader, IonLabel, IonToolbar, useIonLoading } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import AuthSuccessPage from '../../components/layout/EmailSentPage'
import Page from '../../components/layout/Page'
import Button from '../../components/ui/Button/Button'
import Flex from '../../components/ui/Flex'
import FormInput from '../../components/ui/Input/FormInput'
import AppLink from '../../components/ui/Link/AppLink'
import { useAuthContext } from '../../context/AuthContext'
import { FONT } from '../../theme'

import { FormStyled, WinkiiSvgStyled } from './Auth.styled'

import type { FieldValues } from 'react-hook-form'

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: ${FONT.lineHeight.xl};

  h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.semibold};
  }

  p {
    margin-top: 16px;
    margin-bottom: 36px;
    font-size: ${FONT.size.base};
  }
`

export default function PasswordForget() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'PasswordForget', nameOverride: 'Auth' })
  }, [])

  const { t } = useTranslation()

  const { forgotPassword } = useAuthContext()

  const [present, dismiss] = useIonLoading()

  const [authSuccess, setAuthSuccess] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'onSubmit' })

  const resetErrors = () => {
    if (errors.submitError) clearErrors('submitError')
  }

  const handleForgotPassword = async (data: FieldValues) => {
    await present(t('PasswordReset.loading'))
    forgotPassword(data.email as string)
      .then(async () => {
        await dismiss()
        setAuthSuccess(true)
      })
      .catch(async () => {
        await dismiss()
        setError('submitError', { message: t('PasswordReset.error') })
      })
  }

  if (authSuccess) {
    return (
      <AuthSuccessPage
        message={t('PasswordReset.success')}
        messageTitle={t('PasswordReset.successTitle')}
      />
    )
  }

  return (
    <Page>
      <IonHeader>
        <IonToolbar />
      </IonHeader>
      <IonContent className="ion-padding">
        <Flex direction="column">
          <WinkiiSvgStyled size="sm" className="icon icon-default" />
          <TitleWrapper>
            <h1>{t('PasswordReset.title')}</h1>
            <h1>{t('PasswordReset.subtitle')}</h1>
            <p>{t('PasswordReset.description')}</p>
          </TitleWrapper>
          <FormStyled onSubmit={async () => await handleSubmit(handleForgotPassword)()}>
            <FormInput
              control={control}
              inputName="email"
              type="email"
              errorMessage={t('Login.error')}
              label={t('Login.email')}
              placeholder={t('Login.emailPlaceholder')}
            />
            {errors.email || errors.password || errors.submitError ? (
              <IonLabel color="danger">
                {errors.submitError ? (errors.submitError.message as string) : t('Login.error')}
              </IonLabel>
            ) : null}
            <Button type="submit" onClick={resetErrors}>
              {t('PasswordReset.send')}
            </Button>
          </FormStyled>
          <AppLink direction="back" to={ROUTES.login}>
            {t('Login.backToLogin')}
          </AppLink>
        </Flex>
      </IonContent>
    </Page>
  )
}
