import type { PluginListenerHandle } from '@capacitor/core'

export interface UploadOptions {
  fileName: string
  apiUrl: string
  supabaseKey: string
}

export enum UploadEvent {
  Progress = 'progress',
}

export interface UploadProgressEvent {
  percent: number
}

interface UploadEventMap {
  [UploadEvent.Progress]: UploadProgressEvent
}

export interface UploadResponse {
  url: string
}

export interface FileUploadPlugin {
  upload: (options: UploadOptions) => Promise<UploadResponse>

  addListener: <T extends UploadEvent>(
    eventName: T,
    listenerFunc: (payload: UploadEventMap[T]) => void,
  ) => Promise<PluginListenerHandle>
}
