import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgTeamwork(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        d="M16.637 69.633L24.3 62.161C24.4899 61.9759 24.7456 61.8738 25.0107 61.8772C25.2759 61.8806 25.5289 61.9891 25.714 62.179L35.984 72.719C36.1691 72.9089 36.2712 73.1646 36.2678 73.4297C36.2644 73.6949 36.1559 73.9479 35.966 74.133L28.066 81.841C23.4199 78.6443 19.5209 74.4795 16.637 69.633V69.633Z"
      />
      <path
        fill={COLOR.white}
        d="M57.253 64.351C57.2567 64.0677 57.2046 63.7865 57.0996 63.5234C56.9946 63.2603 56.8388 63.0204 56.6411 62.8175C56.4434 62.6146 56.2076 62.4526 55.9473 62.3409C55.687 62.2291 55.4073 62.1697 55.124 62.166C54.591 62.159 50.585 62.343 50.168 61.866C49.273 60.839 50.19 58.308 52.228 56.797L62.86 48.648C63.3061 48.3072 63.6017 47.8058 63.6841 47.2505C63.7664 46.6952 63.6291 46.1297 63.3011 45.674C62.9732 45.2184 62.4805 44.9085 61.9278 44.8103C61.3751 44.7121 60.8058 44.8332 60.341 45.148L50.259 52.687L60.631 43.071C61.0646 42.7004 61.3337 42.1732 61.3795 41.6046C61.4253 41.0361 61.2441 40.4726 60.8755 40.0373C60.5069 39.6021 59.9809 39.3306 59.4126 39.2821C58.8443 39.2337 58.2799 39.4124 57.843 39.779L47.326 49.679L56.047 40.258C56.2419 40.0478 56.393 39.8008 56.4913 39.5315C56.5897 39.2622 56.6334 38.9759 56.6198 38.6896C56.6063 38.4032 56.5358 38.1224 56.4124 37.8636C56.2891 37.6047 56.1154 37.3731 55.9015 37.1822C55.6876 36.9913 55.4378 36.845 55.1666 36.7517C54.8955 36.6585 54.6085 36.6202 54.3225 36.6392C54.0364 36.6582 53.757 36.734 53.5005 36.8622C53.2441 36.9905 53.0158 37.1685 52.829 37.386L50.881 39.568C50.9527 39.1006 50.8683 38.6225 50.641 38.2078C50.4136 37.7932 50.056 37.465 49.6233 37.274C49.1907 37.0831 48.7072 37.0401 48.2476 37.1516C47.788 37.2631 47.378 37.523 47.081 37.891L37.67 49.133C33.59 53.107 31.616 58.043 31.308 58.343L28.838 60.75L37.493 69.633C39.335 67.833 42.513 67.917 55.068 66.479C55.3512 66.4828 55.6324 66.4308 55.8955 66.3259C56.1586 66.2211 56.3985 66.0654 56.6014 65.8677C56.8043 65.6701 56.9663 65.4344 57.0781 65.1742C57.1899 64.9139 57.2493 64.6342 57.253 64.351V64.351Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.637 69.633L24.3 62.16C24.4899 61.9749 24.7456 61.8728 25.0107 61.8762C25.2759 61.8796 25.5289 61.9881 25.714 62.178L35.984 72.719C36.1691 72.9089 36.2712 73.1646 36.2678 73.4297C36.2644 73.6949 36.1559 73.9479 35.966 74.133L28.066 81.841"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M35.929 72.663L37.529 71.103C37.7189 70.9179 37.8274 70.6649 37.8308 70.3997C37.8342 70.1346 37.7321 69.8789 37.547 69.689L28.782 60.689C28.5969 60.4991 28.3439 60.3906 28.0787 60.3872C27.8136 60.3838 27.5579 60.4859 27.368 60.671L25.768 62.231L35.929 72.663Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M57.253 64.351C57.2567 64.0677 57.2046 63.7865 57.0996 63.5234 56.9946 63.2603 56.8388 63.0204 56.6411 62.8175 56.4434 62.6146 56.2076 62.4526 55.9473 62.3409 55.687 62.2291 55.4073 62.1697 55.124 62.166 54.591 62.159 50.585 62.343 50.168 61.866 49.273 60.839 50.19 58.308 52.228 56.797L62.86 48.648C63.3061 48.3072 63.6017 47.8058 63.6841 47.2505 63.7664 46.6952 63.6291 46.1297 63.3011 45.674 62.9732 45.2184 62.4805 44.9085 61.9278 44.8103 61.3751 44.7121 60.8058 44.8332 60.341 45.148L50.259 52.687 60.631 43.071C61.0646 42.7004 61.3337 42.1732 61.3795 41.6046 61.4253 41.0361 61.2441 40.4726 60.8755 40.0373 60.5069 39.6021 59.9809 39.3306 59.4126 39.2821 58.8443 39.2337 58.2799 39.4124 57.843 39.779L47.326 49.679 56.047 40.258C56.2419 40.0478 56.393 39.8008 56.4913 39.5315 56.5897 39.2622 56.6334 38.9759 56.6198 38.6896 56.6063 38.4032 56.5358 38.1224 56.4124 37.8636 56.2891 37.6047 56.1154 37.3731 55.9015 37.1822 55.6876 36.9913 55.4378 36.845 55.1666 36.7517 54.8955 36.6585 54.6085 36.6202 54.3225 36.6392 54.0364 36.6582 53.757 36.734 53.5005 36.8622 53.2441 36.9905 53.0158 37.1685 52.829 37.386L50.881 39.568C50.9527 39.1006 50.8683 38.6225 50.641 38.2078 50.4136 37.7932 50.056 37.465 49.6233 37.274 49.1907 37.0831 48.7072 37.0401 48.2476 37.1516 47.788 37.2631 47.378 37.523 47.081 37.891L37.67 49.133C33.59 53.107 31.616 58.043 31.308 58.343L28.838 60.75 37.493 69.633C39.335 67.833 42.513 67.917 55.068 66.479 55.3512 66.4828 55.6324 66.4308 55.8955 66.3259 56.1586 66.2211 56.3985 66.0654 56.6014 65.8677 56.8043 65.6701 56.9663 65.4344 57.0781 65.1742 57.1899 64.9139 57.2493 64.6342 57.253 64.351V64.351ZM50.881 39.564 44.29 47.247"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M30.065 17.034L37.29 24.6C37.3809 24.695 37.4522 24.8069 37.4998 24.9294C37.5474 25.052 37.5704 25.1827 37.5674 25.3141C37.5644 25.4456 37.5356 25.5751 37.4825 25.6954C37.4294 25.8156 37.3531 25.9242 37.258 26.015L26.612 36.173C26.4202 36.3555 26.1639 36.4546 25.8992 36.4484C25.6345 36.4422 25.383 36.3313 25.2 36.14L17.994 28.6C21.1089 23.8961 25.2324 19.9451 30.065 17.034V17.034Z"
      />
      <path
        fill={COLOR.white}
        d="M34.761 57.527C35.0441 57.5336 35.3258 57.4844 35.59 57.3822C35.8541 57.2799 36.0955 57.1266 36.3003 56.9311C36.5052 56.7355 36.6695 56.5015 36.7839 56.2424C36.8983 55.9833 36.9605 55.7042 36.967 55.421C36.98 54.888 36.837 50.881 37.32 50.469C38.357 49.584 40.878 50.527 42.368 52.581L50.406 63.3C50.7351 63.7677 51.2366 64.0855 51.8 64.1835C52.3635 64.2815 52.9428 64.1516 53.4105 63.8225C53.8782 63.4934 54.196 62.9919 54.294 62.4285C54.392 61.865 54.2621 61.2857 53.933 60.818L46.5 50.654L56.009 61.124C56.1898 61.342 56.4117 61.5223 56.6621 61.6545C56.9126 61.7867 57.1866 61.8683 57.4685 61.8946C57.7505 61.921 58.0349 61.8915 58.3055 61.8079C58.576 61.7243 58.8275 61.5883 59.0455 61.4075C59.2635 61.2267 59.4437 61.0048 59.576 60.7544C59.7082 60.5039 59.7898 60.2299 59.8161 59.948C59.8425 59.666 59.813 59.3816 59.7294 59.111C59.6458 58.8405 59.5098 58.589 59.329 58.371L49.538 47.752L58.869 56.57C59.2929 56.9485 59.8492 57.1444 60.4168 57.1152C60.9843 57.0859 61.5174 56.8337 61.9002 56.4136C62.2829 55.9934 62.4844 55.4392 62.4607 54.8713C62.4371 54.3035 62.1903 53.7679 61.774 53.381L59.612 51.412C60.0785 51.4878 60.557 51.4079 60.9736 51.1847C61.3902 50.9614 61.7217 50.6073 61.9169 50.1768C62.1122 49.7464 62.1603 49.2637 62.0538 48.8032C61.9474 48.3427 61.6923 47.9301 61.328 47.629L50.18 38.1C46.247 33.98 41.333 31.955 41.035 31.643L38.655 29.149L29.683 37.712C31.458 39.572 31.347 42.749 32.655 55.319C32.6481 55.6023 32.6971 55.8842 32.7992 56.1486C32.9014 56.4129 33.0546 56.6546 33.2502 56.8596C33.4458 57.0647 33.6799 57.2292 33.9392 57.3437C34.1984 57.4582 34.4777 57.5205 34.761 57.527Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M30.065 17.034L37.291 24.6C37.4739 24.7918 37.5731 25.0484 37.5669 25.3133C37.5607 25.5783 37.4496 25.8299 37.258 26.013L26.612 36.172C26.4204 36.3547 26.1641 36.4539 25.8994 36.4479C25.6347 36.4419 25.3832 36.3312 25.2 36.14L18 28.599"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.669 36.118L28.212 37.735C28.3027 37.83 28.4112 37.9063 28.5313 37.9593C28.6514 38.0124 28.7809 38.0413 28.9122 38.0444C29.0435 38.0475 29.1741 38.0246 29.2966 37.9772C29.4191 37.9298 29.531 37.8587 29.626 37.768L38.711 29.1C38.806 29.0093 38.8823 28.9008 38.9353 28.7807C38.9884 28.6606 39.0173 28.5311 39.0204 28.3998C39.0235 28.2685 39.0006 28.1379 38.9532 28.0154C38.9058 27.8929 38.8347 27.781 38.744 27.686L37.2 26.066L26.669 36.118Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M34.761 57.527C35.0441 57.5336 35.3258 57.4844 35.59 57.3822 35.8541 57.2799 36.0955 57.1266 36.3003 56.9311 36.5052 56.7355 36.6695 56.5015 36.7839 56.2424 36.8983 55.9833 36.9605 55.7042 36.967 55.421 36.98 54.888 36.837 50.881 37.32 50.469 38.357 49.584 40.878 50.527 42.368 52.581L50.406 63.3C50.7351 63.7677 51.2366 64.0855 51.8 64.1835 52.3635 64.2815 52.9428 64.1516 53.4105 63.8225 53.8782 63.4934 54.196 62.9919 54.294 62.4285 54.392 61.865 54.2621 61.2857 53.933 60.818L46.5 50.654 56.009 61.124C56.1898 61.342 56.4117 61.5223 56.6621 61.6545 56.9126 61.7867 57.1866 61.8683 57.4685 61.8946 57.7505 61.921 58.0349 61.8915 58.3055 61.8079 58.576 61.7243 58.8275 61.5883 59.0455 61.4075 59.2635 61.2267 59.4437 61.0048 59.576 60.7544 59.7082 60.5039 59.7898 60.2299 59.8161 59.948 59.8425 59.666 59.813 59.3816 59.7294 59.111 59.6458 58.8405 59.5098 58.589 59.329 58.371L49.538 47.752 58.869 56.57C59.2929 56.9485 59.8492 57.1444 60.4168 57.1152 60.9843 57.0859 61.5174 56.8337 61.9002 56.4136 62.2829 55.9934 62.4844 55.4392 62.4607 54.8713 62.4371 54.3035 62.1903 53.7679 61.774 53.381L59.612 51.412C60.0785 51.4878 60.557 51.4079 60.9736 51.1847 61.3902 50.9614 61.7217 50.6073 61.9169 50.1768 62.1122 49.7464 62.1603 49.2637 62.0538 48.8032 61.9474 48.3427 61.6923 47.9301 61.328 47.629L50.18 38.1C46.247 33.98 41.333 31.955 41.035 31.643L38.655 29.149 29.683 37.712C31.458 39.572 31.347 42.749 32.655 55.319 32.6481 55.6023 32.6971 55.8842 32.7992 56.1486 32.9014 56.4129 33.0546 56.6546 33.2502 56.8596 33.4458 57.0647 33.6799 57.2292 33.9392 57.3437 34.1984 57.4582 34.4777 57.5205 34.761 57.527ZM59.612 51.412 51.997 44.741"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M83.364 30.415L76.72 37.17C76.6279 37.2637 76.5181 37.3383 76.3971 37.3896C76.2761 37.4409 76.1462 37.4678 76.0148 37.4688C75.8834 37.4698 75.753 37.4449 75.6312 37.3955C75.5095 37.3462 75.3986 37.2733 75.305 37.181L64.818 26.856C64.6294 26.67 64.5223 26.4167 64.5202 26.1518C64.5181 25.8869 64.6213 25.6319 64.807 25.443L71.9 18.229C76.3417 21.6593 80.2111 25.7724 83.364 30.415V30.415Z"
      />
      <path
        fill={COLOR.white}
        d="M43.727 35.665C43.7292 35.9483 43.7872 36.2283 43.8977 36.4892C44.0081 36.75 44.1689 36.9865 44.3708 37.1853C44.5727 37.384 44.8117 37.541 45.0742 37.6473C45.3368 37.7537 45.6177 37.8072 45.901 37.805C46.434 37.805 50.435 37.534 50.862 38.005C51.778 39.013 50.914 41.563 48.908 43.116L38.448 51.482C37.9968 51.8278 37.7003 52.3377 37.6231 52.9009C37.5458 53.4642 37.694 54.0351 38.0355 54.4896C38.3769 54.9441 38.884 55.2455 39.4464 55.3281C40.0089 55.4108 40.5812 55.2681 41.039 54.931L50.962 47.182L40.792 57.01C40.3631 57.3887 40.1023 57.9223 40.0669 58.4934C40.0314 59.0644 40.2243 59.6261 40.603 60.055C40.9817 60.4838 41.5153 60.7447 42.0864 60.7801C42.6574 60.8156 43.2191 60.6227 43.648 60.244L53.957 50.131L45.433 59.731C45.0863 60.1686 44.9228 60.7236 44.9768 61.2793C45.0308 61.8349 45.2982 62.348 45.7227 62.7106C46.1472 63.0732 46.6958 63.2571 47.2531 63.2236C47.8104 63.1901 48.333 62.9418 48.711 62.531L50.611 60.309C50.5484 60.778 50.6422 61.2546 50.8779 61.6649C51.1136 62.0753 51.478 62.3964 51.9147 62.5787C52.3514 62.7609 52.836 62.7941 53.2934 62.673C53.7509 62.5519 54.1556 62.2834 54.445 61.909L63.621 50.471C67.621 46.412 69.488 41.437 69.791 41.13L72.21 38.673L63.372 29.973C61.572 31.805 58.389 31.793 45.866 33.491C45.5828 33.4932 45.3028 33.5512 45.042 33.6617C44.7812 33.7722 44.5447 33.9329 44.3461 34.1348C44.1474 34.3367 43.9905 34.5758 43.8843 34.8383C43.7781 35.1009 43.7246 35.3818 43.727 35.665Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M83.364 30.415L76.719 37.17C76.5329 37.3589 76.2794 37.4662 76.0143 37.4683C75.7491 37.4704 75.494 37.367 75.305 37.181L64.819 26.857C64.7252 26.7649 64.6505 26.6553 64.5992 26.5343C64.5478 26.4133 64.5208 26.2834 64.5197 26.152C64.5186 26.0206 64.5434 25.8902 64.5927 25.7684C64.642 25.6466 64.7148 25.5357 64.807 25.442L71.9 18.229"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M64.874 26.911L63.306 28.5C63.2137 28.5936 63.1408 28.7045 63.0915 28.8262C63.0421 28.948 63.0172 29.0784 63.0182 29.2098C63.0192 29.3412 63.0461 29.4711 63.0974 29.5921C63.1487 29.7131 63.2233 29.8228 63.317 29.915L72.267 38.726C72.456 38.912 72.7111 39.0154 72.9763 39.0133C73.2415 39.0112 73.4949 38.9039 73.681 38.715L75.249 37.122L64.874 26.911Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M43.727 35.665C43.7292 35.9483 43.7872 36.2283 43.8977 36.4892 44.0081 36.75 44.1689 36.9865 44.3708 37.1853 44.5727 37.384 44.8117 37.541 45.0742 37.6473 45.3368 37.7537 45.6177 37.8072 45.901 37.805 46.434 37.805 50.435 37.534 50.862 38.005 51.778 39.013 50.914 41.563 48.908 43.116L38.448 51.482C37.9968 51.8278 37.7003 52.3377 37.6231 52.9009 37.5458 53.4642 37.694 54.0351 38.0355 54.4896 38.3769 54.9441 38.884 55.2455 39.4464 55.3281 40.0089 55.4108 40.5812 55.2681 41.039 54.931L50.962 47.182 40.792 57.01C40.3631 57.3887 40.1023 57.9223 40.0669 58.4934 40.0314 59.0644 40.2243 59.6261 40.603 60.055 40.9817 60.4838 41.5153 60.7447 42.0864 60.7801 42.6574 60.8156 43.2191 60.6227 43.648 60.244L53.957 50.131 45.433 59.731C45.0863 60.1686 44.9228 60.7236 44.9768 61.2793 45.0308 61.8349 45.2982 62.348 45.7227 62.7106 46.1472 63.0732 46.6958 63.2571 47.2531 63.2236 47.8104 63.1901 48.333 62.9418 48.711 62.531L50.611 60.309C50.5484 60.778 50.6422 61.2546 50.8779 61.6649 51.1136 62.0753 51.478 62.3964 51.9147 62.5787 52.3514 62.7609 52.836 62.7941 53.2934 62.673 53.7509 62.5519 54.1556 62.2834 54.445 61.909L63.621 50.471C67.621 46.412 69.488 41.437 69.791 41.13L72.21 38.673 63.372 29.973C61.572 31.805 58.389 31.793 45.866 33.491 45.5828 33.4932 45.3028 33.5512 45.042 33.6617 44.7812 33.7722 44.5447 33.9329 44.3461 34.1348 44.1474 34.3367 43.9905 34.5758 43.8843 34.8383 43.7781 35.1009 43.7246 35.3818 43.727 35.665ZM50.612 60.314 57.043 52.496"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M70.436 82.712L63.426 75.673C63.333 75.5801 63.2593 75.4698 63.2089 75.3484C63.1586 75.227 63.1327 75.0969 63.1327 74.9655C63.1327 74.8341 63.1586 74.704 63.2089 74.5826C63.2593 74.4612 63.333 74.3509 63.426 74.258L73.861 63.879C74.0485 63.6915 74.3028 63.5862 74.568 63.5862C74.8332 63.5862 75.0875 63.6915 75.275 63.879L82.333 70.966C79.396 75.802 75.3091 79.837 70.436 82.712V82.712Z"
      />
      <path
        fill={COLOR.white}
        d="M65.271 42.7C64.9878 42.6992 64.7072 42.7542 64.4452 42.8619C64.1833 42.9696 63.9451 43.1278 63.7444 43.3276C63.5436 43.5273 63.3841 43.7647 63.2751 44.0261C63.1661 44.2875 63.1097 44.5678 63.109 44.851C63.109 45.385 63.333 49.388 62.859 49.81C61.841 50.716 59.301 49.826 57.768 47.803L49.51 37.255C49.3434 37.0237 49.1326 36.8277 48.8898 36.6784C48.6469 36.5291 48.3769 36.4293 48.0953 36.385C47.8137 36.3407 47.5261 36.3526 47.2492 36.4201C46.9722 36.4875 46.7114 36.6093 46.4817 36.7782C46.2521 36.9471 46.0582 37.1598 45.9113 37.4041C45.7644 37.6484 45.6674 37.9194 45.6258 38.2014C45.5843 38.4834 45.5991 38.7709 45.6693 39.0472C45.7395 39.3234 45.8638 39.5831 46.035 39.811L53.681 49.811L43.958 39.542C43.7769 39.3144 43.5517 39.1258 43.2958 38.9875C43.04 38.8492 42.7588 38.7641 42.4692 38.7372C42.1796 38.7104 41.8876 38.7424 41.6107 38.8313C41.3338 38.9202 41.0777 39.0642 40.8579 39.2546C40.6381 39.445 40.459 39.6779 40.3315 39.9393C40.204 40.2007 40.1306 40.4852 40.1159 40.7756C40.1011 41.0661 40.1453 41.3565 40.2457 41.6295C40.346 41.9025 40.5006 42.1523 40.7 42.364L50.7 52.777L41.188 44.155C40.7544 43.8037 40.2016 43.6341 39.6456 43.6818C39.0896 43.7295 38.5738 43.9909 38.2064 44.4109C37.8391 44.831 37.6488 45.377 37.6756 45.9344C37.7023 46.4918 37.9441 47.0171 38.35 47.4L40.55 49.325C40.0819 49.2584 39.6049 49.3478 39.1928 49.5795C38.7807 49.8112 38.4564 50.1722 38.27 50.6067C38.0837 51.0412 38.0457 51.525 38.1619 51.9833C38.2781 52.4416 38.5421 52.8488 38.913 53.142L50.256 62.436C54.273 66.473 59.228 68.396 59.532 68.701L61.964 71.146L70.759 62.4C68.946 60.577 68.991 57.4 67.423 44.859C67.4238 44.2874 67.1975 43.7388 66.794 43.334C66.3904 42.9291 65.8426 42.7011 65.271 42.7V42.7Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M70.436 82.712L63.425 75.672C63.2378 75.4845 63.1327 75.2304 63.1327 74.9655C63.1327 74.7006 63.2378 74.4465 63.425 74.259L73.857 63.879C73.9499 63.786 74.0602 63.7123 74.1816 63.6619C74.303 63.6116 74.4331 63.5857 74.5645 63.5857C74.6959 63.5857 74.826 63.6116 74.9474 63.6619C75.0688 63.7123 75.1791 63.786 75.272 63.879L82.329 70.966"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M73.806 63.934L72.229 62.349C72.0415 62.1615 71.7872 62.0562 71.522 62.0562C71.2568 62.0562 71.0025 62.1615 70.815 62.349L61.915 71.207C61.7275 71.3945 61.6222 71.6488 61.6222 71.914C61.6222 72.1792 61.7275 72.4335 61.915 72.621L63.485 74.2L73.806 63.934Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M65.271 42.7C64.9878 42.6992 64.7072 42.7542 64.4452 42.8619 64.1833 42.9696 63.9451 43.1278 63.7444 43.3276 63.5436 43.5273 63.3841 43.7647 63.2751 44.0261 63.1661 44.2875 63.1097 44.5678 63.109 44.851 63.109 45.385 63.333 49.388 62.859 49.81 61.841 50.716 59.301 49.826 57.768 47.803L49.51 37.255C49.3434 37.0237 49.1326 36.8277 48.8898 36.6784 48.6469 36.5291 48.3769 36.4293 48.0953 36.385 47.8137 36.3407 47.5261 36.3526 47.2492 36.4201 46.9722 36.4875 46.7114 36.6093 46.4817 36.7782 46.2521 36.9471 46.0582 37.1598 45.9113 37.4041 45.7644 37.6484 45.6674 37.9194 45.6258 38.2014 45.5843 38.4834 45.5991 38.7709 45.6693 39.0472 45.7395 39.3234 45.8638 39.5831 46.035 39.811L53.681 49.811 43.958 39.542C43.7769 39.3144 43.5517 39.1258 43.2958 38.9875 43.04 38.8492 42.7588 38.7641 42.4692 38.7372 42.1796 38.7104 41.8876 38.7424 41.6107 38.8313 41.3338 38.9202 41.0777 39.0642 40.8579 39.2546 40.6381 39.445 40.459 39.6779 40.3315 39.9393 40.204 40.2007 40.1306 40.4852 40.1159 40.7756 40.1011 41.0661 40.1453 41.3565 40.2457 41.6295 40.346 41.9025 40.5006 42.1523 40.7 42.364L50.7 52.777 41.188 44.155C40.7544 43.8037 40.2016 43.6341 39.6456 43.6818 39.0896 43.7295 38.5738 43.9909 38.2064 44.4109 37.8391 44.831 37.6488 45.377 37.6756 45.9344 37.7023 46.4918 37.9441 47.0171 38.35 47.4L40.55 49.325C40.0819 49.2584 39.6049 49.3478 39.1928 49.5795 38.7807 49.8112 38.4564 50.1722 38.27 50.6067 38.0837 51.0412 38.0457 51.525 38.1619 51.9833 38.2781 52.4416 38.5421 52.8488 38.913 53.142L50.256 62.436C54.273 66.473 59.228 68.396 59.532 68.701L61.964 71.146 70.759 62.4C68.946 60.577 68.991 57.4 67.423 44.859 67.4238 44.2874 67.1975 43.7388 66.794 43.334 66.3904 42.9291 65.8426 42.7011 65.271 42.7V42.7ZM40.553 49.328 48.304 55.839"
      />
    </svg>
  )
}
const Memo = memo(SvgTeamwork)
export default Memo
