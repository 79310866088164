import { IonSpinner } from '@ionic/react'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../../App'
import { ROUTES } from '../../../../Router'
import ContentWithImageHeader from '../../../../components/layout/ContentWithImageHeader'
import Page from '../../../../components/layout/Page'
import EmptyState from '../../../../components/ui/EmptyState/EmptyState'
import { useSingleBlogPost } from '../../../../hooks/useSingleBlogPost'
import { Question } from '../../../../illustrations'

import { PostContentStyled, PostWrapper } from './SingleBlogPost.styled'

import type { BlogPostDto } from '../../../../types'
import type { FC } from 'react'

const PostContent: FC<{
  isLoading: boolean
  isError: boolean
  blogPost?: BlogPostDto
}> = (props) => {
  const { isLoading, isError, blogPost } = props

  const { t } = useTranslation()

  if (isLoading) return <IonSpinner name={DEFAULT_SPINNER} />

  if (isError) {
    return (
      <EmptyState illustration={<Question />}>{t('Dashboard.BlogPosts.Single.error')}</EmptyState>
    )
  }

  if (blogPost) {
    return (
      <PostWrapper>
        <PostContentStyled
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blogPost.content) }}
        />
      </PostWrapper>
    )
  }
  return null
}

/**
 * In Ionic versions higher than 6.0.10, testing this component with
 * data fetching will result in an error.
 * See: https://github.com/ionic-team/ionic-framework/issues/25334
 */
export default function SingleBlogPost() {
  const { id } = useParams<{ id: string }>()

  const { data: blogPost, isLoading, isError } = useSingleBlogPost(id)

  return (
    <Page>
      <ContentWithImageHeader
        image={blogPost?.featuredImage.sourceUrl || ''}
        title={blogPost?.title}
        backButtonRoute={ROUTES.dashboard}
      >
        <PostContent isLoading={isLoading} isError={isError} blogPost={blogPost} />
      </ContentWithImageHeader>
    </Page>
  )
}
