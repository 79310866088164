import { Clipboard } from '@capacitor/clipboard'
import { Share as NativeShare } from '@capacitor/share'
import { Toast } from '@capacitor/toast'
import { useIonLoading, useIonToast } from '@ionic/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import { createFirebaseDynamicShortLink } from '../../../AppUrlListener'
import { ROUTES } from '../../../Router'
import ProjectSubpage from '../../../components/layout/ProjectSubpage'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Input from '../../../components/ui/Input/Input'
import Text from '../../../components/ui/Text'
import { useApiContext } from '../../../context/ApiContext'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { CloseBold, CopyFill, Envelope, PlusCircle, Share } from '../../../icons'
import { COLOR } from '../../../theme'
import { CollaboratorStatus } from '../../../types'

import { MAX_COLLABORATORS } from './Collaborators'
import {
  CollaboratorEmailsBoxStyled,
  InviteViaEmailWrapper,
  PinStyled,
} from './InviteToProject.styled'

import type { ProjectDto } from '../../../types'

export function InviteViaEmail({
  projectId,
  dismissModal,
  numberOfCollaborators,
  invitationMails,
  setInvitationMails,
}: {
  projectId?: string
  dismissModal: () => void
  numberOfCollaborators: number
  invitationMails?: string[]
  setInvitationMails?: (value: string[]) => void
}) {
  const { t } = useTranslation()
  const [presentToast] = useIonToast()
  const [collaborators, setCollaborators] = useState<Array<{ email: string }>>([])
  const [presentLoading, dismissLoading] = useIonLoading()

  const { refetch } = useSingleProject(projectId ?? '')

  const [email, setEmail] = useState('')

  const emailIsValid = useMemo(() => {
    const regex =
      /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
    return regex.test(email)
  }, [email])

  const canSubmit = useMemo(() => {
    if (email)
      return emailIsValid && collaborators.length + numberOfCollaborators < MAX_COLLABORATORS

    return collaborators.length + numberOfCollaborators <= MAX_COLLABORATORS
  }, [collaborators.length, email, emailIsValid, numberOfCollaborators])

  const canAddCollaborator = useMemo(
    () => emailIsValid && collaborators.length + numberOfCollaborators < MAX_COLLABORATORS,
    [collaborators.length, emailIsValid, numberOfCollaborators],
  )

  const { apiClient } = useApiContext()

  function addCollaborator() {
    if (!emailIsValid) return

    const newCollaborators = [...collaborators, { email }]
    setEmail('')
    setCollaborators(newCollaborators)
  }

  const sendEmailInvitation = async () => {
    if (!canSubmit) return

    const collaboratorsToInvite = email ? [...collaborators, { email }] : collaborators

    if (!projectId && invitationMails && setInvitationMails) {
      setInvitationMails([
        ...invitationMails,
        ...collaboratorsToInvite.map((collaborator) => collaborator.email),
      ])
      setCollaborators([])
      dismissModal()

      return
    }
    if (!projectId) return

    await presentLoading(t('ProjectDashboard.Invitation.Modal.InviteViaEmail.loading'))

    try {
      await apiClient.inviteCollaborators(projectId, collaboratorsToInvite)
    } catch {
      await dismissLoading()
      await presentToast({
        message: t('ProjectDashboard.Invitation.Modal.InviteViaEmail.error'),
        duration: 2000,
      })
      return
    }

    await dismissLoading()
    await presentToast({
      message: t('ProjectDashboard.Invitation.Modal.InviteViaEmail.toast'),
      duration: 2000,
    })
    setCollaborators([])
    dismissModal()
    await refetch()
  }

  function deleteCollaborator(index: number) {
    const newCollaborators = collaborators.filter((_, i) => i !== index)
    setCollaborators(newCollaborators)
  }

  return (
    <InviteViaEmailWrapper>
      <h2>{t('ProjectDashboard.Invitation.Modal.InviteViaEmail.title')}</h2>
      <p>{t('ProjectDashboard.Invitation.Modal.InviteViaEmail.text')}</p>
      {collaborators.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          {collaborators.map((collaborator, index) => (
            <CollaboratorEmailsBoxStyled
              key={collaborator.email}
              onClick={() => deleteCollaborator(index)}
            >
              {collaborator.email}
              <CloseBold height={14} width={14} />
            </CollaboratorEmailsBoxStyled>
          ))}
        </div>
      )}
      <Input
        inputName="email"
        type="email"
        label={t('ProjectDashboard.Invitation.Modal.InviteViaEmail.email')}
        placeholder={t('ProjectDashboard.Invitation.Modal.InviteViaEmail.placeholder')}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <ButtonGroup buttonsGrow>
        <Button
          variant="secondary"
          disabled={!canAddCollaborator}
          type="button"
          onClick={() => addCollaborator()}
        >
          {t('ProjectDashboard.Invitation.Modal.InviteViaEmail.moreEmails')}
        </Button>
        <Button disabled={!canSubmit} type="submit" onClick={() => void sendEmailInvitation()}>
          {collaborators.length > 1
            ? t('ProjectDashboard.Invitation.Modal.InviteViaEmail.multipleInvites')
            : t('ProjectDashboard.Invitation.Modal.InviteViaEmail.oneInvite')}
        </Button>
      </ButtonGroup>
    </InviteViaEmailWrapper>
  )
}

export default function InviteToProject() {
  const { t } = useTranslation()
  const routeParams = useParams<{ id: string }>()

  const [invitationVisible, setInvitationVisible] = useState(false)
  const [emailInvitationVisible, setEmailInvitationVisible] = useState(false)
  const { project } = useSingleProject(routeParams.id)

  const [presentLoading, dismissLoading] = useIonLoading()

  const numberOfCollaborators = useMemo(() => {
    return (
      project?.collaborators.filter((collaborator) =>
        [CollaboratorStatus.Active, CollaboratorStatus.Pending].includes(collaborator.status),
      ).length || 0
    )
  }, [project?.collaborators])

  const showCopiedToast = async () => {
    await Toast.show({
      text: t('General.copied'),
    })
  }

  const copyToClipboard = async () => {
    await Clipboard.write({
      string: project?.invitationCode,
    }).then(() => {
      void showCopiedToast()
    })
  }

  const share = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER, showBackdrop: false })

    const { value: link } = await createFirebaseDynamicShortLink(
      `instant-join?code=${project?.invitationCode}`,
      {
        title: project?.title,
        imageUrl: project?.thumbnail.sourceUrl,
      },
    ).catch(() => ({
      value: '',
    }))

    await dismissLoading()

    await NativeShare.share({
      title: t('ProjectDashboard.Invitation.Share.title', { title: project?.title }),
      text: t('ProjectDashboard.Invitation.Share.text', {
        title: project?.title,
        code: project?.invitationCode,
      }),
      url: link,
      dialogTitle: 'Share with buddies',
    })

    setInvitationVisible(false)
  }

  return (
    <ProjectSubpage
      project={project as ProjectDto}
      title={t('ProjectDashboard.Invitation.Modal.title')}
      backButtonRoute={ROUTES.collaboratorsList(project?.id)}
    >
      <Flex direction="column" gap={32}>
        <Text>{t('ProjectDashboard.Invitation.Modal.text')}</Text>
        <Flex direction="column" alignItems="center">
          <Text>{t('ProjectDashboard.Invitation.Modal.pin')}</Text>
          <Flex alignItems="center">
            <PinStyled>{project?.invitationCode}</PinStyled>
            <IconButton
              icon={<CopyFill />}
              title=""
              variant="primary"
              onClick={() => void copyToClipboard()}
            />
          </Flex>
        </Flex>
        <Button
          variant="secondary"
          icon={<PlusCircle />}
          onClick={() => setInvitationVisible(true)}
        >
          {t('ProjectDashboard.Invitation.Modal.button')}
        </Button>
      </Flex>
      <ActionSheetModal
        isOpen={invitationVisible}
        onClose={() => {
          setInvitationVisible(false)
          setEmailInvitationVisible(false)
        }}
      >
        {emailInvitationVisible && project?.id ? (
          <InviteViaEmail
            projectId={project?.id}
            dismissModal={() => setInvitationVisible(false)}
            numberOfCollaborators={numberOfCollaborators}
          />
        ) : (
          <Flex direction="column" gap={24}>
            <Text color={COLOR.neutral.dark}>
              {t('ProjectDashboard.Invitation.Modal.description')}
            </Text>
            <ButtonGroup buttonsGrow>
              <Button variant="secondary" icon={<Share />} onClick={() => void share()}>
                {t('ProjectDashboard.Invitation.Modal.inviteFriendsButton')}
              </Button>
              <Button
                variant="secondary"
                icon={<Envelope />}
                onClick={() => setEmailInvitationVisible(true)}
              >
                {t('ProjectDashboard.Invitation.Modal.inviteEmailButton')}
              </Button>
            </ButtonGroup>
          </Flex>
        )}
      </ActionSheetModal>
    </ProjectSubpage>
  )
}
