import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { useTranslation } from 'react-i18next'
import { useSwiper } from 'swiper/react'

import MusicTrackSelection from '../../../components/projects/musicTracks/MusicTrackSelection'
import Flex from '../../../components/ui/Flex'
import InfoBox from '../../../components/ui/InfoBox'
import Text from '../../../components/ui/Text'
import { FONT } from '../../../theme'
import { stopAllAudio } from '../../../utils/media'

import type { MusicTrackDto } from '../../../types'

interface Props {
  musicTracks?: MusicTrackDto[]
  activeMusicTrackId?: string
  onSelect: (value?: string) => void
}

export default function SelectMusicStep(props: Props) {
  void FirebaseAnalytics.setScreenName({
    screenName: 'SelectMusicStep',
    nameOverride: 'NewProject',
  })

  const { musicTracks, activeMusicTrackId, onSelect } = props

  const { t } = useTranslation()

  const swiper = useSwiper()

  swiper.on('slideChange', () => {
    stopAllAudio()
  })

  return (
    <Flex direction="column" gap={24}>
      <Text tag="p" style={{ textAlign: 'center' }} size={FONT.size.xl} weight={FONT.weight.normal}>
        {t('NewProject.MusicTrack.title')}
      </Text>
      <MusicTrackSelection
        musicTracks={musicTracks}
        activeMusicTrackId={activeMusicTrackId}
        onSelect={onSelect}
      />
      <InfoBox>{t('NewProject.MusicTrack.description')}</InfoBox>
    </Flex>
  )
}
