import { useMemo } from 'react'

import { CollaboratorStatus } from '../types'

import type { ProjectDto } from '../types'

export function useCollaborators(project: ProjectDto | null) {
  const collaborators = useMemo(() => {
    const { collaborators = [] } = project || {}
    return collaborators
  }, [project])

  const pendingCollaborators = useMemo(() => {
    return collaborators.filter(
      (collaborator) => collaborator.status === CollaboratorStatus.Pending,
    )
  }, [collaborators])

  const activeCollaborators = useMemo(() => {
    return collaborators.filter((collaborator) => collaborator.status === CollaboratorStatus.Active)
  }, [collaborators])

  const numberOfCollaborators = activeCollaborators.length + pendingCollaborators.length

  return {
    collaborators,
    pendingCollaborators,
    activeCollaborators,
    numberOfCollaborators,
  }
}
