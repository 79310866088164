import styled from '@emotion/styled'
import { IonContent, IonModal, IonicSlides } from '@ionic/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import { SwiperPagination, swiperBulletStyles } from '../../../../components/common/SwiperStyles'
import Button from '../../../../components/ui/Button/Button'
import Flex from '../../../../components/ui/Flex'
import { useAppConfig } from '../../../../context/AppConfigContext'
import { useAuthContext } from '../../../../context/AuthContext'
import { enterAnimation, leaveAnimation } from '../../../../hooks/useProjectStudioWizard'
import { PADDING } from '../../../../theme'
import { stopAllVideos } from '../../../../utils/media'
import { SwiperStyled } from '../../../NewProject/NewProjectWizard/NewProjectWizard.styled'

import OnboardingWizardStep from './OnboardingWizardStep'

import type { Swiper } from 'swiper/types'

const Content = styled(IonContent)`
  --background: transparent;
`

interface Props {
  isOpen: boolean
  onDismiss: () => void
}

export default function OnboardingWizard(props: Props) {
  const { isOpen, onDismiss } = props

  const { t } = useTranslation()
  const { user } = useAuthContext()
  const { onboardingSlides } = useAppConfig()

  const paginationRef = useRef<HTMLDivElement>(null)
  const [swiper, setSwiper] = useState<Swiper | null>(null)
  const [isLastSlide, setIsLastSlide] = useState(onboardingSlides.length === 1)

  const dismissModal = () => {
    onDismiss()
  }

  const reset = () => {
    setIsLastSlide(false)
    setSwiper(null)
  }

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={isLastSlide || !!user}
      backdropDismiss={false}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
      keepContentsMounted={false}
      onDidDismiss={reset}
    >
      <Content className="ion-padding ion-safe-area-bottom ion-safe-area-top">
        <Flex
          direction="column"
          style={{ height: '100%', paddingBottom: PADDING.DEFAULT }}
          justifyContent="flex-end"
        >
          <Flex direction="column" style={{ height: '100%' }} gap={20}>
            <div style={{ flex: '1' }}>
              <SwiperStyled
                style={{ margin: '0 -20px', padding: '0 20px' }}
                spaceBetween={50}
                modules={[Pagination, IonicSlides]}
                pagination={{
                  el: paginationRef.current,
                  bulletActiveClass: swiperBulletStyles(true),
                  bulletClass: swiperBulletStyles(),
                }}
                onSwiper={setSwiper}
                onSlideChange={({ isEnd }) => {
                  stopAllVideos()
                  setIsLastSlide(isEnd)
                }}
              >
                {onboardingSlides.map((slide, index) => (
                  <SwiperSlide key={`onboarding-${index}`}>
                    <OnboardingWizardStep slide={slide} />
                  </SwiperSlide>
                ))}
              </SwiperStyled>
            </div>
            <SwiperPagination ref={paginationRef} />
            <Button
              variant={isLastSlide ? 'primary' : 'secondary'}
              onClick={() => (isLastSlide ? dismissModal() : swiper?.slideNext())}
            >
              {isLastSlide ? t('Onboarding.complete') : t('General.next')}
            </Button>
          </Flex>
        </Flex>
      </Content>
    </IonModal>
  )
}
