import { Directory, Filesystem } from '@capacitor/filesystem'
import { Preferences } from '@capacitor/preferences'
import { useIonViewWillEnter } from '@ionic/react'
import { useState } from 'react'

export interface LocalAnswer {
  questionTitle: string
  questionId: string
  sourceUrl: string
  thumbnailUrl: string
  remoteSourceUrl?: string
  remoteThumbnailUrl?: string
}

export function useLocalAnswers(projectId: string) {
  const [localAnswers, setLocalAnswers] = useState<LocalAnswer[]>([])

  const createLocalAnswer = (localAnswer: LocalAnswer) =>
    Preferences.get({ key: projectId }).then(({ value }) => {
      const localAnswersToCreate = value ? (JSON.parse(value) as LocalAnswer[]) : []
      void Preferences.set({
        key: projectId,
        value: JSON.stringify([...localAnswersToCreate, localAnswer]),
      })
      setLocalAnswers([...localAnswersToCreate, localAnswer])
    })

  const updateLocalAnswer = (localAnswer: LocalAnswer) =>
    Preferences.get({ key: projectId }).then(({ value }) => {
      if (!value) return

      const localAnswersToUpdate = JSON.parse(value) as LocalAnswer[]
      const updatedLocalAnswers = localAnswersToUpdate.map((answer) => {
        if (answer.questionId === localAnswer.questionId) return localAnswer

        return answer
      })
      void Preferences.set({
        key: projectId,
        value: JSON.stringify(updatedLocalAnswers),
      })
      setLocalAnswers(updatedLocalAnswers)
    })

  const deleteLocalAnswer = (localAnswer: LocalAnswer) =>
    Preferences.get({ key: projectId }).then(({ value }) => {
      if (!value) return

      const localAnswersToDelete = JSON.parse(value) as LocalAnswer[]
      const updatedLocalAnswers = localAnswersToDelete.filter(
        (answer) => answer.questionId !== localAnswer.questionId,
      )
      void Preferences.set({
        key: projectId,
        value: JSON.stringify(updatedLocalAnswers),
      })
      setLocalAnswers(updatedLocalAnswers)
    })

  const syncLocalAnswers = () => {
    return Filesystem.readdir({ path: '', directory: Directory.Data }).then((result) => {
      const fileNames = result.files.map((file) => file.name)

      void Preferences.get({ key: projectId }).then(({ value }) => {
        if (value) {
          const localAnswersToSync = JSON.parse(value) as LocalAnswer[]

          const filteredLocalAnswers = localAnswersToSync.filter((localAnswer) => {
            const localAnswerFileName = localAnswer.sourceUrl.split('/').pop()
            return localAnswerFileName && fileNames.includes(localAnswerFileName)
          })

          void Preferences.set({
            key: projectId,
            value: JSON.stringify(
              filteredLocalAnswers.map((localAnswer) => ({
                ...localAnswer,
                isUploading: false,
              })),
            ),
          })
          setLocalAnswers(filteredLocalAnswers)
        }
      })
    })
  }

  useIonViewWillEnter(() => {
    void syncLocalAnswers()
  })

  const refetch = () => syncLocalAnswers()

  return {
    localAnswers,
    setLocalAnswers,
    refetch,
    createLocalAnswer,
    updateLocalAnswer,
    deleteLocalAnswer,
  }
}
