import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgDownload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M22.67,16.94a1.22,1.22,0,0,0-1.22,1.21V20a1.57,1.57,0,0,1-1.57,1.57H4.12A1.57,1.57,0,0,1,2.55,20V18.15a1.22,1.22,0,0,0-2.43,0V20a4,4,0,0,0,4,4H19.88a4,4,0,0,0,4-4V18.15A1.21,1.21,0,0,0,22.67,16.94Z" />
      <path d="M12,0a1.94,1.94,0,0,0-1.94,1.94V11a.25.25,0,0,1-.25.25H7.15a1,1,0,0,0-.73,1.6l4.85,5.58a1,1,0,0,0,1.46,0l4.85-5.58a1,1,0,0,0-.73-1.6H14.19a.25.25,0,0,1-.25-.25V1.94A1.94,1.94,0,0,0,12,0Z" />
    </svg>
  )
}
const Memo = memo(SvgDownload)
export default Memo
