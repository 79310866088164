import { IonProgressBar } from '@ionic/react'

import { COLOR, FONT } from '../../theme'
import Dialog from '../ui/Dialog'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

interface Props {
  text: string
  bytesAmount: number
  isOpen: boolean
  onClose: () => void
}

export default function LoadingDialog(props: Props) {
  const { text, bytesAmount, isOpen, onClose } = props

  const formattedBytesAmount = (bytesAmount / 1000 / 1000).toLocaleString('de-DE', {
    maximumFractionDigits: 2,
  })

  return (
    <Dialog isOpen={isOpen} backdropDismiss={false} fullWidth={true} onClose={onClose}>
      <Dialog.Title>
        <Text>{text}</Text>
      </Dialog.Title>
      <Dialog.Body>
        <Flex direction="column">
          <Text size={FONT.size.sm} color={COLOR.neutral.DEFAULT}>
            {formattedBytesAmount} MB
          </Text>
          <IonProgressBar type="indeterminate" />
        </Flex>
      </Dialog.Body>
    </Dialog>
  )
}
