import { Clipboard } from '@capacitor/clipboard'
import { Share as NativeShare } from '@capacitor/share'
import { Toast } from '@capacitor/toast'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { useIonLoading } from '@ionic/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_SPINNER } from '../../../App'
import { createFirebaseDynamicShortLink } from '../../../AppUrlListener'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import { CopyFill, Envelope, PlusCircle, Share } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { CollaboratorStatus } from '../../../types'
import CollaboratorItem from '../../Project/Collaborators/CollaboratorItem'
import { CollaboratorList } from '../../Project/Collaborators/Collaborators'
import { InviteViaEmail } from '../../Project/Collaborators/InviteToProject'
import { PinStyled } from '../../Project/Collaborators/InviteToProject.styled'
import ProjectDashboardItem from '../../Project/Dashboard/ProjectDashboardItem'

import type { CollaboratorDto, CreateProjectDto } from '../../../types'

interface Props {
  invitationMails: string[]
  setInvitationMails: (value: string[]) => void
  project?: CreateProjectDto
  invitationCode: string
  onSelect: (value: CollaboratorDto[]) => void
}

export default function AddPersonStep(props: Props) {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({
      screenName: 'AddPersonStep',
      nameOverride: 'NewProject',
    })
  }, [])

  const { project, invitationCode, onSelect } = props
  const [invitationVisible, setInvitationVisible] = useState(false)
  const [emailInvitationVisible, setEmailInvitationVisible] = useState<boolean>(false)

  const [presentLoading, dismissLoading] = useIonLoading()

  const [currentCollaborators, setCurrentCollaborators] = useState<CollaboratorDto[]>(
    project?.collaborators ?? [],
  )

  const { t } = useTranslation()

  const pendingCollaborators = useMemo(() => {
    return (currentCollaborators || []).filter(
      (currentCollaborator) => currentCollaborator.status === CollaboratorStatus.Pending,
    )
  }, [currentCollaborators])

  const activeCollaborators = useMemo(() => {
    return (currentCollaborators || []).filter(
      (currentCollaborator) => currentCollaborator.status === CollaboratorStatus.Active,
    )
  }, [currentCollaborators])

  const numberOfCollaborators = activeCollaborators.length + pendingCollaborators.length

  function onDeleteCollaborator(collaboratorId: string) {
    const newCollaborators = currentCollaborators.filter(
      (collaborator) => collaborator.id !== collaboratorId,
    )
    setCurrentCollaborators(newCollaborators)
    onSelect(newCollaborators)
  }

  // swiper.on('slideChange', () => {
  //   stopAllAudio()
  // })
  const showCopiedToast = async () => {
    await Toast.show({
      text: t('General.copied'),
    })
  }

  const copyToClipboard = async () => {
    await Clipboard.write({
      string: invitationCode,
    }).then(() => {
      void showCopiedToast()
    })
  }

  const share = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER, showBackdrop: false })

    const { value: link } = await createFirebaseDynamicShortLink(
      `instant-join?code=${invitationCode}`,
      {
        title: project?.title,
        imageUrl: project?.thumbnail.sourceUrl,
      },
    ).catch(() => ({
      value: '',
    }))

    await dismissLoading()

    await NativeShare.share({
      title: t('ProjectDashboard.Invitation.Share.title', { title: project?.title }),
      text: t('ProjectDashboard.Invitation.Share.text', {
        title: project?.title,
        code: project?.invitationCode,
      }),
      url: link,
      dialogTitle: 'Share with buddies',
    })

    setInvitationVisible(false)
  }

  return (
    <Flex direction="column" gap={24}>
      <Text tag="p" style={{ textAlign: 'center' }} size={FONT.size.xl} weight={FONT.weight.normal}>
        {t('NewProject.AddPerson.title')}
      </Text>
      {/* <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={DEFAULT_SPINNER} />
  </IonRefresher>*/}
      <Flex direction="column" gap={40}>
        <Text tag="p" color={COLOR.neutral.dark}>
          {t('ProjectDashboard.Collaborators.description')}
        </Text>
        <CollaboratorList>
          {activeCollaborators?.map((collaborator) => (
            <li key={collaborator.id}>
              <CollaboratorItem
                user={collaborator.user}
                collaboratorId={collaborator.id}
                onDelete={() => onDeleteCollaborator(collaborator.id)}
              />
            </li>
          ))}
        </CollaboratorList>
        <Flex direction="column" gap={32}>
          <Text>{t('ProjectDashboard.Invitation.Modal.text')}</Text>
          {invitationCode && (
            <Flex direction="column" alignItems="center">
              <Text>{t('ProjectDashboard.Invitation.Modal.pin')}</Text>
              <Flex alignItems="center">
                <PinStyled>{invitationCode}</PinStyled>
                <IconButton
                  icon={<CopyFill />}
                  title=""
                  variant="primary"
                  onClick={() => void copyToClipboard()}
                />
              </Flex>
            </Flex>
          )}
          <Button
            variant="secondary"
            icon={<PlusCircle />}
            onClick={() => setInvitationVisible(true)}
          >
            {t('ProjectDashboard.Collaborators.addCollaborator')}
          </Button>
          <ActionSheetModal
            isOpen={invitationVisible}
            onClose={() => {
              setInvitationVisible(false)
              setEmailInvitationVisible(false)
            }}
          >
            <span>{emailInvitationVisible}</span>
            {emailInvitationVisible ? (
              <InviteViaEmail
                invitationMails={props.invitationMails}
                setInvitationMails={props.setInvitationMails}
                dismissModal={() => setInvitationVisible(false)}
                numberOfCollaborators={numberOfCollaborators}
              />
            ) : (
              <Flex direction="column" gap={24}>
                <Text color={COLOR.neutral.dark}>
                  {t('ProjectDashboard.Invitation.Modal.description')}
                </Text>
                <ButtonGroup buttonsGrow>
                  <Button variant="secondary" icon={<Share />} onClick={() => void share()}>
                    {t('ProjectDashboard.Invitation.Modal.inviteFriendsButton')}
                  </Button>
                  <Button
                    variant="secondary"
                    icon={<Envelope />}
                    onClick={() => setEmailInvitationVisible(true)}
                  >
                    {t('ProjectDashboard.Invitation.Modal.inviteEmailButton')}
                  </Button>
                </ButtonGroup>
              </Flex>
            )}
          </ActionSheetModal>
        </Flex>
        {pendingCollaborators.length > 0 && (
          <ProjectDashboardItem title={t('ProjectDashboard.Collaborators.pendingCollaborators')}>
            <CollaboratorList>
              {pendingCollaborators.map((pendingCollaborator) => (
                <li key={pendingCollaborator.id}>
                  <CollaboratorItem
                    user={pendingCollaborator.user}
                    collaboratorId={pendingCollaborator.id}
                    onDelete={() => onDeleteCollaborator(pendingCollaborator.id)}
                  />
                </li>
              ))}
            </CollaboratorList>
          </ProjectDashboardItem>
        )}
      </Flex>
      {/*  <ActionSheetModal
        isOpen={actionSheetVisible}
        onClose={() => {
          setActionSheetVisible(false)
        }}
      >
        <ButtonGroup buttonsGrow>
          <Button
            icon={<PlusCircle />}
            variant="secondary"
            onClick={() => {
              setActionSheetVisible(false)
              ionRouter.push(ROUTES.inviteToProject(project?.id), 'forward')
            }}
          >
            {t('ProjectDashboard.Collaborators.addCollaborator')}
          </Button>
        </ButtonGroup>
      </ActionSheetModal>
      <InfoBox>{t('NewProject.AddPerson.description')}</InfoBox>
      <InfoBox>
        {`${numberOfCollaborators}/${MAX_COLLABORATORS}`}
        {t('NewProject.AddPerson.number')}
      </InfoBox> */}
    </Flex>
  )
}
