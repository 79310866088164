import { useRef, useState } from 'react'

import { FileUpload, UploadEvent } from '../plugins/FileUpload'
import { REACT_APP_SUPABASE_KEY, REACT_APP_SUPABASE_URL } from '../utils/env'

import type { PluginListenerHandle } from '@capacitor/core'

export function useFileUpload() {
  const progressListener = useRef<PluginListenerHandle>()
  const [progressPercent, setProgressPercent] = useState(0)

  const [isUploading, setIsUploading] = useState(false)
  const [isUploadError, setIsUploadError] = useState(false)

  const resetProgress = () => {
    setProgressPercent(0)
  }

  const setListener = async () => {
    if (progressListener.current) return

    progressListener.current = await FileUpload.addListener(UploadEvent.Progress, (event) => {
      setProgressPercent(event.percent)
    })
  }

  const removeListener = async () => {
    if (progressListener.current) await progressListener.current.remove()

    progressListener.current = undefined
  }

  const handleError = async () => {
    await removeListener()
    setIsUploading(false)
    setIsUploadError(true)
  }

  const upload = async (fileName: string, apiUrl: string) => {
    setIsUploadError(false)
    resetProgress()
    await setListener()

    setIsUploading(true)

    try {
      const { url } = await FileUpload.upload({
        fileName,
        apiUrl,
        supabaseKey: REACT_APP_SUPABASE_KEY,
      })
      setIsUploading(false)
      await removeListener()
      return `${REACT_APP_SUPABASE_URL}/storage/v1/object/public/${url}`
    } catch {
      await handleError()
      throw new Error('Upload failed')
    }
  }

  return {
    upload,
    progressPercent,
    isUploading,
    isUploadError,
  }
}
