import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCaretDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_240_374)">
        <path
          fillRule="evenodd"
          d="M0.219645 6.78073C0.512525 6.48782 0.987399 6.4878 1.28031 6.78068L12 17.4994L22.7197 6.78068C23.0126 6.4878 23.4875 6.48782 23.7804 6.78073C24.0732 7.07364 24.0732 7.54851 23.7803 7.84139L13.0607 18.56C13.0607 18.56 13.0607 18.56 13.0607 18.56C12.9215 18.6994 12.7561 18.81 12.5741 18.8855C12.3921 18.9609 12.197 18.9998 12 18.9998C11.803 18.9998 11.6079 18.9609 11.4259 18.8855C11.2439 18.81 11.0785 18.6994 10.9393 18.56C10.9393 18.56 10.9393 18.56 10.9393 18.56L0.219695 7.84139C-0.0732122 7.54851 -0.0732344 7.07364 0.219645 6.78073Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_374">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgCaretDown)
export default Memo
