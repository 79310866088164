import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCheckBold(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path d="M20.5171 3.214C20.3977 3.12707 20.2622 3.06468 20.1184 3.03044C19.9747 2.9962 19.8256 2.99078 19.6798 3.01451C19.534 3.03823 19.3943 3.09062 19.2688 3.16866C19.1434 3.24669 19.0346 3.34882 18.9488 3.46913L8.42094 18.1542L4.90164 14.6349C4.68827 14.436 4.40605 14.3278 4.11445 14.3329C3.82285 14.3381 3.54463 14.4562 3.3384 14.6624C3.13218 14.8687 3.01405 15.1469 3.0089 15.4385C3.00376 15.7301 3.112 16.0123 3.31082 16.2257L7.7606 20.683C7.87802 20.7949 8.01782 20.8806 8.17079 20.9346C8.32376 20.9886 8.48642 21.0095 8.64807 20.996C8.80972 20.9826 8.96668 20.935 9.10861 20.8565C9.25054 20.7779 9.37421 20.6702 9.47148 20.5404L20.7798 4.78231C20.8663 4.66238 20.9282 4.52648 20.9619 4.38246C20.9956 4.23845 21.0003 4.08919 20.9759 3.94332C20.9515 3.79746 20.8983 3.65789 20.8196 3.5327C20.7408 3.40751 20.638 3.29919 20.5171 3.214Z" />
    </svg>
  )
}
const Memo = memo(SvgCheckBold)
export default Memo
