export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function arrayToObject<T extends { id: string }>(array: T[]) {
  return array.reduce(
    (obj, item) => {
      obj[item.id] = item
      return obj
    },
    {} as Record<string, T>,
  )
}
