import { createAnimation, useIonRouter } from '@ionic/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../Router'
import { useAuthContext } from '../../../context/AuthContext'
import { CheckBold, InfoCircle } from '../../../icons'
import ProjectDashboardItem from '../../../views/Project/Dashboard/ProjectDashboardItem'
import { UnStyledLink } from '../../common/UnStyledLink'
import Flex from '../../ui/Flex'
import MessageCard from '../../ui/MessageCard'

import OpenQuestionItem from './OpenQuestionItem'

import type { LocalAnswer } from '../../../hooks/useLocalAnswers'
import type { ProjectDto, ProjectQuestionDto } from '../../../types'
import type { SVGProps } from 'react'

interface Props {
  project: ProjectDto
  questions?: ProjectQuestionDto[]
  localAnswers?: LocalAnswer[]
  showLocalAnswersMessage?: boolean
}

export const VideoCameraIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={1.5}
    fill="none"
    className="icon"
    {...props}
  >
    <path
      strokeLinecap="round"
      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
    />
  </svg>
))
VideoCameraIcon.displayName = 'VideoCameraIcon'

export default function OpenQuestions(props: Props) {
  const { project, questions = [], localAnswers = [], showLocalAnswersMessage = true } = props

  const ionRouter = useIonRouter()
  const { t } = useTranslation()

  const { user } = useAuthContext()

  const hasQuestions = questions.length > 0
  const hasLocalAnswers = localAnswers.length > 0

  const openQuestions = questions.filter((question) => {
    const hasAnswer = question.answers?.some((answer) => answer.answerBy.id === user?.id) ?? false
    const hasLocalAnswer = localAnswers.some((answer) => answer.questionId === question.id)

    return !hasAnswer && !hasLocalAnswer
  })

  if (!hasQuestions) return null

  if (openQuestions.length === 0 && hasQuestions && !hasLocalAnswers) {
    return (
      <MessageCard icon={<CheckBold />} message={t('ProjectDashboard.OpenQuestions.allAnswered')} />
    )
  }

  return (
    <ProjectDashboardItem title={t('ProjectDashboard.OpenQuestions.title')}>
      <Flex direction="column" gap={10}>
        {openQuestions.map((question) => (
          <OpenQuestionItem
            key={question.id}
            question={question}
            receiverName={project.receiverName}
            onClick={() =>
              ionRouter.push(
                ROUTES.projectRecordAnswer(project.id, question.id),
                undefined,
                undefined,
                undefined,
                (baseEl) =>
                  createAnimation()
                    .addElement(baseEl as Element)
                    .easing('ease-out')
                    .duration(150)
                    .fromTo('opacity', '1', '0'),
              )
            }
          />
        ))}
      </Flex>
      {hasLocalAnswers && showLocalAnswersMessage && (
        <UnStyledLink to={ROUTES.projectRecordingStudio(project.id)}>
          <MessageCard
            message={t('ProjectDashboard.OpenQuestions.localQuestions', {
              count: localAnswers.length,
            })}
            icon={<InfoCircle />}
          />
        </UnStyledLink>
      )}
    </ProjectDashboardItem>
  )
}
