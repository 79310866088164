import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgAlertTriangleFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_234_401)">
        <path d="M23.77 20.57L13.77 1.57C13.6005 1.24728 13.346 0.977021 13.034 0.788436C12.7221 0.599852 12.3645 0.500113 12 0.5C11.6355 0.500113 11.2779 0.599852 10.9659 0.788436C10.654 0.977021 10.3995 1.24728 10.23 1.57L0.229985 20.57C0.0634808 20.8793 -0.0186359 21.227 -0.00810311 21.578C0.00242964 21.9291 0.10524 22.2713 0.289985 22.57C0.472219 22.8578 0.724949 23.0943 1.02421 23.2571C1.32347 23.4198 1.65935 23.5034 1.99999 23.5H22C22.3442 23.4999 22.6826 23.4109 22.9824 23.2417C23.2822 23.0725 23.5332 22.8288 23.7112 22.5341C23.8892 22.2395 23.9882 21.9039 23.9985 21.5598C24.0088 21.2157 23.9301 20.8748 23.77 20.57ZM11 8.5C11 8.23478 11.1053 7.98043 11.2929 7.79289C11.4804 7.60536 11.7348 7.5 12 7.5C12.2652 7.5 12.5196 7.60536 12.7071 7.79289C12.8946 7.98043 13 8.23478 13 8.5V14.5C13 14.7652 12.8946 15.0196 12.7071 15.2071C12.5196 15.3946 12.2652 15.5 12 15.5C11.7348 15.5 11.4804 15.3946 11.2929 15.2071C11.1053 15.0196 11 14.7652 11 14.5V8.5ZM12.05 20C11.6561 19.9977 11.2783 19.8436 10.9952 19.5698C10.712 19.296 10.5454 18.9236 10.53 18.53C10.5233 18.3323 10.5563 18.1353 10.6271 17.9506C10.6978 17.7659 10.8049 17.5972 10.9419 17.4546C11.0789 17.312 11.2432 17.1982 11.4249 17.1202C11.6067 17.0421 11.8022 17.0012 12 17C12.3939 17.0023 12.7717 17.1564 13.0548 17.4302C13.3379 17.704 13.5045 18.0764 13.52 18.47C13.5267 18.6677 13.4937 18.8647 13.4229 19.0494C13.3522 19.2341 13.2451 19.4028 13.1081 19.5454C12.971 19.688 12.8068 19.8018 12.6251 19.8798C12.4433 19.9579 12.2478 19.9988 12.05 20Z" />
      </g>
      <defs>
        <clipPath id="clip0_234_401">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgAlertTriangleFill)
export default Memo
