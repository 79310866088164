import { useTranslation } from 'react-i18next'

import { useAuthContext } from '../context/AuthContext'
import { FONT } from '../theme'

import Flex from './ui/Flex'
import Text from './ui/Text'

export default function Credits() {
  const { t } = useTranslation()
  const { availableProductionJobs, userIsGuest } = useAuthContext()

  if (userIsGuest) return null

  return (
    <Flex direction="column" alignItems="center" gap={2}>
      <Text size={FONT.size.xl} weight={FONT.weight.medium}>
        {availableProductionJobs}
      </Text>
      <Text size={FONT.size.sm}>{t('Shop.creditsLabel')}</Text>
    </Flex>
  )
}
