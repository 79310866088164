import { css, cx } from '@emotion/css'
import { useTranslation } from 'react-i18next'

import { AlertTriangleFill } from '../../icons'
import { COLOR, PADDING } from '../../theme'
import Button from '../ui/Button/Button'
import { ButtonGroup } from '../ui/Button/ButtonGroup'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

interface Props {
  message: string
  onDismiss: () => void
}

export default function CameraErrorView(props: Props) {
  const { message, onDismiss } = props

  const { t } = useTranslation()

  const onButtonClick = () => {
    onDismiss()
  }

  return (
    <Flex
      direction="column"
      className={cx(
        'ion-safe-area-bottom',
        css({
          color: COLOR.white,
          width: '100%',
          height: '100%',
          position: 'absolute',
          inset: 0,
          paddingLeft: PADDING.DEFAULT,
          paddingRight: PADDING.DEFAULT,
        }),
      )}
    >
      <div className={css({ flex: 1 })} />
      <Flex direction="column" gap={20} alignItems="center">
        <AlertTriangleFill />
        <Text className={css({ textAlign: 'center' })}>{message}</Text>
      </Flex>
      <div
        className={css({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingBottom: PADDING.DEFAULT,
        })}
      >
        <ButtonGroup>
          <Button variant="secondary" onClick={onButtonClick}>
            {t('RecordingStudio.ErrorView.dismiss')}
          </Button>
        </ButtonGroup>
      </div>
    </Flex>
  )
}
