import { IonProgressBar } from '@ionic/react'

import { COLOR, FONT } from '../../theme'
import Dialog from '../ui/Dialog'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

interface Props {
  isOpen: boolean
  text: string
  progressPercent: number
}

export default function ProgressDialog(props: Props) {
  const { isOpen, text, progressPercent } = props

  return (
    <Dialog
      isOpen={isOpen}
      backdropDismiss={false}
      onClose={() => {
        /* empty */
      }}
    >
      <Dialog.Title>
        <Text>{text}</Text>
      </Dialog.Title>
      <Dialog.Body>
        <Flex direction="column">
          <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.DEFAULT}>
            {progressPercent}%
          </Text>
          <IonProgressBar value={progressPercent / 100} />
        </Flex>
      </Dialog.Body>
    </Dialog>
  )
}
