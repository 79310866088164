import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgStudio(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.381 66.5H88.381M5.88101 66.5H8.38101M90.381 66.5H92.881"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M76.025 81.9C76.0553 82.0035 76.0611 82.1127 76.0418 82.2188C76.0226 82.325 75.9788 82.4252 75.9141 82.5115C75.8493 82.5978 75.7654 82.6678 75.6688 82.716C75.5723 82.7641 75.4659 82.7891 75.358 82.789H22.377C22.2691 82.7891 22.1627 82.7641 22.0662 82.716C21.9696 82.6678 21.8857 82.5978 21.8209 82.5115C21.7562 82.4252 21.7124 82.325 21.6932 82.2188C21.6739 82.1127 21.6797 82.0035 21.71 81.9C22.9384 77.5151 23.5612 72.9827 23.561 68.429V15.3H74.174V68.427C74.1741 72.9814 74.7969 77.5143 76.025 81.9Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.64 14.599H79.095V18.114H18.64V14.599Z"
      />
      <path fill={COLOR.white} d="M90.928 40.609H93.74V77.163H90.928V40.609Z" />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M90.928 40.609H93.74V77.163H90.928V40.609Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M89.522 38.5C89.522 38.8693 89.5947 39.2349 89.7361 39.5761C89.8774 39.9173 90.0845 40.2273 90.3456 40.4884C90.6067 40.7495 90.9167 40.9566 91.2579 41.0979C91.5991 41.2393 91.9647 41.312 92.334 41.312C92.7033 41.312 93.0689 41.2393 93.4101 41.0979C93.7513 40.9566 94.0613 40.7495 94.3224 40.4884C94.5835 40.2273 94.7906 39.9173 94.9319 39.5761C95.0733 39.2349 95.146 38.8693 95.146 38.5C95.146 38.1307 95.0733 37.765 94.9319 37.4239C94.7906 37.0827 94.5835 36.7727 94.3224 36.5116C94.0613 36.2505 93.7513 36.0434 93.4101 35.902C93.0689 35.7607 92.7033 35.688 92.334 35.688C91.9647 35.688 91.5991 35.7607 91.2579 35.902C90.9167 36.0434 90.6067 36.2505 90.3456 36.5116C90.0845 36.7727 89.8774 37.0827 89.7361 37.4239C89.5947 37.765 89.522 38.1307 89.522 38.5Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M87.564 39.034L94.37 36.534C94.6641 36.4249 94.903 36.2036 95.0342 35.9187C95.1654 35.6337 95.1783 35.3084 95.07 35.014L94.189 32.614C94.1352 32.4682 94.0532 32.3344 93.9477 32.2204C93.8422 32.1063 93.7152 32.0141 93.574 31.9491C93.4329 31.8841 93.2803 31.8475 93.125 31.8415C92.9697 31.8355 92.8148 31.8601 92.669 31.914L85.869 34.414L87.564 39.034Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M87.564 39.034L94.37 36.534C94.6641 36.4249 94.903 36.2036 95.0342 35.9187C95.1654 35.6337 95.1783 35.3084 95.07 35.014L94.189 32.614C94.1352 32.4682 94.0532 32.3344 93.9477 32.2204C93.8422 32.1063 93.7152 32.0141 93.574 31.9491C93.4329 31.8841 93.2803 31.8475 93.125 31.8415C92.9697 31.8355 92.8148 31.8601 92.669 31.914L85.869 34.414L87.564 39.034Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M83.848 41.15C83.9768 41.5 84.2393 41.7844 84.5778 41.9408C84.9163 42.0972 85.303 42.1128 85.653 41.984L86.753 41.578C86.9547 41.504 87.1399 41.3909 87.2978 41.2453C87.4558 41.0996 87.5835 40.9242 87.6736 40.7292C87.7637 40.5341 87.8144 40.3232 87.8229 40.1085C87.8314 39.8938 87.7974 39.6796 87.723 39.478L85.7 33.971C85.5501 33.5639 85.2447 33.233 84.8509 33.051C84.4572 32.8689 84.0073 32.8506 83.6 33L82.494 33.407C82.1453 33.5358 81.8617 33.7975 81.7054 34.1348C81.5491 34.4722 81.5328 34.8577 81.66 35.207L83.848 41.15Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M76.29 26.7C76.0301 26.7955 75.7916 26.9413 75.588 27.1291C75.3845 27.3168 75.2199 27.5427 75.1037 27.7941C74.9875 28.0454 74.9219 28.3171 74.9107 28.5938C74.8995 28.8705 74.943 29.1466 75.0385 29.4065C75.134 29.6664 75.2798 29.9049 75.4675 30.1085C75.6553 30.312 75.8812 30.4766 76.1325 30.5928C76.3839 30.709 76.6556 30.7746 76.9323 30.7858C77.2089 30.797 77.4851 30.7535 77.745 30.658C77.2196 30.851 76.7924 31.2447 76.5573 31.7527C76.3222 32.2606 76.2985 32.8411 76.4915 33.3665C76.6844 33.8919 77.0782 34.3191 77.5861 34.5542C78.0941 34.7893 78.6746 34.813 79.2 34.62C78.9401 34.7155 78.7016 34.8613 78.498 35.0491C78.2945 35.2368 78.1299 35.4627 78.0137 35.7141C77.779 36.2216 77.7555 36.8016 77.9485 37.3265C78.1414 37.8514 78.535 38.2781 79.0425 38.5128C79.5501 38.7475 80.1301 38.771 80.655 38.578C80.395 38.6736 80.1565 38.8195 79.9529 39.0073C79.7493 39.1951 79.5847 39.4212 79.4685 39.6726C79.3523 39.924 79.2867 40.1958 79.2756 40.4726C79.2644 40.7493 79.3079 41.0256 79.4035 41.2855C79.4991 41.5455 79.645 41.784 79.8328 41.9876C80.0206 42.1912 80.2466 42.3558 80.498 42.472C80.7495 42.5882 81.0213 42.6538 81.2981 42.6649C81.5748 42.6761 81.851 42.6326 82.111 42.537C81.8508 42.6326 81.6119 42.7785 81.4081 42.9664C81.2043 43.1543 81.0395 43.3806 80.9231 43.6322C80.8067 43.8838 80.741 44.1558 80.7297 44.4328C80.7185 44.7098 80.7619 44.9863 80.8575 45.2465C80.9531 45.5067 81.099 45.7456 81.2869 45.9494C81.4748 46.1532 81.701 46.318 81.9526 46.4344C82.2042 46.5508 82.4763 46.6165 82.7533 46.6278C83.0303 46.639 83.3068 46.5956 83.567 46.5C83.0456 46.6956 82.6228 47.0895 82.3907 47.5958C82.1587 48.102 82.1363 48.6794 82.3284 49.2021C82.5205 49.7248 82.9115 50.1503 83.4161 50.3857C83.9207 50.6212 84.498 50.6476 85.022 50.459C78.828 52.732 71.851 49.261 69.44 42.7C67.029 36.139 70.1 28.98 76.29 26.7Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M76.29 26.7C76.0301 26.7955 75.7916 26.9413 75.588 27.1291 75.3845 27.3168 75.2199 27.5427 75.1037 27.7941 74.9875 28.0454 74.9219 28.3171 74.9107 28.5938 74.8995 28.8705 74.943 29.1466 75.0385 29.4065 75.134 29.6664 75.2798 29.9049 75.4675 30.1085 75.6553 30.312 75.8812 30.4766 76.1325 30.5928 76.3839 30.709 76.6556 30.7746 76.9323 30.7858 77.2089 30.797 77.4851 30.7535 77.745 30.658 77.2196 30.851 76.7924 31.2447 76.5573 31.7527 76.3222 32.2606 76.2985 32.8411 76.4915 33.3665 76.6844 33.8919 77.0782 34.3191 77.5861 34.5542 78.0941 34.7893 78.6746 34.813 79.2 34.62 78.9401 34.7155 78.7016 34.8613 78.498 35.0491 78.2945 35.2368 78.1299 35.4627 78.0137 35.7141 77.779 36.2216 77.7555 36.8016 77.9485 37.3265 78.1414 37.8514 78.535 38.2781 79.0425 38.5128 79.5501 38.7475 80.1301 38.771 80.655 38.578 80.395 38.6736 80.1565 38.8195 79.9529 39.0073 79.7493 39.1951 79.5847 39.4212 79.4685 39.6726 79.3523 39.924 79.2867 40.1958 79.2756 40.4726 79.2644 40.7493 79.3079 41.0256 79.4035 41.2855 79.4991 41.5455 79.645 41.784 79.8328 41.9876 80.0206 42.1912 80.2466 42.3558 80.498 42.472 80.7495 42.5882 81.0213 42.6538 81.2981 42.6649 81.5748 42.6761 81.851 42.6326 82.111 42.537 81.8508 42.6326 81.6119 42.7785 81.4081 42.9664 81.2043 43.1543 81.0395 43.3806 80.9231 43.6322 80.8067 43.8838 80.741 44.1558 80.7297 44.4328 80.7185 44.7098 80.7619 44.9863 80.8575 45.2465 80.9531 45.5067 81.099 45.7456 81.2869 45.9494 81.4748 46.1532 81.701 46.318 81.9526 46.4344 82.2042 46.5508 82.4763 46.6165 82.7533 46.6278 83.0303 46.639 83.3068 46.5956 83.567 46.5 83.0456 46.6956 82.6228 47.0895 82.3907 47.5958 82.1587 48.102 82.1363 48.6794 82.3284 49.2021 82.5205 49.7248 82.9115 50.1503 83.4161 50.3857 83.9207 50.6212 84.498 50.6476 85.022 50.459 78.828 52.732 71.851 49.261 69.44 42.7 67.029 36.139 70.1 28.98 76.29 26.7ZM80.656 38.578 82.635 37.851M90.928 70.133 86.007 77.163M93.74 70.133 98.661 77.163"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        d="M58.631 77.709C58.631 78.573 54.085 79.272 48.477 79.272C42.869 79.272 38.323 78.572 38.323 77.709C38.323 76.846 42.87 76.147 48.477 76.147C54.084 76.147 58.631 76.847 58.631 77.709Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54.465 63.174H42.489V60.587C42.4889 60.4019 42.5253 60.2186 42.5962 60.0476C42.6672 59.8767 42.7712 59.7214 42.9023 59.5908C43.0334 59.4601 43.1891 59.3566 43.3603 59.2863C43.5315 59.216 43.7149 59.1802 43.9 59.181H53.064C53.2487 59.1809 53.4316 59.2172 53.6022 59.2878C53.7729 59.3584 53.9279 59.4619 54.0585 59.5925C54.1891 59.7231 54.2926 59.8782 54.3633 60.0488C54.4339 60.2194 54.4701 60.4023 54.47 60.587L54.465 63.174Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M42.489 63.174V77.145M54.465 63.174V77.145M42.489 69.161H54.465"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.487 48.618L24.778 28.326L20.969 29.626C20.4592 29.8011 19.9953 30.0884 19.6114 30.4667C19.2275 30.8451 18.9335 31.3048 18.751 31.812L15.059 42.09C14.8759 42.5975 14.8096 43.1398 14.865 43.6765C14.9204 44.2132 15.0961 44.7306 15.379 45.19L17.487 48.618Z"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.308 39.119L15.69 40.334L18.12 33.571L14.738 32.355C14.2892 32.1943 13.795 32.2182 13.3638 32.4214C12.9327 32.6247 12.5997 32.9906 12.438 33.439L11.224 36.82C11.0636 37.2687 11.0877 37.7626 11.2909 38.1935C11.4941 38.6245 11.8598 38.9573 12.308 39.119Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.193 39.074V77.163M1.33899 77.163 12.193 66.309 22.829 76.945M17.817 71.933H6.569"
      />
      <path
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.487 48.618L24.778 28.326L26.468 28.933C26.9164 29.0947 27.2824 29.4277 27.4856 29.8589C27.6888 30.29 27.7127 30.7842 27.552 31.233L21.477 48.143C21.3151 48.5912 20.982 48.9569 20.5509 49.1599C20.1197 49.363 19.6256 49.3867 19.177 49.226L17.487 48.618Z"
      />
    </svg>
  )
}
const Memo = memo(SvgStudio)
export default Memo
