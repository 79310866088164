import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'

import { COLOR } from '../../../theme'

import BaseButton from './BaseButton'

import type { BaseButtonProps, ButtonVariant } from './BaseButton'

const GhostButtonStyled = styled(BaseButton)`
  background: transparent;
`

export const ghostButtonVariantStyles: Record<ButtonVariant, string> = {
  primary: css({
    'color': COLOR.primary.darker,
    ':active': {
      background: COLOR.primary.lighter,
    },
  }),
  secondary: css({
    'color': `${COLOR.neutral.dark} !important`,
    ':active': {
      background: COLOR.primary.lighter,
    },
  }),
  danger: css({
    'color': COLOR.danger.darker,
    ':active': {
      background: COLOR.danger.lighter,
    },
  }),
}

export default function GhostButton(props: BaseButtonProps) {
  const { children, variant = 'primary', className, ...rest } = props

  return (
    <GhostButtonStyled {...rest} className={cx(ghostButtonVariantStyles[variant], className)}>
      {children}
    </GhostButtonStyled>
  )
}
