import styled from '@emotion/styled'
import { useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import { useNewProjectModal } from '../../hooks/useNewProjectModal'
import { PlusCircle } from '../../icons'
import { Clapperboard } from '../../illustrations'
import { flexGapString } from '../../utils/css'
import { isInWeb } from '../../utils/user'
import Button from '../ui/Button/Button'
import EmptyState from '../ui/EmptyState/EmptyState'

const NoProjectsStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(20, 'row')}

  button {
    width: 100%;
  }
`

export default function NoProjects() {
  const { t } = useTranslation()
  const ionRouter = useIonRouter()

  const [present] = useNewProjectModal()

  return (
    <NoProjectsStyled>
      <EmptyState illustration={<Clapperboard />}>{t('MyProjects.noProjects')}</EmptyState>
      <Button
        icon={<PlusCircle />}
        onClick={() => (isInWeb() ? ionRouter.push(ROUTES.downloadsLinks) : present())}
      >
        {t('MyProjects.createProject')}
      </Button>
    </NoProjectsStyled>
  )
}
