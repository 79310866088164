import { IonHeader, IonSpinner, IonTitle, useIonLoading, useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useBoolean } from 'usehooks-ts'

import { DEFAULT_SPINNER } from '../../App'
import { ROUTES } from '../../Router'
import { useApiContext } from '../../context/ApiContext'
import { useSingleProjectInvite } from '../../hooks/useSingleProjectInvite'
import { useToast } from '../../hooks/useToast'
import { ArrowCircleLeft } from '../../icons'
import { setNavigationBarLight } from '../../utils/navigationBar'
import { setStatusbarLight } from '../../utils/statusBar'
import BlockUserSheet from '../common/BlockUserSheet'
import Page from '../layout/Page'
import Button from '../ui/Button/Button'
import { ButtonGroup } from '../ui/Button/ButtonGroup'
import GhostButton from '../ui/Button/GhostButton'
import Flex from '../ui/Flex'
import IconButton from '../ui/IconButton/IconButton'

import ProjectDetailsSheet, { ProjectDetails } from './ProjectDetailsSheet'
import {
  ButtonsStartStyled,
  ButtonsStyled,
  ContentStyled,
  HeaderStyled,
} from './ViewProjectInvite.styled'

export default function ViewProjectInvite() {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation()
  const { data: invite, isLoading, isError } = useSingleProjectInvite(id)
  const { apiClient } = useApiContext()

  const ionRouter = useIonRouter()
  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast } = useToast()

  const [detailsVisible, setDetailsVisible] = useState(false)

  const {
    value: rejectSheetVisible,
    setTrue: showRejectSheet,
    setFalse: hideRejectSheet,
  } = useBoolean(false)

  useEffect(() => {
    return () => {
      setNavigationBarLight()
      setStatusbarLight()
    }
  })

  const pageContent = () => {
    if (isLoading) {
      return (
        <Flex alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%' }}>
          <IonSpinner name={DEFAULT_SPINNER} />
        </Flex>
      )
    }

    if (isError || !invite) return <div>Error</div>

    const handleAccept = async () => {
      await presentLoading()

      try {
        await apiClient.acceptInvite(invite.id)
      } catch {
        await dismissLoading()
        await presentErrorToast(t('General.error'))
        return
      }

      await dismissLoading()
      ionRouter.push(ROUTES.projectDashboard(invite?.project.id), 'root')
    }

    const handleReject = async () => {
      await presentLoading()

      try {
        await apiClient.removeCollaborator(invite.id)
      } catch {
        await dismissLoading()
        await presentErrorToast(t('General.error'))
        return
      }

      await dismissLoading()
      ionRouter.push(ROUTES.dashboard, 'back', 'pop')
    }

    return (
      <>
        <ProjectDetails project={invite.project} />
        <ButtonsStyled>
          <ButtonGroup buttonsGrow={true}>
            <Button onClick={() => void handleAccept()}>{t('Invites.accept')}</Button>
            <Button size="small" variant="secondary" onClick={() => void handleReject()}>
              {t('Invites.decline')}
            </Button>
            <GhostButton
              size="small"
              variant="danger"
              style={{ marginBottom: -16 }}
              onClick={showRejectSheet}
            >
              {t('Block.block')}
            </GhostButton>
          </ButtonGroup>
        </ButtonsStyled>
        <ProjectDetailsSheet
          project={invite.project}
          isOpen={detailsVisible}
          onDismiss={() => setDetailsVisible(false)}
        />
        <BlockUserSheet
          user={invite.project.creator}
          isOpen={rejectSheetVisible}
          onClose={hideRejectSheet}
          onBlocked={() => ionRouter.push(ROUTES.dashboard, 'back', 'pop')}
        />
      </>
    )
  }

  return (
    <Page pageStyle="light">
      <IonHeader>
        <HeaderStyled>
          <ButtonsStartStyled slot="start">
            <IconButton
              icon={<ArrowCircleLeft />}
              title="close"
              onClick={() => ionRouter.push(ROUTES.dashboard, 'back', 'pop')}
            />
          </ButtonsStartStyled>
          <IonTitle>{invite?.project.title}</IonTitle>
        </HeaderStyled>
      </IonHeader>
      <ContentStyled fullscreen={false}>{pageContent()}</ContentStyled>
    </Page>
  )
}
