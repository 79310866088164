import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import styled from '@emotion/styled'
import { IonButtons, IonHeader, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from '../../Router'
import { useAuthContext } from '../../context/AuthContext'
import { InfoCircle, Ticket } from '../../icons'
import { IronSourceIntegration } from '../../plugins/IronSourceIntegration'
import { FONT, PADDING, TRANSITION } from '../../theme'
import { flexGapString } from '../../utils/css'
import OnboardingWizard from '../../views/Tabs/Dashboard/OnboardingWizard/OnboardingWizard'
import BackButton from '../common/BackButton'
import Avatar from '../ui/Avatar/Avatar'
import Flex from '../ui/Flex'
import IconButton from '../ui/IconButton/IconButton'

import type { PropsWithChildren, ReactNode } from 'react'

interface PageHeaderProps {
  title: ReactNode
  showAvatar?: boolean
  showBackButton?: boolean
  backButtonRoute?: string
}

const PageHeaderStyled = styled(IonHeader)``

const ToolbarStyled = styled(IonToolbar)`
  --height: 64px;
  --min-height: 64px;
  --padding-start: ${PADDING.DEFAULT};
  --padding-end: ${PADDING.DEFAULT};
  transition: ${TRANSITION.base};
`

const TitleStyled = styled(IonTitle)`
  font-size: ${FONT.size.xl};
  font-weight: ${FONT.weight.semibold};
  padding: 0 ${PADDING.DEFAULT};
  text-align: start;
`

const TitleInnerStyled = styled.span`
  display: inline-flex;
  align-items: center;

  ${flexGapString(2, 'column')}
  > button {
    margin-inline-start: -10px;
  }
`

const AvatarLinkStyled = styled(Link)`
  display: inline-flex;
`

const ButtonsStyled = styled(IonButtons)`
  ${flexGapString(12, 'column')}
`

export default function PageHeader(props: PropsWithChildren<PageHeaderProps>) {
  const { title, showAvatar = true, showBackButton = false, backButtonRoute, children } = props

  const { user, availableProductionJobs } = useAuthContext()

  const [onboardingVisible, setOnboardingVisible] = useState(false)
  const handleOnboardingClose = () => {
    setOnboardingVisible(false)
  }

  useIonViewWillEnter(() => {
    if (!user) return
    void Device.getInfo().then(async ({ iOSVersion }) => {
      if (iOSVersion && iOSVersion > 140000 && Capacitor.isNativePlatform()) {
        const { isTrackingUndetermined } = await IronSourceIntegration.isTrackingUndetermined()

        if (isTrackingUndetermined) await IronSourceIntegration.requestTracking()
      }
    })
  }, [user])

  const onButtonClick = () => setOnboardingVisible(true)

  return (
    <PageHeaderStyled>
      <ToolbarStyled>
        <TitleStyled>
          <TitleInnerStyled>
            {showBackButton && <BackButton backRoute={backButtonRoute} />}
            <span>{title}</span>
          </TitleInnerStyled>
        </TitleStyled>
        <ButtonsStyled slot="end">
          {availableProductionJobs > 0 && (
            <Flex gap={6} alignItems="center">
              {availableProductionJobs}
              <Ticket height={30} width={30} />
            </Flex>
          )}
          <IconButton icon={<InfoCircle />} title="" onClick={onButtonClick} />
          <AvatarLinkStyled to={ROUTES.profile}>
            {showAvatar && <Avatar alt={'Avatar'} src={user?.avatar?.sourceUrl} />}
          </AvatarLinkStyled>
        </ButtonsStyled>
      </ToolbarStyled>
      {children}
      <OnboardingWizard isOpen={onboardingVisible} onDismiss={() => void handleOnboardingClose()} />
    </PageHeaderStyled>
  )
}
