import { Capacitor } from '@capacitor/core'
import { useEffect, useState } from 'react'

import 'cordova-plugin-purchase'

import {
  REACT_APP_IAP_ONE_CREDIT_ID,
  REACT_APP_IAP_SIX_CREDITS_ID,
  REACT_APP_IAP_TWO_CREDITS_ID,
} from '../utils/env'

export function useProducts() {
  const [products, setProducts] = useState<CdvPurchase.Product[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!Capacitor.isNativePlatform) return

    setIsLoading(true)

    setProducts([
      CdvPurchase.store.get(REACT_APP_IAP_ONE_CREDIT_ID) as CdvPurchase.Product,
      CdvPurchase.store.get(REACT_APP_IAP_TWO_CREDITS_ID) as CdvPurchase.Product,
      CdvPurchase.store.get(REACT_APP_IAP_SIX_CREDITS_ID) as CdvPurchase.Product,
    ])

    setIsLoading(false)
  }, [])

  return { products, isLoading }
}
