import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import LazyImage from '../../../components/common/LazyImage'
import IconButton from '../../../components/ui/IconButton/IconButton'
import { useAuthContext } from '../../../context/AuthContext'
import { View, ViewOff } from '../../../icons'
import { BORDER_RADIUS } from '../../../theme'
import { getFullName } from '../../../utils/user'

import { DraggableItemStyled } from './DraggableClipItem'

import type { ProjectAttachmentDto } from '../../../types'

interface Props {
  attachment: ProjectAttachmentDto
  isDragging?: boolean
  onAttachmentUpdated: (id: string, excluded: boolean) => void
  index: number
  openModal: (index: number) => void
}

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 48px;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  border-radius: ${BORDER_RADIUS.sm};
  overflow: hidden;
`

const DataStyled = styled.div`
  flex: 1;
`

export default function DraggableAttachmentItem(props: Props) {
  const { attachment, isDragging = false, onAttachmentUpdated, index, openModal } = props

  const { t } = useTranslation()
  const { user } = useAuthContext()

  const toggleExcluded = () => {
    onAttachmentUpdated(attachment.id, !attachment.isExcluded)
  }

  const onClick = () => {
    openModal(index)
  }

  return (
    <DraggableItemStyled excluded={attachment.isExcluded} isDragging={isDragging} onClick={onClick}>
      <ThumbnailWrapper>
        <LazyImage src={attachment.media.thumbnailUrl} />
      </ThumbnailWrapper>
      <DataStyled>{getFullName(attachment.submittedBy, user, t)}</DataStyled>
      <IconButton
        icon={attachment.isExcluded ? <View /> : <ViewOff />}
        title=""
        onClick={(event) => {
          event.stopPropagation()
          toggleExcluded()
        }}
      />
    </DraggableItemStyled>
  )
}
