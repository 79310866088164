import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { SHADOW } from '../../../theme'

import Button from './Button'

import type { BaseButtonProps } from './BaseButton'

const FABStyled = styled(Button)`
  height: 56px;
  min-width: 56px;
  border: none;
  box-shadow: ${SHADOW.lg};

  :active {
    transform: scale(0.975);
  }
`

type Props = Omit<BaseButtonProps, 'size'>

const FAB = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <FABStyled {...props} ref={ref} size="default" />
))

FAB.displayName = 'FAB'

export default FAB
