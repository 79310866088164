import styled from '@emotion/styled'
import { useIonRouter } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../Router'
import { ArrowCircleLeft } from '../../icons'
import IconButton from '../ui/IconButton/IconButton'

const BackButtonIcon = styled(ArrowCircleLeft)`
  [dir='rtl'] & {
    transform: rotate(180deg);
  }
`

interface BackButtonProps {
  backRoute?: string
  white?: boolean
}

export default function BackButton(props: BackButtonProps) {
  const { backRoute, white = false } = props
  const { t } = useTranslation()

  const ionRouter = useIonRouter()
  const canGoBack = ionRouter.canGoBack()

  const handleBack = () => {
    if (backRoute) ionRouter.push(backRoute, 'back', 'pop')
    else if (canGoBack) ionRouter.goBack()
    else ionRouter.push(ROUTES.dashboard, 'back', 'pop')
  }

  if (!canGoBack && !backRoute) return null

  return (
    <IconButton
      icon={<BackButtonIcon />}
      title={t('General.back')}
      onDark={white}
      onClick={handleBack}
    />
  )
}
