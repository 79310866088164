import styled from '@emotion/styled'
import { IonModal } from '@ionic/react'

import { BORDER_RADIUS, COLOR, FONT, PADDING, SHADOW } from '../../theme'

import { ButtonGroup } from './Button/ButtonGroup'

import type { PropsWithChildren } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  backdropDismiss?: boolean
  fullWidth?: boolean
}

const DialogStyled = styled(IonModal)<{ fullWidth: boolean }>`
  flex-direction: column;
  justify-content: center;
  padding: 0 ${PADDING.DEFAULT};

  --height: fit-content;
  --width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  --max-width: ${({ fullWidth }) => (fullWidth ? 'auto' : '300px')};
  --border-radius: ${BORDER_RADIUS.base};
  --background: ${COLOR.white};

  ::part(content) {
    box-shadow: ${SHADOW.lg};
  }
`

const Content = styled.div`
  padding: 20px 24px;
  font-family: ${FONT.family.sans};
  text-align: center;
`

export default function Dialog(props: PropsWithChildren<Props>) {
  const { isOpen, onClose, children, backdropDismiss = true, fullWidth = false } = props

  return (
    <DialogStyled
      isOpen={isOpen}
      backdropDismiss={backdropDismiss}
      fullWidth={fullWidth}
      onDidDismiss={onClose}
    >
      <Content>{children}</Content>
    </DialogStyled>
  )
}

const DialogTitle = styled.h2`
  margin-top: 4px;
  font-size: 18px;
  font-weight: ${FONT.weight.semibold};
`

const DialogBody = styled.div`
  margin-top: 8px;
  font-size: ${FONT.size.base};
`

const DialogButtons = styled(ButtonGroup)`
  margin-top: 16px;
`

Dialog.Title = DialogTitle
Dialog.Body = DialogBody
Dialog.Buttons = DialogButtons
