import { useQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'

export function useQuestionOptions(category: string) {
  const { apiClient } = useApiContext()

  return useQuery(
    ['question-options', category],
    () => {
      if (!category) return []

      return apiClient.getQuestionOptions(category).then((data) => {
        return data
      })
    },
    { retry: 2 },
  )
}
