import { forwardRef } from 'react'
import { Controller } from 'react-hook-form'

import Input from './Input'

import type { InputProps } from './Input'
import type { TextFieldTypes } from '@ionic/core'
import type { Control } from 'react-hook-form'

export interface FormInputProps extends InputProps {
  control?: Control
  variant?: 'primary' | 'ghost'
  inputName: string
  errorMessage: string
  type: TextFieldTypes
  label: string
  backgroundColor?: string
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>((props, inputRef) => {
  const { type, control, variant = 'primary', inputName, label, errorMessage, ...rest } = props
  return (
    <Controller
      name={inputName}
      control={control}
      rules={{
        required: rest.required !== undefined ? rest.required : true,
        pattern: {
          value: type === 'email' ? /^[\w.%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i : /.*/,
          message: errorMessage,
        },
      }}
      defaultValue={rest.defaultValue || ''}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
        <Input
          ref={inputRef || ref}
          variant={variant}
          error={!!error}
          errorMessage={errorMessage}
          inputName={name}
          label={label}
          value={value as string}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  )
})

FormInput.displayName = 'FormInput'

export default FormInput
