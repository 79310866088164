import { css } from '@emotion/css'
import { IonSpinner } from '@ionic/react'
import { forwardRef } from 'react'

import { DEFAULT_SPINNER } from '../../App'
import { BORDER_RADIUS, COLOR, TRANSITION } from '../../theme'
import { alpha } from '../../utils/colors'

import type { ReactNode } from 'react'

type CameraIconButtonVariant = 'primary' | 'secondary'
type CameraIconButtonSize = 'sm' | 'lg'

interface CameraIconButtonProps {
  isPending?: boolean
  icon: ReactNode
  title: string
  onClick?: () => void
  variant?: CameraIconButtonVariant
  size?: CameraIconButtonSize
}

export function cameraIconButtonStyles(
  variant: CameraIconButtonVariant,
  size: CameraIconButtonSize,
) {
  return css`
    height: ${size === 'sm' ? '36px' : '64px'};
    width: ${size === 'sm' ? '36px' : '64px'};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${variant === 'primary' ? COLOR.black : COLOR.white};
    border-radius: ${BORDER_RADIUS.full};
    background-color: ${variant === 'primary' ? COLOR.primary.DEFAULT : alpha(COLOR.black, 0.25)};
    box-shadow: 0 0 16px 0 ${alpha(COLOR.black, 0.15)};
    transition: ${TRANSITION.base};

    .icon {
      height: ${size === 'sm' ? '18px' : '32px'};
      width: ${size === 'sm' ? '18px' : '32px'};
      transition: ${TRANSITION.base};
    }

    &:active {
      background-color: ${variant === 'primary' ? COLOR.primary.dark : alpha(COLOR.black, 0.4)};
      transform: scale(0.975);
    }
  `
}

const CameraIconButton = forwardRef<HTMLButtonElement, CameraIconButtonProps>((props, ref) => {
  const { icon, onClick, title, variant = 'secondary', size = 'lg', isPending } = props

  return (
    <button
      ref={ref}
      className={cameraIconButtonStyles(variant, size)}
      title={title}
      aria-label={title}
      onClick={onClick}
      disabled={isPending}
    >
      {isPending ? <IonSpinner name={DEFAULT_SPINNER} /> : icon}
    </button>
  )
})

CameraIconButton.displayName = 'CameraIconButton'
export default CameraIconButton
