import { css, cx } from '@emotion/css'
import { useTranslation } from 'react-i18next'

import { LogoApple } from '../../../icons'
import GoogleIcon from '../../../icons/custom/GoogleIcon'
import { COLOR, FONT } from '../../../theme'
import Text from '../Text'

import { buttonSizeStyles } from './BaseButton'
import { buttonStyles, variantStyles } from './Button'

import type { ButtonHTMLAttributes } from 'react'

const signInButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    left: 16px;
  }
`

export function GoogleSignInButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()

  return (
    <button
      {...props}
      className={cx(
        buttonSizeStyles.default,
        buttonStyles('default'),
        variantStyles.secondary,
        signInButtonStyles,
      )}
    >
      <GoogleIcon width={20} height={20} />
      <Text weight={FONT.weight.medium}>{t('Auth.SignIn.google')}</Text>
    </button>
  )
}

export function AppleSignInButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()

  return (
    <button
      {...props}
      className={cx(
        buttonSizeStyles.default,
        buttonStyles('default'),
        signInButtonStyles,
        css({
          'background': COLOR.neutral.darkest,
          'color': COLOR.white,
          ':active': {
            background: COLOR.black,
          },
        }),
      )}
    >
      <LogoApple width={20} height={20} />
      <Text>{t('Auth.SignIn.apple')}</Text>
    </button>
  )
}

export function EmailSignInButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()

  return (
    <button
      {...props}
      className={cx(
        buttonSizeStyles.default,
        buttonStyles('default'),
        variantStyles.secondary,
        signInButtonStyles,
      )}
    >
      <svg
        aria-hidden={true}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className={css({ width: 20, height: 20 })}
      >
        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
      </svg>
      <Text weight={FONT.weight.medium}>{t('Auth.SignIn.email')}</Text>
    </button>
  )
}

export function GuestSignInButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation()

  return (
    <button
      {...props}
      className={cx(
        buttonSizeStyles.default,
        buttonStyles('default'),
        variantStyles.secondary,
        signInButtonStyles,
      )}
    >
      <svg
        aria-hidden={true}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className={css({ width: 20, height: 20 })}
      >
        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
      </svg>
      <Text weight={FONT.weight.medium}>{t('Auth.SignIn.guest')}</Text>
    </button>
  )
}
