import styled from '@emotion/styled'

import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { COLOR, FONT } from '../../../theme'
import { flexGapString } from '../../../utils/css'

import type { ReactNode } from 'react'

interface DashboardSectionProps {
  title: string
  children: ReactNode
  id?: string
}

export const DashboardSectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGapString(20, 'row')}

  button {
    width: 100%;
  }
`

export default function DashboardSection(props: DashboardSectionProps) {
  const { title, children, id } = props

  return (
    <Flex direction="column" gap={20} id={id}>
      <Text
        tag="h2"
        size={FONT.size.xl}
        weight={FONT.weight.semibold}
        color={COLOR.neutral.darkest}
      >
        {title}
      </Text>
      <div>{children}</div>
    </Flex>
  )
}
