import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgShare(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M14.25 6C14.25 3.51472 16.2647 1.5 18.75 1.5C21.2353 1.5 23.25 3.51472 23.25 6C23.25 8.48528 21.2353 10.5 18.75 10.5C17.1083 10.5 15.672 9.6209 14.886 8.30777L9.65813 10.3409C9.71836 10.6345 9.75 10.9386 9.75 11.25C9.75 11.7073 9.68179 12.1487 9.55498 12.5645L15.1162 15.3451C15.9349 14.2265 17.2576 13.5 18.75 13.5C21.2353 13.5 23.25 15.5147 23.25 18C23.25 20.4853 21.2353 22.5 18.75 22.5C16.2647 22.5 14.25 20.4853 14.25 18C14.25 17.543 14.3181 17.102 14.4447 16.6864L8.88322 13.9056C8.06451 15.0238 6.74206 15.75 5.25 15.75C2.76472 15.75 0.75 13.7353 0.75 11.25C0.75 8.76472 2.76472 6.75 5.25 6.75C6.89197 6.75 8.32854 7.62942 9.11441 8.94294L14.342 6.90989C14.2817 6.61602 14.25 6.31171 14.25 6ZM18.75 3C17.0931 3 15.75 4.34315 15.75 6C15.75 7.65685 17.0931 9 18.75 9C20.4069 9 21.75 7.65685 21.75 6C21.75 4.34315 20.4069 3 18.75 3ZM5.25 8.25C3.59315 8.25 2.25 9.59315 2.25 11.25C2.25 12.9069 3.59315 14.25 5.25 14.25C6.90685 14.25 8.25 12.9069 8.25 11.25C8.25 9.59315 6.90685 8.25 5.25 8.25ZM15.75 18C15.75 16.3431 17.0931 15 18.75 15C20.4069 15 21.75 16.3431 21.75 18C21.75 19.6569 20.4069 21 18.75 21C17.0931 21 15.75 19.6569 15.75 18Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
const Memo = memo(SvgShare)
export default Memo
