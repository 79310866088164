import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgPhoneMessage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        fill={COLOR.primary.DEFAULT}
        d="M51.414 91.375C51.414 90.033 44.885 88.944 36.829 88.944C28.773 88.944 22.242 90.033 22.242 91.375C22.242 92.717 28.773 93.806 36.829 93.806C44.885 93.806 51.414 92.718 51.414 91.375Z"
      />
      <path
        fill={COLOR.white}
        d="M53.568 86.037C53.568 87.2835 53.0728 88.479 52.1914 89.3604C51.31 90.2418 50.1145 90.737 48.868 90.737H24.508C23.2615 90.737 22.066 90.2418 21.1846 89.3604C20.3032 88.479 19.808 87.2835 19.808 86.037V33.788C19.808 32.5415 20.3032 31.346 21.1846 30.4646C22.066 29.5832 23.2615 29.088 24.508 29.088H48.871C50.1175 29.088 51.313 29.5832 52.1944 30.4646C53.0758 31.346 53.571 32.5415 53.571 33.788L53.568 86.037Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.568 86.037C53.568 87.2835 53.0728 88.479 52.1914 89.3604C51.31 90.2418 50.1145 90.737 48.868 90.737H24.508C23.2615 90.737 22.066 90.2418 21.1846 89.3604C20.3032 88.479 19.808 87.2835 19.808 86.037V33.788C19.808 32.5415 20.3032 31.346 21.1846 30.4646C22.066 29.5832 23.2615 29.088 24.508 29.088H48.871C50.1175 29.088 51.313 29.5832 52.1944 30.4646C53.0758 31.346 53.571 32.5415 53.571 33.788L53.568 86.037Z"
      />
      <path fill={COLOR.primary.DEFAULT} d="M50.634 37.897H22.747V81.195H50.634V37.897Z" />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50.634 37.897H22.747V81.195H50.634V37.897ZM35.222 33.494H43.294"
      />
      <path
        fill={COLOR.black}
        d="M31.73 33.494C31.73 33.6745 31.6765 33.8508 31.5762 34.0008C31.476 34.1508 31.3335 34.2677 31.1668 34.3367C31.0001 34.4057 30.8167 34.4237 30.6397 34.3884C30.4628 34.3532 30.3003 34.2662 30.1728 34.1386C30.0453 34.0109 29.9585 33.8483 29.9234 33.6713C29.8883 33.4944 29.9065 33.311 29.9757 33.1443C30.0449 32.9777 30.1619 32.8353 30.3121 32.7352C30.4622 32.6352 30.6386 32.5818 30.819 32.582C30.9388 32.5818 31.0575 32.6052 31.1682 32.6509C31.2789 32.6967 31.3795 32.7639 31.4641 32.8486C31.5488 32.9334 31.6159 33.034 31.6615 33.1448C31.7071 33.2556 31.7304 33.3742 31.73 33.494Z"
      />
      <path
        fill={COLOR.white}
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M36.69 88.534C38.1088 88.534 39.259 87.3838 39.259 85.965C39.259 84.5462 38.1088 83.396 36.69 83.396C35.2712 83.396 34.121 84.5462 34.121 85.965C34.121 87.3838 35.2712 88.534 36.69 88.534Z"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeDasharray="8 16"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M61.869 32.2401C58.7039 35.7485 56.759 40.1869 56.325 44.892C55.21 56.043 66.225 57.8281 68.48 52.1851C70.911 46.1081 59.972 40.03 51.463 44.892C42.954 49.754 42.955 60.6921 42.955 60.6921"
      />
      <path
        fill={COLOR.white}
        d="M88.6 6.12903 55.269 20.942C54.9868 21.067 54.7501 21.2764 54.5917 21.5413 54.4333 21.8063 54.3608 22.1138 54.3843 22.4216 54.4077 22.7294 54.526 23.0224 54.7227 23.2603 54.9195 23.4982 55.1851 23.6693 55.483 23.75L67.883 27.131 76.693 39.718C76.8511 39.941 77.0671 40.1166 77.3178 40.2258 77.5684 40.3349 77.8441 40.3735 78.115 40.3374 78.386 40.3013 78.6419 40.1918 78.8552 40.0207 79.0684 39.8497 79.2309 39.6237 79.325 39.367L90.612 8.00003C90.7081 7.72488 90.7219 7.42771 90.6518 7.14483 90.5817 6.86196 90.4307 6.60562 90.2173 6.40716 90.0039 6.2087 89.7373 6.07671 89.4501 6.02732 89.1629 5.97792 88.8675 6.01327 88.6 6.12903ZM67.879 27.131V37.286C67.8792 37.4338 67.9231 37.5782 68.0053 37.701 68.0874 37.8239 68.2041 37.9196 68.3406 37.9762 68.4771 38.0328 68.6273 38.0477 68.7722 38.019 68.9172 37.9903 69.0504 37.9193 69.155 37.815L72.8 34.166"
      />
      <path
        stroke={COLOR.black}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M67.879 27.131V37.286C67.8792 37.4338 67.9231 37.5782 68.0053 37.701 68.0874 37.8239 68.2041 37.9196 68.3406 37.9762 68.4771 38.0328 68.6273 38.0477 68.7722 38.019 68.9172 37.9903 69.0504 37.9193 69.155 37.815L72.8 34.166M67.879 27.131 90.141 6.328M88.6 6.12903 55.269 20.942C54.9868 21.067 54.7501 21.2764 54.5917 21.5413 54.4333 21.8063 54.3608 22.1138 54.3843 22.4216 54.4077 22.7294 54.526 23.0224 54.7227 23.2603 54.9195 23.4982 55.1851 23.6693 55.483 23.75L67.883 27.131 76.693 39.718C76.8511 39.941 77.0671 40.1166 77.3178 40.2258 77.5684 40.3349 77.8441 40.3735 78.115 40.3374 78.386 40.3013 78.6419 40.1918 78.8552 40.0207 79.0684 39.8497 79.2309 39.6237 79.325 39.367L90.612 8.00003C90.7081 7.72488 90.7219 7.42771 90.6518 7.14483 90.5817 6.86196 90.4307 6.60562 90.2173 6.40716 90.0039 6.2087 89.7373 6.07671 89.4501 6.02732 89.1629 5.97792 88.8675 6.01327 88.6 6.12903Z"
      />
    </svg>
  )
}
const Memo = memo(SvgPhoneMessage)
export default Memo
