import styled from '@emotion/styled'
import { IonRefresher, IonRefresherContent, useIonRouter } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { DEFAULT_SPINNER } from '../../../App'
import { ROUTES } from '../../../Router'
import ProjectSubpage from '../../../components/layout/ProjectSubpage'
import ActionSheetModal from '../../../components/ui/ActionSheetModal'
import Button from '../../../components/ui/Button/Button'
import { ButtonGroup } from '../../../components/ui/Button/ButtonGroup'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { useCollaborators } from '../../../hooks/useCollaborators'
import { useSingleProject } from '../../../hooks/useSingleProject'
import { PlusCircle } from '../../../icons'
import { COLOR, PADDING } from '../../../theme'
import ProjectDashboardItem from '../Dashboard/ProjectDashboardItem'

import CollaboratorItem from './CollaboratorItem'

import type { RefresherCustomEvent } from '@ionic/react'

export const CollaboratorList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 -${PADDING.DEFAULT};
  list-style: none;

  > li + li {
    border-top: 1px solid ${COLOR.neutral.lighter};
  }
`

export const MAX_COLLABORATORS = 30

export default function Collaborators() {
  const { t } = useTranslation()
  const routeParams = useParams<{ id: string }>()
  const ionRouter = useIonRouter()
  const [actionSheetVisible, setActionSheetVisible] = useState(false)

  const { project, refetch } = useSingleProject(routeParams.id)

  const handleRefresh = (event: RefresherCustomEvent) => {
    void refetch().then(() => {
      event.detail.complete()
    })
  }

  const { activeCollaborators, pendingCollaborators, numberOfCollaborators } =
    useCollaborators(project)

  function onDeleteCollaborator() {
    void refetch()
  }

  return (
    <ProjectSubpage
      title={`${t(
        'ProjectDashboard.Collaborators.acceptedCollaborators',
      )} (${numberOfCollaborators}/${MAX_COLLABORATORS})`}
      project={project}
    >
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={DEFAULT_SPINNER} />
      </IonRefresher>
      <Flex direction="column" gap={40}>
        <Text tag="p" color={COLOR.neutral.dark}>
          {t('ProjectDashboard.Collaborators.description')}
        </Text>
        <CollaboratorList>
          {!!project && (
            <li>
              <CollaboratorItem user={project.creator} isCreator={true} />
            </li>
          )}
          {activeCollaborators?.map((collaborator) => (
            <li key={collaborator.id}>
              <CollaboratorItem
                user={collaborator.user}
                collaboratorId={collaborator.id}
                onDelete={onDeleteCollaborator}
              />
            </li>
          ))}
        </CollaboratorList>
        <ButtonGroup buttonsGrow>
          <Button
            icon={<PlusCircle />}
            variant="secondary"
            disabled={numberOfCollaborators >= MAX_COLLABORATORS}
            onClick={() => {
              setActionSheetVisible(false)
              ionRouter.push(ROUTES.inviteToProject(project?.id), 'forward')
            }}
          >
            {t('ProjectDashboard.Collaborators.addCollaborator')}
          </Button>
        </ButtonGroup>
        {pendingCollaborators.length > 0 && (
          <ProjectDashboardItem title={t('ProjectDashboard.Collaborators.pendingCollaborators')}>
            <CollaboratorList>
              {pendingCollaborators.map((pendingCollaborator) => (
                <li key={pendingCollaborator.id}>
                  <CollaboratorItem
                    user={pendingCollaborator.user}
                    collaboratorId={pendingCollaborator.id}
                    onDelete={onDeleteCollaborator}
                  />
                </li>
              ))}
            </CollaboratorList>
          </ProjectDashboardItem>
        )}
      </Flex>
      <ActionSheetModal
        isOpen={actionSheetVisible}
        onClose={() => {
          setActionSheetVisible(false)
        }}
      >
        <ButtonGroup buttonsGrow>
          <Button
            icon={<PlusCircle />}
            variant="secondary"
            onClick={() => {
              setActionSheetVisible(false)
              ionRouter.push(ROUTES.inviteToProject(project?.id), 'forward')
            }}
          >
            {t('ProjectDashboard.Collaborators.addCollaborator')}
          </Button>
        </ButtonGroup>
      </ActionSheetModal>
    </ProjectSubpage>
  )
}
