import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgAlertTriangle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_234_375)">
        <path d="M12.75 8.25C12.75 7.83579 12.4142 7.5 12 7.5 11.5858 7.5 11.25 7.83579 11.25 8.25V15C11.25 15.4142 11.5858 15.75 12 15.75 12.4142 15.75 12.75 15.4142 12.75 15V8.25ZM12 17.25C12.6213 17.25 13.125 17.7537 13.125 18.375 13.125 18.9963 12.6213 19.5 12 19.5 11.3787 19.5 10.875 18.9963 10.875 18.375 10.875 17.7537 11.3787 17.25 12 17.25Z" />
        <path
          fillRule="evenodd"
          d="M12 0.750488C11.5978 0.750488 11.203 0.858265 10.8565 1.06268C10.5103 1.26703 10.2249 1.56051 10.0304 1.91266L0.280368 19.9127L0.278791 19.9156C0.090739 20.2574 -0.00523871 20.6423 0.000353162 21.0324C0.00595922 21.4236 0.113459 21.8066 0.312336 22.1436C0.511206 22.4807 0.794497 22.76 1.13424 22.954C1.47387 23.1479 1.85854 23.2502 2.24997 23.2502H21.75C22.1413 23.2502 22.5259 23.148 22.8655 22.9541C23.2052 22.7601 23.4887 22.4807 23.6876 22.1437C23.8864 21.8068 23.9939 21.4237 23.9996 21.0326C24.0052 20.6424 23.9091 20.2574 23.7212 19.9157L23.7197 19.9129L13.9725 1.918L13.9694 1.91245C13.7749 1.56048 13.4897 1.26704 13.1434 1.06268C12.797 0.858278 12.4022 0.750488 12 0.750488ZM11.6189 2.35452C11.7343 2.28643 11.8658 2.25049 12 2.25049C12.134 2.25049 12.2656 2.28643 12.381 2.35454C12.4958 2.42223 12.5904 2.51927 12.6553 2.63568L12.6566 2.63797L22.4035 20.6324L22.4063 20.6375C22.4694 20.7518 22.5016 20.8805 22.4998 21.0108C22.4979 21.1413 22.462 21.2691 22.3957 21.3814C22.3295 21.4936 22.2351 21.5867 22.1219 21.6515C22.0085 21.7162 21.8803 21.7502 21.75 21.7502H2.24997C2.11976 21.7502 1.99162 21.7162 1.87826 21.6516C1.76494 21.5868 1.67045 21.4936 1.60416 21.3813C1.53793 21.2691 1.50207 21.1414 1.5002 21.011C1.49833 20.8806 1.53052 20.7519 1.59359 20.6377L11.3436 2.63775L11.3448 2.63551C11.4095 2.51926 11.5041 2.42224 11.6189 2.35452Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_234_375">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgAlertTriangle)
export default Memo
