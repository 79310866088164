import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_241_379)">
        <path d="M23.14 10.61L2.25 0.160039C2.01209 0.0427105 1.7482 -0.012015 1.48326 0.0010346C1.21831 0.0140842 0.961073 0.0944778 0.735849 0.234617C0.510625 0.374757 0.324855 0.570014 0.196098 0.801933C0.0673404 1.03385 -0.000151637 1.29477 2.55811e-07 1.56004V22.44C-0.000151637 22.7053 0.0673404 22.9662 0.196098 23.1981C0.324855 23.4301 0.510625 23.6253 0.735849 23.7655C0.961073 23.9056 1.21831 23.986 1.48326 23.999C1.7482 24.0121 2.01209 23.9574 2.25 23.84L23.14 13.39C23.3994 13.2621 23.6178 13.064 23.7705 12.8184C23.9232 12.5728 24.0041 12.2893 24.0041 12C24.0041 11.7108 23.9232 11.4273 23.7705 11.1817C23.6178 10.936 23.3994 10.738 23.14 10.61Z" />
      </g>
      <defs>
        <clipPath id="clip0_241_379">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgPlay)
export default Memo
