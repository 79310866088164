import { registerPlugin } from '@capacitor/core'

import type { IronSourceIntegrationPlugin } from './definitions'

const IronSourceIntegration = registerPlugin<IronSourceIntegrationPlugin>(
  'IronSourceIntegration',
  {},
)

export * from './definitions'
export { IronSourceIntegration }
