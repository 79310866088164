import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgArrowCircleRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_97_394)">
        <path d="M13.2813 7.71657C12.9884 7.42367 12.5135 7.42367 12.2207 7.71657C11.9278 8.00946 11.9278 8.48433 12.2207 8.77723L14.6903 11.2469H7.50098C7.08677 11.2469 6.75098 11.5827 6.75098 11.9969C6.75098 12.4111 7.08677 12.7469 7.50098 12.7469H14.6903L12.2207 15.2166C11.9278 15.5095 11.9278 15.9843 12.2207 16.2772C12.5135 16.5701 12.9884 16.5701 13.2813 16.2772L17.0313 12.5272C17.3242 12.2343 17.3242 11.7595 17.0313 11.4666L13.2813 7.71657Z" />
        <path
          fillRule="evenodd"
          d="M12.001 2.24695C6.61623 2.24695 2.25101 6.61217 2.25101 11.9969C2.25101 17.3817 6.61623 21.7469 12.001 21.7469C17.3858 21.7469 21.751 17.3817 21.751 11.9969C21.751 6.61217 17.3858 2.24695 12.001 2.24695ZM0.751007 11.9969C0.751007 5.78374 5.7878 0.746948 12.001 0.746948C18.2142 0.746948 23.251 5.78374 23.251 11.9969C23.251 18.2102 18.2142 23.2469 12.001 23.2469C5.7878 23.2469 0.751007 18.2102 0.751007 11.9969Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_97_394">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgArrowCircleRight)
export default Memo
