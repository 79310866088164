import styled from '@emotion/styled'
import { forwardRef } from 'react'

import { flexGapObject } from '../../utils/css'

import type { HTMLAttributes, ReactNode } from 'react'

interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  direction?: 'row' | 'column'
  gap?: number
  alignItems?: 'normal' | 'flex-start' | 'center' | 'flex-end'
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
}

export const FlexStyled = styled.div<FlexProps>((props) => ({
  display: 'flex',
  flexDirection: props.direction,
  ...flexGapObject(props.gap || 12, props.direction === 'column' ? 'row' : 'column'),
  alignItems: props.alignItems,
  justifyContent: props.justifyContent,
}))

const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    children,
    direction = 'row',
    gap = 12,
    alignItems = 'normal',
    justifyContent = 'flex-start',
    ...rest
  } = props

  return (
    <FlexStyled
      {...rest}
      ref={ref}
      gap={gap}
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      {children}
    </FlexStyled>
  )
})

Flex.displayName = 'Flex'
export default Flex
