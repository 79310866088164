import { Capacitor } from '@capacitor/core'
import { Toast } from '@capacitor/toast'
import { SignInWithApple } from '@capacitor-community/apple-sign-in'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { css } from '@emotion/css'
import { IonContent, useIonLoading, useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { DEFAULT_SPINNER } from '../../App'
import { ROUTES } from '../../Router'
import Page from '../../components/layout/Page'
import { ButtonGroup } from '../../components/ui/Button/ButtonGroup'
import GhostButton from '../../components/ui/Button/GhostButton'
import {
  AppleSignInButton,
  EmailSignInButton,
  GoogleSignInButton,
  GuestSignInButton,
} from '../../components/ui/Button/SignUpButtons'
import Flex from '../../components/ui/Flex'
import Text from '../../components/ui/Text'
import { useAuthContext } from '../../context/AuthContext'
import LogoWinkii from '../../icons/custom/LogoWinkii'
import { COLOR } from '../../theme'

export default function Auth() {
  useEffect(() => {
    void FirebaseAnalytics.setScreenName({ screenName: 'Auth', nameOverride: 'Auth' })
  }, [])

  const { t } = useTranslation()

  const ionRouter = useIonRouter()
  const { loginWithGoogle, loginWithApple } = useAuthContext()
  const history = useHistory()

  const [presentLoading, dismissLoading] = useIonLoading()

  const generalErrorMessage = t('General.error')

  const handleGoogleSignIn = async () => {
    await presentLoading({ spinner: DEFAULT_SPINNER })
    await FirebaseAnalytics.logEvent({ name: 'handleGoogleSignIn', params: {} })
    let googleUser

    try {
      googleUser = await GoogleAuth.signIn()
    } catch (error: any) {
      await FirebaseAnalytics.logEvent({
        name: 'googleSignInFailed',
        params: {
          error: error.message as string,
        },
      })
      await dismissLoading()

      const hasCancelled = error.code === 12501 || error.message.includes('canceled')

      if (!hasCancelled) await Toast.show({ text: generalErrorMessage })

      return
    }

    try {
      await loginWithGoogle(googleUser)
      await dismissLoading()
      history.replace(ROUTES.dashboard)
    } catch {
      await FirebaseAnalytics.logEvent({
        name: 'googleSignInFailed',
        params: {
          error: 'authContextError',
        },
      })
      await dismissLoading()
      await Toast.show({
        text: generalErrorMessage,
      })
    }

    await dismissLoading()
  }

  const handleAppleSignIn = async () => {
    if (Capacitor.getPlatform() !== 'ios') return

    await presentLoading({ spinner: DEFAULT_SPINNER })
    await FirebaseAnalytics.logEvent({ name: 'handleAppleSignIn', params: {} })

    let appleUser

    try {
      appleUser = await SignInWithApple.authorize({
        scopes: 'name email',
        nonce: '',
        state: '',
        redirectURI: '',
        clientId: '',
      })
    } catch (error: any) {
      await FirebaseAnalytics.logEvent({
        name: 'appleSignInFailed',
        params: {
          error: error.message as string,
        },
      })
      await dismissLoading()
      return
    }

    try {
      await loginWithApple(appleUser)
      await dismissLoading()
      history.replace(ROUTES.dashboard)
    } catch {
      await FirebaseAnalytics.logEvent({
        name: 'appleSignInFailed',
        params: {
          error: 'authContextError',
        },
      })
      await dismissLoading()
      await Toast.show({
        text: t('General.error'),
      })
    }

    await dismissLoading()
  }

  return (
    <Page safeArea className="ion-safe-area-top">
      <IonContent className="ion-padding">
        <div
          aria-hidden
          style={{
            position: 'absolute',
            backgroundColor: COLOR.primary.lighter,
            inset: 0,
            zIndex: -1,
            opacity: 0.25,
            pointerEvents: 'none',
          }}
        >
          <img
            aria-hidden
            src="/img/background-blobs.png"
            alt=""
            style={{
              position: 'absolute',
              inset: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              pointerEvents: 'none',
            }}
          />
        </div>
        <Flex
          direction="column"
          justifyContent="space-between"
          className={css({ height: '100%', paddingTop: 32 })}
        >
          <div />
          <Flex direction="column" alignItems="center">
            <img src="/img/winkii.svg" alt="" width={128} height={128} />
            <LogoWinkii height={56} />
          </Flex>
          <Flex direction="column" gap={32}>
            <ButtonGroup>
              {Capacitor.getPlatform() === 'ios' && (
                <AppleSignInButton onClick={() => void handleAppleSignIn()} />
              )}
              {Capacitor.getPlatform() !== 'web' && (
                <GoogleSignInButton onClick={() => void handleGoogleSignIn()} />
              )}
              <EmailSignInButton onClick={() => ionRouter.push(ROUTES.register)} />
              {!Capacitor.isNativePlatform() && (
                <GuestSignInButton onClick={() => ionRouter.push(ROUTES.guestLogin)} />
              )}
            </ButtonGroup>
            <Flex direction="column" gap={2}>
              <Text className={css({ textAlign: 'center' })}>{t('Auth.alreadyAccount')}</Text>
              <GhostButton
                variant="secondary"
                className={css({ color: `${COLOR.black} !important` })}
                onClick={() => ionRouter.push(ROUTES.login)}
              >
                {t('Auth.toLogin')}
              </GhostButton>
            </Flex>
          </Flex>
        </Flex>
      </IonContent>
    </Page>
  )
}
