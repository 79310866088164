import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links'
import { useCallback, useEffect } from 'react'

import { buildUrl } from './utils/api'
import { REACT_APP_DYNAMIC_LINK_URL } from './utils/env'

import type { URLOpenListener } from '@capacitor/app'
import type {
  DeepLinkOpen,
  LinkConfig,
  SocialMetaTagParameters,
} from '@pantrist/capacitor-firebase-dynamic-links'

export function createFirebaseDynamicShortLink(slug: string, socialMeta?: SocialMetaTagParameters) {
  // issue: https://github.com/Pantrist-dev/capacitor-firebase-dynamic-links/issues/37

  const config: LinkConfig = {
    domainUriPrefix: REACT_APP_DYNAMIC_LINK_URL,
    uri: buildUrl(`https://${process.env.REACT_APP_WEB_DOMAIN}`, slug),
    iosParameters: {
      bundleId: null as unknown as string,
    },
  }

  if (Capacitor.getPlatform() === 'ios') {
    delete config.iosParameters
  }

  if (socialMeta) config.socialMeta = socialMeta

  return FirebaseDynamicLinks.createDynamicShortLink(config)
}

export default function AppUrlListener() {
  const ionRouter = useIonRouter()

  /**
   * Handle direct app links from notifications
   */
  const appUrlOpen = useCallback<URLOpenListener>(
    (data) => {
      const regexDomain = new RegExp(`^https?:\\/\\/${process.env.REACT_APP_WEB_DOMAIN}\\/?`, 'i')
      const slug = `/${data.url.replace(/^winkii:\/\//i, '').replace(regexDomain, '')}`

      if (slug) ionRouter.push(slug)
    },
    [ionRouter],
  )

  /**
   * Handle Firebase dynamic links
   */
  const deepLinkOpen = useCallback(
    (data: DeepLinkOpen) => {
      const { url } = data

      appUrlOpen({ url })
    },
    [ionRouter],
  )

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    FirebaseDynamicLinks.addListener('deepLinkOpen', deepLinkOpen)

    /**
     * Handle direct app links from notifications
     */
    void App.addListener('appUrlOpen', appUrlOpen)
  }, [appUrlOpen, deepLinkOpen, ionRouter])

  return null
}
