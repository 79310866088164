import styled from '@emotion/styled'

import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../../theme'
import Flex from '../Flex'
import Text from '../Text'

import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react'

export interface SelectionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  image: ReactJSXElement
}

const SelectionButtonStyled = styled.button`
  border-radius: ${BORDER_RADIUS.base};
  box-shadow: ${SHADOW.base};
  background: ${COLOR.white};
  border: 1px solid ${COLOR.neutral.light};
  padding: 16px 12px;
  transition: ${TRANSITION.base};

  :active {
    box-shadow: ${SHADOW.lg};
    background-color: ${COLOR.primary.lighter};
    transform: scale(0.975);
  }

  svg {
    width: 56px;
    height: 56px;
  }
`

export default function SelectionButton(props: PropsWithChildren<SelectionButtonProps>) {
  const { title, image, ...rest } = props

  return (
    <SelectionButtonStyled {...rest}>
      <Flex alignItems="center">
        {image}
        <Text size={FONT.size.base} weight={FONT.weight.medium}>
          {title}
        </Text>
      </Flex>
    </SelectionButtonStyled>
  )
}
