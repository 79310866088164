import { useTranslation } from 'react-i18next'

import Button from '../ui/Button/Button'

export default function SupportButton() {
  const { t } = useTranslation()

  const onClick = () => window.open('mailto:support@winkii.de')

  return <Button onClick={onClick}>{t('Shop.Failed.button')}</Button>
}
