import styled from '@emotion/styled'
import { IonContent, useIonLoading, useIonRouter, useIonToast } from '@ionic/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import Button from '../../../../components/ui/Button/Button'
import FormInput from '../../../../components/ui/Input/FormInput'
import { useAuthContext } from '../../../../context/AuthContext'
import { COLOR, FONT } from '../../../../theme'
import { FormStyled } from '../../../Auth/Auth.styled'

import type { FieldValues } from 'react-hook-form'

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;

  h2 {
    font-size: ${FONT.size.xl};
    font-weight: ${FONT.weight.medium};
    color: ${COLOR.neutral.darkest};
    margin: 0;
    margin-bottom: 16px;
  }
  span {
    font-size: ${FONT.size.base};
    color: ${COLOR.neutral.darkest};
    line-height: ${FONT.lineHeight.xl};
  }
`

export default function ChangePassword() {
  const { t } = useTranslation()
  const [present, dismiss] = useIonLoading()
  const ionRouter = useIonRouter()
  const [presentToast] = useIonToast()
  const { user, changePassword } = useAuthContext()
  const { control, handleSubmit } = useForm({ mode: 'onSubmit' })

  function changePasswordSubmit(data: FieldValues) {
    if (user?.id) {
      void present({ message: t('Profile.AccountSettings.ChangePassword.loading') })
      changePassword(user.id, data.password as string)
        .then(() => {
          void dismiss()
          void presentToast({
            message: t('Profile.AccountSettings.ChangePassword.success'),
            duration: 2000,
          })
          ionRouter.push(ROUTES.profile, 'back', 'pop')
        })
        .catch(() => {
          void dismiss()
          void presentToast({
            message: t('Profile.AccountSettings.ChangePassword.error'),
            duration: 2000,
          })
        })
    }
  }

  return (
    <Page>
      <SubpageHeader
        title={t('Profile.AccountSettings.title')}
        showBackButton={true}
        backButtonRoute={ROUTES.accountSettings}
      />
      <IonContent className="ion-padding">
        <TextWrapper>
          <h2>{t('Profile.AccountSettings.ChangePassword.headline')}</h2>
          <span>{t('Profile.AccountSettings.ChangePassword.description')}</span>
        </TextWrapper>
        <FormStyled onSubmit={() => void handleSubmit(changePasswordSubmit)()}>
          <FormInput
            control={control}
            inputName="password"
            type="password"
            errorMessage={t('Login.error')}
            label={t('Profile.AccountSettings.ChangePassword.inputLabel')}
          />
          <Button type="submit" variant="primary">
            {t('Profile.AccountSettings.ChangePassword.confirmButton')}
          </Button>
        </FormStyled>
      </IonContent>
    </Page>
  )
}
