import { Device } from '@capacitor/device'
import { Preferences } from '@capacitor/preferences'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FadingSwitchTransition } from '../components/common/Transitions'
import { Loading } from '../components/common/WithLoading'
import Flex from '../components/ui/Flex'

import { useApiContext } from './ApiContext'

import type { AppConfigDto } from '../types'
import type { PropsWithChildren } from 'react'

type AppConfigValue = AppConfigDto & { refetchConfig: () => Promise<void> }

// @ts-expect-error default value will never be used
const defaultValue: AppConfigValue = {}
export const AppConfigContext = createContext<AppConfigValue>(defaultValue)

export function AppConfigProvider(props: PropsWithChildren) {
  const { children } = props

  const APP_CONFIG_STORAGE_KEY = 'appConfig'
  const { apiClient } = useApiContext()
  const { i18n } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [appConfig, setAppConfig] = useState<AppConfigDto | null>(null)

  useEffect(() => {
    void Preferences.get({ key: APP_CONFIG_STORAGE_KEY }).then(async (result) => {
      if (result.value) {
        setAppConfig(JSON.parse(result.value) as AppConfigDto)
        setIsLoading(false)
        return
      }

      const preferredLang = await Device.getLanguageCode()
      await apiClient
        .getAppConfig(preferredLang.value)
        .then(async (currentAppConfig) => {
          setAppConfig(currentAppConfig)
          await Preferences.set({
            key: APP_CONFIG_STORAGE_KEY,
            value: JSON.stringify(currentAppConfig),
          })
          await i18n.changeLanguage(preferredLang.value)
        })
        .catch(() => {
          // noop
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [])

  const refetchConfig = async () => {
    await apiClient
      .getAppConfig(i18n.language)
      .then(async (currentAppConfig) => {
        setAppConfig(currentAppConfig)
        await Preferences.set({
          key: APP_CONFIG_STORAGE_KEY,
          value: JSON.stringify(currentAppConfig),
        })
      })
      .catch(() => {
        // noop
      })
  }

  return (
    <FadingSwitchTransition transitionKey={isLoading ? 'loading' : 'content'}>
      {isLoading ? (
        <Flex style={{ position: 'fixed', inset: 0 }} alignItems="center" justifyContent="center">
          <Loading />
        </Flex>
      ) : appConfig ? (
        <AppConfigContext.Provider value={{ ...appConfig, refetchConfig }}>
          {children}
        </AppConfigContext.Provider>
      ) : null}
    </FadingSwitchTransition>
  )
}

export const useAppConfig = () => useContext(AppConfigContext)
