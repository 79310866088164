import styled from '@emotion/styled'
import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react'

import { FONT } from '../../theme'
import BackButton from '../common/BackButton'

import type { ReactNode } from 'react'

export interface SubpageHeaderProps {
  button?: ReactNode
  title?: string
  showBackButton?: boolean
  backButtonRoute?: string
}

const ToolbarStyled = styled(IonToolbar)`
  --padding-start: 10px;
  --padding-end: 10px;
`

const TitleStyled = styled(IonTitle)`
  font-size: ${FONT.size.sm};
  font-weight: ${FONT.weight.medium};
`

export default function SubpageHeader(props: SubpageHeaderProps) {
  const { button, title, showBackButton = false, backButtonRoute } = props

  return (
    <IonHeader>
      <ToolbarStyled>
        {showBackButton && (
          <IonButtons slot="start">
            <BackButton backRoute={backButtonRoute} />
          </IonButtons>
        )}
        {!!title && <TitleStyled>{title}</TitleStyled>}
        {!!button && <IonButtons slot="end">{button}</IonButtons>}
      </ToolbarStyled>
    </IonHeader>
  )
}
