import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { IonButtons, IonContent, IonHeader, IonToolbar } from '@ionic/react'
import { useEffect, useRef } from 'react'

import { COLOR, FONT } from '../../theme'
import { alpha } from '../../utils/colors'
import { lineClamp } from '../../utils/css'
import { setStatusbarBlack, setStatusbarLight } from '../../utils/statusBar'
import BackButton from '../common/BackButton'
import LazyImage from '../common/LazyImage'
import { FadingEnterLeaveTransition } from '../common/Transitions'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

import type { SubpageHeaderProps } from './SubpageHeader'
import type { ScrollDetail } from '@ionic/core'
import type { IonContentCustomEvent } from '@ionic/core/dist/types/components'
import type { PropsWithChildren } from 'react'

interface Props extends SubpageHeaderProps {
  image: string
  subtitle?: string
  onSubtitleClick?: () => void
}

export const HEADER_HEIGHT = 225

const Content = styled(IonContent)`
  --padding-top: calc(${HEADER_HEIGHT}px + 24px - 50px);
`

const Toolbar = styled(IonToolbar)`
  --background: transparent !important;
  --color: white !important;
`

const HeaderContent = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: calc(50px + var(--ion-safe-area-top));
  height: calc(${HEADER_HEIGHT}px + var(--ion-safe-area-top));
  color: white;
  background-size: cover;
  overflow: hidden;
`

const HeaderContentInner = styled(Flex)`
  padding-top: calc(50px + var(--ion-safe-area-top) + 32px);
`

export default function ContentWithImageHeader(props: PropsWithChildren<Props>) {
  const { button, backButtonRoute, image, title, subtitle, onSubtitleClick, children } = props

  const headerContentRef = useRef<HTMLDivElement>(null)

  const onScroll = (event: IonContentCustomEvent<ScrollDetail>) => {
    if (headerContentRef.current) {
      const { scrollTop } = event.detail
      headerContentRef.current.style.maxHeight = `calc(var(--ion-safe-area-top) + ${
        HEADER_HEIGHT - scrollTop
      }px)`
    }
  }

  useEffect(() => {
    setStatusbarBlack()

    return () => {
      setStatusbarLight()
    }
  }, [])

  return (
    <>
      <IonHeader>
        <Toolbar>
          <IonButtons slot="start">
            <BackButton white backRoute={backButtonRoute} />
          </IonButtons>
          <IonButtons slot="end">{button}</IonButtons>
        </Toolbar>
        <HeaderContent ref={headerContentRef}>
          <LazyImage className={css({ zIndex: -2 })} src={image} />
          <div
            className={css({
              position: 'absolute',
              inset: 0,
              background: alpha(COLOR.black, 0.6),
              zIndex: -1,
            })}
          />
          <HeaderContentInner className="ion-padding" direction="column" onClick={onSubtitleClick}>
            <FadingEnterLeaveTransition show={!!title}>
              <Text
                tag="h1"
                size={FONT.size['2xl']}
                weight={FONT.weight.semibold}
                className={lineClamp(2)}
              >
                {title}
              </Text>
            </FadingEnterLeaveTransition>
            <FadingEnterLeaveTransition show={!!subtitle}>
              <Text tag="p" className={lineClamp(2)}>
                {subtitle}
              </Text>
            </FadingEnterLeaveTransition>
          </HeaderContentInner>
        </HeaderContent>
      </IonHeader>
      <Content className="ion-padding" scrollEvents={true} onIonScroll={onScroll}>
        {children}
      </Content>
    </>
  )
}
