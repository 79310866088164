import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgLogoApple(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M16.9915 5.61889C14.8318 5.61889 13.9191 6.64944 12.4151 6.64944 10.873 6.64944 9.69678 5.62639 7.8253 5.62639 5.99345 5.62639 4.04002 6.74478 2.79951 8.65001 1.05765 11.3367 1.35332 16.3968 4.17447 20.7075 5.18359 22.2506 6.53122 23.9813 8.29879 24H8.33093C9.86711 24 10.3235 22.9941 12.4376 22.9823H12.4697C14.5522 22.9823 14.97 23.9941 16.4998 23.9941H16.5319C18.2995 23.9754 19.7194 22.0578 20.7285 20.5206 21.4549 19.415 21.7248 18.8601 22.2819 17.6094 18.2009 16.0604 17.5453 10.2751 21.5813 8.05707 20.3493 6.51446 18.6182 5.62103 16.9861 5.62103L16.9915 5.61889ZM16.5159 0C15.2304.0873072 13.7307.905745 12.8522 1.97432 12.0552 2.94273 11.3996 4.37929 11.6567 5.77245H11.7596C13.1286 5.77245 14.5298 4.94812 15.3483 3.89186 16.1367 2.88649 16.7345 1.46173 16.5159 0Z" />
    </svg>
  )
}
const Memo = memo(SvgLogoApple)
export default Memo
