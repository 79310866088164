import { Capacitor } from '@capacitor/core'
import { useIonRouter } from '@ionic/react'

export default function AppStoreRedirect() {
  if (!Capacitor.isNativePlatform())
    window.location.href = 'https://apps.apple.com/us/app/winkii/id1667873462'

  const ionRouter = useIonRouter()
  ionRouter.push('/')

  return <div></div>
}
