import { useBoolean } from 'usehooks-ts'

import QuestionItem from '../../../components/projects/questions/QuestionItem'
import IconButton from '../../../components/ui/IconButton/IconButton'
import { DotsVertical } from '../../../icons'

import QuestionActionSheet from './QuestionActionSheet'
import QuestionFormSheet from './QuestionFormSheet'

import type { CreateProjectQuestionDto } from '../../../types'

interface Props {
  question: CreateProjectQuestionDto
  receiverName: string
  onUpdate: (values: CreateProjectQuestionDto) => void
  onDelete: () => void
}

export default function UnsavedQuestionItem(props: Props) {
  const { question, receiverName, onUpdate, onDelete } = props

  const {
    value: editSheetOpen,
    setTrue: openEditSheet,
    setFalse: closeEditSheet,
  } = useBoolean(false)

  const {
    value: actionSheetOpen,
    setTrue: openActionSheet,
    setFalse: closeActionSheet,
  } = useBoolean(false)

  const onSelectEdit = () => {
    closeActionSheet()
    openEditSheet()
  }

  const onSelectDelete = () => {
    closeActionSheet()

    /**
     * This is a hack to make sure the action sheet is closed before the question is deleted.
     * Otherwise, the action sheet won't be closed, as the component is unmounted before the action
     * sheet is closed.
     */
    setTimeout(() => {
      onDelete()
    }, 1)
  }

  const handleUpdate = (values: CreateProjectQuestionDto) => {
    closeEditSheet()
    onUpdate(values)
  }

  return (
    <>
      <QuestionItem
        questionTitle={question.title}
        receiverName={receiverName}
        button={<IconButton icon={<DotsVertical />} title="" onClick={openActionSheet} />}
      />
      <QuestionActionSheet
        isOpen={actionSheetOpen}
        onClose={closeActionSheet}
        onSelectDelete={onSelectDelete}
        onSelectEdit={onSelectEdit}
      />
      <QuestionFormSheet
        question={question}
        isOpen={editSheetOpen}
        onClose={closeEditSheet}
        onSubmit={handleUpdate}
      />
    </>
  )
}
