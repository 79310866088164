import { useQuery } from '@tanstack/react-query'

import { useApiContext } from '../context/ApiContext'

export function useInvitationCode(authReady: boolean) {
  if (!authReady) return undefined

  const { apiClient } = useApiContext()

  const { data, isLoading } = useQuery(
    ['projects/invitation-code'],
    () => apiClient.getInvitationCode(),
    {
      retry: 2,
      enabled: authReady,
    },
  ) as { data: { invitationCode: string } | undefined; isLoading: boolean } // TODO: fix this type hack

  if (isLoading) {
    return undefined
  }

  if (!data) {
    throw new Error('Could not load invitation code')
  }

  return data
}
