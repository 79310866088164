import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgCalendarFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <g clipPath="url(#clip0_221_464)">
        <path d="M21.5 3H18.75C18.6837 3 18.6201 2.97366 18.5732 2.92678C18.5263 2.87989 18.5 2.8163 18.5 2.75V1C18.5 0.734784 18.3946 0.48043 18.2071 0.292893C18.0196 0.105357 17.7652 0 17.5 0C17.2348 0 16.9804 0.105357 16.7929 0.292893C16.6054 0.48043 16.5 0.734784 16.5 1V5.75C16.5 5.94891 16.421 6.13968 16.2803 6.28033C16.1397 6.42098 15.9489 6.5 15.75 6.5C15.5511 6.5 15.3603 6.42098 15.2197 6.28033C15.079 6.13968 15 5.94891 15 5.75V3.5C15 3.36739 14.9473 3.24021 14.8536 3.14645C14.7598 3.05268 14.6326 3 14.5 3H8.25C8.1837 3 8.12011 2.97366 8.07322 2.92678C8.02634 2.87989 8 2.8163 8 2.75V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0C6.73478 0 6.48043 0.105357 6.29289 0.292893C6.10536 0.48043 6 0.734784 6 1V5.75C6 5.94891 5.92098 6.13968 5.78033 6.28033C5.63968 6.42098 5.44891 6.5 5.25 6.5C5.05109 6.5 4.86032 6.42098 4.71967 6.28033C4.57902 6.13968 4.5 5.94891 4.5 5.75V3.5C4.5 3.36739 4.44732 3.24021 4.35355 3.14645C4.25979 3.05268 4.13261 3 4 3H2.5C1.96957 3 1.46086 3.21071 1.08579 3.58579C0.710714 3.96086 0.5 4.46957 0.5 5V22C0.5 22.5304 0.710714 23.0391 1.08579 23.4142C1.46086 23.7893 1.96957 24 2.5 24H21.5C22.0304 24 22.5391 23.7893 22.9142 23.4142C23.2893 23.0391 23.5 22.5304 23.5 22V5C23.5 4.46957 23.2893 3.96086 22.9142 3.58579C22.5391 3.21071 22.0304 3 21.5 3ZM21 22H3C2.86739 22 2.74021 21.9473 2.64645 21.8536C2.55268 21.7598 2.5 21.6326 2.5 21.5V9.5C2.5 9.36739 2.55268 9.24021 2.64645 9.14645C2.74021 9.05268 2.86739 9 3 9H21C21.1326 9 21.2598 9.05268 21.3536 9.14645C21.4473 9.24021 21.5 9.36739 21.5 9.5V21.5C21.5 21.6326 21.4473 21.7598 21.3536 21.8536C21.2598 21.9473 21.1326 22 21 22Z" />
      </g>
      <defs>
        <clipPath id="clip0_221_464">
          <rect width={24} height={24} />
        </clipPath>
      </defs>
    </svg>
  )
}
const Memo = memo(SvgCalendarFill)
export default Memo
