import { de, enUS, fr } from 'date-fns/locale'

export const dateFnsLocales = {
  de,
  en: enUS,
  fr,
}

export function getDateFnsLocale(locale: string) {
  const [language] = locale.split('-')

  if (language in dateFnsLocales) return dateFnsLocales[language as keyof typeof dateFnsLocales]

  return dateFnsLocales.en
}
