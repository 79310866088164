export const COLOR = {
  orange: {
    light: '#FFD3A3',
    DEFAULT: '#F9C184',
    dark: '#DD8636',
  },

  salmon: {
    light: '#FFC1A9',
    DEFAULT: '#F4A586',
    dark: '#CF6136',
  },

  turquoise: {
    light: '#ACEAE6',
    DEFAULT: '#68C2C6',
    dark: '#2F8F94',
  },

  primary: {
    lightest: '#FFFCEA',
    lighter: '#FFF6C8',
    light: '#FFE89F',
    DEFAULT: '#FCDB70',
    dark: '#EFC53C',
    darker: '#CE9E2E',
  },

  danger: {
    lighter: '#ffefec',
    light: '#ffa6a6',
    DEFAULT: '#ff7575',
    dark: '#db4f58',
    darker: '#bc3d45',
  },

  neutral: {
    lightest: '#ffffff',
    lighter: '#F5F4F2',
    light: '#E7E5E2',
    DEFAULT: '#8B8986',
    dark: '#555352',
    darkest: '#24211F',
  },

  black: '#000000',
  white: '#ffffff',
}

export const SHADOW = {
  sm: `0px 1px 6px rgba(0, 0, 0, 0.06)`,
  base: `0px 0px 12px rgba(0, 0, 0, 0.04)`,
  lg: `0px 0px 16px rgba(0, 0, 0, 0.1)`,
}

export const BORDER_RADIUS = {
  xs: '8px',
  sm: '10px',
  base: '16px',
  full: '9999px',
}

export const FONT = {
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },

  size: {
    'xs': '11px',
    'sm': '14px',
    'base': '16px',
    'xl': '20px',
    '2xl': '24px',
  },

  lineHeight: {
    'xs': '14px',
    'sm': '17px',
    'base': '20px',
    'xl': '24px',
    '2xl': '30px',
  },

  family: {
    sans: "'Outfit', sans-serif",
    mono: "'JetBrains Mono', monospace",
  },
}

export const TRANSITION = {
  base: 'all 0.1s ease-out',
  longer: 'all 0.2s ease-out',
}

export const PADDING = {
  DEFAULT: '20px',
}
