import {
  AlertTriangle,
  Bell,
  Envelope,
  Picture,
  QuestionBubble,
  Sparkles,
  VideoClip,
  View,
  WavingHand,
} from '../icons'
import { ActivityAction } from '../types'

const ActivitiesInformation = {
  [ActivityAction.CREATE_PROJECT]: {
    icon: <Sparkles />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.created',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.created',
      translationMore: '',
    },
  },
  [ActivityAction.CREATE_ANSWER]: {
    icon: <VideoClip />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.addOneClip',
      translationMore: 'ProjectDashboard.Activities.You.addMoreClips',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.addOneClip',
      translationMore: 'ProjectDashboard.Activities.Someone.addMoreClips',
    },
  },
  [ActivityAction.CREATE_QUESTION]: {
    icon: <QuestionBubble />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.addOneQuestion',
      translationMore: 'ProjectDashboard.Activities.You.addMoreQuestions',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.addOneQuestion',
      translationMore: 'ProjectDashboard.Activities.Someone.addMoreQuestions',
    },
  },
  [ActivityAction.ACCEPT_INVITATION]: {
    icon: <WavingHand />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.joinedInvitation',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.joinedInvitation',
      translationMore: '',
    },
  },
  [ActivityAction.CREATE_INVITATION]: {
    icon: <Envelope />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.inviteOneEmail',
      translationMore: 'ProjectDashboard.Activities.You.inviteMoreEmails',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.inviteOneEmail',
      translationMore: 'ProjectDashboard.Activities.Someone.inviteMoreEmails',
    },
  },
  [ActivityAction.JOINED_PROJECT]: {
    icon: <Envelope />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.joinedCode',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.joinedCode',
      translationMore: '',
    },
  },
  [ActivityAction.PRODUCTION_QUEUED]: {
    icon: <Bell />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionQueued',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionQueued',
      translationMore: '',
    },
  },
  [ActivityAction.PRODUCTION_STARTED]: {
    icon: <Bell />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionStarted',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionStarted',
      translationMore: '',
    },
  },
  [ActivityAction.PRODUCTION_FINISHED]: {
    icon: <Sparkles />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionFinished',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionFinished',
      translationMore: '',
    },
  },
  [ActivityAction.PRODUCTION_FAILED]: {
    icon: <AlertTriangle />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionFailed',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.productionFailed',
      translationMore: '',
    },
  },
  [ActivityAction.RECEIVER_HAS_VIEWED]: {
    icon: <View />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.receiverHasViewed',
      translationMore: '',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.General.receiverHasViewed',
      translationMore: '',
    },
  },
  [ActivityAction.CREATE_ATTACHMENT]: {
    icon: <Picture />,
    youTranslations: {
      translationOne: 'ProjectDashboard.Activities.You.addOneAttachment',
      translationMore: 'ProjectDashboard.Activities.You.addMoreAttachments',
    },
    otherTranslations: {
      translationOne: 'ProjectDashboard.Activities.Someone.addOneAttachment',
      translationMore: 'ProjectDashboard.Activities.Someone.addMoreAttachments',
    },
  },
}

export function getActivityInformation(activity: ActivityAction) {
  if (!ActivitiesInformation[activity]) return null

  return { ...ActivitiesInformation[activity] }
}
