import { Capacitor } from '@capacitor/core'
import { useEffect } from 'react'

import {
  REACT_APP_IAP_ONE_CREDIT_ID,
  REACT_APP_IAP_SIX_CREDITS_ID,
  REACT_APP_IAP_TWO_CREDITS_ID,
} from './utils/env'

export default function IAPListeners() {
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    const isAndroid = Capacitor.getPlatform() === 'android'

    CdvPurchase.store.register({
      id: REACT_APP_IAP_ONE_CREDIT_ID,
      type: CdvPurchase.ProductType.CONSUMABLE,
      platform: isAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE,
    })

    CdvPurchase.store.register({
      id: REACT_APP_IAP_TWO_CREDITS_ID,
      type: CdvPurchase.ProductType.CONSUMABLE,
      platform: isAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE,
    })

    CdvPurchase.store.register({
      id: REACT_APP_IAP_SIX_CREDITS_ID,
      type: CdvPurchase.ProductType.CONSUMABLE,
      platform: isAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE,
    })

    void CdvPurchase.store.initialize()

    CdvPurchase.store.ready(() => {
      console.log('Cordova Purchase Plugin ready')
    })

    CdvPurchase.store
      .when()
      .approved((transaction) => void transaction.verify())
      .verified((receipt) => void receipt.finish())
  })

  return null
}
