import { useIonLoading } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'

import UserAvatar from '../../../../components/common/UserAvatar'
import Button from '../../../../components/ui/Button/Button'
import Dialog from '../../../../components/ui/Dialog'
import Flex from '../../../../components/ui/Flex'
import Text from '../../../../components/ui/Text'
import { useApiContext } from '../../../../context/ApiContext'
import { useToast } from '../../../../hooks/useToast'
import { getFullName } from '../../../../utils/user'

import type { UserDto } from '../../../../types'

interface Props {
  user: UserDto
  onUnblock: () => void
}

export default function BlockedUserItem(props: Props) {
  const { user, onUnblock } = props

  const { t } = useTranslation()
  const { apiClient } = useApiContext()

  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast, presentSuccessToast } = useToast()

  const {
    value: confirmDialogOpen,
    setTrue: openConfirmDialog,
    setFalse: closeConfirmDialog,
  } = useBoolean(false)

  const unblock = async () => {
    closeConfirmDialog()
    await presentLoading()

    try {
      await apiClient.unblockUser(user.id)
    } catch {
      await dismissLoading()
      await presentErrorToast(t('General.error'))
      return
    }

    onUnblock()
    await dismissLoading()
    await presentSuccessToast(t('Profile.BlockedUsers.Unblock.success'))
  }

  const fullName = getFullName(user)

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <UserAvatar user={user} />
          <Text>{fullName}</Text>
        </Flex>
        <Button
          size="small"
          variant="secondary"
          style={{ flexShrink: 0 }}
          onClick={openConfirmDialog}
        >
          {t('Profile.BlockedUsers.Unblock.title')}
        </Button>
      </Flex>
      <Dialog isOpen={confirmDialogOpen} onClose={closeConfirmDialog}>
        <Dialog.Title>
          {t('Profile.BlockedUsers.Unblock.Confirm.title', { user: fullName })}
        </Dialog.Title>
        <Dialog.Body>
          {t('Profile.BlockedUsers.Unblock.Confirm.body', { user: fullName })}
        </Dialog.Body>
        <Dialog.Buttons>
          <Button variant="primary" onClick={() => void unblock()}>
            {t('Profile.BlockedUsers.Unblock.Confirm.confirm')}
          </Button>
          <Button variant="secondary" onClick={closeConfirmDialog}>
            {t('Profile.BlockedUsers.Unblock.Confirm.cancel')}
          </Button>
        </Dialog.Buttons>
      </Dialog>
    </>
  )
}
