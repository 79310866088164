import { Preferences } from '@capacitor/preferences'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { css, cx } from '@emotion/css'
import { IonContent, IonIcon, useIonAlert, useIonLoading, useIonRouter } from '@ionic/react'
import { keyOutline, logOutOutline, trashBinOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../../Router'
import Page from '../../../../components/layout/Page'
import SubpageHeader from '../../../../components/layout/SubpageHeader'
import { ghostButtonVariantStyles } from '../../../../components/ui/Button/GhostButton'
import { useAuthContext } from '../../../../context/AuthContext'
import { ArrowCircleRight } from '../../../../icons'
import { COLOR } from '../../../../theme'
import { RowsWithDividingWrapper } from '../../../Project/Dashboard/ProjectDasboard.styled'
import { ProfileSectionItem } from '../Profile'

const DeleteDialogStyling = css({
  '.alert-head': {
    h2: {
      color: COLOR.danger.DEFAULT,
    },
  },
})

export default function AccountSettings() {
  const { t } = useTranslation()
  const [present, dismiss] = useIonLoading()
  const ionRouter = useIonRouter()
  const { user, removeUser, userIsGuest } = useAuthContext()
  const [presentDialog, dismissDialog] = useIonAlert()

  async function logout() {
    await Preferences.remove({ key: 'appConfig' })
    await Preferences.remove({ key: 'user' }).then(() => {
      void GoogleAuth.signOut()
      ionRouter.push(ROUTES.start, 'none', 'replace')
      window.location.reload()
    })
  }

  function deleteAccount() {
    void presentDialog({
      header: t('Profile.AccountSettings.DeleteAccount.title'),
      message: t('Profile.AccountSettings.DeleteAccount.description'),
      cssClass: cx(DeleteDialogStyling),

      buttons: [
        {
          text: t('General.back'),
          role: 'cancel',
          cssClass: cx(ghostButtonVariantStyles.secondary),
          handler: () => {
            void dismissDialog()
          },
        },
        {
          text: t('Profile.AccountSettings.DeleteAccount.deleteAccount'),
          role: 'destructive',
          cssClass: cx(ghostButtonVariantStyles.danger),
          handler: () => {
            void dismissDialog()
            void present(t('Profile.AccountSettings.DeleteAccount.deleteAccountButtonLoading'))
            if (user?.id) {
              removeUser(user.id)
                .then(() => {
                  void dismiss()
                  void logout()
                })
                .catch(() => {
                  void dismiss()
                })
            }
          },
        },
      ],
    })
  }

  if (!user) {
    ionRouter.push('/login', 'none')
    return null
  } else {
    return (
      <Page>
        <SubpageHeader
          title={t('Profile.AccountSettings.title')}
          showBackButton={true}
          backButtonRoute={ROUTES.profile}
        />
        <IonContent className="ion-padding">
          <RowsWithDividingWrapper topBorder={true}>
            {!user.isRegisteredWithGoogle && !user.isRegisteredWithApple && !userIsGuest && (
              <ProfileSectionItem to={ROUTES.changePassword}>
                <IonIcon icon={keyOutline} style={{ width: 20, height: 20 }} />
                <span>{t('Profile.AccountSettings.ChangePassword.title')}</span>
                <ArrowCircleRight />
              </ProfileSectionItem>
            )}
            <ProfileSectionItem to={'#'} onClick={() => void logout()}>
              <IonIcon icon={logOutOutline} style={{ width: 20, height: 20 }} />
              <span>{t('Profile.AccountSettings.logout')}</span>
              <ArrowCircleRight />
            </ProfileSectionItem>
            <ProfileSectionItem to={'#'} danger={true} onClick={deleteAccount}>
              <IonIcon icon={trashBinOutline} style={{ width: 20, height: 20 }} />
              <span>{t('Profile.AccountSettings.DeleteAccount.title')}</span>
              <ArrowCircleRight />
            </ProfileSectionItem>
          </RowsWithDividingWrapper>
        </IonContent>
      </Page>
    )
  }
}
