import { IonRouterOutlet, useIonRouter } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import AppUrlListener from './AppUrlListener'
import ViewProjectInvite from './components/projects/ViewProjectInvite'
import { useAuthContext } from './context/AuthContext'
import useMarkerio from './markerio'
import { OneSignalIntegration } from './plugins/OneSignalIntegration'
import AppStart from './views/AppStart'
import Auth from './views/Auth/Auth'
import GuestLogin from './views/Auth/GuestLogin'
import Login from './views/Auth/Login'
import PasswordForget from './views/Auth/PasswordForget'
import Register from './views/Auth/Register'
import DownloadLinks from './views/DownloadLinks'
import NewProjectWizard from './views/NewProject/NewProjectWizard/NewProjectWizard'
import ProjectClips from './views/Project/Clips/ProjectClips'
import CollaboratorsList from './views/Project/Collaborators/Collaborators'
import InviteToProject from './views/Project/Collaborators/InviteToProject'
import ProjectDashboard from './views/Project/Dashboard/ProjectDashboard'
import EditProject from './views/Project/EditProject'
import InstantJoin from './views/Project/InstantJoin'
import JoinProject from './views/Project/JoinProject'
import ManageQuestions from './views/Project/Questions/ManageQuestions'
import ProjectStudio from './views/Project/Studio/ProjectStudio'
import RecordAnswer from './views/Project/Studio/RecordAnswer'
import SingleBlogPost from './views/Tabs/Dashboard/BlogPosts/SingleBlogPost'
import MainTabs from './views/Tabs/MainTabs'
import AccountSettings from './views/Tabs/Profile/AccountSettings/AccountSettings'
import ChangeAvatar from './views/Tabs/Profile/AccountSettings/ChangeAvatar'
import ChangePassword from './views/Tabs/Profile/AccountSettings/ChangePassword'
import ProfileSettings from './views/Tabs/Profile/AccountSettings/ProfileSettings'
import AppSettings from './views/Tabs/Profile/AppSettings/AppSettings'
import LanguageSettings from './views/Tabs/Profile/AppSettings/LanguageSettings'
import BlockedUsers from './views/Tabs/Profile/BlockedUsers/BlockedUsers'
import AppStoreRedirect from './views/redirects/AppStoreRedirect'
import PlayStoreRedirect from './views/redirects/PlayStoreRedirect'

import type { RouteProps } from 'react-router-dom'

export const ROUTES = {
  root: '/',
  start: '/start',
  login: '/login',
  guestLogin: '/guest-login',
  auth: '/auth',
  register: '/register',
  passwordReset: '/password-reset',
  tabs: '/tabs',
  dashboard: '/tabs/dashboard',
  projects: '/tabs/projects',
  events: '/tabs/events',
  profile: '/tabs/profile',
  profileSettings: '/profile/settings/profile',
  appSettings: '/profile/settings/app',
  languageSettings: '/profile/settings/app/language',
  accountSettings: '/profile/settings/account',
  changePassword: '/profile/settings/account/change-password',
  changeAvatar: '/profile/settings/account/change-avatar',
  notifications: '/notifications',
  createProject: '/create-project',
  joinProject: '/join-project',
  instantJoin: '/instant-join',
  shop: '/tabs/shop',
  blockedUsers: '/profile/settings/blocked',
  downloadsLinks: '/downloads-links',
  openAndroidApp: '/open-android-app',
  openIosApp: '/open-ios-app',

  projectDashboard: (id = ':projectId') => `/projects/${id}`,
  editProject: (id = ':id') => `/projects/${id}/edit`,
  manageQuestions: (id = ':id') => `/projects/${id}/manage-questions`,
  inviteToProject: (id = ':id') => `/projects/${id}/invite`,
  collaboratorsList: (id = ':id') => `/projects/${id}/collaboratorsList`,
  singleBlogPost: (id = ':id') => `/blog/${id}`,
  projectClips: (id = ':id') => `/projects/${id}/clips`,
  projectRecordingStudio: (id = ':id') => `/projects/${id}/studio`,
  projectRecordAnswer: (projectId = ':projectId', questionId = ':questionId') =>
    `/projects/${projectId}/studio/${questionId}`,
  viewProjectInvite: (id = ':id') => `/invites/${id}`,
}

function PrivateRoute(props: RouteProps) {
  const { user, redirectToAfterLogin, setRedirectToAfterLogin } = useAuthContext()

  const route = useIonRouter()

  useEffect(() => {
    if (!user && typeof props.path === 'string' && !redirectToAfterLogin.current) {
      setRedirectToAfterLogin({ path: props.path, search: route.routeInfo.search })
    }
  }, [props.path, redirectToAfterLogin.current, route.routeInfo.search])

  if (user) return <Route {...props} />

  return <Redirect to={ROUTES.start} />
}

function PublicRoute(props: RouteProps) {
  const { user } = useAuthContext()

  if (user) return <Redirect to={ROUTES.dashboard} />

  return <Route {...props} />
}

export default function Router() {
  const { authReady, user } = useAuthContext()
  void useMarkerio(user)

  useEffect(() => {
    if (authReady && user) {
      void OneSignalIntegration.syncUser({ externalId: user.id })
    }
  }, [authReady, user])

  if (!authReady) return null

  return (
    <IonReactRouter>
      <AppUrlListener />
      <IonRouterOutlet onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
        <Route exact path={ROUTES.openAndroidApp} render={() => <PlayStoreRedirect />} />
        <Route exact path={ROUTES.openIosApp} render={() => <AppStoreRedirect />} />
        <PrivateRoute path={ROUTES.tabs} render={() => <MainTabs />} />
        <PrivateRoute exact path={ROUTES.singleBlogPost()} component={SingleBlogPost} />
        <PrivateRoute exact path={ROUTES.viewProjectInvite()} component={ViewProjectInvite} />
        <PrivateRoute
          exact
          path="/projects/instant-join"
          render={() => <Redirect to={ROUTES.instantJoin} />}
        />
        <PrivateRoute exact path={ROUTES.projectDashboard()} component={ProjectDashboard} />
        <PrivateRoute exact path={ROUTES.editProject()} component={EditProject} />
        <PrivateRoute exact path={ROUTES.manageQuestions()} render={() => <ManageQuestions />} />
        <PrivateRoute exact path={ROUTES.inviteToProject()} render={() => <InviteToProject />} />
        <PrivateRoute
          exact
          path={ROUTES.collaboratorsList()}
          render={() => <CollaboratorsList />}
        />
        <PrivateRoute exact path={ROUTES.createProject} render={() => <NewProjectWizard />} />
        <PrivateRoute exact path={ROUTES.joinProject} render={() => <JoinProject />} />
        <PrivateRoute exact path={ROUTES.instantJoin} render={() => <InstantJoin />} />
        <PrivateRoute exact path={ROUTES.projectClips()} component={ProjectClips} />
        <PrivateRoute exact path={ROUTES.projectRecordingStudio()} component={ProjectStudio} />
        <PrivateRoute exact path={ROUTES.projectRecordAnswer()} component={RecordAnswer} />
        <PrivateRoute exact path={ROUTES.appSettings} component={AppSettings} />
        <PrivateRoute exact path={ROUTES.languageSettings} component={LanguageSettings} />
        <PrivateRoute exact path={ROUTES.profileSettings} component={ProfileSettings} />
        <PrivateRoute exact path={ROUTES.accountSettings} component={AccountSettings} />
        <PrivateRoute exact path={ROUTES.changePassword} component={ChangePassword} />
        <PrivateRoute exact path={ROUTES.changeAvatar} component={ChangeAvatar} />
        <PrivateRoute exact path={ROUTES.blockedUsers} component={BlockedUsers} />
        <PrivateRoute exact path={ROUTES.root} render={() => <Redirect to={ROUTES.dashboard} />} />
        <Route exact path={ROUTES.downloadsLinks} render={() => <DownloadLinks />} />
        <PublicRoute render={() => <Redirect to={ROUTES.dashboard} />} />
        <PublicRoute exact path={ROUTES.auth} render={() => <Auth />} />
        <PublicRoute exact path={ROUTES.login} render={() => <Login />} />
        <PublicRoute exact path={ROUTES.register} render={() => <Register />} />
        <PublicRoute exact path={ROUTES.guestLogin} render={() => <GuestLogin />} />
        <PublicRoute exact path={ROUTES.passwordReset} render={() => <PasswordForget />} />
        <Route exact path={ROUTES.start} render={() => <AppStart />} />
        <PublicRoute render={() => <Redirect to={ROUTES.root} />} />
      </IonRouterOutlet>
    </IonReactRouter>
  )
}
