import { useIonLoading } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { useApiContext } from '../../context/ApiContext'
import { useToast } from '../../hooks/useToast'
import { COLOR, FONT } from '../../theme'
import { getFullName } from '../../utils/user'
import ActionSheetModal from '../ui/ActionSheetModal'
import Button from '../ui/Button/Button'
import { ButtonGroup } from '../ui/Button/ButtonGroup'
import Flex from '../ui/Flex'
import Text from '../ui/Text'

import type { UserDto } from '../../types'

interface Props {
  user: UserDto
  isOpen: boolean
  onClose: () => void
  onBlocked?: () => void
}

export default function BlockUserSheet(props: Props) {
  const { user, isOpen, onClose, onBlocked } = props

  const { t } = useTranslation()
  const { apiClient } = useApiContext()

  const [presentLoading, dismissLoading] = useIonLoading()
  const { presentErrorToast, presentSuccessToast } = useToast()

  const block = async () => {
    await presentLoading()

    try {
      await apiClient.blockUser(user.id)
      onBlocked?.()
    } catch {
      await dismissLoading()
      await presentErrorToast(t('General.error'))

      return
    }

    await dismissLoading()

    if (onBlocked) onBlocked()
    else await presentSuccessToast(t('Block.success'))

    onClose()
  }

  const fullName = getFullName(user)

  return (
    <ActionSheetModal isOpen={isOpen} onClose={onClose}>
      <Flex direction="column" gap={24}>
        <Flex direction="column">
          <Text size={FONT.size.xl} weight={FONT.weight.semibold} style={{ textAlign: 'center' }}>
            {t('Block.Dialog.title', { user: fullName })}
          </Text>
          <Text color={COLOR.neutral.dark}>{t('Block.Dialog.text', { user: fullName })}</Text>
        </Flex>
        <ButtonGroup>
          <Button onClick={() => void block()}>{t('Block.block')}</Button>
          <Button variant="secondary" onClick={onClose}>
            {t('Block.cancel')}
          </Button>
        </ButtonGroup>
      </Flex>
    </ActionSheetModal>
  )
}
