import styled from '@emotion/styled'
import { IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import { useEffect, useRef, useState } from 'react'
import { Autoplay, EffectFade } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import 'swiper/css/effect-fade'

import Logo from '../../../components/Logo'
import {
  ModalImage,
  SwiperStyled,
} from '../../../components/projects/attachments/ViewAttachmentsModal'
import Flex from '../../../components/ui/Flex'
import IconButton from '../../../components/ui/IconButton/IconButton'
import Text from '../../../components/ui/Text'
import PausableVideo from '../../../components/video/PausableVideo'
import { CloseCircle } from '../../../icons'
import { COLOR } from '../../../theme'
import { alpha } from '../../../utils/colors'
import { parseReceiverName } from '../../../utils/textParsing'

import type {
  ProjectAnswerDto,
  ProjectAttachmentDto,
  ProjectDto,
  ProjectQuestionDto,
} from '../../../types'

export interface VideoPreviewProps {
  questions: ProjectQuestionDto[]
  onDismiss: () => void
  project: ProjectDto
  attachments: ProjectAttachmentDto[]
}

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 9 / 16;
`

const WatermarkOverlay = styled.div`
  pointer-events: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: ${alpha(COLOR.white, 0.05)};
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 200;
`

const Watermark = styled(Logo)`
  rotate: -24deg;
`

const ToolbarStyled = styled(IonToolbar)`
  --background: ${COLOR.black};
  --color: ${COLOR.white};
`

const ContentStyled = styled(IonContent)`
  --background: ${COLOR.black};
  --color: ${COLOR.white};
`

const SingleVideoWrapperStyled = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute')};
  inset: 0;
  width: 100%;
  aspect-ratio: 9 / 16;
`

export interface AnswerWithQuestionTitle {
  answer: ProjectAnswerDto
  questionTitle: string
}

export default function VideoPreview(props: VideoPreviewProps) {
  const { attachments = [], questions, onDismiss, project } = props

  const answers = questions
    .map((question) =>
      question.answers
        ?.filter((answer) => !answer.isExcluded)
        .map((answer) => {
          return {
            answer,
            questionTitle: parseReceiverName(question.title, project.receiverName),
          }
        }),
    )
    .flat()
    .filter(Boolean) as AnswerWithQuestionTitle[]

  const [currentItem, setCurrentItem] = useState(0)

  const hasAttachments = attachments.length > 0

  const attachmentsVisible = currentItem >= answers.length

  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (audioRef.current) audioRef.current.volume = 0.03
  }, [])

  const dismiss = () => {
    audioRef.current?.pause()
    onDismiss()
  }

  return (
    <>
      <IonHeader>
        <ToolbarStyled>
          <IonTitle>{project.title}</IonTitle>
          <IonButtons slot="end">
            <IconButton icon={<CloseCircle />} title="" onClick={dismiss} onDark={true} />
          </IonButtons>
        </ToolbarStyled>
      </IonHeader>
      <ContentStyled className="ion-padding" scrollY={false}>
        <Flex style={{ height: '100%' }} direction="column" alignItems="center" gap={20}>
          {!attachmentsVisible && <Text>{answers[currentItem]?.questionTitle}</Text>}
          <VideoWrapper>
            {!attachmentsVisible &&
              answers.map(({ answer }, index) => (
                <SingleVideoWrapperStyled key={answer.id} isVisible={currentItem === index}>
                  {
                    // render only current and next video
                    [currentItem, currentItem + 1].includes(index) && (
                      <PausableVideo
                        videoId={answer.id}
                        video={answer.video}
                        autoPlay={index === currentItem}
                        loop={false}
                        showProgress={false}
                        onEnded={() => {
                          setCurrentItem(index + 1)

                          if (index < answers.length - 1) {
                            const video = document.getElementById(
                              answers[index + 1].answer.id,
                            ) as HTMLVideoElement
                            void video.play()
                          } else if (!hasAttachments) {
                            dismiss()
                          } else if (audioRef.current) {
                            audioRef.current.volume = 0.2
                          }
                        }}
                      />
                    )
                  }
                </SingleVideoWrapperStyled>
              ))}
            {hasAttachments && attachmentsVisible && (
              <SwiperStyled
                modules={[Autoplay, EffectFade]}
                effect={'fade'}
                autoplay={{ delay: 4000, stopOnLastSlide: true }}
                allowTouchMove={false}
                loop={false}
                onReachEnd={() => {
                  setTimeout(() => {
                    dismiss()
                  }, 4000)
                }}
              >
                {attachments.map((attachment) => (
                  <SwiperSlide key={attachment.id}>
                    <ModalImage src={attachment.media.sourceUrl} />
                  </SwiperSlide>
                ))}
              </SwiperStyled>
            )}
            <WatermarkOverlay>
              <Watermark height={80} />
            </WatermarkOverlay>
          </VideoWrapper>
        </Flex>
      </ContentStyled>
      {!!project.musicTrack && (
        <audio ref={audioRef} autoPlay loop src={project.musicTrack.sourceUrl} />
      )}
    </>
  )
}
