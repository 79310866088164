import { useIonModal, useIonRouter } from '@ionic/react'

import { ROUTES } from '../Router'
import NewProject from '../views/NewProject/NewProject'

export function useNewProjectModal() {
  const ionRouter = useIonRouter()

  const [present, dismiss] = useIonModal(NewProject, {
    onDismiss: () => dismiss(),
    onSelectCreate: () => {
      dismiss()
      ionRouter.push(ROUTES.createProject)
    },
    onSelectJoin: () => {
      dismiss()
      ionRouter.push(ROUTES.joinProject)
    },
  })

  return [present, dismiss]
}
