import { css } from '@emotion/css'

import type { ReactNode } from 'react'

interface GridProps {
  children: ReactNode
  columns?: number
  gap?: number
  as?: 'div' | 'ul' | 'ol'
}

function gridStyles(columns: number, gap: number) {
  return css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-gap: ${gap}px;
    padding: 0;
    margin: 0;
    list-style: none;
  `
}

export default function Grid(props: GridProps) {
  const { children, columns = 2, as: Tag = 'div', gap = 12 } = props

  return <Tag className={gridStyles(columns, gap)}>{children}</Tag>
}
