import { addMinutes, formatDistanceToNow, formatRelative } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ROUTES } from '../../../Router'
import { UnStyledLink } from '../../../components/common/UnStyledLink'
import UserAvatar from '../../../components/common/UserAvatar'
import Flex from '../../../components/ui/Flex'
import { MessageCardIconStyled } from '../../../components/ui/MessageCard'
import Text from '../../../components/ui/Text'
import { useAuthContext } from '../../../context/AuthContext'
import { useCollaborators } from '../../../hooks/useCollaborators'
import { ArrowCircleRight, CalendarFill, UsersCircle } from '../../../icons'
import { COLOR, FONT } from '../../../theme'
import { CollaboratorStatus } from '../../../types'
import { getDateFnsLocale } from '../../../utils/date'
import { getFullName } from '../../../utils/user'

import ProjectDashboardItem from './ProjectDashboardItem'

import type { ProjectDto } from '../../../types'

interface Props {
  project: ProjectDto
  isCreator: boolean
}

export default function GeneralInfo(props: Props) {
  const { project, isCreator } = props

  const { t, i18n } = useTranslation()

  const { user } = useAuthContext()

  const locale = getDateFnsLocale(i18n.language)

  const dateWithoutTimeZone = addMinutes(new Date(project.dueAt), new Date().getTimezoneOffset())

  const formattedDeadline = formatRelative(dateWithoutTimeZone, new Date(), { locale })
  const formattedDistance = formatDistanceToNow(dateWithoutTimeZone, { locale })

  const collaborators = project.collaborators.filter(
    (collaborator) => collaborator.status === CollaboratorStatus.Active,
  )

  const { numberOfCollaborators } = useCollaborators(project)

  return (
    <ProjectDashboardItem title={t('ProjectDashboard.General.title')}>
      {project.collaborators?.length > 0 && (
        <Flex alignItems="center">
          <UserAvatar user={project.creator} size="sm" />
          <Flex direction="column" gap={2}>
            <Text weight={FONT.weight.medium}>
              {getFullName(project.creator, user, t)}
              {collaborators.length > 1 && (
                <>
                  {' '}
                  <Text weight={FONT.weight.normal} tag="span" color={COLOR.neutral.DEFAULT}>
                    {t('ProjectDashboard.General.andOthers', { count: collaborators.length })}
                  </Text>
                </>
              )}
            </Text>
            <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.DEFAULT}>
              {t('ProjectDashboard.General.creator')}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex alignItems="flex-start">
        <MessageCardIconStyled>
          <CalendarFill />
        </MessageCardIconStyled>
        <Flex direction="column" gap={2}>
          <Text weight={FONT.weight.medium}>
            {t('ProjectDashboard.General.dueAt', { time: formattedDeadline })}
          </Text>
          <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.DEFAULT}>
            {t('ProjectDashboard.General.timeLeft', { time: formattedDistance })}
          </Text>
        </Flex>
      </Flex>
      {isCreator && (
        <Flex alignItems="center" gap={12} justifyContent="space-between">
          <Flex alignItems="flex-start">
            <MessageCardIconStyled>
              <UsersCircle />
            </MessageCardIconStyled>
            <Flex direction="column" gap={2}>
              <Text weight={FONT.weight.medium}>{t('ProjectDashboard.General.collaborators')}</Text>
              <Text size={FONT.size.sm} weight={FONT.weight.medium} color={COLOR.neutral.DEFAULT}>
                {t('ProjectDashboard.General.collaboratorCount', { count: numberOfCollaborators })}
              </Text>
            </Flex>
          </Flex>
          <UnStyledLink to={ROUTES.collaboratorsList(project.id)}>
            <ArrowCircleRight />
          </UnStyledLink>
        </Flex>
      )}
    </ProjectDashboardItem>
  )
}
