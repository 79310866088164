import { memo } from 'react'

import { COLOR } from '../theme'

import type { SVGProps } from 'react'

function SvgBlocked(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      fill="none"
      viewBox="0 0 100 100"
      role="img"
      className="illustration"
      {...props}
    >
      <path
        d="M76.462,75.408c0-1.1-11.661-2-26.046-2s-26.046.895-26.046,2,11.661,2,26.046,2S76.462,76.511,76.462,75.408Z"
        fill={COLOR.primary.DEFAULT}
      />
      <path
        d="M68.331,19,75.3,74.786l-5.788-.217-1.788-5.363a2.315,2.315,0,0,0-2.2-1.583H51.505a2.315,2.315,0,0,0-2.2,1.583l-1.788,5.363H40.575L35.945,19h5.788l.9,7.233a2.316,2.316,0,0,0,2.3,2.028h16.17a2.316,2.316,0,0,0,2.3-2.6L62.57,19Z"
        fill={COLOR.primary.DEFAULT}
      />
      <path
        d="M42.637,26.236,41.733,19H35.945l.772,9.261h8.218A2.316,2.316,0,0,1,42.637,26.236Z"
        fill={COLOR.primary.DEFAULT}
      />
      <path
        d="M68.331,19,75.3,74.786l-5.788-.217-1.788-5.363a2.315,2.315,0,0,0-2.2-1.583H51.505a2.315,2.315,0,0,0-2.2,1.583l-1.788,5.363H40.575L35.945,19h5.788l.9,7.233a2.316,2.316,0,0,0,2.3,2.028h16.17a2.316,2.316,0,0,0,2.3-2.6L62.57,19Z"
        fill="none"
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.331,14.372a1.157,1.157,0,0,0-1.157-1.157H34.787a1.157,1.157,0,0,0-1.157,1.157V19h34.7Z"
        fill={COLOR.white}
      />
      <path
        d="M68.331,14.372a1.157,1.157,0,0,0-1.157-1.157H34.787a1.157,1.157,0,0,0-1.157,1.157V19h34.7Z"
        fill="none"
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.255,19l-.9,7.233a2.316,2.316,0,0,1-2.3,2.028H40.883a2.316,2.316,0,0,1-2.3-2.6L39.418,19H33.63L26.684,74.569h5.788l1.788-5.363a2.315,2.315,0,0,1,2.2-1.583H50.483a2.315,2.315,0,0,1,2.2,1.583l1.788,5.363h6.946L66.043,19Z"
        fill={COLOR.white}
      />
      <path
        d="M60.255,19l-.9,7.233a2.316,2.316,0,0,1-2.3,2.028H40.883a2.316,2.316,0,0,1-2.3-2.6L39.418,19H33.63L26.684,74.569h5.788l1.788-5.363a2.315,2.315,0,0,1,2.2-1.583H50.483a2.315,2.315,0,0,1,2.2,1.583l1.788,5.363h6.946L66.043,19Z"
        fill="none"
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.653,37.428A11.065,11.065,0,1,0,57.717,48.493,11.064,11.064,0,0,0,46.653,37.428Zm0,4.149a6.847,6.847,0,0,1,3.175.806l-9.284,9.285a6.835,6.835,0,0,1-.806-3.175A6.915,6.915,0,0,1,46.653,41.577Zm0,13.831a6.841,6.841,0,0,1-3.175-.806l9.284-9.285a6.836,6.836,0,0,1,.806,3.176A6.915,6.915,0,0,1,46.653,55.408Z"
        fill={COLOR.primary.DEFAULT}
        stroke={COLOR.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const Memo = memo(SvgBlocked)
export default Memo
