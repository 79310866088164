import { memo } from 'react'

import type { SVGProps } from 'react'

function SvgEnvelope(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="currentColor"
      className="icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M2.25 3.75C1.00736 3.75 0 4.75736 0 6V18C0 19.2426 1.00736 20.25 2.25 20.25H21.75C22.9926 20.25 24 19.2426 24 18V6C24 4.75736 22.9926 3.75 21.75 3.75H2.25ZM1.5 6.18706V18C1.5 18.4142 1.83579 18.75 2.25 18.75H21.75C22.1642 18.75 22.5 18.4142 22.5 18V6.18706L16.8837 10.0599L20.0091 12.9493C20.3133 13.2304 20.3319 13.7049 20.0507 14.0091C19.7696 14.3133 19.295 14.3319 18.9909 14.0507L15.6181 10.9327L13.7931 12.1912C13.2661 12.555 12.6404 12.75 12 12.75C11.3596 12.75 10.7343 12.5552 10.2072 12.1914L8.38189 10.9327L5.00913 14.0507C4.70497 14.3319 4.23046 14.3133 3.94928 14.0091C3.6681 13.7049 3.68673 13.2304 3.99088 12.9493L7.11626 10.0599L1.5 6.18706ZM21.2166 5.25H2.7834L11.0588 10.9566C11.3354 11.1475 11.6639 11.25 12 11.25C12.3361 11.25 12.6643 11.1478 12.9409 10.9568L21.2166 5.25Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
const Memo = memo(SvgEnvelope)
export default Memo
