import styled from '@emotion/styled'

import { UnStyledLink } from '../../../components/common/UnStyledLink'
import Flex from '../../../components/ui/Flex'
import Text from '../../../components/ui/Text'
import { ArrowCircleRight } from '../../../icons'
import { BORDER_RADIUS, COLOR, FONT, SHADOW, TRANSITION } from '../../../theme'

import type { ReactNode } from 'react'

interface Props {
  title: string
  icon: ReactNode
  color: string
  link?: string
  disabled?: boolean
  emphasized?: boolean
  customClass?: string
  isLoading?: boolean
  click?: () => void
}

const StatusItemStyled = styled(UnStyledLink)<{
  disabled: boolean
  emphasized: boolean
  color: string
}>`
  background: ${COLOR.white};
  border-radius: ${BORDER_RADIUS.base};
  border: 1px solid ${COLOR.neutral.lighter};
  box-shadow: ${SHADOW.base};
  padding: 16px;
  transition: ${TRANSITION.base};
  outline: ${({ emphasized, color }) => (emphasized ? `4px solid ${color}` : 'none')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  :active {
    box-shadow: ${SHADOW.lg};
    transform: scale(0.975);
  }
`

const StatusItemIconStyled = styled.div<{ color: string }>`
  flex-shrink: 0;
  border-radius: ${BORDER_RADIUS.base};
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  svg {
    height: 20px;
    width: 20px;
  }
`

export default function StatusItem(props: Props) {
  const {
    title,
    icon,
    color,
    link,
    disabled = false,
    emphasized = false,
    customClass = '',
    click,
    isLoading = false,
  } = props

  return (
    <StatusItemStyled
      to={link ?? '#'}
      onClick={click}
      disabled={disabled || isLoading}
      emphasized={emphasized}
      color={color}
      className={customClass}
    >
      <Flex direction="column" gap={12}>
        <Flex alignItems="flex-start" gap={12} justifyContent="space-between">
          <StatusItemIconStyled color={color}>{icon}</StatusItemIconStyled>
          <ArrowCircleRight color={COLOR.neutral.DEFAULT} />
        </Flex>
        <Text weight={FONT.weight.semibold}>{title}</Text>
      </Flex>
    </StatusItemStyled>
  )
}
